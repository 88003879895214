import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { ProductAction, productActions } from './product.actions';
import { ProductApiService } from './product.api.service';

@Injectable()
export class ProductEffects {
  constructor(
    private readonly productApiService: ProductApiService,
    private readonly action$: Actions<ProductAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    productActions.getById,
    this.productApiService.getById
  );
  add = createApiCallEffects(
    this,
    this.action$,
    productActions.add,
    this.productApiService.add
  );
  update = createApiCallEffects(
    this,
    this.action$,
    productActions.update,
    this.productApiService.update
  );
  createInstance = createApiCallEffects(
    this,
    this.action$,
    productActions.createInstance,
    this.productApiService.createInstance
  );
}
