import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { BankApiService } from "./bank.api.service";
import { BankEffects } from "./bank.effects";
import { IBankState, bankReducer, bankInitialState } from "./bank.reducer";
import { BankService } from "./bank.service";
import { bankFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IBankState>(bankFeatureKey, bankReducer,{
            initialState: bankInitialState,
        }),
        EffectsModule.forFeature([BankEffects])
    ],
    providers:[BankApiService, BankService]
})
export class StoreBankModule{}