<ng-container >
  <div class="card-list p-3">
    <div class="card-col" >
      <div class="card data-card h-100">

      </div>
    </div>
  </div>
</ng-container>



