import { Component } from '@angular/core';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { ViewService } from '../../store/view/views.service';
import { Observable } from 'rxjs';
import { View, ViewType } from '../../store/view/types';

@Component({
  selector: 'app-list-subheader',
  templateUrl: './list-subheader.component.html',
  styleUrls: ['./list-subheader.component.scss'],
})
export class ListSubheaderComponent {
  view$: Observable<View>;
  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
  }

  changeViewType(viewType: string) {
    this.viewService.setViewType(viewType as ViewType);
  }
  triggerAction(actionName: string) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
