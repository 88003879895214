import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DocumentTypeApiService } from './document-type.api.service';
import { DocumentTypeEffects } from './document-type.effects';
import {
  documentTypeReducer,
  documentTypeInitialState,
  IDocumentTypeState,
} from './document-type.reducer';
import { DocumentTypeService } from './document-type.service';
import { documentTypeFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IDocumentTypeState>(
      documentTypeFeatureKey,
      documentTypeReducer,
      {
        initialState: documentTypeInitialState,
      },
    ),
    EffectsModule.forFeature([DocumentTypeEffects]),
  ],
  providers: [DocumentTypeApiService, DocumentTypeService],
})
export class StoreDocumentTypeModule {}
