import { ProductCoverageModel } from '../product-coverage/types';

export const productFeatureKey = 'product';

export type ProductModel = {
  id: number;
  name: string;
  productLineId: number;
  productLineName: string;
  productLineInsuranceCompanyContactName: string;
  productLineProductCategoryName: string;
  startDate: Date;
  endDate: Date;
  taxRate: number;
  productCategoryId: number;
  productCoverages: ProductCoverageModel[];
  statusColor: string;
  isActiveLoc: string;
  notes: string;
};

export type ProductListItemModel = {
  name: string;
  productCategoryName: string;
  productLineName: string;
  productLineInsuranceCompanyContactName: string;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
};
