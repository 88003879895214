import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ProductCreateComponent } from './product-create/product-create.component';
import { ProductDetailsCoverageComponent } from './product-details/product-details-tab/product-details-coverage/product-details-coverage.component';
import { ProductDetailsNotesComponent } from './product-details/product-details-tab/product-details-notes/product-details-notes.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductTabsDetailsComponent } from './product-details/product-tabs-details/product-tabs-details.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProductRoutingModule } from './product-routing.module';

@NgModule({
  declarations: [
    ProductDetailsComponent,
    ProductDetailsCoverageComponent,
    ProductTabsDetailsComponent,
    ProductDetailsNotesComponent,
    ProductCreateComponent,
    ProductEditComponent,
  ],
  imports: [CommonModule, ProductRoutingModule, SharedModule],
})
export class ProductModule {}
