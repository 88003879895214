import { createApiCallActions } from '../../../common/store/generic.actions';
import { ProductCoverageModel } from './types';

export const productCoverageActions = {
  getById: createApiCallActions<ProductCoverageModel>(
    'productCoverage',
    'getById'
  ),
  getByProductId: createApiCallActions<ProductCoverageModel[]>(
    'productCoverage',
    'getByProductId'
  ),
};

export type ProductCoverageAction = typeof productCoverageActions;
