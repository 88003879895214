import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { JobService } from 'src/app/shared/store/job/job.service';
import { Job } from 'src/app/shared/store/job/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-job-edit',
  templateUrl: './job-edit.component.html',
  styleUrls: ['./job-edit.component.scss'],
})
export class JobEditComponent implements OnInit, OnDestroy {
  job$: Observable<Job>;
  jobForm: FormGroup;
  view$: Observable<View>;

  constructor(
    private readonly jobService: JobService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService
  ) {
    this.job$ = this.jobService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'jobUpdate') this.onSubmit();
        if (x == 'jobDetails') {
          this.formService.clear();
          this.router.navigate([
            'HumanResources/Jobs/Details/',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.jobService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.jobForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(80),
      ]),
    });

    this.job$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@jobEdit', x.name);
        this.jobForm.patchValue({
          name: x.name,
        });
      });
  }

  onSubmit(): void {
    if (this.jobForm.invalid) {
      this.jobForm.markAllAsTouched();
      this.formService.countErrors(this.jobForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.jobService.updateJob.call({
      id: this.route.snapshot.params['id'],
      name: this.jobForm.value.name!,
    });
    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.router.navigate(['/HumanResources/Jobs/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.jobService.updateJob.reset();
    this.breadcrumbService.set('@jobEdit', ' ');
  }
}
