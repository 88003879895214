import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { NavigationAction, navigationActions } from './navigation.actions';
import { NavigationApiService } from './navigation.api.services';
import { INavigationState } from './navigation.reducer';

@Injectable()
export class NavigationEffects {
  constructor(
    private readonly store: Store<INavigationState>,
    private readonly navigationApiService: NavigationApiService,
    private readonly actions$: Actions<NavigationAction>,
  ) {}

  getModules = createApiCallEffects(
    this,
    this.actions$,
    navigationActions.getModules,
    this.navigationApiService.getModules,
  );
  getMenus = createApiCallEffects(
    this,
    this.actions$,
    navigationActions.getMenus,
    this.navigationApiService.getMenus,
  );
}
