import { CommentModel } from '../comment/types';
import { DocumentModel } from '../document/types';

export const historyFeatureKey = 'history';
export type History = {
  id: number;
  name: string;
  entityType: string;
  entityId: number;
  creationDate: Date;
  localeCreationDate: Date;
  message: string;
  historyType: HistoryType;
};
export type InputHistory = {
  entityType: string;
  entityId: number;
};

export type HistoryType = 'Comment' | 'Document';
export type HistoryTypeExtendedFilter = 'Comment' | 'Document' | '';
export type MessageModel = CommentModel | DocumentModel;

export type HistoryModel = {
  historyType: HistoryType;
  createdDate: Date;
  value: MessageModel;
};
