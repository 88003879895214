import {
  historyInitialState,
  historyReducer,
  IHistoryState,
} from './history.reducer';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { historyFeatureKey } from './types';
import { EffectsModule } from '@ngrx/effects';
import { HistoryEffects } from './history.effects';
import { HistoryApiService } from './history.api.service';
import { HistoryService } from './history.service';

@NgModule({
  imports: [
    StoreModule.forFeature<IHistoryState>(historyFeatureKey, historyReducer, {
      initialState: historyInitialState,
    }),
    EffectsModule.forFeature([HistoryEffects]),
  ],
  providers: [HistoryApiService, HistoryService],
})
export class StoreHistoryModule {}
