import { Injectable } from '@angular/core';
import { CommentApiService } from './comment.api.service';
import { CommentAction, commentActions } from './comment.action';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { Store } from '@ngrx/store';
import { commentSelectors } from './comment.selector';

@Injectable()
export class CommentEffects {
  constructor(
    private readonly store: Store<any>,
    private readonly commentApiService: CommentApiService,
    private readonly action$: Actions<CommentAction>
  ) {}

  getByEntityId = createApiCallEffects(
    this,
    this.action$,
    commentActions.getByEntityId,
    this.commentApiService.getByEntityId,
    () => {},
    () => {
      return this.store.select(commentSelectors.getByEntityId.paramsSelector);
    }
  );

  postComment = createApiCallEffects(
    this,
    this.action$,
    commentActions.postComment,
    this.commentApiService.postComment
  );
}
