import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginFormComponent } from './login-form/login-form.component';

const routes: Routes = [
  {
    path: 'Login',
    component: LoginComponent,
    data: {
      fullscreen: true,
    },
    // children: [
    //     {
    //         path: '',
    //         component: LoginComponent,
    //         outlet: 'loginOutlet',
    //     },
    // ]
  },
  {
    path: 'ForgotPassword',
    component: ForgotPasswordComponent,
    data: {
      fullscreen: true,
    },
    // children: [{
    //     path: '',
    //     component: ForgotPasswordComponent,
    //     outlet: 'loginOutlet',
    // },]
  },
  {
    path: 'ResetPassword',
    component: ResetPasswordComponent,
    data: {
      fullscreen: true,
    },
    // children: [{
    //     path: '',
    //     component: ResetPasswordComponent,
    //     outlet: 'loginOutlet',
    // },]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthentificationRoutingModule {}
