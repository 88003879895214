import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mail-to-link',
  template: '<a href="mailto:{{ email }}">{{ email }}</a>',
  styles: [''],
})
export class MailToLinkComponent {
  @Input() email!: string;
}
