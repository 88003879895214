import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TerminationReasonService } from 'src/app/shared/store/termination-reason/termination-reason.service';
import { TerminationReasonModel } from 'src/app/shared/store/termination-reason/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-termination-reason-details',
  templateUrl: './termination-reason-details.component.html',
  styleUrls: ['./termination-reason-details.component.scss'],
})
export class TerminationReasonDetailsComponent implements OnInit, OnDestroy {
  terminationReasonId: number;
  terminationReasonModel$: Observable<TerminationReasonModel>;
  view$: Observable<View>;

  @Input() modalId: number;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly terminationReasonService: TerminationReasonService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.terminationReasonModel$ = this.terminationReasonService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.terminationReasonId = this.route.snapshot.params['id'];

    if (this.modalId) this.terminationReasonId = this.modalId;

    this.terminationReasonService.getById.call(
      this.terminationReasonId.toString()
    );

    this.terminationReasonModel$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@terminationReasonDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'terminationReasonEdit') {
          this.router.navigate([
            'Crm/TerminationReasons/Edit/',
            this.terminationReasonId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy(): void {
    this.terminationReasonService.getById.reset();
    this.breadcrumbService.set('@terminationReasonDetails', ' ');
  }
}
