import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { ProductCategoryModel } from './types';
import { productCategoryActions } from './product-category.actions';

export interface IProductCategoryState extends IState<ProductCategoryModel> {
  getById: GenericState<ProductCategoryModel>;
  createInstance: GenericState<ProductCategoryModel>;
  add: GenericState<ProductCategoryModel>;
  update: GenericState<ProductCategoryModel>;
}

export const productCategoryInitialState: IProductCategoryState = {
  getById: new GenericState<ProductCategoryModel>(),
  createInstance: new GenericState<ProductCategoryModel>(),
  add: new GenericState<ProductCategoryModel>(),
  update: new GenericState<ProductCategoryModel>(),
};

const productCategoryReducers = [
  ...onApiCall<ProductCategoryModel>(productCategoryActions.getById, 'getById'),
  ...onApiCall<ProductCategoryModel>(
    productCategoryActions.createInstance,
    'createInstance'
  ),
  ...onApiCall<ProductCategoryModel>(productCategoryActions.add, 'add'),
  ...onApiCall<ProductCategoryModel>(productCategoryActions.update, 'update'),
];

export const productCategoryReducer = createReducer(
  productCategoryInitialState,
  ...productCategoryReducers
) as ActionReducer<IProductCategoryState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return productCategoryReducer(state, action);
}
