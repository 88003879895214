import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesTeamRoutingModule } from './sales-team-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { SalesTeamDetailsComponent } from './sales-team-details/sales-team-details.component';
import { SalesTeamCreateComponent } from './sales-team-create/sales-team-create.component';
import { SalesTeamEditComponent } from './sales-team-edit/sales-team-edit.component';
import { SalesTeamReportingComponent } from './sales-team-reporting/sales-team-reporting.component';
import { SalesTeamMembersReportingComponent } from './sales-team-members-reporting/sales-team-members-reporting.component';

@NgModule({
  declarations: [
    SalesTeamDetailsComponent,
    SalesTeamCreateComponent,
    SalesTeamEditComponent,
    SalesTeamReportingComponent,
    SalesTeamMembersReportingComponent,
  ],
  imports: [CommonModule, SalesTeamRoutingModule, SharedModule],
})
export class SalesTeamModule {}
