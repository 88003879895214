import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { activityTypeActions } from './activity-type.actions';
import { activityTypeSelectors } from './activity-type.selector';
import { ActivityTypeModel } from './types';

@Injectable()
export class ActivityTypeService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ActivityTypeModel, string> = this.genericApiCall(
    activityTypeActions.getById,
    activityTypeSelectors.getById,
    true,
  );
  createInstance: GenericApiCall<ActivityTypeModel, void> = this.genericApiCall(
    activityTypeActions.createInstance,
    activityTypeSelectors.createInstance,
    true,
  );
  add: GenericApiCall<ActivityTypeModel, ActivityTypeModel> =
    this.genericApiCall(
      activityTypeActions.add,
      activityTypeSelectors.add,
      true,
    );
  update: GenericApiCall<ActivityTypeModel, ActivityTypeModel> =
    this.genericApiCall(
      activityTypeActions.update,
      activityTypeSelectors.update,
      true,
    );
}
