import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccountingApiService } from './accounting.api.service';
import { AccountingEffects } from './accounting.effects';
import {
  IAccountingState,
  accountingInitialState,
  accountingReducer,
} from './accounting.reducer';
import { AccountingService } from './accounting.service';
import { accountingFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IAccountingState>(
      accountingFeatureKey,
      accountingReducer,
      {
        initialState: accountingInitialState,
      },
    ),
    EffectsModule.forFeature([AccountingEffects]),
  ],
  providers: [AccountingApiService, AccountingService],
})
export class StoreAccountingModule {}
