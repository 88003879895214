import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeRoutingModule } from './employee-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeeTabsDetailsComponent } from './employee-details/employee-tabs-details/employee-tabs-details.component';
import { EmployeeDetailsNotesComponent } from './employee-details/employee-details-tab/employee-details-notes/employee-details-notes.component';
import { EmployeeDetailsPublicInformationsComponent } from './employee-details/employee-details-tab/employee-details-public-informations/employee-details-public-informations.component';
import { EmployeeDetailsPrivateInformationsComponent } from './employee-details/employee-details-tab/employee-details-private-informations/employee-details-private-informations.component';
import { EmployeeDetailsParametersComponent } from './employee-details/employee-details-tab/employee-details-parameters/employee-details-parameters.component';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { EmployeeTabsFormComponent } from './employee-form/employee-tabs-form/employee-tabs-form.component';
import { EmployeeFormNotesComponent } from './employee-form/employee-form-tabs/employee-form-notes/employee-form-notes.component';
import { EmployeeFormParametersComponent } from './employee-form/employee-form-tabs/employee-form-parameters/employee-form-parameters.component';
import { EmployeeFormPrivateInformationsComponent } from './employee-form/employee-form-tabs/employee-form-private-informations/employee-form-private-informations.component';
import { EmployeeFormPublicInformationsComponent } from './employee-form/employee-form-tabs/employee-form-public-informations/employee-form-public-informations.component';
import { EmployeeCreateComponent } from './employee-create/employee-create.component';

@NgModule({
  declarations: [
    EmployeeDetailsComponent,
    EmployeeTabsDetailsComponent,
    EmployeeDetailsNotesComponent,
    EmployeeDetailsPublicInformationsComponent,
    EmployeeDetailsPrivateInformationsComponent,
    EmployeeDetailsParametersComponent,
    EmployeeEditComponent,
    EmployeeTabsFormComponent,
    EmployeeFormNotesComponent,
    EmployeeFormParametersComponent,
    EmployeeFormPrivateInformationsComponent,
    EmployeeFormPublicInformationsComponent,
    EmployeeCreateComponent,
  ],
  imports: [CommonModule, EmployeeRoutingModule, SharedModule],
})
export class EmployeeModule {}
