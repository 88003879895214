import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { InvoiceModel, invoiceFeatureKey } from './types';
import { IInvoiceState } from './invoice.reducer';

const invoiceFeatureState =
  createFeatureSelector<IInvoiceState>(invoiceFeatureKey);

export const invoiceSelectors = {
  getById: createApiCallSelectors<number, IInvoiceState>(
    invoiceFeatureState,
    'getById'
  ),
};