import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { AppState } from '../types';
import { countyActions } from './county.actions';
import { countySelectors } from './county.selectors';
import { CountyModel } from './types';

@Injectable()
export class CountyService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  get: GenericApiCall<CountyModel[], void> = this.genericApiCall(
    countyActions.get,
    countySelectors.get,
  );

  getById: GenericApiCall<CountyModel, string> = this.genericApiCall(
    countyActions.getById,
    countySelectors.getById,
    true,
  );
}
