import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { BankAccountModel } from './types';
import { bankAccountActions } from './bank-account.actions';

export interface IBankAccountState extends IState<BankAccountModel> {
  getById: GenericState<BankAccountModel>;
  postBankAccount: GenericState<BankAccountModel>;
  updateBankAccount: GenericState<BankAccountModel>;
}

export const bankAccountInitialState: IBankAccountState = {
  getById: new GenericState<BankAccountModel>(),
  postBankAccount: new GenericState<BankAccountModel>(),
  updateBankAccount: new GenericState<BankAccountModel>(),
};

const bankAccountReducers = [
  ...onApiCall<BankAccountModel>(bankAccountActions.getById, 'getById'),
  ...onApiCall<BankAccountModel>(
    bankAccountActions.postBankAccount,
    'postBankAccount',
  ),
  ...onApiCall<BankAccountModel>(
    bankAccountActions.updateBankAccount,
    'updateBankAccount',
  ),
];

export const bankAccountReducer = createReducer(
  bankAccountInitialState,
  ...bankAccountReducers,
) as ActionReducer<IBankAccountState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return bankAccountReducer(state, action);
}
