import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { invoiceLineActions } from './invoice-line.actions';
import { invoiceLineSelectors } from './invoice-line.selector';
import { InvoiceLineModel } from './types';

@Injectable()
export class InvoiceLineService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<InvoiceLineModel, string> = this.genericApiCall(
    invoiceLineActions.getById,
    invoiceLineSelectors.getById,
    true
  );
  updateById: GenericApiCall<InvoiceLineModel, InvoiceLineModel> =
    this.genericApiCall(
      invoiceLineActions.updateById,
      invoiceLineSelectors.updateById,
      true
    );
}
