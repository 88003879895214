import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { View } from '../../../../../../shared/store/view/types';
import { User } from '../../../../../../shared/store/user/type';
import { ViewService } from '../../../../../../shared/store/view/views.service';
import { SubHeaderService } from '../../../../../../shared/store/subheader/subheader.service';
import { UserService } from '../../../../../../shared/store/user/user.service';

@Component({
  selector: 'app-custom-actions-flow-user',
  templateUrl: './custom-actions-flow-user.component.html',
  styleUrls: ['./custom-actions-flow-user.component.scss'],
})
export class CustomActionsFlowUserComponent {
  view$: Observable<View>;
  user$: Observable<User>;

  constructor(
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
    private readonly userService: UserService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.user$ = this.userService.getById.value$;
  }

  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
