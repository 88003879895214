import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { CurrencyModel } from './types';

export const currencyActions = {
  getById: createApiCallActions<CurrencyModel>('currency', 'getById'),
  postCurrency: createApiCallActions<any>('currency', 'postCurrency'),
  updateCurrency: createApiCallActions<any>('currency', 'updateCurrency'),
};

export type CurrencyAction = typeof currencyActions;
