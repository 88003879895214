export const employeeFeatureKey = 'employee';

export type AddressModel = {
  id: number;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  countryId?: number;
  countryName: string;
};

export type BankAccountModel = {
  id: number;
  iban: string;
  bic: string;
  holderName: string;
  active: boolean;
};

export type EmployeeModel = {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  registrationNumber: string;
  resourceId: number;
  homeAddress: AddressModel;
  userId?: number;
  userName: string;
  nationalityId?: number;
  nationalityName: string;
  birthDate?: Date;
  placeOfBirth: string;
  ssnNumber: string;
  gender: string;
  genderLoc: string;
  maritalStatus: string;
  maritalStatusLoc: string;
  workingAddress: AddressModel | undefined | null;
  bankAccount: BankAccountModel | undefined | null;
  workPhone: string;
  mobilePhone: string;
  workEmail: string;
  privatePhoneNumber: string;
  privateEmail: string;
  managerId?: number;
  managerName: string;
  isManager: boolean;
  children?: number;
  vehicle: string;
  homeWorkDistance?: number;
  color?: number;
  notes: string;
  jobId: number;
  jobName: string;
  passportNumber: string;
  active: boolean;
};
