<div class="sub-header" *ngIf="this.contractBase$ | async as contractBase">
  <div class="sub-header-row border-top align-items-center px-1">
    <div class="row">
      <div class="col" *ngIf="this.customActions$ | async as customActions">
        <a
          *ngFor="let action of customActions"
          [class]="action.buttonType | buttontypepipe"
          class="btn-sm btn"
          (click)="triggerStatusFlow(action.actionName, contractBase.id)"
        >
          {{ action.title }}
        </a>
      </div>

      <div
        class="col-auto state-breadcrumb-col"
        *ngIf="this.contractStatusFlows$ | async as contractStatus"
      >
        <div class="state-breadcrumb">
          <li
            *ngFor="let contractStatus of contractStatus"
            [ngClass]="
              contractBase.statusId == contractStatus.status
                ? ['active-' + contractStatus.statusColor]
                : ''
            "
          >
            {{ contractStatus.label }}
          </li>
        </div>
      </div>
    </div>
  </div>
</div>
