import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { LostReasonService } from 'src/app/shared/store/lost-reason/lost-reason.service';
import { LostReasonModel } from 'src/app/shared/store/lost-reason/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-lost-reason-edit',
  templateUrl: './lost-reason-edit.component.html',
  styleUrls: ['./lost-reason-edit.component.scss'],
})
export class LostReasonEditComponent implements OnInit, OnDestroy {
  lostReason$: Observable<LostReasonModel>;
  lostReasonForm: FormGroup;
  view$: Observable<View>;

  constructor(
    private readonly lostReasonService: LostReasonService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder
  ) {
    this.lostReason$ = this.lostReasonService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'lostReasonUpdate') this.onSubmit();
        if (x == 'lostReasonDetails') {
          this.formService.clear();
          this.router.navigate([
            'Crm/LostReasons/Details/',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.lostReasonService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.lostReasonForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      active: [false],
      needSummary: [false],
    });

    this.lostReason$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@lostReasonEdit', x.name);
        this.lostReasonForm.patchValue({
          name: x.name,
          active: x.active,
          needSummary: x.needSummary,
        });
      });
  }

  onSubmit(): void {
    if (this.lostReasonForm.invalid) {
      this.lostReasonForm.markAllAsTouched();
      this.formService.countErrors(this.lostReasonForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.lostReasonService.update.call({
      id: this.route.snapshot.params['id'],
      name: this.lostReasonForm.value.name!,
      active: this.lostReasonForm.value.active!,
      needSummary: this.lostReasonForm.value.needSummary!,
    });
    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.router.navigate(['/Crm/LostReasons/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.lostReasonService.update.reset();
    this.breadcrumbService.set('@lostReasonEdit', ' ');
  }
}
