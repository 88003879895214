import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { currencyActions } from './currency.actions';
import { currencySelectors } from './currency.selectors';
import { CurrencyModel } from './types';

@Injectable()
export class CurrencyService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<CurrencyModel, string> = this.genericApiCall(
    currencyActions.getById,
    currencySelectors.getById,
    true,
  );

  postCurrency: GenericApiCall<CurrencyModel, CurrencyModel> =
    this.genericApiCall(
      currencyActions.postCurrency,
      currencySelectors.postCurrency,
      true,
    );

  updateCurrency: GenericApiCall<CurrencyModel, CurrencyModel> =
    this.genericApiCall(
      currencyActions.updateCurrency,
      currencySelectors.updateCurrency,
      true,
    );
}
