import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthentificationService } from 'src/app/shared/authentification/authentification.service';
import { Module } from '../../shared/store/navigation/types';
import { NavigationService } from '../../shared/store/navigation/navigation.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  modules$: Observable<Module[]>;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly authService: AuthentificationService,
  ) {
    this.modules$ = this.navigationService.getModules.value$;
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.navigationService.getModules.call();
    }
  }
}
