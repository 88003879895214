import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ProductLineCreateComponent } from './product-line-create/product-line-create.component';
import { ProductLineDetailsInsuranceDocumentComponent } from './product-line-details/product-line-details-tab/product-line-details-insurance-documents/product-line-details-insurance-documents.component';
import { ProductLineDetailsNotesComponent } from './product-line-details/product-line-details-tab/product-line-details-notes/product-line-details-notes.component';
import { ProductLineDetailsProductComponent } from './product-line-details/product-line-details-tab/product-line-details-products/product-line-details-products.component';
import { ProductLineDetailsComponent } from './product-line-details/product-line-details.component';
import { ProductLineTabsDetailsComponent } from './product-line-details/product-line-tabs-details/product-line-tabs-details.component';
import { ProductLineEditComponent } from './product-line-edit/product-line-edit.component';
import { ProductLineRoutingModule } from './product-line-routing.module';

@NgModule({
  declarations: [
    ProductLineDetailsComponent,
    ProductLineTabsDetailsComponent,
    ProductLineDetailsProductComponent,
    ProductLineDetailsInsuranceDocumentComponent,
    ProductLineDetailsNotesComponent,
    ProductLineCreateComponent,
    ProductLineEditComponent,
  ],
  imports: [CommonModule, ProductLineRoutingModule, SharedModule],
})
export class ProductLineModule {}
