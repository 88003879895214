<ngx-ui-loader
  *ngIf="this.loader"
  [appLoader]="this.apiCall.isLoading$"
  [appLoaderId]="this.loaderId"
  [loaderId]="this.loaderId"
  [fgsColor]="'var(--loader-spinner-color)'"
  [overlayColor]="'var(--loader-bg)'"
  [pbThickness]="0"
></ngx-ui-loader>

<div class="text-center" *ngIf="this.showError$ | async">
  {{ errorMessage | translate }}
</div>

<div class="text-center" *ngIf="this.showEmptyContent$ | async">
  {{ emptyMessage | translate }}
</div>

<ng-content *ngIf="this.showContent$ | async"></ng-content>
<!--<ng-content *ngIf="true"></ng-content>-->

