<div *ngIf="this.view$ | async as view">
  <div
    ngbDropdown #dropDownMenuFilters="ngbDropdown"
    class="dropdown dropdown-filter d-inline"
    autoClose="outside"
    *ngIf="this.activeFilters$ | async as activeFilters"
  >
    <button
      type="button"
      class="btn btn-sm btn-outline-light dropdown-toggle"
      id="dropdownBasic1"
      ngbDropdownToggle
    >
      <i class="mdi mdi-filter"></i>
      <span class="btn-text" translate> SEARCH_LBL_CUSTOM_FILTERS </span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <ng-container
        *ngFor="let builtInFilter of this.view.searchView.builtInFilters"
      >
        <button
          class="dropdown-item dropdown-item-normal dropdown-filter"
          (click)="toggleBuiltInFilter(builtInFilter); dropDownMenuFilters.close();"
          [class.active]="activeFilters | isBuiltInActive : builtInFilter"
          [ngClass]="{
            active: activeFilters | isBuiltInActive : builtInFilter
          }"
        >
          <span
            class="mdi mdi-check"
            *ngIf="activeFilters | isBuiltInActive : builtInFilter"
          ></span>
          <span
            [class.ms-3]="!(activeFilters | isBuiltInActive : builtInFilter)"
          >
            {{ builtInFilter.text }}</span
          >
        </button>
      </ng-container>
      <div
        *ngIf="this.view.searchView.builtInFilters != null"
        class="dropdown-divider"
      ></div>

      <ng-container *ngIf="customFilters$ | async as customFilters">
        <div *ngFor="let customFilter of this.customFilters">
          <button
            class="dropdown-item dropdown"
            type="button"
            *ngFor="let domain of customFilter.domains"
            (click)="
              changeDomainState(
                customFilters.indexOf(customFilter),
                customFilter.domains.indexOf(domain)
              )
            "
            [class.active]="
              activeFilters | isDomainActive : customFilter : domain
            "
          >
            <span>
              <span
                class="mdi mdi-check"
                *ngIf="activeFilters | isDomainActive : customFilter : domain"
              ></span>
              {{ domain.fieldLabel }}
              {{ domain.operator.label }}
              {{ domain.value }}</span
            >
          </button>
          <div class="dropdown-divider"></div>
        </div>
      </ng-container>
      <button ngbDropdownItem (click)="addCustomFilter()" translate>
        SEARCH_LBL_CUSTOM_CUSTOM_FILTER_ADD
      </button>
      <app-search-custom-filter
        *ngFor="let index of this.domains"
        (removeFilter)="removeCustomFilter($event)"
        [searchModuleIndex]="this.domains.indexOf(index)"
      />
      <div ngbDropdownItem *ngIf="this.domains.length > 0">
        <button
          class="btn btn-sm btn-primary mr-2"
          (click)="applyCustomFilter()"
          translate
        >
          GLOBAL_BTN_APPLY
        </button>
        <button
          class="btn btn-sm btn-outline-primary"
          (click)="addCustomFilter()"
          translate
        >
          SEARCH_LBL_CUSTOM_CONDITION_ADD
        </button>
      </div>
    </div>
  </div>
  <div ngbDropdown class="dropdown dropdown-filter d-inline">
    <button
      type="button"
      class="btn btn-sm btn-outline-light dropdown-toggle"
      id="dropdownBasic1"
      ngbDropdownToggle
    >
      <i class="mdi mdi-star"></i>
      <span class="btn-text" translate> SEARCH_LBL_CUSTOM_FAVORITE </span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownFavorites">
      <button ngbDropdownItem (click)="saveFavorite()" translate>
        SEARCH_LBL_CUSTOM_FAVORITE_ADD
      </button>
    </div>
  </div>
</div>
