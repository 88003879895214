import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { LostReasonModel, lostReasonFeatureKey } from './types';
import { ILostReasonState } from './lost-reason.reducer';

const lostReasonFeatureState =
  createFeatureSelector<ILostReasonState>(lostReasonFeatureKey);

export const lostReasonSelectors = {
  getById: createApiCallSelectors<LostReasonModel, ILostReasonState>(
    lostReasonFeatureState,
    'getById',
  ),

  createInstance: createApiCallSelectors<LostReasonModel, ILostReasonState>(
    lostReasonFeatureState,
    'createInstance',
  ),

  add: createApiCallSelectors<LostReasonModel, ILostReasonState>(
    lostReasonFeatureState,
    'add',
  ),

  update: createApiCallSelectors<LostReasonModel, ILostReasonState>(
    lostReasonFeatureState,
    'update',
  ),
};
