import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { LockoutModel, User } from 'src/app/shared/store/user/type';
import { UserService } from 'src/app/shared/store/user/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter, take } from 'rxjs/operators';
import { View } from '../../../../../shared/store/view/types';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { environment } from '../../../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  userId: string;
  userEmail: string;

  user$: Observable<User>;
  view$: Observable<View>;

  apiUrl: string = environment.comparanooapiurl;

  constructor(
    private userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
  ) {
    this.user$ = this.userService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params['id'];
    this.userService.getById.call(this.userId);

    this.user$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@userDetails', x.contactName);
        this.userEmail = x.aspNetUserEmail;
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'userEdit') {
          this.router.navigate(['Configuration/Users/Edit/', this.userId]);
        }
        if (x == 'userUnlock') this.activate(true);
        if (x == 'userLock') this.activate(false);
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy() {
    this.userService.getById.reset();
    this.breadcrumbService.set('@userDetails', ' ');
  }

  private activate(activate: boolean) {
    let lockoutModel: LockoutModel = {
      email: this.userEmail,
      activate: activate,
    };

    let resultLockout$ = this.userService.lockout.call(lockoutModel);
    resultLockout$
      .pipe(
        debounceTime(200),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.userService.getById.call(this.userId);
        this.userService.lockout.reset();
      });
  }
}
