import { createApiCallActions } from '../../../common/store/generic.actions';
import { TerminationReasonModel } from './types';

export const terminationReasonActions = {
  getById: createApiCallActions<TerminationReasonModel>(
    'terminationReason',
    'getById',
  ),
  createInstance: createApiCallActions<TerminationReasonModel>(
    'terminationReason',
    'createInstance',
  ),
  add: createApiCallActions<TerminationReasonModel>('terminationReason', 'add'),
  update: createApiCallActions<TerminationReasonModel>(
    'terminationReason',
    'update',
  ),
};

export type TerminationReasonAction = typeof terminationReasonActions;
