import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from 'src/app/shared/store/product/product.service';
import { ProductModel } from 'src/app/shared/store/product/types';

@Component({
  selector: 'app-product-details-notes',
  templateUrl: './product-details-notes.component.html',
  styleUrls: ['./product-details-notes.component.scss'],
})
export class ProductDetailsNotesComponent {
  productDetail$: Observable<ProductModel>;

  constructor(private readonly productService: ProductService) {
    this.productDetail$ = this.productService.getById.value$;
  }
}
