import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'viewType',
})
export class ViewTypePipe implements PipeTransform {
  transform(value: string): string[] {
    return value.split(' ');
  }
}
