import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  mergeMap,
  Observable,
  of,
  startWith,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HistoryModel,
  HistoryTypeExtendedFilter,
} from '../../../store/history/types';
import { CommentService } from '../../../store/comment/comment.service';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { DocumentService } from '../../../store/document/document.service';
import { DocumentModel } from '../../../store/document/types';
import { CommentModel, InputByEntity } from '../../../store/comment/types';
import { ContractService } from '../../../store/contract/contract.service';
import { FormControl } from '@angular/forms';
import { ContactService } from '../../../store/contact/contact.service';

@Component({
  selector: 'app-history-panel',
  templateUrl: './history-panel.component.html',
  styleUrls: ['./history-panel.component.scss'],
})
export class HistoryPanelComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: string;

  inputByEntity: InputByEntity;

  comments$: Observable<CommentModel[]>;
  documents$: Observable<DocumentModel[]>;
  histories$: Observable<HistoryModel[]>;

  //Search
  searchControl = new FormControl();
  historiesTypeFilterSubject$ = new BehaviorSubject<HistoryTypeExtendedFilter>(
    '',
  );
  // historyTypeFilter$ = this.historiesTypeFilterSubject.asObservable();
  // Déclarez un BehaviorSubject pour gérer les mises à jour
  filteredHistories$: Observable<HistoryModel[]>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly commentService: CommentService,
    private readonly documentService: DocumentService,
    private readonly contractService: ContractService,
    private readonly contactService: ContactService,
  ) {
    this.comments$ = this.commentService.getByEntityId.value$;
    this.documents$ = this.documentService.getByEntityId.value$;
  }

  ngOnInit(): void {
    this.inputByEntity = {
      entityId: +this.entityId,
      entityType: this.entityType,
    };

    this.commentService.getByEntityId.call(this.inputByEntity);
    this.documentService.getByEntityId.call(this.inputByEntity);

    this.histories$ = combineLatest([this.comments$, this.documents$]).pipe(
      // filter(([comments, documents]) => !!comments && !!documents),
      map(([comments, documents]) => {
        let histories: HistoryModel[] = [];
        if (comments) {
          for (let i = 0; i < comments.length; i++) {
            histories = [
              ...histories,
              {
                historyType: 'Comment',
                createdDate: comments[i].creationDate ?? new Date(),
                value: comments[i],
              },
            ];
          }
        }
        if (documents) {
          for (let i = 0; i < documents.length; i++) {
            histories = [
              ...histories,
              {
                historyType: 'Document',
                createdDate: documents[i].creationDate,
                value: documents[i],
              },
            ];
          }
        }
        histories = histories.sort((a: HistoryModel, b: HistoryModel) =>
          a.createdDate > b.createdDate ? -1 : 1,
        );
        return histories;
      }),
    );

    this.filteredHistories$ = combineLatest([
      this.searchControl.valueChanges.pipe(startWith('')),
      this.historiesTypeFilterSubject$,
      this.histories$,
    ]).pipe(
      debounceTime(300),
      map(([searchTerm, historyTypeFilter, histories]) => {
        return this.filterHistories(searchTerm, historyTypeFilter, histories);
      }),
    );
  }

  trackByHistory(index: any, history: HistoryModel) {
    return history.value.id;
  }

  refreshDocument(isNew: boolean) {
    this.documentService.getByEntityId.call(this.inputByEntity);

    switch (this.entityType) {
      case 'Contract':
        this.contractService.getByUniqueId.call(
          this.route.snapshot.params['id'],
        );
        break;
      case 'Contact':
        this.contactService.getById.call(this.route.snapshot.params['id']);
        break;
      default:
        break;
    }
  }

  refreshComment(isNew: boolean) {
    this.commentService.getByEntityId.call(this.inputByEntity);
  }

  filterHistories(
    searchTerm: string,
    historyTypeFilter: HistoryTypeExtendedFilter,
    items: HistoryModel[],
  ): HistoryModel[] {
    return items.filter(
      (item) =>
        (historyTypeFilter.length == 0 ||
          historyTypeFilter === item.historyType) &&
        // (type === '' || item.historyType === type) &&
        ((item.historyType === 'Comment' &&
          ((item.value['message'] &&
            item.value['message']
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
            (item.value['name'] &&
              item.value['name']
                .toLowerCase()
                .includes(searchTerm.toLowerCase())))) ||
          (item.historyType === 'Document' &&
            ((item.value['description'] &&
              item.value['description']
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
              (item.value['name'] &&
                item.value['name']
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())) ||
              item.value['creationDate']))),
    );
  }

  onClickHistoryType(historyType: HistoryTypeExtendedFilter) {
    this.historiesTypeFilterSubject$
      .pipe(
        take(1), // Prendre une seule valeur
      )
      .subscribe((item) => {
        let historyTypeSelected: HistoryTypeExtendedFilter = '';
        if (historyType === item) {
          historyTypeSelected = '';
        } else {
          historyTypeSelected = historyType;
        }
        this.historiesTypeFilterSubject$.next(historyTypeSelected); // Mettre à jour l'observable
      });
  }
}
