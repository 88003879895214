export const productCoverageFeatureKey = 'productCoverage';

export type ProductCoverageModel = {
  id: number;
  productId: number;
  insuranceCoverageId: number;
  value: string;
  comment: string;
  insuranceCoverageSequence: number;
};
