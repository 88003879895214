import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProductLineApiService } from './product-line.api.service';
import { ProductLineEffects } from './product-line.effects';
import {
  IProductLineState,
  productLineReducer,
  productLineInitialState,
} from './product-line.reducer';
import { ProductLineService } from './product-line.service';
import { productLineFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IProductLineState>(
      productLineFeatureKey,
      productLineReducer,
      {
        initialState: productLineInitialState,
      }
    ),
    EffectsModule.forFeature([ProductLineEffects]),
  ],
  providers: [ProductLineApiService, ProductLineService],
})
export class StoreProductLineModule {}
