<div class="sub-header" *ngIf="this.leadDetail$ | async as leadDetail">
  <div class="sub-header-row border-top align-items-center px-1">
    <div class="row">
      <div class="col" *ngIf="this.customActions$ | async as customActions">
        <!--        href="https://www.comparanoo.re/offres-mutuelles-sante?leadId={{this.leadId}}" target="_blank"-->

        <a
          *ngFor="let action of customActions"
          [class]="action.buttonType | buttontypepipe"
          class="btn-sm btn"
          (click)="
            triggerStatusFlow(
              action.actionName,
              leadDetail.id,
              leadDetail.productCategoryId
            )
          "
        >
          {{ action.title }}
        </a>

        <a
          *ngIf="
            leadDetail.uniqueId &&
            leadDetail.category ===
              InsuranceCategory.Health &&
            this.leadDetail.leadStatus != 'Lost'
          "
          class="btn-sm btn btn-outline-primary mr-2"
          href="{{ this.comparanooUrl }}/offres-mutuelles-sante?leadId={{
            leadDetail.uniqueId
          }}"
          target="_blank"
          translate
        >
          GLOBAL_BTN_SEE_OFFERS
        </a>
      </div>
      <div
        class="col-auto state-breadcrumb-col"
        *ngIf="this.leadStatusFlows$ | async as leadStatus"
      >
        <div class="state-breadcrumb">
          <li
            *ngFor="let leadStatus of leadStatus"
            [ngClass]="
              leadDetail.leadStatus == leadStatus.status
                ? ['active-' + leadStatus.statusColor]
                : ''
            "
          >
            {{ leadStatus.label }}
          </li>
        </div>
      </div>
    </div>
  </div>
</div>
