import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { InsuranceCompanyService } from 'src/app/shared/store/insurance-company/insurance-company.service';
import { InsuranceCompanyModel } from 'src/app/shared/store/insurance-company/types';

@Component({
  selector: 'app-insurance-company-details-notes',
  templateUrl: './insurance-company-details-notes.component.html',
  styleUrls: ['./insurance-company-details-notes.component.scss'],
})
export class InsuranceCompanyDetailsNotesComponent {
  insuranceCompanyDetail$: Observable<InsuranceCompanyModel>;

  constructor(
    private readonly insuranceCompanyService: InsuranceCompanyService
  ) {
    this.insuranceCompanyDetail$ = this.insuranceCompanyService.getById.value$;
  }
}
