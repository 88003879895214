import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductLineService } from 'src/app/shared/store/product-line/product-line.service';
import { ProductLineModel } from 'src/app/shared/store/product-line/types';

@Component({
  selector: 'app-product-line-details-notes',
  templateUrl: './product-line-details-notes.component.html',
  styleUrls: ['./product-line-details-notes.component.scss'],
})
export class ProductLineDetailsNotesComponent {
  productLineDetail$: Observable<ProductLineModel>;

  constructor(private readonly productLineService: ProductLineService) {
    this.productLineDetail$ = this.productLineService.getById.value$;
  }
}
