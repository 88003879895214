import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { ActivityModel, ActivityStatusFlow } from './types';
import { activityActions } from './activity.actions';

export interface IActivityState extends IState<ActivityModel> {
  getById: GenericState<ActivityModel>;
  createInstance: GenericState<ActivityModel>;
  add: GenericState<ActivityModel>;
  update: GenericState<ActivityModel>;
  getStatusFlow: GenericState<ActivityStatusFlow[]>;
  getStatusActions: GenericState<any[]>;
}

export const activityInitialState: IActivityState = {
  getById: new GenericState<ActivityModel>(),
  createInstance: new GenericState<ActivityModel>(),
  add: new GenericState<ActivityModel>(),
  update: new GenericState<ActivityModel>(),
  getStatusFlow: new GenericState<ActivityStatusFlow[]>(),
  getStatusActions: new GenericState<any[]>(),
};

const activityReducers = [
  ...onApiCall<ActivityModel>(activityActions.getById, 'getById'),
  ...onApiCall<ActivityModel>(activityActions.createInstance, 'createInstance'),
  ...onApiCall<ActivityModel>(activityActions.add, 'add'),
  ...onApiCall<ActivityModel>(activityActions.update, 'update'),
  ...onApiCall<ActivityStatusFlow[]>(
    activityActions.getStatusFlow,
    'getStatusFlow',
  ),
  ...onApiCall<any[]>(activityActions.getStatusActions, 'getStatusActions'),
];

export const activityReducer = createReducer(
  activityInitialState,
  ...activityReducers,
) as ActionReducer<IActivityState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return activityReducer(state, action);
}
