<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <div class="card-body">
      <div class="container-fluid">
        <form
          [formGroup]="productLineForm"
          (ngSubmit)="onSubmit()"
          [hasUnsavedData]="productLineForm"
        >
          <button type="submit" hidden="hidden"></button>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-title-label" for="name" translate
                  >PRODUCTLINE_LBL_NAME</label
                >
                <input
                  id="name"
                  class="form-control form-title"
                  type="text"
                  formControlName="name"
                  checkFormFieldValidity
                  [maxLength]="80"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" translate
                  >PRODUCTLINE_LBL_INSURANCECOMPANY</label
                >
                <div class="col">
                  <app-typeahead
                    [routeEndpoint]="'InsuranceCompanies'"
                    [limitToList]="true"
                    [controlForm]="
                      this.productLineForm.get('insuranceCompanyId')
                    "
                    [orderBy]="'contactName asc'"
                    [dbNameOfName]="'contactName'"
                  ></app-typeahead>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" translate
                  >PRODUCTLINE_LBL_PRODUCTCATEGORY</label
                >
                <div class="col">
                  <app-typeahead
                    [routeEndpoint]="'ProductCategories'"
                    [limitToList]="true"
                    [controlForm]="
                      this.productLineForm.get('productCategoryId')
                    "
                    [orderBy]="'name asc'"
                  ></app-typeahead>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
