import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface IRouterState {
  router: RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<
  IRouterState,
  RouterReducerState<any>
>('router');

export const {
  selectQueryParams, // select the current route query params
  selectRouteParams, // select the current route params
  selectRouteData, // select the current route data
  selectUrl, // select the current url
  selectQueryParam,
  selectRouteParam,
} = getRouterSelectors(selectRouter);

export const routeTitleSelector = createSelector(
  selectRouteData,
  (data) => data && data['title'],
);

export const routeHeaderSelector = createSelector(
  selectRouteData,
  (data) => data && data['header'],
);

export const routeHeaderMenu = createSelector(
  selectRouteData,
  (data) => data && data['headerMenu'],
);

export const routeModuleId = createSelector(
  selectRouteData,
  (data) => data && data['moduleId'],
);

export const routeFullscreenData = createSelector(
  selectRouteData,
  (data) => data && data['fullscreen'],
);

export const routeEntity = createSelector(
  selectRouteData,
  (data) => data && data['entity'],
);

export const requestedUrl = createSelector(selectUrl, (url) => !!url && url);
