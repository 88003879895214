import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { DocumentTypeModel } from './types';
import { documentTypeActions } from './document-type.actions';
import { ListItem } from '../typeahead/types';

export interface IDocumentTypeState extends IState<DocumentTypeModel> {
  getById: GenericState<DocumentTypeModel>;
  getListItem: GenericState<ListItem[]>;
  createInstance: GenericState<DocumentTypeModel>;
  add: GenericState<DocumentTypeModel>;
  update: GenericState<DocumentTypeModel>;
  documentTypes: DocumentTypeModel[];
}

export const documentTypeInitialState: IDocumentTypeState = {
  getById: new GenericState<DocumentTypeModel>(),
  getListItem: new GenericState<ListItem[]>(),
  createInstance: new GenericState<DocumentTypeModel>(),
  add: new GenericState<DocumentTypeModel>(),
  update: new GenericState<DocumentTypeModel>(),
  documentTypes: [],
};

const documentTypeReducers = [
  ...onApiCall<DocumentTypeModel>(documentTypeActions.getById, 'getById'),
  ...onApiCall<ListItem[]>(documentTypeActions.getListItem, 'getListItem'),
  ...onApiCall<DocumentTypeModel>(
    documentTypeActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<DocumentTypeModel>(documentTypeActions.add, 'add'),
  ...onApiCall<DocumentTypeModel>(documentTypeActions.update, 'update'),
  on(
    documentTypeActions.setDocumentTypes,
    (state: IDocumentTypeState, { documentTypes }) => ({
      ...state,
      documentTypes: documentTypes,
    }),
  ),
];

export const documentTypeReducer = createReducer(
  documentTypeInitialState,
  ...documentTypeReducers,
) as ActionReducer<IDocumentTypeState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return documentTypeReducer(state, action);
}
