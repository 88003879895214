import { Pipe, type PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Pipe({
  name: 'quoteArrayChecked',
})
export class QuoteArrayCheckedPipe implements PipeTransform {
  transform(quoteArray: any[], index: number): boolean {
    if (quoteArray == null) return false;
    if (quoteArray[index].checked == true) {
      return true;
    } else {
      return false;
    }
  }
}
