<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.zipCodeModel$ | async as zipcode">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row">
                <form class="col-lg-10">
                  <div class="form-group">
                    <label class="form-title-label" translate>ZIPCODE_LBL_NAME</label>
                    <div class="form-title">{{ zipcode.city }}</div>
                  </div>
                </form>
              </div>

              <div class="row">

                <div class="col-6 col-lg-6">

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>ZIPCODE_LBL_CODE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                          {{ zipcode.code }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>ZIPCODE_LBL_COUNTRY</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                          {{ zipcode.countryName }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.zipCodeModel$ | async as zipCode">
      <app-comment-panel [entityType]="'ZipCode'" [entityId]="zipCode.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
