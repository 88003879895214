import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { InsuranceCategory } from '../../../../../shared/store/lead/types';
import { LeadDetailsHealthComponent } from '../lead-details-tab/lead-details-health/lead-details-health.component';
import { LeadDetailsDriverComponent } from '../lead-details-tab/lead-details-driver/lead-details-driver.component';
import { LeadDetailsVehicleComponent } from '../lead-details-tab/lead-details-vehicle/lead-details-vehicle.component';
import { LeadDetailsCarInsuranceComponent } from '../lead-details-tab/lead-details-car-insurance/lead-details-car-insurance.component';
import { LeadDetailsHouseComponent } from '../lead-details-tab/lead-details-house/lead-details-house.component';
import { BehaviorSubject } from 'rxjs';
import { TabsContent } from '../../../../../shared/models/tabs-content';
import { LeadDetailsQuotesComponent } from '../lead-details-tab/lead-details-quotes/lead-details-quotes.component';

@Component({
  selector: 'app-lead-tabs-details',
  templateUrl: './lead-tabs-details.component.html',
  styleUrls: ['./lead-tabs-details.component.scss'],
})
export class LeadTabsDetailsComponent implements OnChanges {
  @Input() category: InsuranceCategory;
  active = 1;

  tabsContent$: BehaviorSubject<TabsContent[]> = new BehaviorSubject<
    TabsContent[]
  >([]);

  tabsInit: TabsContent[] = [
    {
      title: 'LEAD_TABS_HEALTH_DETAIL',
      index: 1,
      name: 'health',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: LeadDetailsHealthComponent,
    },
    {
      title: 'LEAD_TABS_QUOTES',
      index: 2,
      name: 'quotes',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: LeadDetailsQuotesComponent,
    },
    {
      title: 'LEAD_TABS_DRIVER',
      index: 1,
      name: 'driver',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: LeadDetailsDriverComponent,
    },
    {
      title: 'LEAD_TABS_VEHICLE',
      index: 2,
      name: 'vehicle',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: LeadDetailsVehicleComponent,
    },
    {
      title: 'LEAD_TABS_INFORMATIONS',
      index: 3,
      name: 'car-insurance',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: LeadDetailsCarInsuranceComponent,
    },
    {
      title: 'LEAD_TABS_HOUSE',
      index: 1,
      name: 'house',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: LeadDetailsHouseComponent,
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    this.active = 1;
    if (this.tabsInit && this.tabsInit.length > 0) {
      this.tabsContent$.next(
        this.tabsInit.filter((x) => x.category === this.category),
      );
    }
  }

  trackByTabsContent(index: any, item: TabsContent) {
    return item.index;
  }
}
