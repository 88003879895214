import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { CommentModel } from './types';

export const commentActions = {
  getByEntityId: createApiCallActions<CommentModel[]>(
    'comment',
    'getByEntityId',
  ),
  postComment: createApiCallActions<any>('comment', 'postComment'),
};

export type CommentAction = typeof commentActions;
