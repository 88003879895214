import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error';
import { DocumentTypeModel } from './types';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';
import { InputTypeahead, ListItem } from '../typeahead/types';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<DocumentTypeModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<DocumentTypeModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getListItem = ({
    filter,
    pageSize,
    orderBy,
  }: {
    filter: string;
    pageSize: number;
    orderBy: string;
  }): Observable<ListItem[]> => {
    let parameters: string[];
    parameters = ['listItem'];
    let url = this.formatUrl(parameters);

    let params = new HttpParams();
    if (filter) {
      params = params.append('filter', filter);
    }

    if (pageSize) {
      params = params.append('pageSize', pageSize);
    }

    if (orderBy) {
      params = params.append('orderBy', orderBy);
    }

    return this.httpClient.get<ListItem[]>(url, { params: params }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  createInstance = (): Observable<DocumentTypeModel> => {
    let parameters: string[];
    parameters = ['create'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<DocumentTypeModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  add = (
    documentTypeModel: DocumentTypeModel,
  ): Observable<DocumentTypeModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<DocumentTypeModel>(url, documentTypeModel, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  update = (
    documentTypeModel: DocumentTypeModel,
  ): Observable<DocumentTypeModel> => {
    let parameters: string[];
    parameters = [documentTypeModel.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<DocumentTypeModel>(url, documentTypeModel, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.documentType.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
