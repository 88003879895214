<!-- <app-loader
  [apiCall]="this.apiCall"
  [loader]="true"
  [loaderId]="'accountMoveBindings'"
  style="min-height: 200px"
>
</app-loader> -->

<div class="row mb-2" *ngIf="this.accountMove$ | async as accountMove">
  <div class="col-6 col-md">
    <label class="form-title-label" translate>{{ labelPayments }}</label>
    <table class="table table-striped table-hover">
      <tbody>
        <ng-container
          *ngFor="
            let payment of accountMove.payingAccountMoves;
            trackBy: trackByAccountMoveId
          "
        >
          <ng-container *ngIf="true">
            <tr
              *ngFor="
                let accountMoveLine of payment.accountMoveLines;
                trackBy: trackByAccountMoveLineId
              "
            >
              <td>
                <ng-container *ngIf="!isVendorInvoice">{{
                  accountMoveLine.paymentModeLoc
                }}</ng-container>
                <ng-container *ngIf="isVendorInvoice">{{
                  payment.name
                }}</ng-container>
              </td>
              <td>
                {{ accountMoveLine.unitPrice | currency : "EUR" }}
              </td>
              <td>
                <ng-container *ngIf="!isVendorInvoice"
                  >{{ accountMoveLine.creationDate | date : "dd/MM/yyyy" }}
                </ng-container>
                <ng-container *ngIf="isVendorInvoice"
                  >{{
                    payment.periodStartDate | period : payment.periodEndDate
                  }}
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="col">
    <label class="form-title-label" translate>{{ labelInvoices }}</label>
    <table class="table table-striped table-hover">
      <tbody>
        <ng-container
          *ngFor="
            let accountMove of accountMove.issuingAccountMoves;
            trackBy: trackByAccountMoveId
          "
        >
          <tr
            *ngFor="
              let accountMoveLine of accountMove.accountMoveLines;
              trackBy: trackByAccountMoveLineId
            "
          >
            <td>
              {{ accountMove.name }}
            </td>
            <td>
              {{ accountMoveLine.unitPrice | currency : "EUR" }}
            </td>
            <td>
              {{
                accountMove.periodStartDate | period : accountMove.periodEndDate
              }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="this.accountMove$ | async as accountMove">
  <div class="row">
    <div class="col-12 col-md">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >ACCOUNTMOVE_LBL_TOTAL_PAYMENT</label
        >
        <div class="col-auto">
          <span class="form-control-plaintext form-control-sm">
            {{
              accountMove.payingAccountMoves
                | accountmovesamount
                | currency : "EUR"
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >ACCOUNTMOVE_LBL_TOTAL_INVOICE</label
        >
        <div class="col-auto">
          <span class="form-control-plaintext form-control-sm">
            {{
              accountMove.issuingAccountMoves
                | accountmovesamount
                | currency : "EUR"
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >ACCOUNTMOVE_LBL_BALANCE</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{
              (accountMove.payingAccountMoves | accountmovesamount) -
                (accountMove.issuingAccountMoves | accountmovesamount)
                | currency : "EUR"
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>
