<div class="d-none">
  <input type='file' #fileInput id="FileInput" name="FileInput" [accept]="this.acceptFiles"
         [multiple]="false" (change)="onFileSelected($event)"/>
</div>

<ng-container *ngIf="this.file">
  <span>{{ this.file.name }}</span>

  <ng-container *ngIf="format === 'button'">
    <button *ngIf="isValidationButton"
            type="button"
            class="btn btn-sm ps-2"
            (click)="onClickValidateFile()"
    ><i class="mdi mdi-check"></i></button>
    <button type="button"
            class="btn btn-sm ps-2"
            (click)="onClickClearSelectedFile()"><i class="mdi mdi-close"></i></button>
  </ng-container>

  <ng-container *ngIf="format === 'link'">
    <a *ngIf="isValidationButton"
       role="button"
       class="ps-2"
       (click)="onClickValidateFile()"
    ><i class="mdi mdi-check"></i></a>
    <a role="button"
       class="ps-2"
       (click)="onClickClearSelectedFile()"><i class="mdi mdi-close"></i></a>
  </ng-container>
</ng-container>


<ng-container *ngIf="format === 'button'">
  <button type="button"
          class="btn-sm btn btn-outline-primary"
          (click)="fileInput.click()"
          *ngIf="!this.file"
          translate
  >DOCUMENT_LBL_NEWFILE
  </button>
</ng-container>
<ng-container *ngIf="format === 'link'">
  <a role="button"
     (click)="fileInput.click()"
     *ngIf="!this.file"
     translate
  >DOCUMENT_LBL_NEWFILE
  </a>
</ng-container>
