import { Component } from '@angular/core';
import { SubHeaderService } from '../../store/subheader/subheader.service';

@Component({
  selector: 'app-comparator-subheader',
  templateUrl: './comparator-subheader.component.html',
  styleUrls: ['./comparator-subheader.component.scss'],
})
export class ComparatorSubheaderComponent {
  constructor(private readonly subHeaderService: SubHeaderService) {}

  ngOnInit(): void {}

  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
