<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.invoice$ | async as invoice">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-title-label" translate
                      >PRODUCTLINE_LBL_NAME</label
                    >
                    <div class="form-title">{{ invoice.name }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >INVOICE_DESCRIPTION</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm h-100"
                      >
                        {{ invoice.description }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >INVOICE_INSURANCECOMPANYNAME</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm h-100"
                      >
                        {{ invoice.insuranceCompanyContactName }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >INVOICE_INVOICETYPE</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm h-100"
                      >
                        {{ invoice.invoiceTypeLoc }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >INVOICE_STATUS</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm h-100"
                      >
                        {{ invoice.invoiceStatusLoc }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >INVOICE_DUEDATE</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm h-100"
                      >
                        {{ invoice.dueDate | date : "dd/MM/yyyy" }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >INVOICE_CREATIONDATE</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm h-100"
                      >
                        {{ invoice.creationDate | date : "dd/MM/yyyy" }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <ag-grid-angular
                  style="width: 100%; height: 300px"
                  class="ag-theme-alpine"
                  [rowData]="invoice.invoiceLines"
                  [columnDefs]="colDefs"
                  (gridSizeChanged)="onGridSizeChanged($event)"
                  [gridOptions]="config"
                >
                </ag-grid-angular>
              </div>
              <div class="row mt-2">
                <div class="col"></div>
                <div class="col-auto">
                  <table
                    class="table page-detail-table horizontal-table total-table"
                  >
                    <tbody>
                      <tr class="total-table-totalHT-row">
                        <th>Montant HT</th>
                        <td class="numeric-data-cell">
                          {{ invoice.totalAmount | currency : "EUR" }}
                        </td>
                      </tr>
                      <tr class="total-table-taxes-row">
                        <th>Taxes</th>
                        <td class="numeric-data-cell">
                          {{ 0 | currency : "EUR" }}
                        </td>
                      </tr>
                      <tr class="total-table-totalTTC-row">
                        <th>Montant TTC</th>
                        <td class="numeric-data-cell">
                          {{ invoice.totalAmount | currency : "EUR" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.invoice$ | async as invoice">
      <app-comment-panel
        [entityType]="'Invoice'"
        [entityId]="invoice.id.toString()"
      ></app-comment-panel>
    </div>
  </div>
</div>
