import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter, take } from 'rxjs/operators';
import { InsuranceCompanyService } from '../../../../../shared/store/insurance-company/insurance-company.service';
import { InsuranceCompanyModel } from '../../../../../shared/store/insurance-company/types';

@Component({
  selector: 'app-insurance-company-details-content',
  templateUrl: './insurance-company-details-content.component.html',
  styleUrls: ['./insurance-company-details-content.component.scss'],
})
export class InsuranceCompanyDetailsContentComponent
  implements OnInit, OnDestroy
{
  insuranceCompanyId: number;
  insuranceCompanyDetail$: Observable<InsuranceCompanyModel>;

  @Input() modalId: number;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly insuranceCompanyService: InsuranceCompanyService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    this.insuranceCompanyDetail$ = this.insuranceCompanyService.getById.value$;
  }

  ngOnInit(): void {
    this.insuranceCompanyId = this.route.snapshot.params['id'];
    if (this.modalId) this.insuranceCompanyId = this.modalId;
    this.insuranceCompanyService.getById.call(
      this.insuranceCompanyId.toString(),
    );

    this.insuranceCompanyDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@insuranceCompanyDetails', x.contact.name);
      });
  }

  ngOnDestroy(): void {
    this.insuranceCompanyService.getById.reset();
    this.breadcrumbService.set('@insuranceCompanyDetails', ' ');
  }
}
