import { createApiCallActions } from '../../../common/store/generic.actions';
import { ContactModel } from './types';

export const contactActions = {
  getById: createApiCallActions<ContactModel>('contact', 'getById'),
  postContact: createApiCallActions<ContactModel>('contact', 'postContact'),
  updateContact: createApiCallActions<ContactModel>('contact', 'updateContact'),
  createInstance: createApiCallActions<ContactModel>(
    'contact',
    'createInstance',
  ),
  getChildren: createApiCallActions<ContactModel[]>('contact', 'getChildren'),
};

export type ContactAction = typeof contactActions;
