import { GenericState, IState } from 'src/app/common/store/types';
import { CommentModel } from './types';
import { commentActions } from './comment.action';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';

export interface ICommentState extends IState<CommentModel> {
  getByEntityId: GenericState<CommentModel[]>;
  postComment: GenericState<any>;
}

export const commentInitialState: ICommentState = {
  getByEntityId: new GenericState<CommentModel[]>(),
  postComment: new GenericState<any>(),
};

const commentReducers = [
  ...onApiCall<CommentModel[]>(commentActions.getByEntityId, 'getByEntityId'),
  ...onApiCall<any>(commentActions.postComment, 'postComment'),
];

export const commentReducer = createReducer(
  commentInitialState,
  ...commentReducers,
) as ActionReducer<ICommentState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return commentReducer(state, action);
}
