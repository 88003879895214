import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { PageResult } from '../../types';
import { InputCodeAndCityZipCode, ZipCodeModel } from './types';

export const zipCodeActions = {
  get: createApiCallActions<ZipCodeModel[]>('zipcode', 'get'),
  getById: createApiCallActions<ZipCodeModel>('zipcode', 'getById'),
  getByCodeAndCity: createApiCallActions<ZipCodeModel>(
    'zipcode',
    'getByCodeAndCity',
  ),
};

export type ZipCodeAction = typeof zipCodeActions;
