import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { terminationReasonActions } from './termination-reason.actions';
import { terminationReasonSelectors } from './termination-reason.selector';
import { TerminationReasonModel } from './types';

@Injectable()
export class TerminationReasonService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<TerminationReasonModel, string> = this.genericApiCall(
    terminationReasonActions.getById,
    terminationReasonSelectors.getById,
    true,
  );
  createInstance: GenericApiCall<TerminationReasonModel, void> =
    this.genericApiCall(
      terminationReasonActions.createInstance,
      terminationReasonSelectors.createInstance,
      true,
    );
  add: GenericApiCall<TerminationReasonModel, TerminationReasonModel> =
    this.genericApiCall(
      terminationReasonActions.add,
      terminationReasonSelectors.add,
      true,
    );
  update: GenericApiCall<TerminationReasonModel, TerminationReasonModel> =
    this.genericApiCall(
      terminationReasonActions.update,
      terminationReasonSelectors.update,
      true,
    );
}
