import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { IInsuranceCoverageState } from './insurance-coverage.reducer';
import { InsuranceCoverageModel, insuranceCoverageFeatureKey } from './types';

const insuranceCoverageFeatureState =
  createFeatureSelector<IInsuranceCoverageState>(insuranceCoverageFeatureKey);

export const insuranceCoverageSelectors = {
  getById: createApiCallSelectors<
    InsuranceCoverageModel,
    IInsuranceCoverageState
  >(insuranceCoverageFeatureState, 'getById'),
  getByProductCategory: createApiCallSelectors<
    InsuranceCoverageModel[],
    IInsuranceCoverageState
  >(insuranceCoverageFeatureState, 'getByProductCategory'),
};
