export const bankAccountFeatureKey = 'bankAccount';

export type BankAccountModel = {
  id: number;
  iban: string;
  bic: string;
  contactId: number;
  holderName: string;
  bankId?: number;
  bankName: string;
  bankBIC: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  fullCity: string;
  countryId?: number;
  countryName: string;
  active: boolean;
};
