import { ContractStatus } from '../contract/types';

export const activityFeatureKey = 'activity';
export type ActivityModel = {
  id: number;
  name: string;
  summary: string;
  activityTypeId?: number | undefined;
  activityTypeName: string;
  status: ActivityStatus;
  deadline: Date;
  assignedTo: number;
  assignedToName: string;
  contactId?: number | undefined;
  contactName: string;
  notes: string;
  entityType: string;
  entityId: number;
  entityName: string;
  isAutomated: boolean;
  creationDate?: Date;
};

export enum ActivityStatus {
  Draft = 1,
  Done = 2,
  Cancelled = 3,
}

export type ActivityStatusFlow = {
  label: string;
  status: ActivityStatus;
  statusId: number;
  statusColor: string;
};
