import { Component, Input, OnInit } from '@angular/core';
import { CommentModel, InputByEntity } from '../../../store/comment/types';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentService } from '../../../store/comment/comment.service';

@Component({
  selector: 'app-comment-panel',
  templateUrl: './comment-panel.component.html',
  styleUrls: ['./comment-panel.component.scss'],
})
export class CommentPanelComponent implements OnInit {
  inputComment: InputByEntity;
  @Input() entityId: string;
  @Input() entityType: string;
  commentBase$: Observable<CommentModel[]>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly commentService: CommentService,
  ) {
    this.commentBase$ = this.commentService.getByEntityId.value$;
  }

  ngOnInit(): void {
    this.inputComment = {
      entityId: +this.entityId,
      entityType: this.entityType,
    };

    this.commentService.getByEntityId.call(this.inputComment);
  }

  trackByComment(index: any, comment: CommentModel) {
    return comment.id;
  }

  refresh(newComment: boolean) {
    this.commentService.getByEntityId.call(this.inputComment);
  }
}
