import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { activityActions } from './activity.actions';
import { activitySelectors } from './activity.selector';
import { ActivityModel, ActivityStatus, ActivityStatusFlow } from './types';
import { Action } from '../view/types';

@Injectable()
export class ActivityService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ActivityModel, string> = this.genericApiCall(
    activityActions.getById,
    activitySelectors.getById,
    true,
  );
  createInstance: GenericApiCall<ActivityModel, void> = this.genericApiCall(
    activityActions.createInstance,
    activitySelectors.createInstance,
    true,
  );
  add: GenericApiCall<ActivityModel, ActivityModel> = this.genericApiCall(
    activityActions.add,
    activitySelectors.add,
    true,
  );
  update: GenericApiCall<ActivityModel, ActivityModel> = this.genericApiCall(
    activityActions.update,
    activitySelectors.update,
    true,
  );
  getStatusFlow: GenericApiCall<ActivityStatusFlow[], ActivityStatus> =
    this.genericApiCall(
      activityActions.getStatusFlow,
      activitySelectors.getStatusFlow,
      true,
    );
  getStatusActions: GenericApiCall<Action[], ActivityStatus> =
    this.genericApiCall(
      activityActions.getStatusActions,
      activitySelectors.getStatusActions,
      true,
    );
  markAsDone: GenericApiCall<ActivityModel, number> = this.genericApiCall(
    activityActions.markAsDone,
    activitySelectors.markAsDone,
    true,
  );
}
