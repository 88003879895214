import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { QuoteAction, quoteActions } from './quote.actions';
import { QuoteApiService } from './quote.api.service';
import { leadActions } from '../lead/lead.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class QuoteEffects {
  constructor(
    private readonly store: Store<any>,
    private readonly quoteApiService: QuoteApiService,
    private readonly action$: Actions<QuoteAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    quoteActions.getById,
    this.quoteApiService.getById
  );
  getComparatorQuotes = createApiCallEffects(
    this,
    this.action$,
    quoteActions.getComparatorQuotes,
    this.quoteApiService.getComparatorQuotes
  );
  addQuotes = createApiCallEffects(
    this,
    this.action$,
    quoteActions.addQuotes,
    this.quoteApiService.addQuotes,
    (value) => {
      this.store.dispatch(leadActions.getByUniqueId.refresh());
    }
  );
}
