<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <app-contact-form-content></app-contact-form-content>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.contactId">
      <app-history-panel
        [entityType]="'Contact'"
        [entityId]="this.contactId.toString()"
      ></app-history-panel>
    </div>
  </div>
</div>
