import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { Avatar } from '../../../../shared/store/user/type';
import { environment } from '../../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-kanban-view-contact',
  templateUrl: './kanban-view-contact.component.html',
  styleUrls: ['./kanban-view-contact.component.scss'],
})
export class KanbanViewContactComponent implements OnInit {
  currentAvatar$: Observable<Avatar>;
  @Input() data: any[] | undefined;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    let avatar = new Avatar(
      '',
      [
        environment.comparanooapiurl,
        environment.features.thumbnail.url,
        environment.features.thumbnail.avatar,
      ].join(''),
      window
        .getComputedStyle(document.body)
        .getPropertyValue('--comment-avatar-bg')
        .replace('#', ''),
    );

    this.currentAvatar$ = of(avatar);
  }

  onCardClick(id: string): void {
    this.router.navigate([
      this.router.url.substring(0, this.router.url.lastIndexOf('/')),
      'Details',
      id,
    ]);
  }
}
