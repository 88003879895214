import { Component, Input, OnInit } from '@angular/core';
import { filter } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';
import { Router } from '@angular/router';
import { DocumentService } from '../../../../shared/store/document/document.service';
import { DocumentModel } from '../../../../shared/store/document/types';

@Component({
  selector: 'app-kanban-view-document',
  templateUrl: './kanban-view-document.component.html',
  styleUrls: ['./kanban-view-document.component.scss'],
})
export class KanbanViewDocumentComponent {
  @Input() item: any;

  downloadFinished: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly documentService: DocumentService,
    private readonly fileSaverService: FileSaverService,
  ) {}

  onCardClick(id: string): void {
    this.router.navigate([
      this.router.url.substring(0, this.router.url.lastIndexOf('/')),
      'Details',
      id,
    ]);
  }

  onClickDownload(document: DocumentModel): void {
    const getFileSubscription = this.documentService.getFile.value$
      .pipe(
        // tap((x) => {
        //   console.log(x);
        // }),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result: Blob) => {
        this.fileSaverService.save(result, document.name, document.contentType);
        this.downloadFinished = true;
        this.documentService.getFile.reset();
      });

    this.documentService.getFile.call(document.id.toString());
  }
}
