<div
  class="tab-pane fade flex-even active show"
  id="insuranceDocuments"
  role="tabpanel"
  aria-labelledby="insuranceDocuments-tab"
  *ngIf="this.productCategory$ | async as productCategory"
>
  <div class="d-flex flex-column h-100">
    <!-- <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [gridOptions]="config"
      [rowData]="productCategory.insuranceDocuments"
      [animateRows]="true"
      (gridReady)="onGridReady($event)"
      [suppressMiddleClickScrolls]="true"
    ></ag-grid-angular> -->
    <div class="row mt-2">
      <table
        class="table table-striped border-bottom"
        *ngIf="productCategory.productCategoryDocumentTypes.length != 0"
      >
        <thead>
          <tr>
            <th>
              <a translate>PRODUCT_CATEGORY_LBL_DOCUMENTTYPE</a>
            </th>
            <th class="text-center">
              <a translate>PRODUCT_CATEGORY_LBL_MANDATORY</a>
            </th>
            <th class="text-center">
              <a translate>PRODUCT_CATEGORY_LBL_DOCUMENTFLOW</a>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let documentType of productCategory.productCategoryDocumentTypes"
            class="row-action"
          >
            <td>{{ documentType.documentTypeName }}</td>
            <td class="text-center">
              <input
                type="checkbox"
                [checked]="documentType.mandatory"
                disabled
              />
            </td>
            <td class="text-center" translate>{{ documentType.documentFlow === 'ToSend' ? 'PRODUCT_CATEGORY_LBL_DOCUMENTFLOW_TO_SEND' : 'PRODUCT_CATEGORY_LBL_DOCUMENTFLOW_TO_RECEIVE' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
