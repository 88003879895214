import { createAction, props } from '@ngrx/store';
import { createApiCallActions } from '../../../common/store/generic.actions';
import { ProductModel } from './types';

export const productActions = {
  getById: createApiCallActions<ProductModel>('product', 'getById'),
  createInstance: createApiCallActions<ProductModel>(
    'product',
    'createInstance'
  ),
  add: createApiCallActions<ProductModel>('product', 'add'),
  update: createApiCallActions<ProductModel>('product', 'update'),
  comparatorProducts: createAction(
    '[Product] Add Product to comparator',
    props<{ product: ProductModel }>()
  ),
};

export type ProductAction = typeof productActions;
