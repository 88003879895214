import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {
  onApiCall,
  onArrayApiCall,
} from 'src/app/common/store/generic.reducer';
import { IState, GenericState } from 'src/app/common/store/types';
import { navigationActions } from './navigation.actions';
import { Menu, Module } from './types';

export interface INavigationState extends IState<Module> {
  getModules: GenericState<Module[]>;
  getMenus: GenericState<Menu[]>;
}

export const navigationInitialState: INavigationState = {
  getModules: new GenericState<Module[]>(),
  getMenus: new GenericState<Menu[]>(),
};

const navigationReducers = [
  ...onApiCall<Module[]>(navigationActions.getModules, 'getModules'),
  ...onApiCall<Menu[]>(navigationActions.getMenus, 'getMenus'),
];

export const navigationReducer = createReducer(
  navigationInitialState,
  ...navigationReducers,
) as ActionReducer<INavigationState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return navigationReducer(state, action);
}
