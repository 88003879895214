import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { createApiCallEffects } from "src/app/common/store/generic.effects";
import { TitleAction, titleActions } from "./title.action";
import { TitleApiService } from "./title.api.service";
import { ITitleState } from "./title.reducer";

@Injectable()
export class TitleEffects {
  constructor(
    private readonly store: Store<ITitleState>,
    private readonly titleApiService: TitleApiService,
    private readonly action$: Actions<TitleAction>    
  ) {}
  
  getById = createApiCallEffects(
    this,
    this.action$,
    titleActions.getById,
    this.titleApiService.getById
  );

  postTitle = createApiCallEffects(
    this,
    this.action$,
    titleActions.postTitle,
    this.titleApiService.postTitle
  );

  updateTitle = createApiCallEffects(
    this,
    this.action$,
    titleActions.updateTitle,
    this.titleApiService.updateTitle
  );
}