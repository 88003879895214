<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.contactDetail$ | async as contactDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
          <div class="ps-3">
            <button
              type="button"
              [class]="ButtonType.Primary | buttontypepipe"
              class="btn-sm btn"
              translate
              (click)="openModalCreate(contactDetail.id)"
            >
              CONTACT_LBL_CONTACT_ADD
            </button>
          </div>

        <app-kanban-view-contacts />
      </div>
    </div>
  </div>
</div>
