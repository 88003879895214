import { GenericState, IState } from '../../../common/store/types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { historyActions } from './history.action';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {History} from "./types";

export interface IHistoryState extends IState<History> {
  getByEntityId: GenericState<History[]>;
}

export const historyInitialState: IHistoryState = {
  getByEntityId: new GenericState<History[]>(),
};

const historyReducers = [
  ...onApiCall<History[]>(historyActions.getByEntityId, 'getByEntityId'),
];

export const historyReducer = createReducer(
  historyInitialState,
  ...historyReducers,
) as ActionReducer<IHistoryState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return historyReducer(state, action);
}
