import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  @Input() address!: string | undefined | null;
  @Input() address2!: string | undefined | null;
  @Input() zipCode!: string | undefined | null;
  @Input() city!: string | undefined | null;
  @Input() country!: string | undefined | null;
}
