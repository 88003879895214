import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { InvoiceLineAction, invoiceLineActions } from './invoice-line.actions';
import { InvoiceLineApiService } from './invoice-line.api.service';
import { Store } from '@ngrx/store';
import { invoiceActions } from '../invoice/invoice.actions';

@Injectable()
export class InvoiceLineEffects {
  constructor(
    private readonly store: Store<any>,
    private readonly invoiceLineApiService: InvoiceLineApiService,
    private readonly action$: Actions<InvoiceLineAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    invoiceLineActions.getById,
    this.invoiceLineApiService.getById
  );
  updateById = createApiCallEffects(
    this,
    this.action$,
    invoiceLineActions.updateById,
    this.invoiceLineApiService.updateById,
    (value) => {
      setTimeout((x) => {
        this.store.dispatch(invoiceActions.getById.refresh());
      }, 200);
    }
  );
}
