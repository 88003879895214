import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AccountMoveModel } from '../../store/accounting/types';

@Pipe({
  name: 'showRepaymentAndTruncate',
})
export class ShowRepaymentAndTruncatePipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(values: AccountMoveModel[], ...args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';

    let repayments = '';

    if (values.length === 0) return '';

    for (let i = 0; i < values.length; i++) {
      const accountMove = values[i];

      if (!accountMove) continue;

      for (
        let index = 0;
        index < accountMove.accountMoveLines.length;
        index++
      ) {
        const accountMoveLine = accountMove.accountMoveLines[index];

        if (!accountMoveLine) continue;

        repayments += `${accountMove.name} (${this.currencyPipe.transform(accountMoveLine.unitPrice, 'EUR')})`;

        if (
          i != values.length - 1 ||
          index != accountMove.accountMoveLines.length - 1
        ) {
          repayments += ', ';
        }
      }
    }

    return repayments.length > limit
      ? repayments.substring(0, limit) + ' ' + trail
      : repayments;
  }
}
