import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { documentFeatureKey, DocumentModel } from './types';
import { IDocumentState } from './document.reducer';
import { InputByEntity } from '../comment/types';

const documentFeatureState =
  createFeatureSelector<IDocumentState>(documentFeatureKey);

export const documentSelectors = {
  getById: createApiCallSelectors<number, IDocumentState>(
    documentFeatureState,
    'getById',
  ),
  getByEntityId: createApiCallSelectors<InputByEntity, IDocumentState>(
    documentFeatureState,
    'getByEntityId',
  ),
  getFile: createApiCallSelectors<number, IDocumentState>(
    documentFeatureState,
    'getFile',
  ),
  add: createApiCallSelectors<DocumentModel, IDocumentState>(
    documentFeatureState,
    'add',
  ),
  update: createApiCallSelectors<DocumentModel, IDocumentState>(
    documentFeatureState,
    'update',
  ),
};
