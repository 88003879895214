import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { InvoiceAction, invoiceActions } from './invoice.actions';
import { InvoiceApiService } from './invoice.api.service';
import { invoiceSelectors } from './invoice.selector';
import { Store } from '@ngrx/store';

@Injectable()
export class InvoiceEffects {
  constructor(
    private readonly store: Store<any>,
    private readonly invoiceApiService: InvoiceApiService,
    private readonly action$: Actions<InvoiceAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    invoiceActions.getById,
    this.invoiceApiService.getById,
    () => {},
    () => {
      return this.store.select(invoiceSelectors.getById.paramsSelector);
    }
  );
}
