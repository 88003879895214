import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProductCategoryApiService } from './product-category.api.service';
import { ProductCategoryEffects } from './product-category.effects';
import {
  IProductCategoryState,
  productCategoryInitialState,
  productCategoryReducer,
} from './product-category.reducer';
import { ProductCategoryService } from './product-category.service';
import { productCategoryFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IProductCategoryState>(
      productCategoryFeatureKey,
      productCategoryReducer,
      {
        initialState: productCategoryInitialState,
      }
    ),
    EffectsModule.forFeature([ProductCategoryEffects]),
  ],
  providers: [ProductCategoryApiService, ProductCategoryService],
})
export class StoreProductCategoryModule {}
