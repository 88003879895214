import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { DocumentTypeService } from 'src/app/shared/store/document-type/document-type.service';
import { DocumentTypeModel } from 'src/app/shared/store/document-type/types';

@UntilDestroy()
@Component({
  selector: 'app-document-type-details',
  templateUrl: './document-type-details.component.html',
  styleUrls: ['./document-type-details.component.scss'],
})
export class DocumentTypeDetailsComponent implements OnInit, OnDestroy {
  documentTypeId: number;
  documentType$: Observable<DocumentTypeModel>;
  view$: Observable<View>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly documentTypeService: DocumentTypeService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
  ) {
    this.documentType$ = this.documentTypeService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.documentTypeId = this.route.snapshot.params['id'];
    this.documentTypeService.getById.call(this.documentTypeId.toString());

    this.documentType$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@documentTypeDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'documentTypeEdit') {
          this.router.navigate([
            'Documents/DocumentTypes/Edit/',
            this.documentTypeId,
          ]);
          this.subHeaderService.setTriggerAction('');
        }
      });
  }

  ngOnDestroy(): void {
    this.documentTypeService.getById.reset();
    this.breadcrumbService.set('@documentTypeDetails', ' ');
  }
}
