import { Component } from '@angular/core';

@Component({
  selector: 'app-document-type-create',
  templateUrl: './document-type-create.component.html',
  styleUrls: ['./document-type-create.component.scss']
})
export class DocumentTypeCreateComponent {

}
