import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuoteApiService } from './quote.api.service';
import { QuoteEffects } from './quote.effects';
import { IQuoteState, quoteInitialState, quoteReducer } from './quote.reducer';
import { QuoteService } from './quote.service';
import { quoteFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IQuoteState>(quoteFeatureKey, quoteReducer, {
      initialState: quoteInitialState,
    }),
    EffectsModule.forFeature([QuoteEffects]),
  ],
  providers: [QuoteApiService, QuoteService],
})
export class StoreQuoteModule {}
