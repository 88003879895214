import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ContactAction, contactActions } from './contact.actions';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { ContactApiService } from './contact.api.service';

@Injectable()
export class ContactEffects {
  constructor(
    private readonly contactApiService: ContactApiService,
    private readonly action$: Actions<ContactAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    contactActions.getById,
    this.contactApiService.getById,
  );

  postContact = createApiCallEffects(
    this,
    this.action$,
    contactActions.postContact,
    this.contactApiService.postContact,
  );

  updateContact = createApiCallEffects(
    this,
    this.action$,
    contactActions.updateContact,
    this.contactApiService.updateContact,
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    contactActions.createInstance,
    this.contactApiService.createInstance,
  );

  getChildren = createApiCallEffects(
    this,
    this.action$,
    contactActions.getChildren,
    this.contactApiService.getChildren,
  );
}
