import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { EmployeeModel } from './types';
import { employeeActions } from './employee.actions';
import { employeeSelectors } from './employee.selector';

@Injectable()
export class EmployeeService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<EmployeeModel, string> = this.genericApiCall(
    employeeActions.getById,
    employeeSelectors.getById,
    true,
  );

  getEmployeeModel: GenericApiCall<EmployeeModel, void> = this.genericApiCall(
    employeeActions.getEmployeeModel,
    employeeSelectors.getEmployeeModel,
    true,
  );
  postEmployee: GenericApiCall<EmployeeModel, EmployeeModel> =
    this.genericApiCall(
      employeeActions.postEmployee,
      employeeSelectors.postEmployee,
      true,
    );

  updateEmployee: GenericApiCall<EmployeeModel, EmployeeModel> =
    this.genericApiCall(
      employeeActions.updateEmployee,
      employeeSelectors.updateEmployee,
      true,
    );
}
