import { Component, OnInit } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Action, View } from '../../../../../shared/store/view/types';
import {
  ContractModel,
  ContractStatusFlow,
} from '../../../../../shared/store/contract/types';
import { ContractService } from '../../../../../shared/store/contract/contract.service';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';

@UntilDestroy()
@Component({
  selector: 'app-custom-actions-flow-contract',
  templateUrl: './custom-actions-flow-contract.component.html',
  styleUrls: ['./custom-actions-flow-contract.component.scss'],
})
export class CustomActionsFlowContractComponent implements OnInit {
  view$: Observable<View>;
  contractBase$: Observable<ContractModel>;
  contractStatusFlows$: Observable<ContractStatusFlow[]>;
  customActions$: Observable<Action[]>;
  contractId: number;

  constructor(
    private readonly contractService: ContractService,
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.contractBase$ = this.contractService.getByUniqueId.value$;
    this.contractStatusFlows$ = this.contractService.getStatusFlow.value$;
    this.customActions$ = this.contractService.getStatusActions.value$;

    this.contractBase$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((contractBase) => {
        this.contractService.getStatusFlow.call(contractBase.statusId);
        this.contractService.getStatusActions.call(contractBase.statusId);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'DraftConfirm') this.confirm();
        if (x == 'SaveAsDraft') this.saveAsDraft();
        if (x == 'RequestValidation') this.requestValidation();
        if (x == 'Validate') this.validate();
        if (x == 'Reject') this.reject();
        if (x == 'MarkAsRegularized') this.markAsRegularized();
        if (x == 'ToVerify') this.verify();
        if (x == 'SuspendContract') this.suspendContract();
        if (x == 'CloseContract') this.closeContract();

        this.subHeaderService.setTriggerAction('');
      });
  }
  triggerStatusFlow(actionName: string, contractId: number) {
    this.contractId = contractId;
    this.subHeaderService.setTriggerAction(actionName);
  }
  confirm() {
    this.contractService.confirm.call(this.contractId);
  }
  saveAsDraft() {
    this.contractService.saveAsDraft.call(this.contractId);
  }
  requestValidation() {
    this.contractService.requestValidation.call(this.contractId);
  }
  validate() {
    this.contractService.validate.call(this.contractId);
  }
  reject() {
    this.contractService.reject.call(this.contractId);
  }
  markAsRegularized() {
    this.contractService.markAsRegularized.call(this.contractId);
  }
  verify() {
    this.contractService.verify.call(this.contractId);
  }
  suspendContract() {
    this.contractService.suspendContract.call(this.contractId);
  }
  closeContract() {
    this.contractService.closeContract.call(this.contractId);
  }
}
