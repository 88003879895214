import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BankAccountCreateComponent } from './bank-account-create/bank-account-create.component';
import { BankAccountDetailsComponent } from './bank-account-details/bank-account-details.component';
import { BankAccountEditComponent } from './bank-account-edit/bank-account-edit.component';
import { BankAccountRoutingModule } from './bank-account-routing.module';
import { BankAccountEditCardComponent } from './shared/bank-account-edit-card/bank-account-edit-card.component';

@NgModule({
  declarations: [
    BankAccountDetailsComponent,
    BankAccountEditComponent,
    BankAccountCreateComponent,
    BankAccountEditCardComponent,
  ],
  imports: [CommonModule, SharedModule, BankAccountRoutingModule],
})
export class BankAccountModule {}
