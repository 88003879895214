import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LostReasonApiService } from './lost-reason.api.service';
import { LostReasonEffects } from './lost-reason.effects';
import {
  ILostReasonState,
  lostReasonInitialState,
  lostReasonReducer,
} from './lost-reason.reducer';
import { LostReasonService } from './lost-reason.service';
import { lostReasonFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<ILostReasonState>(
      lostReasonFeatureKey,
      lostReasonReducer,
      {
        initialState: lostReasonInitialState,
      },
    ),
    EffectsModule.forFeature([LostReasonEffects]),
  ],
  providers: [LostReasonApiService, LostReasonService],
})
export class StoreLostReasonModule {}
