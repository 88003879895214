export const activityTypeFeatureKey = 'activityType';
export type ActivityTypeModel = {
  id: number;
  name: string;
  description: string;
  delay: number;
  delayUnit: number;
  delayFrom: number;
  entityType: string;
  nextActivityPolicy: number;
  nextActivityTypeId?: number | undefined;
  defaultUserId?: number | undefined;
};
