import { historyFeatureKey, InputHistory } from './types';
import { createFeatureSelector } from '@ngrx/store';
import { IHistoryState } from './history.reducer';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';

const historyFeatureState =
  createFeatureSelector<IHistoryState>(historyFeatureKey);

export const historySelectors = {
  getByEntityId: createApiCallSelectors<InputHistory, IHistoryState>(
    historyFeatureState,
    'getByEntityId',
  ),
};
