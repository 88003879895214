import { Action, createReducer, on } from "@ngrx/store";
import { uploadFileInitialState, UploadStatus } from "./types";
import { uploadFileActions } from "./upload-file.actions";

const uploadFileReducers = [
  on(uploadFileActions.UPLOAD_REQUEST, (state: any, _action) => ({
    ...state,
    status: UploadStatus.Requested,
    progress: null,
    error: null,
  })),
  on(uploadFileActions.UPLOAD_CANCEL, (state: any, _action) => ({
    ...state,
    status: UploadStatus.Ready,
    progress: null,
    error: null,
  })),
  on(uploadFileActions.UPLOAD_RESET, (state: any, _action) => ({
    ...state,
    status: UploadStatus.Ready,
    progress: null,
    error: null,
  })),
  on(uploadFileActions.UPLOAD_FAILURE, (state: any, action) => ({
    ...state,
    status: UploadStatus.Failed,
    error: action.error,
    progress: null,
  })),
  on(uploadFileActions.UPLOAD_STARTED, (state: any, _action) => ({
    ...state,
    status: UploadStatus.Started,
    progress: 0,
  })),
  on(uploadFileActions.UPLOAD_PROGRESS, (state: any, action) => ({
    ...state,
    progress: action.progress,
  })),
  on(uploadFileActions.UPLOAD_COMPLETED, (state: any, _action) => ({
    ...state,
    status: UploadStatus.Completed,
    progress: 100,
    error: null,
  })),
];

const internalReducer = createReducer(
  uploadFileInitialState,
  ...uploadFileReducers
);

export function uploadFileReducer(state: any | undefined, action: Action) {
  return internalReducer(state, action);
}
