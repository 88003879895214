import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { ProductLineModel } from './types';
import { productLineActions } from './product-line.actions';
import { productLineSelectors } from './product-line.selector';

@Injectable()
export class ProductLineService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ProductLineModel, string> = this.genericApiCall(
    productLineActions.getById,
    productLineSelectors.getById,
    true
  );
  createInstance: GenericApiCall<ProductLineModel, void> = this.genericApiCall(
    productLineActions.createInstance,
    productLineSelectors.createInstance,
    true
  );
  add: GenericApiCall<ProductLineModel, ProductLineModel> = this.genericApiCall(
    productLineActions.add,
    productLineSelectors.add,
    true
  );
  update: GenericApiCall<ProductLineModel, ProductLineModel> =
    this.genericApiCall(
      productLineActions.update,
      productLineSelectors.update,
      true
    );
}
