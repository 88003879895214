import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error';
import { InsuranceCompanyModel } from './types';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';

@Injectable({
  providedIn: 'root',
})
export class InsuranceCompanyApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<InsuranceCompanyModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<InsuranceCompanyModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  createInstance = (): Observable<InsuranceCompanyModel> => {
    let parameters: string[];
    parameters = ['create'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<InsuranceCompanyModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  add = (
    insuranceCompanyModel: InsuranceCompanyModel,
  ): Observable<InsuranceCompanyModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<InsuranceCompanyModel>(url, insuranceCompanyModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  update = (
    insuranceCompanyModel: InsuranceCompanyModel,
  ): Observable<InsuranceCompanyModel> => {
    let parameters: string[];
    parameters = [insuranceCompanyModel.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<InsuranceCompanyModel>(url, insuranceCompanyModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.insuranceCompany.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
