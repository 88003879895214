<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container">
        <div class="card card-light w-100">
          <app-smart-actions [smartActions]="this.smartActions" />
          <div class="card-body">
            <div class="container-fluid">
              <app-insurance-company-details-content />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.insuranceCompanyId">
      <app-comment-panel
        [entityType]="'InsuranceCompany'"
        [entityId]="this.insuranceCompanyId.toString()"
      ></app-comment-panel>
    </div>
  </div>
</div>
