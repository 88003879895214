<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <div class="card-body">
      <div class="container-fluid">

        <form [formGroup]="activityTypeForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="activityTypeForm">
          <button type="submit" hidden="hidden"></button>
          <div class="row mb-2">
            <div class="col-9 mr-auto">
              <div class="form-group">
                <label class="form-title-label" for="name" translate>ACTIVITYTYPE_LBL_NAME</label>
                <input
                  class="form-control form-title"
                  id="name"
                  name="name"
                  [placeholder]="''"
                  type="text"
                  value=""
                  formControlName="name"
                  checkFormFieldValidity
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
