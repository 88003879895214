import { Observable } from 'rxjs';
import { Directive, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Directive({
  selector: '[appLoader]',
})
export class CommonLoaderDirective implements OnInit {
  @Input('appLoader') isLoading$: Observable<boolean>;
  @Input('appLoaderId') loaderId: string;

  constructor(private ngxService: NgxUiLoaderService) {}

  ngOnInit() {
    this.isLoading$.subscribe((isLoading) => {
      if (isLoading) {
        this.ngxService.startLoader(this.loaderId);
      } else {
        this.ngxService.stopLoader(this.loaderId);
      }
    });
  }
}
