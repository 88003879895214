import { createReducer, ActionReducer, Action } from "@ngrx/store";
import { onApiCall } from "src/app/common/store/generic.reducer";
import { IState, GenericState } from "src/app/common/store/types";
import { titleActions } from "./title.action";
import { Title } from "./types";

export interface ITitleState extends IState<Title> {
    getById: GenericState<Title>;
    postTitle: GenericState<any>;
    updateTitle: GenericState<any>;
}

export const titleInitialState: ITitleState = {
    getById: new GenericState<Title>(),
    postTitle: new GenericState<any>(),
    updateTitle: new GenericState<any>(),
};

const titleReducers = [
    ...onApiCall<Title>(titleActions.getById, 'getById'),
    ...onApiCall<any>(titleActions.postTitle, 'postTitle'),
    ...onApiCall<any>(titleActions.updateTitle, 'updateTitle'),
];

export const titleReducer = createReducer(
    titleInitialState,
    ...titleReducers
) as ActionReducer<ITitleState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
 return titleReducer(state, action);
}