<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
>
  <div class="d-flex flex-column h-100" *ngIf="this.accountMoves">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="ps-3">
          <button
            *ngIf="this.isClient"
            type="button"
            [class]="ButtonType.Primary | buttontypepipe"
            class="btn-sm btn"
            translate
            (click)="openModalCreateInvoice(contractId)"
          >
            CONTRACT_ACCOUNTMOVE_ADD
          </button>
          <button
            *ngIf="this.isClient"
            type="button"
            [class]="ButtonType.Light | buttontypepipe"
            class="btn-sm btn"
            translate
            (click)="openModalBindAccountMoves(contractId, 'Entry')"
          >
            PAYMENT_LBL_BIND_ACCOUNT_MOVE
          </button>
          <button
            type="button"
            [class]="ButtonType.Light | buttontypepipe"
            class="btn-sm btn"
            translate
            (click)="openModalRepaymentBindAccountMoves(contractId)"
          >
            REPAYMENT_LBL_BIND_ACCOUNT_MOVE
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <table class="table table-striped border-bottom">
        <thead>
        <tr>
          <th>
            <a translate>CONTRACT_TABLE_ACCOUNTMOVE_TYPE</a>
          </th>
          <th>
            <a translate>CONTRACT_TABLE_ACCOUNTMOVE_AMOUNT</a>
          </th>
          <th>
            <a translate>CONTRACT_TABLE_ACCOUNTMOVE_PERIOD</a>
          </th>
          <th>
            <a translate>CONTRACT_TABLE_ACCOUNTMOVE_NAME</a>
          </th>
          <th>
            <ng-container *ngIf="!isClient">
              <a translate>CONTRACT_TABLE_ACCOUNTMOVE_SLIP_BINDED</a>
            </ng-container>
          </th>
          <th>
            <a translate>CONTRACT_TABLE_ACCOUNTMOVE_BINDED</a>
          </th>
          <th>
            <a translate>CONTRACT_TABLE_ACCOUNTMOVE_STATUS</a>
          </th>
          <th>
            <a translate>PAYMENT_LBL_ACTIONS</a>
          </th>
          <th>
            <a translate>CONTRACT_TABLE_ACCOUNTMOVE_REPAYMENT_BINDED</a>
          </th>
        </tr>
        </thead>

        <tbody>
        <ng-container
          *ngFor="let accountMove of accountMoves; trackBy: trackByAccountMoveId"
          class="row-action"
        >
          <tr *ngFor="let accountMoveLine of accountMove.accountMoveLines; trackBy: trackByAccountMoveLineId">
            <td>{{ accountMove.name }}</td>
            <td>{{ accountMoveLine.unitPrice | currency : "EUR" }}</td>
            <td>
              {{ accountMove.period | accountMovePeriod }}
            </td>
            <td>
              {{ accountMove.reference }}
            </td>
            <td>
              <ng-container
                *ngIf="
                    !isClient &&
                    accountMove.journalName &&
                    accountMove.journalId
                  "
              >
                <a
                  role="button"
                  (click)="
                      openModalBindSlipDetails(
                        accountMove,
                        accountMove.journalId,
                        accountMove.journalName
                      )
                    "
                  translate
                >{{ accountMove.journalName }}
                </a>
              </ng-container>
            </td>
            <td *ngIf="accountMove.payingAccountMoves.length == 0">
              <ng-container *ngIf="this.isClient">
              <a
                role="button"
                (click)="
                      openModalBindAccountMoves(contractId,'Entry', accountMove.id )
                    "
                translate
              >PAYMENT_LBL_BIND_PAYMENT</a
              >
              </ng-container>
            </td>
            <td *ngIf="accountMove.payingAccountMoves.length > 0">
              <ng-container *ngIf="this.isClient">
                <a
                  role="button"
                  (click)="
                    openModalBindAccountMovesDetails(
                      accountMove,
                      'Payment'
                    )
                  "
                  translate
                >{{
                    accountMove.payingAccountMoves
                      | showPaymentAndTruncate : [50]
                  }}
                </a>
              </ng-container>
              <ng-container *ngIf="!this.isClient">
                <a
                  role="button"
                  (click)="
                    openModalBindAccountMovesDetails(
                      accountMove,
                      'Slip'
                    )
                  "
                  translate
                >{{
                    accountMove.payingAccountMoves
                      | showPaymentAndTruncate : [50]
                  }}
                </a>
              </ng-container>
            </td>
            <td>
              {{ accountMove.statusLoc }}
            </td>
            <td>
              <ng-container
                *ngFor="let action of accountMove.actions; let last = last;"
              >
                <a
                  role="button"
                  (click)="
                      this.triggerAction(
                        action.name,
                        accountMove.id,
                        contractId
                      )
                    "
                  translate
                >{{ action.actionName }}{{ last ? "" : " | " }}</a
                >
              </ng-container>
            </td>
            <td *ngIf="accountMove.repaymentAccountMoves.length == 0">
              <a
                role="button"
                (click)="openModalRepaymentBindAccountMoves(contractId, accountMove.id)"
                translate
              >REPAYMENT_LBL_BIND_ACCOUNT_MOVE</a
              >
            </td>
            <td *ngIf="accountMove.repaymentAccountMoves.length > 0">
              <a
                role="button"
                (click)="
                      openModalBindAccountMovesDetails(
                        accountMove,
                        'Repayment'
                      )
                    "
                translate
              >{{
                  accountMove.repaymentAccountMoves
                    | showRepaymentAndTruncate : [50]
                }}
              </a>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
