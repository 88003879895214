export const commentFeatureKey = 'comment';
export type CommentModel = {
  id: number;
  parentId: number | null;
  message: string;
  creationDate: Date | null;
  localeCreationDate: Date | null;
  userId: number | null;
  userContactName: string;
  userContactPicturePath: string;
  entityType: string;
  entityId: number;
};

export type InputByEntity = {
  entityType: string;
  entityId: number;
};
