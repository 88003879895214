<form [formGroup]="contactForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="contactForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row mb-2">
    <div class="col-12 mr-auto">

      <div class="row" *ngIf="!this.parentId">
        <div class="col-6">
          <div class="form-group">
            <div class="d-inline-block">
              <div class="form-check d-inline-block me-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="isCompany"
                  id="individual"
                  formControlName="isCompany"
                  [value]="false"
                  checkFormFieldValidity
                  checked
                />
                <label
                  class="form-check-label"
                  for="individual"
                  translate
                >
                  CONTACT_LBL_INDIVIDUAL
                </label>
              </div>
              <div class="form-check d-inline-block ms-2 me-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="isCompany"
                  id="company"
                  formControlName="isCompany"
                  checkFormFieldValidity
                  [value]="true"
                />
                <label
                  class="form-check-label"
                  for="company"
                  translate
                >
                  CONTACT_LBL_COMPANY
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div [ngClass]="{'col-6': source == SourceEnum.Component, 'col': source == SourceEnum.Modal}">
          <div class="form-group">
            <label
              class="form-title-label"
              translate
            >CONTACT_LBL_NAME</label
            >
            <input
              class="form-control form-title"
              type="text"
              formControlName="name"
              [placeholder]="!this.contactForm.controls['isCompany'].value ? ('CONTACT_PLACEHOLDER_NAME_LASTNAME_FIRSTNAME' | translate) : ''"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="col-auto ms-auto" *ngIf="source == SourceEnum.Component">

          <app-avatar-edit
            [picturePath]="this.imageUrl"
            [isCompany]="this.contactForm.controls['isCompany'].value"
            (fileEmitter)="onAvatarChange($event)" />
        </div>
      </div>
      <div class="row" *ngIf="source == SourceEnum.Component"
           [style.display]="this.contactForm.controls['isCompany'].value ? 'none' : 'flex'">
        <div class="col-6">
          <div class="form-group row">
            <div class="col">
              <app-typeahead
                [routeEndpoint]="'Contacts'"
                [entity]="Entity.Contact"
                [controlForm]="this.contactForm.controls['company']"
                [domain]="'name!=null,isCompany=true'"
                [orderBy]="'name asc'"
                [allowCustomActions]="false"
                [limitToList]="true"
                [placeholder]="'CONTACT_PLACEHOLDER_COMPANY' | translate"
              ></app-typeahead>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-12 col-md">
      <app-address-edit [form]="this.contactForm.controls['contactAddress']"></app-address-edit>
    </div>
    <div class="col">
      <!--      invisible-->
      <div class="form-group row collapse">
        <label class="col-4 col-form-label-sm" translate
        >Empty</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="empty"
            checkFormFieldValidity
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CONTACT_LBL_PHONE_NUMBER</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="phoneNumber"
            [placeholder]="'CONTACT_PLACEHOLDER_PHONE_NUMBER' | translate"
            mask="00 00 00 00 00"
            checkFormFieldValidity
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CONTACT_LBL_MOBILE_NUMBER</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="mobilePhoneNumber"
            [placeholder]="'CONTACT_PLACEHOLDER_MOBILE_NUMBER' | translate"
            mask="00 00 00 00 00"
            checkFormFieldValidity
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CONTACT_LBL_EMAIL</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            [placeholder]="'CONTACT_PLACEHOLDER_EMAIL' | translate"
            formControlName="email"
            checkFormFieldValidity
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CONTACT_LBL_TITLE</label
        >
        <div class="col">
          <app-typeahead
            [routeEndpoint]="'Titles'"
            [entity]="Entity.Title"
            [controlForm]="this.contactForm.controls['titleId']"
            [orderBy]="'id asc'"
            [limitToList]="true"
            [placeholder]="'CONTACT_PLACEHOLDER_TITLE' | translate"
          ></app-typeahead>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CONTACT_LBL_PROFESSION</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            [placeholder]="'CONTACT_PLACEHOLDER_PROFESSION' | translate"
            formControlName="profession"
            checkFormFieldValidity
          />
        </div>
      </div>

    </div>
  </div>
  <ng-container *ngIf="source == SourceEnum.Component">
    <app-contact-tabs-add-form *ngIf="!this.isEditMode" [form]="this.contactForm"/>
    <app-contact-tabs-edit-form *ngIf="this.isEditMode" [form]="this.contactForm"/>
  </ng-container>
</form>
