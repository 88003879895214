import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EnumModel, View, viewsFeatureKey as viewsFeatureKey } from './types';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { IViewState } from './view.reducers';

const viewFeatureState = createFeatureSelector<IViewState>(viewsFeatureKey);

export const viewSelectors = {
  getViews: createApiCallSelectors<View, IViewState>(
    viewFeatureState,
    'getViews'
  ),
  getEnumValues: createApiCallSelectors<EnumModel[], IViewState>(
    viewFeatureState,
    'getEnumValues'
  ),
  getViewType: createSelector(
    viewFeatureState,
    (state: IViewState) => state.viewType
  ),
};
