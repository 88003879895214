import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CurrentTimeToString } from '../datetime/type';
import { IGenericApiCallActions } from './generic.actions';

export const createApiCallEffects = <T>(
  _this: object,
  actions$: Actions<any>,
  actions: IGenericApiCallActions<T>,
  apiCall: (arg0: any) => Observable<T>,
  callBackfunc?: (value: T) => void,
  refreshParams$?: () => Observable<any>
) => {
  // calling effect
  var callingEffect$ = createEffect(() =>
    actions$.pipe(
      ofType(actions.calling),
      switchMap((action) => {
        return apiCall(action.params).pipe(
          map((value) => {
            return actions.callingSuccess({ value });
          }),
          catchError((error) => of(actions.callingFailed(error)))
        );
      })
    )
  );
  // refresh effect
  var refreshEffect$ = createEffect(() =>
    actions$.pipe(
      ofType(actions.refresh),
      switchMap((action) => {
        return refreshParams$!().pipe(
          take(1),
          mergeMap((refreshParams) => {
            return apiCall(refreshParams).pipe(
              map((value) => {
                return actions.callingSuccess({ value });
              }),
              catchError((error) => of(actions.callingFailed(error)))
            );
          })
        );
      })
    )
  );

  // calling success effect
  var callingSuccessEffect$ = createEffect(() =>
    actions$.pipe(
      ofType(actions.callingSuccess),
      switchMap((action) => {
        let result = of(
          actions.done({
            value: action.value,
          })
        );
        callBackfunc && callBackfunc(action.value);
        return result;
      })
    )
  );

  // Set the last update date effect
  var setLastUpdateDate$ = createEffect(() =>
    actions$.pipe(
      ofType(
        actions.done,
        actions.added,
        actions.addedOrUpdated,
        actions.updated,
        actions.deleted,
        actions.reset
      ),
      map((action) =>
        actions.setLastUpdateDate({
          value: CurrentTimeToString(),
        })
      )
    )
  );

  const actionName = actions.done.type;

  const result = {};
  result[actionName + 'CallingEffect$'] = callingEffect$;
  result[actionName + 'CallingSuccessEffect$'] = callingSuccessEffect$;
  result[actionName + 'SetLastUpdateDate$'] = setLastUpdateDate$;
  result[actionName + 'RefreshEffect$'] = refreshEffect$;

  Object.assign(_this, result);

  return result;
};
