import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinanceRoutingModule } from './finance-routing.module';
import { PaymentModeComponent } from './payment-mode/payment-mode.component';
import { CommissionComponent } from './commission/commission.component';
import { SharedModule } from '../../shared/shared.module';
import { PaymentModule } from './payment/payment.module';
import { InvoiceModule } from './invoice/invoice.module';

@NgModule({
  declarations: [PaymentModeComponent, CommissionComponent],
  imports: [
    CommonModule,
    FinanceRoutingModule,
    SharedModule,
    PaymentModule,
    InvoiceModule,
  ],
})
export class FinanceModule {}
