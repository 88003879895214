import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from '../../error';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  InputEnumDataTypeahead,
  InputReferenceDataTypeahead,
  InputTypeahead,
  InputTypeaheadQuickAdd,
  ListItem,
  ReferencesItem,
} from './types';
import { environment } from '../../../../environments/environment';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';
import { EnumModel } from '../view/types';

@Injectable({
  providedIn: 'root',
})
export class TypeaheadApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  get = (inputTypeahead: InputTypeahead): Observable<ListItem[]> => {
    let url =
      environment.comparanooapiurl +
      '/api/' +
      inputTypeahead.endpoint +
      '/listItem';
    // console.log(url)
    let params = new HttpParams();
    if (inputTypeahead.filter || inputTypeahead.domain) {
      let fullFilter = '';
      if (inputTypeahead.domain) fullFilter += inputTypeahead.domain;
      if (inputTypeahead.domain && inputTypeahead.filter) fullFilter += ',';
      if (inputTypeahead.filter) fullFilter += inputTypeahead.filter;

      params = params.append('filter', fullFilter);
    }
    params = params.append('pageSize', inputTypeahead.pageSize);
    if (inputTypeahead.orderBy) {
      params = params.append('orderBy', inputTypeahead.orderBy);
    }

    return this.httpClient
      .get<ListItem[]>(url, {
        params: params,
      })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  getReferencesItems = (
    inputReferenceDataTypeahead: InputReferenceDataTypeahead,
  ): Observable<ReferencesItem[]> => {
    let url =
      environment.comparanooapiurl +
      '/api/' +
      inputReferenceDataTypeahead.endpoint;
    // console.log(url)
    let params = new HttpParams();
    params = params.append('field', inputReferenceDataTypeahead.field);

    if (inputReferenceDataTypeahead.filterParentName) {
      params = params.append(
        'filterParentName',
        inputReferenceDataTypeahead.filterParentName ?? null,
      );
      params = params.append(
        'filterParentValue',
        inputReferenceDataTypeahead.filterParentValue ?? null,
      );
    }
    // params = params.append('orderBy', inputReferenceDataTypeahead.orderBy ?? null)

    return this.httpClient
      .get<ReferencesItem[]>(url, {
        params: params,
      })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  getEnumItems = (
    inputEnumDataTypeahead: InputEnumDataTypeahead,
  ): Observable<ListItem[]> => {
    let url =
      environment.comparanooapiurl + '/api/' + inputEnumDataTypeahead.endpoint;
    // console.log(url)
    let params = new HttpParams();
    params = params.append('enumType', inputEnumDataTypeahead.enumType);

    return this.httpClient
      .get<EnumModel[]>(url, {
        params: params,
      })
      .pipe(
        map((result) => {
          return result.map(
            (item) =>
              <ListItem>{ id: item.id, name: item.nameLoc, value: item.name },
          );
        }),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  quickAdd = (
    inputTypeaheadQuickAdd: InputTypeaheadQuickAdd,
  ): Observable<ListItem> => {
    let url =
      environment.comparanooapiurl +
      '/api/' +
      inputTypeaheadQuickAdd.endpoint +
      '/quickAdd';
    // console.log(url)

    return this.httpClient
      .post<ListItem>(
        url,
        { name: inputTypeaheadQuickAdd.name },
        { observe: 'response' },
      )
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
}
