import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {
  onApiCall
} from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { PaymentModel } from './types';
import { paymentActions } from './payment.actions';

export interface IPaymentState extends IState<PaymentModel> {
  getById: GenericState<PaymentModel>;
}

export const paymentInitialState: IPaymentState = {
  getById: new GenericState<PaymentModel>(),
};

const paymentReducers = [
  ...onApiCall<PaymentModel>(paymentActions.getById, 'getById'),
];

export const paymentReducer = createReducer(
  paymentInitialState,
  ...paymentReducers
) as ActionReducer<IPaymentState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return paymentReducer(state, action);
}