import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { UserAction, userActions } from './user.actions';
import { UserApiService } from './user.api.services';
import { IUserState } from './user.reducer';

@Injectable()
export class UserEffects {
  constructor(
    private readonly store: Store<IUserState>,
    private readonly userApiService: UserApiService,
    private readonly action$: Actions<UserAction>,
  ) {}

  get = createApiCallEffects(
    this,
    this.action$,
    userActions.get,
    this.userApiService.get,
  );

  getById = createApiCallEffects(
    this,
    this.action$,
    userActions.getById,
    this.userApiService.getById,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    userActions.add,
    this.userApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    userActions.update,
    this.userApiService.update,
  );

  lockout = createApiCallEffects(
    this,
    this.action$,
    userActions.lockout,
    this.userApiService.lockout,
  );

  getPaged = createApiCallEffects(
    this,
    this.action$,
    userActions.getPaged,
    this.userApiService.getPaged,
  );

  getCurrentUser = createApiCallEffects(
    this,
    this.action$,
    userActions.getCurrentUser,
    this.userApiService.getCurrentUser,
  );
}
