import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { productCategorySelectors } from './product-category.selector';
import { ProductCategoryModel } from './types';
import { productCategoryActions } from './product-category.actions';

@Injectable()
export class ProductCategoryService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ProductCategoryModel, string> = this.genericApiCall(
    productCategoryActions.getById,
    productCategorySelectors.getById,
    true
  );
  createInstance: GenericApiCall<ProductCategoryModel, void> =
    this.genericApiCall(
      productCategoryActions.createInstance,
      productCategorySelectors.createInstance,
      true
    );
  add: GenericApiCall<ProductCategoryModel, ProductCategoryModel> =
    this.genericApiCall(
      productCategoryActions.add,
      productCategorySelectors.add,
      true
    );
  update: GenericApiCall<ProductCategoryModel, ProductCategoryModel> =
    this.genericApiCall(
      productCategoryActions.update,
      productCategorySelectors.update,
      true
    );
}
