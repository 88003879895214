import { Pipe, PipeTransform } from '@angular/core';
import { AccountMoveModel } from '../../store/accounting/types';

@Pipe({
  name: 'showJournalAndTruncate',
})
export class ShowJournalAndTruncatePipe implements PipeTransform {
  transform(values: AccountMoveModel[], ...args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';

    let journals = '';

    if (values.length === 0) return '';

    const journalNames = Array.from(new Set(values.map((x) => x.journalName)));

    for (let index = 0; index < journalNames.length; index++) {
      const journalName = journalNames[index];

      if (!journalName) continue;

      journals += journalName;

      if (index != journalNames.length - 1) {
        journals += ', ';
      }
    }

    return journals.length > limit
      ? journals.substring(0, limit) + ' ' + trail
      : journals;
  }
}
