import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { LeadAction, leadActions } from './lead.actions';
import { LeadApiService } from './lead.api.services';
import { ILeadState } from './lead.reducer';
import { leadSelectors } from './lead.selectors';
import { commentActions } from '../comment/comment.action';

@Injectable()
export class LeadEffects {
  constructor(
    private readonly store: Store<any>,
    private readonly leadApiService: LeadApiService,
    private readonly actions$: Actions<LeadAction>,
  ) {}
  getByUniqueId = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getByUniqueId,
    this.leadApiService.getByUniqueId,
    () => {},
    () => {
      return this.store.select(leadSelectors.getByUniqueId.paramsSelector);
    },
  );
  getHealthDetail = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getHealthDetail,
    this.leadApiService.getHealthDetail,
  );
  getCarDetail = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getCarDetail,
    this.leadApiService.getCarDetail,
  );
  getHouseDetail = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getHouseDetail,
    this.leadApiService.getHouseDetail,
  );
  sendLead = createApiCallEffects(
    this,
    this.actions$,
    leadActions.sendLead,
    this.leadApiService.sendLead,
  );
  updateLeadDetail = createApiCallEffects(
    this,
    this.actions$,
    leadActions.updateLeadDetail,
    this.leadApiService.updateLeadDetail,
  );
  updateHealthDetail = createApiCallEffects(
    this,
    this.actions$,
    leadActions.updateHealthDetail,
    this.leadApiService.updateHealthDetail,
  );
  updateCarDetail = createApiCallEffects(
    this,
    this.actions$,
    leadActions.updateCarDetail,
    this.leadApiService.updateCarDetail,
  );
  updateHouseDetail = createApiCallEffects(
    this,
    this.actions$,
    leadActions.updateHouseDetail,
    this.leadApiService.updateHouseDetail,
  );
  createLead = createApiCallEffects(
    this,
    this.actions$,
    leadActions.createLead,
    this.leadApiService.createLead,
  );
  createHealthLead = createApiCallEffects(
    this,
    this.actions$,
    leadActions.createHealthLead,
    this.leadApiService.createHealthLead,
  );
  createCarLead = createApiCallEffects(
    this,
    this.actions$,
    leadActions.createCarLead,
    this.leadApiService.createCarLead,
  );
  createHouseLead = createApiCallEffects(
    this,
    this.actions$,
    leadActions.createHouseLead,
    this.leadApiService.createHouseLead,
  );
  getBaseLeadModel = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getBaseLeadModel,
    this.leadApiService.getBaseLeadModel,
  );
  getHealthLeadModel = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getHealthLeadModel,
    this.leadApiService.getHealthLeadModel,
  );
  getCarLeadModel = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getCarLeadModel,
    this.leadApiService.getCarLeadModel,
  );
  getHouseLeadModel = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getHouseLeadModel,
    this.leadApiService.getHouseLeadModel,
  );
  getStatusFlow = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getStatusFlow,
    this.leadApiService.getStatusFlow,
  );
  getStatusActions = createApiCallEffects(
    this,
    this.actions$,
    leadActions.getStatusActions,
    this.leadApiService.getStatusActions,
  );

  qualify = createApiCallEffects(
    this,
    this.actions$,
    leadActions.qualify,
    this.leadApiService.qualify,
    (value) => {
      this.store.dispatch(commentActions.getByEntityId.refresh());
      this.store.dispatch(leadActions.getByUniqueId.refresh());
    },
  );
  markAsWon = createApiCallEffects(
    this,
    this.actions$,
    leadActions.markAsWon,
    this.leadApiService.markAsWon,
    (value) => {
      this.store.dispatch(commentActions.getByEntityId.refresh());
      this.store.dispatch(leadActions.getByUniqueId.refresh());
    },
  );
  markAsLost = createApiCallEffects(
    this,
    this.actions$,
    leadActions.markAsLost,
    this.leadApiService.markAsLost,
    (value) => {
      this.store.dispatch(commentActions.getByEntityId.refresh());
      this.store.dispatch(leadActions.getByUniqueId.refresh());
    },
  );
  createContractRequest = createApiCallEffects(
    this,
    this.actions$,
    leadActions.createContractRequest,
    this.leadApiService.createContractRequest,
    (value) => {
      this.store.dispatch(commentActions.getByEntityId.refresh());
      this.store.dispatch(leadActions.getByUniqueId.refresh());
    },
  );
  reactivate = createApiCallEffects(
    this,
    this.actions$,
    leadActions.reactivate,
    this.leadApiService.reactivate,
    (value) => {
      this.store.dispatch(commentActions.getByEntityId.refresh());
      this.store.dispatch(leadActions.getByUniqueId.refresh());
    },
  );
}
