<form>
  <input name="ParentId" type="hidden" value=""/>
  <div class="row new-comment-container">
    <div class="col">
      <div class="row">
        <div class="col py-1">
          <input type="file" id="fileUpload"
                 #fileupload
                 [style.display]="'none'"
                 [multiple]="multiple"
                 (change)="onFileSelected($event)"
                  [accept]="this.acceptFiles"
          >
<!--          <span class="btn btn-sm btn-outline-primary" (click)="fileupload.click()" translate>-->
<!--            DOCUMENT_LBL_NEWDOCUMENT-->
<!--          </span>-->
        </div>
      </div>
    </div>
  </div>
</form>
