import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ActivityModel, activityFeatureKey, ActivityStatus } from './types';
import { IActivityState } from './activity.reducer';

const activityFeatureState =
  createFeatureSelector<IActivityState>(activityFeatureKey);

export const activitySelectors = {
  getById: createApiCallSelectors<ActivityModel, IActivityState>(
    activityFeatureState,
    'getById',
  ),

  createInstance: createApiCallSelectors<ActivityModel, IActivityState>(
    activityFeatureState,
    'createInstance',
  ),

  add: createApiCallSelectors<ActivityModel, IActivityState>(
    activityFeatureState,
    'add',
  ),

  update: createApiCallSelectors<ActivityModel, IActivityState>(
    activityFeatureState,
    'update',
  ),

  getStatusFlow: createApiCallSelectors<ActivityStatus, IActivityState>(
    activityFeatureState,
    'getStatusFlow',
  ),

  getStatusActions: createApiCallSelectors<ActivityStatus, IActivityState>(
    activityFeatureState,
    'getStatusActions',
  ),

  markAsDone: createApiCallSelectors<number, IActivityState>(
    activityFeatureState,
    'markAsDone',
  ),
};
