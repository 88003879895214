import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductCategoryModel } from 'src/app/shared/store/product-category/types';
import { ProductCategoryService } from 'src/app/shared/store/product-category/product-category.service';
import { ColDef, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-product-category-details-documents',
  templateUrl: './product-category-details-documents.component.html',
  styleUrls: ['./product-category-details-documents.component.scss'],
})
export class ProductCategoryDetailsDocumentComponent implements OnInit {
  productCategory$: Observable<ProductCategoryModel>;
  columnDefs: ColDef[];
  config: GridOptions = {};

  constructor(private readonly productCategoryService: ProductCategoryService) {
    this.productCategory$ = this.productCategoryService.getById.value$;
  }
  ngOnInit(): void {
    // this.config.rowSelection = 'single';
    // this.config.suppressCellFocus = true;
    // this.config.headerHeight = 37.2;
    // this.columnDefs = [
    //   { field: 'name', headerName: 'INSURANCEDOCUMENT_LBL_NAME' },
    //   { field: 'isMandatory', headerName: 'INSURANCEDOCUMENT_LBL_MANDATORY' },
    // ];
  }

  // onGridReady(params): void {
  //   params.api.sizeColumnsToFit();
  // }
}
