import { createApiCallActions } from '../../../common/store/generic.actions';
import { InvoiceLineModel } from './types';

export const invoiceLineActions = {
  getById: createApiCallActions<InvoiceLineModel>('invoiceLine', 'getById'),
  updateById: createApiCallActions<InvoiceLineModel>(
    'invoiceLine',
    'updateById'
  ),
};

export type InvoiceLineAction = typeof invoiceLineActions;
