<div class="sub-header" *ngIf="this.activityModel$ | async as activity">
  <div class="sub-header-row border-top align-items-center px-1">
    <div class="row">
      <div class="col" *ngIf="this.customActions$ | async as customActions">
        <a
          *ngFor="let action of customActions"
          [class]="action.buttonType | buttontypepipe"
          class="btn-sm btn"
          (click)="triggerStatusFlow(action.actionName, activity.id)"
        >
          {{ action.title }}
        </a>
      </div>

      <div
        class="col-auto state-breadcrumb-col"
        *ngIf="this.activityStatusFlows$ | async as activityStatus"
      >
        <div class="state-breadcrumb">
          <li
            *ngFor="let status of activityStatus"
            [ngClass]="
              activity.status == status.status
                ? ['active-' + status.statusColor]
                : ''
            "
          >
            {{ status.label }}
          </li>
        </div>
      </div>
    </div>
  </div>
</div>
