import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { productCoverageFeatureKey } from './types';
import { ProductCoverageApiService } from './product-coverage.api.service';
import { ProductCoverageEffects } from './product-coverage.effects';
import {
  IProductCoverageState,
  productCoverageReducer,
  productCoverageInitialState,
} from './product-coverage.reducer';
import { ProductCoverageService } from './product-coverage.service';

@NgModule({
  imports: [
    StoreModule.forFeature<IProductCoverageState>(
      productCoverageFeatureKey,
      productCoverageReducer,
      {
        initialState: productCoverageInitialState,
      }
    ),
    EffectsModule.forFeature([ProductCoverageEffects]),
  ],
  providers: [ProductCoverageApiService, ProductCoverageService],
})
export class StoreProductCoverageModule {}
