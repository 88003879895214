import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { insuranceCoverageActions } from './insurance-coverage.actions';
import { InsuranceCoverageModel } from './types';

export interface IInsuranceCoverageState
  extends IState<InsuranceCoverageModel> {
  getById: GenericState<InsuranceCoverageModel>;
  getByProductCategory: GenericState<InsuranceCoverageModel[]>;
}

export const insuranceCoverageInitialState: IInsuranceCoverageState = {
  getById: new GenericState<InsuranceCoverageModel>(),
  getByProductCategory: new GenericState<InsuranceCoverageModel[]>(),
};

const insuranceCoverageReducers = [
  ...onApiCall<InsuranceCoverageModel>(
    insuranceCoverageActions.getById,
    'getById'
  ),
  ...onApiCall<InsuranceCoverageModel[]>(
    insuranceCoverageActions.getByProductCategory,
    'getByProductCategory'
  ),
];

export const insuranceCoverageReducer = createReducer(
  insuranceCoverageInitialState,
  ...insuranceCoverageReducers
) as ActionReducer<IInsuranceCoverageState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return insuranceCoverageReducer(state, action);
}
