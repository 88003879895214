import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedQuoteCount',
})
export class SelectedQuoteCountPipe implements PipeTransform {
  transform(quoteArray: any[]): number {
    if (quoteArray == null) return 0;
    const count = quoteArray.filter((x) => x.checked).length;
    return count;
  }
}
