<form [formGroup]="activityForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="activityForm">
  <button type="submit" hidden="hidden"></button>
  <div class="row mb-2">
    <div class="col-6 mr-auto">
      <div class="form-group">
        <label class="form-title-label" for="name" translate>ACTIVITY_LBL_NAME</label>
        <input
          class="form-control form-title"
          id="name"
          name="name"
          [placeholder]="''"
          type="text"
          value=""
          formControlName="name"
          checkFormFieldValidity
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md">
      <div class="form-group row" *ngIf="true">
        <label for="activityType" class="col-4 col-form-label-sm" translate
        >ACTIVITY_LBL_ACTIVITY_TYPE</label
        >
        <div class="col">
          <app-typeahead
            id="activityType"
            [limitToList]="true"
            [routeEndpoint]="'ActivityTypes'"
            [controlForm]="this.activityForm.controls['activityTypeId']"
          />
        </div>
      </div>

      <div class="form-group row" *ngIf="true">
        <label for="summary" class="col-4 col-form-label-sm" translate
        >ACTIVITY_LBL_SUMMARY</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            id="summary"
            type="text"
            formControlName="summary"
            [placeholder]="''"
            checkFormFieldValidity
          />
        </div>
      </div>

    </div>
    <div class="col">
      <div class="form-group row" *ngIf="true">
        <label for="assignedTo" class="col-4 col-form-label-sm" translate
        >ACTIVITY_LBL_ASSIGNED_TO</label
        >
        <div class="col">
          <app-typeahead
            id="assignedTo"
            [routeEndpoint]="'Users'"
            [entity]="Entity.User"
            [controlForm]="this.activityForm.controls['assignedTo']"
            [orderBy]="'contactName asc'"
            [limitToList]="true"
          />
        </div>
      </div>

      <div class="form-group row" *ngIf="true">
        <label for="deadline" class="col-4 col-form-label-sm" translate
        >ACTIVITY_LBL_DEADLINE</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            id="deadline"
            type="date"
            formControlName="deadline"
            [placeholder]="''"
            checkFormFieldValidity
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md">

      <div class="form-group row">
        <div class="col">
              <textarea
                formControlName="notes"
                [placeholder]="'ACTIVITY_PLACEHOLDER_NOTES' | translate"
                class="form-control form-control-sm height-size"
              >
              </textarea>
        </div>
      </div>

    </div>
  </div>
</form>
