import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { DocumentTypeModel } from './types';
import { documentTypeActions } from './document-type.actions';
import { documentTypeSelectors } from './document-type.selector';
import { ListItem } from '../typeahead/types';

@Injectable()
export class DocumentTypeService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<DocumentTypeModel, string> = this.genericApiCall(
    documentTypeActions.getById,
    documentTypeSelectors.getById,
    true,
  );
  getListItem: GenericApiCall<
    ListItem[],
    { filter: string; pageSize: number; orderBy: string }
  > = this.genericApiCall(
    documentTypeActions.getListItem,
    documentTypeSelectors.getListItem,
    true,
  );
  createInstance: GenericApiCall<DocumentTypeModel, void> = this.genericApiCall(
    documentTypeActions.createInstance,
    documentTypeSelectors.createInstance,
    true,
  );
  add: GenericApiCall<DocumentTypeModel, DocumentTypeModel> =
    this.genericApiCall(
      documentTypeActions.add,
      documentTypeSelectors.add,
      true,
    );
  update: GenericApiCall<DocumentTypeModel, DocumentTypeModel> =
    this.genericApiCall(
      documentTypeActions.update,
      documentTypeSelectors.update,
      true,
    );

  setDocumentTypes = (documentTypes: DocumentTypeModel[]) => {
    this.store.dispatch(
      documentTypeActions.setDocumentTypes({ documentTypes: documentTypes }),
    );
  };

  getDocumentTypes = () =>
    this.store.select(documentTypeSelectors.getDocumentTypes);
}
