import { AppStoreModule } from './store/store.module';
import { CoreModule } from '../core/core.module';
import { NgModule } from '@angular/core';
import { AuthentificationService } from './authentification/authentification.service';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { GenericGridComponent } from './components/generic-grid/generic-grid.component';
import { ToastComponent } from './components/toast/toast.component';
import { BadgeComponent } from './components/badge/badge/badge.component';
import { MenuDropdownComponent } from './components/menu-dropdown/menu-dropdown.component';
import { CustomActionsFlowComponent } from './components/custom-actions-flow/custom-actions-flow.component';
import { DocumentSubheaderComponent } from './components/document-subheader/document-subheader.component';
import { ListSubheaderComponent } from './components/list-subheader/list-subheader.component';
import { FavoriteSaveComponent } from './components/favorite-save/favorite-save.component';
import { SearchBarBadgeComponent } from './components/search-bar-badge/search-bar-badge.component';
import { SearchCustomFilterComponent } from './components/search-custom-filter/search-custom-filter.component';
import {
  ButtonSubHeaderPipe,
  ButtonTypePipe,
} from './pipe/button.subheader.pipe';
import { RouterModule } from '@angular/router';
import { CommentPanelComponent } from './components/comment/comment-panel/comment-panel.component';
import { CommentAddComponent } from './components/comment/comment-add/comment-add.component';
import { CommentComponent } from './components/comment/comment/comment.component';
import { CommentNoneComponent } from './components/comment/comment-none/comment-none.component';
import { FriendlyDatePipe } from './pipe/friendly-date.pipe';
import { BadgeListComponent } from './components/badge/badge-list/badge-list.component';
import { ErrorModule } from './error';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { KpiComponent } from './components/kpi/kpi.component';
import { DashboardSubheaderComponent } from './components/dashboard-subheader/dashboard-subheader.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AddressComponent } from './components/address/address.component';
import { IbanPipe } from './pipe/iban.pipe';
import { IbanComponent } from './components/iban/iban.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { PhoneNumberPipe } from './pipe/phone-number.pipe';
import { FullPathImagePipe } from './pipe/full-path-image.pipe';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ActionsComponent } from './components/actions/actions.component';
import { FormAccessibleDirective } from './directives/form-accessible.directive';
import { CategoryChoiceComponent } from './components/category-choice/category-choice.component';
import { FormValidationMessageComponent } from './components/form-validation-message/form-validation-message.component';
import { CheckFormFieldValidityDirective } from './directives/check-form-field-validity.directive';
import { FormHasUnsavedDataDirective } from './directives/form-has-unsaved-data.directive';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { GroupByPipe } from './pipe/group-by.pipe';
import { IbanValidatorDirective } from './directives/iban-validator.directive';
import { SearchFiltersDropdownComponent } from './components/search-filters-dropdown/search-filters-dropdown.component';
import { ToastValidationComponent } from './components/toast-validation/toast-validation.component';
import { ToastValidationContentComponent } from './components/toast-validation/toast-validation-content/toast-validation-content.component';
import { ToastCssPipe } from './pipe/toast-css.pipe';
import { PaginationLabelPipe } from './components/pagination/paginationLabel.pipe';
import { SmartActionsComponent } from './components/smart-actions/smart-actions.component';
import { KanbanViewComponent } from './components/kanban-view/kanban-view.component';
import { FormattedDomainPipe } from './components/search-bar-badge/formatted-domain.pipe';
import { IsDomainActivePipe } from './components/search-filters-dropdown/is-domain-active.pipe';
import { IsBuiltInActivePipe } from './components/search-filters-dropdown/is-built-in-active.pipe';
import { SmartActionsLeadComponent } from './components/smart-actions-lead/smart-actions-lead.component';
import { CardActivePipe } from './pipe/card-active.pipe';
import { BankAccountComponent } from './components/bank-account-edit/bank-account.component';
import { AddressEditComponent } from './components/address-edit/address-edit.component';
import { MailToLinkComponent } from './components/mail-to-link/mail-to-link.component';
import { DocumentComponent } from './components/document/document/document.component';
import { DocumentPanelComponent } from './components/document/document-panel/document-panel.component';
import { DocumentAddComponent } from './components/document/document-add/document-add.component';
import { FileSizePipe } from './pipe/file-size.pipe';
import { HistoryPanelComponent } from './components/history/history-panel/history-panel.component';
import { IsHistoryTypeActivePipe } from './components/history/is-history-type-active.pipe';
import { ComparatorSubheaderComponent } from './components/comparator-subheader/comparator-subheader.component';
import { LeadQuotesComponent } from './components/lead-quotes/lead-quotes.component';
import { DocumentRequestComponent } from './components/document/document-request/document-request.component';
import { DocumentRequestCardComponent } from './components/document/document-request-card/document-request-card.component';
import { WholeNumberPipe } from './pipe/whole-number.pipe';
import { DecimalNumberPipe } from './pipe/decimal-number.pipe';
import { QuoteArrayCheckedPipe } from './components/lead-quotes/quote-array-checked.pipe';
import { SelectedQuoteCountPipe } from './components/lead-quotes/selected-quote-count.pipe';
import { ListSubheaderEmptyComponent } from './components/empty/list-subheader-empty/list-subheader-empty.component';
import { KanbanViewEmptyComponent } from './components/empty/kanban-view-empty/kanban-view-empty.component';
import { FirstLetterToUppercasePipe } from './pipe/first-letter-to-uppercase.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { DataListingProviderComponent } from './components/data-listing-provider/data-listing-provider.component';
import { ViewTypePipe } from './pipe/viewType.pipe';
import { ProductCategoryEditComponent } from './components/product-category-edit/product-category-edit.component';
import { AvatarEditComponent } from './components/avatar-edit/avatar-edit.component';
import { DocumentCardComponent } from './components/document/document-card/document-card.component';
import { DocumentFormContentComponent } from './components/document/document-form-content/document-form-content.component';
import { AccountMoveAddContentComponent } from './components/account-moves/account-move-add-content/account-move-add-content.component';
import { AccountMoveAssignContentComponent } from './components/account-moves/account-move-assign-content/account-move-assign-content.component';
import { AccountMoveAssignDetailsContentComponent } from './components/account-moves/account-move-assign-details-content/account-move-assign-details-content.component';
import { KanbanViewContactComponent } from '../features/contacts/contact/kanban-view-contact/kanban-view-contact.component';
import { KanbanViewLeadComponent } from '../features/crm/lead/kanban-view-lead/kanban-view-lead.component';
import { CustomActionsFlowContractComponent } from '../features/crm/contract/contract-details/custom-actions-flow-contract/custom-actions-flow-contract.component';
import { CustomActionsFlowLeadComponent } from '../features/crm/lead/lead-details/custom-actions-flow-lead/custom-actions-flow-lead.component';
import { CustomActionsFlowGlossaryComponent } from '../features/cms/glossary/glossary-detail/custom-actions-flow-glossary/custom-actions-flow-glossary.component';
import { CustomActionsFlowArticleComponent } from '../features/cms/article/article-detail/custom-actions-flow-article/custom-actions-flow-article.component';
import { KanbanViewContractComponent } from '../features/crm/contract/kanban-view-contract/kanban-view-contract.component';
import { KanbanViewContactsComponent } from '../features/contacts/contact/kanban-view-contacts/kanban-view-contacts.component';
import { KanbanViewEmployeeComponent } from '../features/human-resources/employee/kanban-view-employee/kanban-view-employee.component';
import { CustomActionsFlowUserComponent } from '../features/configuration/user/user/user-details/custom-actions-flow-user/custom-actions-flow-user.component';
import { KanbanViewDocumentsComponent } from '../features/documents/document/kanban-view-documents/kanban-view-documents.component';
import { CustomActionsFlowActivityComponent } from '../features/crm/activity/activity-details/custom-actions-flow-activity/custom-actions-flow-activity.component';
import { PaymentAddContentComponent } from './components/account-moves/payment-add-content/payment-add-content.component';
import { KanbanViewDocumentComponent } from '../features/documents/document/kanban-view-document/kanban-view-document.component';
import { GenericFileInputComponent } from './components/generic-file-input/generic-file-input.component';
import { SlipAddContentComponent } from './components/account-moves/slip-add-content/slip-add-content.component';
import { AccountMoveAssignRepaymentContentComponent } from './components/account-moves/account-move-assign-repayment-content/account-move-assign-repayment-content.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ShowJournalAndTruncatePipe } from './pipe/accounting/show-journal-and-truncate.pipe';
import { AccountMoveAssignSlipContentComponent } from './components/account-moves/account-move-assign-slip-content/account-move-assign-slip-content.component';
import { ShowPaymentAndTruncatePipe } from './pipe/accounting/show-payment-and-truncate.pipe';
import { ShowRepaymentAndTruncatePipe } from './pipe/accounting/show-repayment-and-truncate.pipe';
import { ShowInvoiceAndTruncatePipe } from './pipe/accounting/show-invoice-and-truncate.pipe';
import { AccountMovesAmountPipe } from './components/account-moves/account-move-assign-details-content/accountmoves-amount.pipe';
import { PeriodPipe } from './pipe/accounting/period.pipe';
import { AccountMoveAssignSlipDetailsContentComponent } from './components/account-moves/account-move-assign-slip-details-content/account-move-assign-slip-details-content.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    PaginationComponent,
    GenericGridComponent,
    ToastComponent,
    BadgeComponent,
    BadgeListComponent,
    MenuDropdownComponent,
    CustomActionsFlowComponent,
    CustomActionsFlowContractComponent,
    CustomActionsFlowLeadComponent,
    CustomActionsFlowGlossaryComponent,
    CustomActionsFlowArticleComponent,
    DocumentSubheaderComponent,
    ListSubheaderComponent,
    FavoriteSaveComponent,
    SearchBarBadgeComponent,
    SearchBarComponent,
    SearchCustomFilterComponent,
    SearchFiltersDropdownComponent,
    ButtonSubHeaderPipe,
    CommentPanelComponent,
    CommentAddComponent,
    CommentComponent,
    CommentNoneComponent,
    FriendlyDatePipe,
    DashboardComponent,
    KpiComponent,
    DashboardSubheaderComponent,
    ButtonTypePipe,
    BreadcrumbComponent,
    AddressComponent,
    IbanPipe,
    IbanComponent,
    PhoneNumberComponent,
    PhoneNumberPipe,
    FullPathImagePipe,
    TypeaheadComponent,
    ModalContentComponent,
    ActionsComponent,
    FormAccessibleDirective,
    CategoryChoiceComponent,
    FormValidationMessageComponent,
    CheckFormFieldValidityDirective,
    FormHasUnsavedDataDirective,
    ConfirmationComponent,
    GroupByPipe,
    IbanValidatorDirective,
    ToastValidationComponent,
    ToastValidationContentComponent,
    ToastCssPipe,
    FormattedDomainPipe,
    PaginationLabelPipe,
    SmartActionsComponent,
    KanbanViewComponent,
    KanbanViewContactComponent,
    KanbanViewLeadComponent,
    KanbanViewContractComponent,
    IsDomainActivePipe,
    IsBuiltInActivePipe,
    SmartActionsLeadComponent,
    KanbanViewContactsComponent,
    KanbanViewEmployeeComponent,
    CardActivePipe,
    CustomActionsFlowUserComponent,
    BankAccountComponent,
    AddressEditComponent,
    MailToLinkComponent,
    DocumentComponent,
    DocumentPanelComponent,
    DocumentAddComponent,
    KanbanViewDocumentsComponent,
    KanbanViewDocumentComponent,
    FileSizePipe,
    HistoryPanelComponent,
    IsHistoryTypeActivePipe,
    ComparatorSubheaderComponent,
    DocumentRequestComponent,
    LeadQuotesComponent,
    DocumentRequestCardComponent,
    WholeNumberPipe,
    DecimalNumberPipe,
    QuoteArrayCheckedPipe,
    SelectedQuoteCountPipe,
    KanbanViewEmptyComponent,
    ListSubheaderEmptyComponent,
    FirstLetterToUppercasePipe,
    LoaderComponent,
    MultiSelectDropdownComponent,
    DataListingProviderComponent,
    ViewTypePipe,
    ProductCategoryEditComponent,
    AvatarEditComponent,
    CustomActionsFlowActivityComponent,
    DocumentCardComponent,
    DocumentFormContentComponent,
    PaymentAddContentComponent,
    AccountMoveAddContentComponent,
    AccountMoveAssignContentComponent,
    AccountMoveAssignDetailsContentComponent,
    GenericFileInputComponent,
    SlipAddContentComponent,
    AccountMoveAssignRepaymentContentComponent,
    AccountMoveAssignSlipContentComponent,
    ShowPaymentAndTruncatePipe,
    ShowRepaymentAndTruncatePipe,
    ShowInvoiceAndTruncatePipe,
    ShowJournalAndTruncatePipe,
    AccountMovesAmountPipe,
    PeriodPipe,
    AccountMoveAssignSlipDetailsContentComponent,
  ],
  exports: [
    AppStoreModule,
    CoreModule,
    FileUploadComponent,
    GenericGridComponent,
    PaginationComponent,
    ToastComponent,
    BadgeComponent,
    BadgeListComponent,
    MenuDropdownComponent,
    CustomActionsFlowComponent,
    CustomActionsFlowContractComponent,
    CustomActionsFlowLeadComponent,
    CustomActionsFlowGlossaryComponent,
    CustomActionsFlowArticleComponent,
    DocumentSubheaderComponent,
    ListSubheaderComponent,
    FavoriteSaveComponent,
    SearchBarBadgeComponent,
    SearchBarComponent,
    SearchCustomFilterComponent,
    SearchFiltersDropdownComponent,
    ButtonSubHeaderPipe,
    CommentPanelComponent,
    CommentAddComponent,
    CommentComponent,
    CommentNoneComponent,
    FriendlyDatePipe,
    DashboardComponent,
    KpiComponent,
    DashboardSubheaderComponent,
    ButtonTypePipe,
    BreadcrumbComponent,
    AddressComponent,
    IbanPipe,
    IbanComponent,
    PhoneNumberComponent,
    PhoneNumberPipe,
    FullPathImagePipe,
    TypeaheadComponent,
    ModalContentComponent,
    ActionsComponent,
    FormAccessibleDirective,
    CategoryChoiceComponent,
    FormValidationMessageComponent,
    CheckFormFieldValidityDirective,
    FormHasUnsavedDataDirective,
    ConfirmationComponent,
    GroupByPipe,
    IbanValidatorDirective,
    ToastValidationComponent,
    SmartActionsComponent,
    KanbanViewComponent,
    KanbanViewContactComponent,
    KanbanViewContractComponent,
    KanbanViewLeadComponent,
    SmartActionsLeadComponent,
    KanbanViewContactsComponent,
    KanbanViewEmployeeComponent,
    CardActivePipe,
    CustomActionsFlowUserComponent,
    AddressEditComponent,
    MailToLinkComponent,
    KanbanViewDocumentsComponent,
    KanbanViewDocumentComponent,
    FileSizePipe,
    HistoryPanelComponent,
    IsHistoryTypeActivePipe,
    ComparatorSubheaderComponent,
    LeadQuotesComponent,
    DocumentRequestComponent,
    WholeNumberPipe,
    DecimalNumberPipe,
    QuoteArrayCheckedPipe,
    SelectedQuoteCountPipe,
    KanbanViewEmptyComponent,
    ListSubheaderEmptyComponent,
    FirstLetterToUppercasePipe,
    LoaderComponent,
    MultiSelectDropdownComponent,
    DataListingProviderComponent,
    ViewTypePipe,
    ProductCategoryEditComponent,
    AvatarEditComponent,
    CustomActionsFlowActivityComponent,
    BankAccountComponent,
    DocumentCardComponent,
    DocumentFormContentComponent,
    PaymentAddContentComponent,
    AccountMoveAddContentComponent,
    GenericFileInputComponent,
    AccountMoveAssignRepaymentContentComponent,
    ShowPaymentAndTruncatePipe,
    ShowRepaymentAndTruncatePipe,
    ShowInvoiceAndTruncatePipe,
    ShowJournalAndTruncatePipe,
    AccountMovesAmountPipe,
  ],
  providers: [AuthentificationService, CurrencyPipe, DatePipe],
  imports: [CoreModule, AppStoreModule, RouterModule, ErrorModule],
})
export class SharedModule {}
