import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  DocumentTypeAction,
  documentTypeActions,
} from './document-type.actions';
import { DocumentTypeApiService } from './document-type.api.service';

@Injectable()
export class DocumentTypeEffects {
  constructor(
    private readonly documentTypeApiService: DocumentTypeApiService,
    private readonly action$: Actions<DocumentTypeAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    documentTypeActions.getById,
    this.documentTypeApiService.getById,
  );

  getListItem = createApiCallEffects(
    this,
    this.action$,
    documentTypeActions.getListItem,
    this.documentTypeApiService.getListItem,
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    documentTypeActions.createInstance,
    this.documentTypeApiService.createInstance,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    documentTypeActions.add,
    this.documentTypeApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    documentTypeActions.update,
    this.documentTypeApiService.update,
  );
}
