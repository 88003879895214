import { createApiCallActions } from '../../../common/store/generic.actions';
import { LostReasonModel } from './types';

export const lostReasonActions = {
  getById: createApiCallActions<LostReasonModel>(
    'lostReason',
    'getById',
  ),
  createInstance: createApiCallActions<LostReasonModel>(
    'lostReason',
    'createInstance',
  ),
  add: createApiCallActions<LostReasonModel>('lostReason', 'add'),
  update: createApiCallActions<LostReasonModel>(
    'lostReason',
    'update',
  ),
};

export type LostReasonAction = typeof lostReasonActions;
