import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FileUploadState, uploadFileInitialState } from './types';
import { UploadFileApiService } from './upload-file.api.service';
import { UploadFileEffects } from './upload-file.effects';
import { uploadFileReducer } from './upload-file.reducer';
import { UploadFileService } from './upload-file.service.facade';

@NgModule({
  imports: [
    StoreModule.forFeature<FileUploadState>('uploadFile', uploadFileReducer, {
      initialState: uploadFileInitialState,
    }),
    EffectsModule.forFeature([UploadFileEffects]),
  ],
  providers: [UploadFileApiService, UploadFileService],
})
export class StoreUploadFileModule {}
