import { GenericState, IState } from 'src/app/common/store/types';
import { CountryModel } from './types';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { countryActions } from './country.actions';

export interface ICountryState extends IState<CountryModel> {
  getById: GenericState<CountryModel>;
  postCountry: GenericState<CountryModel>;
  updateCountry: GenericState<CountryModel>;
}

export const countryInitialState: ICountryState = {
  getById: new GenericState<CountryModel>(),
  postCountry: new GenericState<CountryModel>(),
  updateCountry: new GenericState<CountryModel>(),
};

const countryReducers = [
  ...onApiCall<CountryModel>(countryActions.getById, 'getById'),
  ...onApiCall<CountryModel>(countryActions.postCountry, 'postCountry'),
  ...onApiCall<CountryModel>(countryActions.updateCountry, 'updateCountry'),
];

export const countryReducer = createReducer(
  countryInitialState,
  ...countryReducers,
) as ActionReducer<ICountryState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return countryReducer(state, action);
}
