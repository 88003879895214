import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'period',
})
export class PeriodPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(periodStartDate: Date, periodEndDate: Date): string {
    const startDate = new Date(periodStartDate).setHours(0, 0, 0, 0);
    const endDate = new Date(periodEndDate).setHours(0, 0, 0, 0);

    const dateStartString = this.datePipe.transform(
      periodStartDate,
      'dd/MM/yyyy',
    );
    const dateEndString = this.datePipe.transform(periodEndDate, 'dd/MM/yyyy');

    if (dateStartString === '01/01/0001' || dateEndString === '01/01/0001')
      return '-';

    let period = '';

    if (startDate < endDate) {
      period = dateStartString + ' - ' + dateEndString;
    } else {
      if (startDate == endDate) {
        period = dateEndString + '';
      } else {
        period = dateEndString + ' - ' + dateStartString;
      }
    }

    return period;
  }
}
