import { Component } from '@angular/core';

@Component({
  selector: 'app-list-subheader-empty',
  templateUrl: './list-subheader-empty.component.html',
  styleUrls: ['./list-subheader-empty.component.scss']
})
export class ListSubheaderEmptyComponent {

}
