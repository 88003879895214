export const searchFeatureKey = 'search';

export type Field = {
  name: string;
  label: string;
  type: TypeGroup;
};
export type Operation = {
  id: string;
  label: string;
  numberOfValues: number;
};
export type OperationModel = {
  type: TypeGroup;
  operations: Operation[];
};
export type Domain = {
  field: string;
  fieldLabel: string;
  operator: Operation;
  value: any;
  value2: any;
  connector: Connector;
  text: string;
  text2: string;
  isActive: boolean;
};
export type Filter = {
  name: string;
  tag: FilterType;
  domains: Domain[];
  text: string;
  // isActive: boolean;
};
export type SearchView = {
  name: string; //Search.Leads
  entity: string; //Lead
  title: string; //Prospects
  inputPlaceholder: string; //Rechercher un prospect
  description: string; //Liste de tous les prospects
  filters: Filter[]; //Liste des built in filters
  fields: string[]; //["Name","ZipCodeModel","Age"]
  view: string[]; //["grid","cards","charts"]
  grid: string[]; //TODO: store grid configuration
  cards: string[]; //TODO: store cards configuration
};
export type FavoriteSearchView = {
  name: string; //ex: Search.Leads.LeadsOfTheDay
  searchView: string; //Search.Leads
  filters: Filter[]; //
  isPublic: boolean;
  userId: string; //author of the favorite view, or owner if not public
};
export type SearchRequest = {
  url: string;
  pageIndex: number;
  pageSize: number;
  filters: Filter[];
  sortBy: string;
  orderBy: string;
};
export type FilterType =
  | 'TextFilter'
  | 'BuiltInFilter'
  | 'CustomFilter'
  | 'SmartActionFilter';
export type Connector = 'And' | 'Or';
export type TypeGroup = 'Text' | 'Number' | 'Boolean' | 'Date';
