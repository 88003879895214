import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ActivityTypeApiService } from './activity-type.api.service';
import { ActivityTypeEffects } from './activity-type.effects';
import {
  IActivityTypeState,
  activityTypeInitialState,
  activityTypeReducer,
} from './activity-type.reducer';
import { ActivityTypeService } from './activity-type.service';
import { activityTypeFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IActivityTypeState>(
      activityTypeFeatureKey,
      activityTypeReducer,
      {
        initialState: activityTypeInitialState,
      },
    ),
    EffectsModule.forFeature([ActivityTypeEffects]),
  ],
  providers: [ActivityTypeApiService, ActivityTypeService],
})
export class StoreActivityTypeModule {}
