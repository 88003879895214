import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { View } from '../../../shared/store/view/types';
import { ViewService } from '../../../shared/store/view/views.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { customValidator } from '../../../shared/validators/custom-validator.validators';
import { matchPasswordValidator } from '../../../shared/validators/match-password.validator';
import { AuthenticationService } from '../../../shared/store/authentication/authentication.service';
import { UserConnected } from '../../../shared/store/authentication/types';
import { SubHeaderService } from '../../../shared/store/subheader/subheader.service';
import { FormService } from '../../../shared/store/form/form.service';
import { SourceEnum } from '../../../shared/store/typeahead/types';
import { take } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Component({
  selector: 'app-password-manage-content',
  templateUrl: './password-manage-content.component.html',
  styleUrls: ['./password-manage-content.component.scss'],
})
export class PasswordManageContentComponent implements OnInit, OnDestroy {
  @Input() modalName: string;
  @Input() source: SourceEnum;

  changePasswordForm: FormGroup;
  userConnected$: Observable<UserConnected>;
  userEmail: string;

  value$: Observable<any>;
  error$: Observable<HttpErrorResponse | null>;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly subHeaderService: SubHeaderService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    protected readonly activeModalService: NgbActiveModal
  ) {
    this.userConnected$ = this.authenticationService.getUserConnected();
    this.value$ = this.authenticationService.changePassword.value$;
    this.error$ = this.authenticationService.changePassword.error$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'changePassword') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.userConnected$.pipe(filter((x) => !!x)).subscribe((user) => {
      this.userEmail = user.email;
    });

    this.changePasswordForm = this.formBuilder.group({
      current: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          customValidator(/\d/),
          customValidator(/[A-Z]/),
          customValidator(/[a-z]/),
        ],
      ],
      passwordConfirm: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          customValidator(/\d/),
          customValidator(/[A-Z]/),
          customValidator(/[a-z]/),
        ],
      ],
    });

    this.changePasswordForm.setValidators(matchPasswordValidator);
    this.changePasswordForm.updateValueAndValidity();
  }

  onSubmit(): void {
    if (this.changePasswordForm.invalid) {
      console.log(this.changePasswordForm);
      this.changePasswordForm.markAllAsTouched();
      this.formService.countErrors(this.changePasswordForm, true);
      this.formService.setEntityErrors('ACCOUNT');
      return;
    }

    this.formService.clear();

    this.authenticationService.changePassword.call({
      currentPassword: this.changePasswordForm.value.current!,
      newPassword: this.changePasswordForm.value.password!,
      email: this.userEmail,
    });
    this.value$.pipe(untilDestroyed(this)).subscribe((result) => {
      this.error$.pipe(take(1)).subscribe((error) => {
        if (error === null) {
          if (result != undefined) {
            this.changePasswordForm.reset();
            this.activeModalService.dismiss(-1);
            this.authenticationService.changePassword.reset();
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.authenticationService.changePassword.reset();
  }
}
