<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <div class="card-body">
      <div class="container-fluid">

        <form [formGroup]="lostReasonForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="lostReasonForm">
          <button type="submit" hidden="hidden"></button>
          <div class="row mb-2">
            <div class="col-6 mr-auto">
              <div class="form-group">
                <label class="form-title-label" for="name" translate>LEADLOSTREASON_LBL_NAME</label>
                <input
                  class="form-control form-title"
                  id="name"
                  name="name"
                  [placeholder]="''"
                  type="text"
                  value=""
                  formControlName="name"
                  checkFormFieldValidity
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md">
<!--              <div class="form-group row">-->
<!--                <label class="col-4 col-form-label-sm" for="active" translate>LEADLOSTREASON_LBL_ACTIVE</label>-->
<!--                <div class="col">-->
<!--                  <input-->
<!--                    class="form-control-sm"-->
<!--                    id="active"-->
<!--                    name="active"-->
<!--                    type="checkbox"-->
<!--                    value=""-->
<!--                    formControlName="active"-->
<!--                    checkFormFieldValidity-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="form-group row">-->
<!--                <label class="col-4 col-form-label-sm" for="needSummary" translate>LEADLOSTREASON_LBL_NEEDSUMMARY</label>-->
<!--                <div class="col">-->
<!--                  <input-->
<!--                    class="form-control-sm"-->
<!--                    id="needSummary"-->
<!--                    name="needSummary"-->
<!--                    type="checkbox"-->
<!--                    value=""-->
<!--                    formControlName="needSummary"-->
<!--                    checkFormFieldValidity-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
