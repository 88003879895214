<div class="connexion-container bg-white shadow-lg">
  <div class="row align-items-end">
    <div class="col-auto">
      <div class="company-img">
        <img class="img-fluid"
             src="../../../assets/images/logo-login.png"
             alt="Groupe Gesco Assurances"
        />
      </div>
    </div>
    <div class="col">
      <h2 class="mb-4" translate>LOGIN_LBL_RESET_PASSWORD</h2>
    </div>
  </div>
<!--<h2 class="mb-4">Changer mot de passe</h2>-->
<input type="hidden" name="returnUrl" value="@ViewBag.ReturnUrl"/>
<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div
    *ngIf="this.showErrorLogin"
    class="alert {{this.css}}"
    id="validationSummary"
  >
    <ul>
      <li>{{ this.msgError }}</li>
    </ul>
  </div>
  <div *ngIf="resetPasswordForm.errors?.['PasswordNoMatch']" class="alert {{this.css}}">
    <ul>
      <li translate>LOGIN_LBL_RESET_PASSWORD_ERRORS_MATCH</li>
    </ul>
  </div>
  <div class="form-group mb-4">
    <label for="password" class="form-label" translate
    >LOGIN_LBL_RESET_PASSWORD_PASSWORD</label
    >
    <input
      id="password"
      type="password"
      formControlName="password"
      class="form-control form-control-md"
      required
      checkFormFieldValidity
    />
  </div>
  <div class="row mb-4">
    <div class="col">
      <ul class="no-bullets">
        <li>Doit avoir au moins de 6 caractères</li>
        <li>Doit contenir au moins une majuscule et une minuscule (A, z)</li>
        <li>Doit contenir au moins un nombre</li>
        <li>Doit contenir au moins un caractère spécial (!, %, @, #, etc.)</li>
      </ul>
    </div>
  </div>
  <div class="form-group mb-4">
    <label for="password" class="form-label" translate
    >LOGIN_LBL_RESET_PASSWORD_PASSWORD_CONFIRM</label
    >
    <input
      id="passwordConfirm"
      type="password"
      formControlName="passwordConfirm"
      class="form-control form-control-md"
      required
      checkFormFieldValidity
    />
  </div>
  <div class="text-right">
    <button
      [routerLink]="'/'"
      class="btn-outline-primary btn me-2"
      translate
    >
      LOGIN_BTN_RESET_PASSWORD_HOME
    </button>
    <!--    [disabled]="resetPasswordForm.invalid"-->
    <button type="submit" class="btn btn-primary" translate>
      LOGIN_BTN_RESET_PASSWORD_REINIT
    </button>
  </div>
</form>
</div>
