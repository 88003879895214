import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactModel } from '../../../../../shared/store/contact/types';
import { ContactService } from '../../../../../shared/store/contact/contact.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../../shared/service/modal.service';
import { SourceEnum } from '../../../../../shared/store/typeahead/types';
import { toFirstLetterLower } from '../../../../../shared/helper/stringhelper';
import { ButtonType } from 'src/app/shared/store/subheader/type';
import { ModalContentComponent } from '../../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../../shared/components/modal-content/action-button';

@Component({
  selector: 'app-contact-details-children',
  templateUrl: './contact-details-children.component.html',
  styleUrls: ['./contact-details-children.component.scss'],
})
export class ContactDetailsChildrenComponent {
  contactDetail$: Observable<ContactModel>;
  parentId: number;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly contactService: ContactService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
  ) {
    this.contactDetail$ = this.contactService.getById.value$;
  }

  openModalCreate(parentId: number) {
    this.parentId = parentId;

    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'Contact' + 'CreateContentComponent',
      );
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        label: 'Contact',
        actionName: toFirstLetterLower('Contact') + 'Create',
      },
    ];
    modalRef.componentInstance.inputParams = {
      parentId: parentId,
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.contactService.getChildren.call(this.parentId);
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }
}
