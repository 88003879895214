<app-document-subheader />
<app-custom-actions-flow-activity />
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.activityModel$ | async as activity">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row mb-2">
                <div class="col-12 col-md-9 mr-auto">
                  <div class="form-group">
                    <label class="form-title-label" translate>ACTIVITY_LBL_NAME</label>
                    <div class="form-title">{{ activity.name }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >ACTIVITY_LBL_ACTIVITY_TYPE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ activity.activityTypeName }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >ACTIVITY_LBL_SUMMARY</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ activity.summary }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col">

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >ACTIVITY_LBL_ASSIGNED_TO</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ activity.assignedToName }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >ACTIVITY_LBL_DEADLINE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ activity.deadline | date : 'dd/MM/yyyy' }}
                      </span>
                    </div>
                  </div>

                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-12">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>ACTIVITY_LBL_NOTES</label>
                    <div>
                      {{ activity.notes }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.activityModel$ | async as activity">
      <app-comment-panel [entityType]="'Activity'" [entityId]="activity.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
