import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JobService } from 'src/app/shared/store/job/job.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-job-create',
  templateUrl: './job-create.component.html',
  styleUrls: ['./job-create.component.scss'],
})
export class JobCreateComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  constructor(
    private readonly jobService: JobService,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'jobCreate') this.onSubmit();
        if (x == 'jobList') {
          this.formService.clear();
          this.router.navigate(['HumanResources/Jobs/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@jobCreate', x.breadcrumbCreate);
      });
  }

  jobForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(80)]),
  });

  onSubmit() {
    if (this.jobForm.invalid) {
      this.jobForm.markAllAsTouched();
      this.formService.countErrors(this.jobForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.jobService.postJob.call({
      name: this.jobForm.value.name!,
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.router.navigate(['/HumanResources/Jobs/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.jobService.postJob.reset();
  }
}
