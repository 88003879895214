import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import {History, InputHistory} from './types';
import { historyActions } from './history.action';
import { historySelectors } from './history.selector';

@Injectable()
export class HistoryService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getByEntityId: GenericApiCall<History[], InputHistory> = this.genericApiCall(
    historyActions.getByEntityId,
    historySelectors.getByEntityId,
    true,
  );
}
