import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {
  onApiCall,
  onArrayApiCall,
} from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { ZipCodeModel } from './types';
import { zipCodeActions } from './zipcode.actions';

export interface IZipCodeState extends IState<ZipCodeModel> {
  get: GenericState<ZipCodeModel[]>;
  getById: GenericState<ZipCodeModel>;
  getByCodeAndCity: GenericState<ZipCodeModel>;
}

export const zipCodeInitialState: IZipCodeState = {
  get: new GenericState<ZipCodeModel[]>(),
  getById: new GenericState<ZipCodeModel>(),
  getByCodeAndCity: new GenericState<ZipCodeModel>(),
};

const zipCodeReducers = [
  ...onArrayApiCall<ZipCodeModel>(zipCodeActions.get, 'get'),
  ...onApiCall<ZipCodeModel>(zipCodeActions.getById, 'getById'),
  ...onApiCall<ZipCodeModel>(
    zipCodeActions.getByCodeAndCity,
    'getByCodeAndCity',
  ),
];

export const zipCodeReducer = createReducer(
  zipCodeInitialState,
  ...zipCodeReducers,
) as ActionReducer<IZipCodeState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return zipCodeReducer(state, action);
}
