<div class="avatar-upload">
  <div class="avatar-edit">
    <input type='file' #contactPicture id="ContactPicture" name="ContactPicture" accept=".png, .jpg, .jpeg, .svg"
           [multiple]="false" (change)="onFileSelected($event)"/>
    <label for="ContactPicture"
           class="d-flex align-items-center justify-content-center shadow-sm btn btn-sm btn-outline-dark rounded-circle mdi mdi-pencil"
    ></label>
  </div>
  <button type="button" id="resetContactPicture" *ngIf="this.imageUrl"
          class="avatar-delete btn btn-danger btn-sm rounded-circle border d-block"
          (click)="onClickClearSelectedPicture()"
  >
    <i class="mdi mdi-close"></i>
  </button>
  <div class="avatar-preview rounded-circle shadow-sm position-relative">
    <div id="imagePreview" class="rounded-circle"
         [ngStyle]="{ 'background-image': 'url(' + this.imageUrl + ')'}">
      <ng-container *ngIf="!this.imageUrl">
        <img *ngIf="this.isCompany" id="pictureCompany"
             class="picture-control active" src="../../../../assets/images/avatar_company.svg"/>
        <img *ngIf="!this.isCompany" id="pictureContact"
             class="picture-control active" src="../../../../assets/images/user.svg"/>
      </ng-container>
    </div>
  </div>
</div>
