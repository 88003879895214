import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { InputByEntity } from '../comment/types';
import { AppState } from '../types';
import { documentActions } from './document.actions';
import { documentSelectors } from './document.selector';
import { DocumentModel } from './types';

@Injectable()
export class DocumentService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<DocumentModel, string> = this.genericApiCall(
    documentActions.getById,
    documentSelectors.getById,
    true
  );
  getByEntityId: GenericApiCall<DocumentModel[], InputByEntity> =
    this.genericApiCall(
      documentActions.getByEntityId,
      documentSelectors.getByEntityId,
      true
    );
  getFile: GenericApiCall<any, string> = this.genericApiCall(
    documentActions.getFile,
    documentSelectors.getFile,
    true
  );
  add: GenericApiCall<DocumentModel, DocumentModel> = this.genericApiCall(
    documentActions.add,
    documentSelectors.add,
    true
  );
  update: GenericApiCall<DocumentModel, DocumentModel> = this.genericApiCall(
    documentActions.update,
    documentSelectors.update,
    true
  );
}
