import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { ActivityAction, activityActions } from './activity.actions';
import { ActivityApiService } from './activity.api.service';
import { Store } from '@ngrx/store';
import { IActivityState } from './activity.reducer';
import { activitySelectors } from './activity.selector';

@Injectable()
export class ActivityEffects {
  constructor(
    private readonly store: Store<IActivityState>,
    private readonly activityApiService: ActivityApiService,
    private readonly action$: Actions<ActivityAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    activityActions.getById,
    this.activityApiService.getById,
    () => {},
    () => {
      return this.store.select(activitySelectors.getById.paramsSelector);
    },
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    activityActions.createInstance,
    this.activityApiService.createInstance,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    activityActions.add,
    this.activityApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    activityActions.update,
    this.activityApiService.update,
  );

  getStatusFlow = createApiCallEffects(
    this,
    this.action$,
    activityActions.getStatusFlow,
    this.activityApiService.getStatusFlow,
  );

  getStatusActions = createApiCallEffects(
    this,
    this.action$,
    activityActions.getStatusActions,
    this.activityApiService.getStatusActions,
  );

  markAsDone = createApiCallEffects(
    this,
    this.action$,
    activityActions.markAsDone,
    this.activityApiService.markAsDone,
    (value) => {
      this.store.dispatch(activityActions.getById.refresh());
      this.store.dispatch(activityActions.getById.refresh());
    },
  );
}
