<div class="modal-header">
  <h5 class="modal-title" translate>{{ this.title }}</h5>
  <button
    *ngIf="this.closeCrossButton"
    type="button"
    class="btn-close"
    aria-describedby="modal-title"
    (click)="activeModalService.dismiss('-1')"
  ></button>
  <!--  <ng-container *ngFor="let secondaryButton of this.secondaryButtons">-->
  <!--    <button-->
  <!--      type="button"-->
  <!--      [class]="secondaryButton.buttonType | buttontypepipe"-->
  <!--      class="btn-sm btn"-->
  <!--      (click)="triggerAction(secondaryButton.actionName)"-->
  <!--      translate-->
  <!--    >-->
  <!--      {{ secondaryButton.label }}-->
  <!--    </button>-->
  <!--  </ng-container>-->
</div>
<div class="modal-body">
  <ng-container *ngComponentOutlet="component; inputs: this.inputs"/>
</div>
<div class="modal-footer">
  <button
    *ngIf="this.cancelButton"
    type="button"
    [class]="ButtonType.Light | buttontypepipe"
    class="btn-sm btn"
    (click)="activeModalService.dismiss(-1)"
    translate
  >
    GLOBAL_BTN_CANCEL
  </button>
  <ng-container *ngFor="let button of this.buttons; let last = last;">
    <button
      *ngIf="!last"
      type="button"
      [class]="button.buttonType ?? ButtonType.Light | buttontypepipe"
      class="btn-sm btn"
      (click)="triggerAction(button.actionName)"
      translate
    >
      {{ button.label ?? 'GLOBAL_BTN_SUBMIT' }}
    </button>
    <button
      *ngIf="last"
      type="button"
      ngbAutofocus
      [class]="button.buttonType ?? ButtonType.Primary | buttontypepipe"
      class="btn-sm btn"
      (click)="triggerAction(button.actionName)"
      translate
    >
      {{ button.label ?? 'GLOBAL_BTN_SUBMIT' }}
    </button>
  </ng-container>
</div>
