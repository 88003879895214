import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { InsuranceCompanyDetailsContentComponent } from '../insurance-company-details-content/insurance-company-details-content.component';
import { InsuranceCompanyDetailsNotesComponent } from '../insurance-company-details-tab/insurance-company-details-notes/insurance-company-details-notes.component';
import { InsuranceCompanyDetailsProductLinesComponent } from '../insurance-company-details-tab/insurance-company-details-product-lines/insurance-company-details-product-lines.component';
import { InsuranceCompanyDetailsPaymentComponent } from '../insurance-company-details-tab/insurance-company-details-payment/insurance-company-details-payment.component';

@Component({
  selector: 'app-insurance-company-tabs-details',
  templateUrl: './insurance-company-tabs-details.component.html',
  styleUrls: ['./insurance-company-tabs-details.component.scss'],
})
export class InsuranceCompanyTabsDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  active = 1;
  currentActive: number = 0;

  tabsContent$: BehaviorSubject<TabsContentContact[]> = new BehaviorSubject<
    TabsContentContact[]
  >([]);
  tabsInit: TabsContentContact[] = [
    {
      title: 'INSURANCECOMPANY_TABS_PRODUCTLINES',
      index: 1,
      name: 'productlines',
      component: InsuranceCompanyDetailsProductLinesComponent,
    },
    {
      title: 'INSURANCECOMPANY_TABS_NOTES',
      index: 2,
      name: 'notes',
      component: InsuranceCompanyDetailsNotesComponent,
    },
    {
      title: 'INSURANCECOMPANY_TABS_PAYMENTS',
      index: 3,
      name: 'payments',
      component: InsuranceCompanyDetailsPaymentComponent,
    },
  ];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.tabsContent$.next(this.tabsInit);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    }
    // this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
