import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { CampaignModel } from './types';
import { campaignActions } from './campaign.action';
import { campaignSelectors } from './campaign.selector';
import { ListItem } from '../typeahead/types';

@Injectable()
export class CampaignService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<CampaignModel, string> = this.genericApiCall(
    campaignActions.getById,
    campaignSelectors.getById,
    true,
  );

  getList: GenericApiCall<ListItem[], void> = this.genericApiCall(
    campaignActions.getList,
    campaignSelectors.getList,
    true,
  );

  createInstance: GenericApiCall<CampaignModel, void> = this.genericApiCall(
    campaignActions.createInstance,
    campaignSelectors.createInstance,
    true,
  );
  postCampaign: GenericApiCall<CampaignModel, CampaignModel> =
    this.genericApiCall(
      campaignActions.postCampaign,
      campaignSelectors.postCampaign,
      true,
    );

  updateCampaign: GenericApiCall<CampaignModel, CampaignModel> =
    this.genericApiCall(
      campaignActions.updateCampaign,
      campaignSelectors.updateCampaign,
      true,
    );
}
