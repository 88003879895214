<ng-container [ngSwitch]="this.entity">
  <app-kanban-view-lead *ngSwitchCase="'Lead'" [data]="this.data" />
  <app-kanban-view-contact *ngSwitchCase="'Contact'" [data]="this.data" />
  <app-kanban-view-contract *ngSwitchCase="'Contract'" [data]="this.data" />
  <app-kanban-view-documents *ngSwitchCase="'Document'" [data]="this.data" />

  <ng-container *ngSwitchDefault>
    <div class="card-list p-3">
      <div class="card-col" *ngFor="let item of data">
        <div class="card data-card h-100">
          <div class="card-body">
            <div class="card-content">
              <h5 class="card-title" *ngIf="item['name']">
                {{ item["name"] }}
              </h5>
            </div>
          </div>
          <div class="card-ur-corner-wrapper"></div>
          <a (click)="onCardClick(item['id'])" class="stretched-link"></a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
