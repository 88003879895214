import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigurationRoutingModule } from './configuration-routing.module';
import { CurrencyModule } from './currency/currency.module';
import { UserModule } from './user/user.module';
import { JobModule } from '../human-resources/job/job.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConfigurationRoutingModule,
    CurrencyModule,
    UserModule,
    JobModule,
  ],
})
export class ConfigurationModule {}
