import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { createApiCallEffects } from "src/app/common/store/generic.effects";
import { BankAction, bankActions } from "./bank.action";
import { BankApiService } from "./bank.api.service";
import { IBankState } from "./bank.reducer";

@Injectable()
export class BankEffects {
  constructor(
    private readonly store: Store<IBankState>,
    private readonly bankApiService: BankApiService,
    private readonly action$: Actions<BankAction>    
  ) {}
  
  getById = createApiCallEffects(
    this,
    this.action$,
    bankActions.getById,
    this.bankApiService.getById
  );

  postBank = createApiCallEffects(
    this,
    this.action$,
    bankActions.postBank,
    this.bankApiService.postBank
  );

  updateBank = createApiCallEffects(
    this,
    this.action$,
    bankActions.updateBank,
    this.bankApiService.updateBank
  );
}