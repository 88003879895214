import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Title } from './types';
import {ErrorService} from "../../error";
import {catchError} from "rxjs/operators";
import {MessageService} from "../../service/message/message.service";
import {CrudType} from "../../service/message/types";

@Injectable({
  providedIn: 'root',
})
export class TitleApiService {
  constructor(private readonly httpClient: HttpClient,
              private readonly errorService:ErrorService,
              private readonly messageService:MessageService
  ) {}

  getById = (id: number): Observable<Title> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Title>(url)
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  postTitle = (titlePost: Title): any => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient.post<Title>(url, titlePost, {observe: 'response'})
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  updateTitle = (titleUpdate: Title): any => {
    let parameters: string[];
    parameters = [titleUpdate.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.put<Title>(url, titleUpdate, {observe: 'response'})
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.titles.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
