<app-document-subheader></app-document-subheader>
<div
  class="details-content container-fluid h-100"
  *ngIf="this.createContractModel$ | async as contractAddModel"
>
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container partner-details">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <form
                [formGroup]="contractForm"
                (ngSubmit)="onSubmit()"
                [hasUnsavedData]="contractForm"
              >
                <button type="submit" hidden="hidden"></button>

                <div class="row mb-2">
                  <div class="col-12 mr-auto">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-6">
                          <label class="form-title-label" translate></label>
                          <input
                            class="form-control-plaintext form-title"
                            type="text"
                            readonly
                            value="{{ contractAddModel.name }}"
                          />
                        </div>
                        <div class="col-6"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_CLIENT</label
                      >
                      <div class="col">
                        <app-typeahead
                          [routeEndpoint]="'Contacts'"
                          [entity]="Entity.Contact"
                          [controlForm]="this.contractForm.controls['clientId']"
                          [orderBy]="'name asc'"
                          [allowCustomActions]="true"
                          [limitToList]="true"
                        ></app-typeahead>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_SALESTEAM</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams'"
                          [controlForm]="
                            this.contractForm.controls['salesTeam']
                          "
                          [limitToList]="true"
                        ></app-typeahead>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_SALESPERSON</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams/SalesTeamMembers'"
                          [controlForm]="
                            this.contractForm.controls['salesTeamMember']
                          "
                          [limitToList]="true"
                          [referenceFilterParentName]="'SalesTeamId'"
                          [referenceFilterParentValue]="
                            this.contractForm.controls['salesTeam'].value
                          "
                          [dbNameOfName]="'UserContactName'"
                          [dbNameOfId]="'UserId'"
                        ></app-typeahead>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_MANAGER</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams/SalesTeamMembers'"
                          [controlForm]="
                            this.contractForm.controls['contractManagerId']
                          "
                          [limitToList]="true"
                          [referenceFilterParentName]="'SalesTeamId'"
                          [referenceFilterParentValue]="
                            this.contractForm.controls['salesTeam'].value
                          "
                          [dbNameOfName]="'UserContactName'"
                          [dbNameOfId]="'UserId'"
                        ></app-typeahead>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_CATEGORY</label
                      >
                      <div class="col">
                        <product-category-edit
                          [controlForm]="this.contractForm.controls['productCategoryId']"
                          (categoryChange)="onCategoryChange($event)"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_COMPANY</label
                      >
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [referenceEndpoint]="
                            'Contracts/Product/ReferenceData'
                          "
                          [referenceField]="'CompanyKey'"
                          [controlForm]="
                            this.contractForm.controls['companyKey']
                          "
                        ></app-typeahead>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_PRODUCTLINE</label
                      >
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [referenceEndpoint]="
                            'Contracts/Product/ReferenceData'
                          "
                          [referenceField]="'ProductLineKey'"
                          [referenceFilterParentName]="'CompanyKey'"
                          [referenceFilterParentValue]="
                            this.contractForm.controls['companyKey']?.value
                          "
                          [controlForm]="
                            this.contractForm.controls['productLineKey']
                          "
                        ></app-typeahead>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_PRODUCT</label
                      >
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [referenceEndpoint]="
                            'Contracts/Product/ReferenceData'
                          "
                          [referenceField]="'ProductKey'"
                          [referenceFilterParentName]="'ProductLineKey'"
                          [referenceFilterParentValue]="
                            this.contractForm.controls['productLineKey']?.value
                          "
                          [controlForm]="
                            this.contractForm.controls['productKey']
                          "
                        ></app-typeahead>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_EFFECT_DATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="date"
                          formControlName="effectiveDate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >CONTRACT_LBL_SUBSCRIPTION_DATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="date"
                          formControlName="subscriptionDate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <app-contract-tabs-form
                  [category]="this.category"
                  [form]="this.contractForm"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
