<nav id="sidebar" class="sidebar">
  <ng-container *ngFor="let module of this.modules$ | async">
    <a
      *ngIf="module.visible"
      class="sidebar-link nav-link"
      [routerLinkActive]="'active'"
      [routerLink]="[module.route]"
      draggable="false"
    >
      <i class="mdi mdi-{{ module.icon }}"></i>
      <span title="{{ module.description }}" translate>{{ module.name }}</span>
    </a>
  </ng-container>
  <a
    class="sidebar-link nav-link nav-bottom"
    [routerLinkActive]="'active'"
    [routerLink]="'/Configuration'"
    draggable="false"
  >
    <i class="mdi mdi-cog"></i>
    <span title="Paramètres" translate>Paramètres</span>
  </a>
</nav>
