import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable, take } from 'rxjs';
import { GenericApiCall } from 'src/app/common/store/types';
import { AccountingService } from 'src/app/shared/store/accounting/accounting.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { filterTrue } from '../../../pipe/rxjs/operators';
import { ModalService } from '../../../service/modal.service';
import {
  AccountMoveLineModel,
  AccountMoveModel,
  BindType,
} from '../../../store/accounting/types';
import { ButtonType } from '../../../store/subheader/type';
import { SourceEnum } from '../../../store/typeahead/types';

@UntilDestroy()
@Component({
  selector: 'app-account-move-assign-details-content',
  templateUrl: './account-move-assign-details-content.component.html',
  styleUrls: ['./account-move-assign-details-content.component.scss'],
})
export class AccountMoveAssignDetailsContentComponent
  implements OnInit, OnDestroy
{
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input() payments: AccountMoveModel[];
  @Input() invoices: AccountMoveModel[];
  @Input({ required: true }) accountMoveId: number;
  @Input() labelPayments: string = 'ACCOUNTMOVE_LBL_PAYMENTS';
  @Input() labelInvoices: string = 'ACCOUNTMOVE_LBL_INVOICES';
  @Input() isVendorInvoice: boolean = false;
  @Input() bindType: BindType;

  paymentAmount: number = 0;
  invoiceAmount: number = 0;

  accountMove$: Observable<AccountMoveModel>;
  apiCall: GenericApiCall<any, any>;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly accountingService: AccountingService,
    private readonly dynamicService: ModalService,
  ) {}

  ngOnDestroy(): void {
    this.accountingService.getAccountMovePdf.reset();
    this.accountingService.unbind.reset();
    this.accountingService.getBindedAccountMoveByAccountMoveId.reset();
  }

  ngOnInit(): void {
    this.apiCall = this.accountingService.getBindedAccountMoveByAccountMoveId;

    this.accountMove$ =
      this.accountingService.getBindedAccountMoveByAccountMoveId.value$;

    this.accountingService.getBindedAccountMoveByAccountMoveId.call({
      accountMoveId: this.accountMoveId,
      bindType: this.bindType,
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'accountMovesPDF') this.getPdf();
        if (x == 'accountMovesUnbind') this.unbind();
        this.subHeaderService.setTriggerAction('');
      });
  }

  getPdf() {
    const getFileSubscription = this.accountingService.getAccountMovePdf.value$
      .pipe(
        // tap((x) => {
        //   console.log(x);
        // }),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result: Blob) => {
        var fileURL = window.URL.createObjectURL(result);
        let tab = window.open();
        if (tab) {
          tab.location.href = fileURL;
        }
        this.accountingService.getAccountMovePdf.reset();
      });

    this.accountingService.getAccountMovePdf.call(this.accountMoveId);
  }

  private unbind() {
    // let bindType: BindType = this.isVendorInvoice ? 'Repayment' : 'Payment';

    let result$ = this.accountingService.unbind.call({
      accountMoveId: this.accountMoveId,
      bindType: this.bindType,
    });

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      switch (this.source) {
        case SourceEnum.Component:
          break;
        case SourceEnum.Modal:
          this.dynamicService.outputFromDynamicComponent('1'); //close the modal
          break;
        default:
          break;
      }
    });
  }

  trackByAccountMoveId(index: any, item: AccountMoveModel) {
    return item.id;
  }
  trackByAccountMoveLineId(index: any, item: AccountMoveLineModel) {
    return item.id;
  }
}
