  <ng-container *ngIf="this.errorsDescription$ | async as errors">
    <ul *ngIf="errors.root.count > 0 || errors.children">
      <li *ngIf="errors.root.count > 0" translate>
      <span
        *ngIf="errors.root.groupName">{{ ( entity + '_LBL_' + errors.root.groupName | uppercase) | translate | translate }}
        - </span>
        <span translate
              [translateParams]="{count: errors.root.count}">{{ errors.root.count === 1 ? 'TOAST_LBL_VALIDATION_ERROR' : 'TOAST_LBL_VALIDATION_ERRORS' }}</span>
        <ul>
          <li *ngFor="let field of errors.root.fieldName"
              translate>{{ (entity  + '_LBL_' + field | uppercase) | translate | translate }}
          </li>
        </ul>
      </li>
      <ng-container *ngFor="let error of errors.children">
        <li
          *ngIf="error.count > 0">
        <span
          *ngIf="error.groupName">{{ (entity + '_LBL_' + error.groupName | uppercase) | translate | translate }}
          - </span>
          <span translate
                [translateParams]="{count: error.count}">{{ error.count === 1 ? 'TOAST_LBL_VALIDATION_ERROR' : 'TOAST_LBL_VALIDATION_ERRORS' }}</span>
          <ul>
            <li
              *ngFor="let field of error.fieldName">{{ (entity  + '_LBL_' + field | uppercase) | translate | translate }}
            </li>
          </ul>

        </li>
      </ng-container>
    </ul>
  </ng-container>
