import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-kanban-view-documents',
  templateUrl: './kanban-view-documents.component.html',
  styleUrls: ['./kanban-view-documents.component.scss'],
})
export class KanbanViewDocumentsComponent implements OnInit {
  @Input() data: any[] | undefined;
  constructor() {}

  ngOnInit(): void {}
}
