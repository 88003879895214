import { ContactModel } from './types';
import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { contactActions } from './contact.actions';
import { contactSelectors } from './contact.selector';

@Injectable()
export class ContactService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<ContactModel, string> = this.genericApiCall(
    contactActions.getById,
    contactSelectors.getById,
    true,
  );

  postContact: GenericApiCall<ContactModel, ContactModel> = this.genericApiCall(
    contactActions.postContact,
    contactSelectors.postContact,
    true,
  );

  updateContact: GenericApiCall<ContactModel, ContactModel> =
    this.genericApiCall(
      contactActions.updateContact,
      contactSelectors.updateContact,
      true,
    );

  createInstance: GenericApiCall<ContactModel, void> = this.genericApiCall(
    contactActions.createInstance,
    contactSelectors.createInstance,
    true,
  );

  getChildren: GenericApiCall<ContactModel[], number> = this.genericApiCall(
    contactActions.getChildren,
    contactSelectors.getChildren,
    true,
  );
}
