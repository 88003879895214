import { createAction, props } from '@ngrx/store';

const UploadRequestAction = createAction(
  '[File Upload Form] Request',
  props<{ id: any; entityType: string; entityId: string; file: File }>(),
);
const UploadCancelAction = createAction('[File Upload Form] Cancel');
const UploadResetAction = createAction('[File Upload Form] Reset');
const UploadStartedAction = createAction('[File Upload API] Started');
const UploadProgressAction = createAction(
  '[File Upload API] Progress',
  props<{ progress: number }>(),
);
const UploadFailureAction = createAction(
  '[File Upload API] Failure',
  props<{ error: string }>(),
);
const UploadCompletedAction = createAction('[File Upload API] Success');

export const uploadFileActions = {
  UPLOAD_REQUEST: UploadRequestAction,
  UPLOAD_CANCEL: UploadCancelAction,
  UPLOAD_RESET: UploadResetAction,
  UPLOAD_STARTED: UploadStartedAction,
  UPLOAD_PROGRESS: UploadProgressAction,
  UPLOAD_FAILURE: UploadFailureAction,
  UPLOAD_COMPLETED: UploadCompletedAction,
};

export type UploadFileAction =
  | typeof UploadRequestAction
  | typeof UploadCancelAction
  | typeof UploadResetAction
  | typeof UploadStartedAction
  | typeof UploadProgressAction
  | typeof UploadFailureAction
  | typeof UploadCompletedAction;
