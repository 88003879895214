import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter } from 'rxjs';
import { CountryService } from 'src/app/shared/store/country/country.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { Entity, View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-country-create',
  templateUrl: './country-create.component.html',
  styleUrls: ['./country-create.component.scss'],
})
export class CountryCreateComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  protected readonly Entity = Entity;

  constructor(
    private readonly countryService: CountryService,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'countryCreate') this.onSubmit();
        if (x == 'countryList') {
          this.formService.clear();
          this.router.navigate(['Contacts/Countries/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@countryCreate', x.breadcrumbCreate);
      });
  }

  countryForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(80)]),
    englishName: new FormControl('', [Validators.maxLength(80)]),
    alpha2Code: new FormControl('', [
      Validators.minLength(2),
      Validators.maxLength(2),
    ]),
    alpha3Code: new FormControl('', [
      Validators.minLength(3),
      Validators.maxLength(3),
    ]),
    currencyId: new FormControl(),
    nationality: new FormControl('', Validators.maxLength(80)),
    isPrimary: new FormControl(false),
  });

  onSubmit(): void {
    if (!this.countryForm.valid) {
      this.countryForm.markAllAsTouched();
      this.formService.countErrors(this.countryForm, true);
      return;
    }

    let result$ = this.countryService.postCountry.call({
      id: 0,
      name: this.countryForm.value.name!,
      englishName: this.countryForm.value.englishName!,
      alpha2Code: this.countryForm.value.alpha2Code!.toUpperCase(),
      alpha3Code: this.countryForm.value.alpha3Code!.toUpperCase(),
      currencyId: this.countryForm.value.currencyId!,
      nationality: this.countryForm.value.nationality!,
      isPrimary: this.countryForm.value.isPrimary!,

      //Others
      currencyName: '',
      currencySymbol: '',
      currencyIsoCode: '',
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate(['/Contacts/Countries/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.countryService.postCountry.reset();
  }
}
