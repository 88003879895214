import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ProductCategoryRoutingModule } from './product-category-routing.module';
import { ProductCategoryDetailsComponent } from './product-category-details/product-category-details.component';
import { ProductCategoryDetailsDocumentComponent } from './product-category-details/product-category-details-tab/product-category-documents/product-category-details-documents.component';
import { ProductCategoryTabsDetailsComponent } from './product-category-details/product-category-tabs-details/product-category-tabs-details.component';
import { ProductCategoryCreateComponent } from './product-category-create/product-category-create.component';
import { ProductCategoryEditComponent } from './product-category-edit/product-category-edit.component';
import { SelectDocumentTypeEditContentComponent } from './select-document-type-edit-content/select-document-type-edit-content.component';

@NgModule({
  declarations: [
    ProductCategoryDetailsComponent,
    ProductCategoryTabsDetailsComponent,
    ProductCategoryDetailsDocumentComponent,
    ProductCategoryCreateComponent,
    ProductCategoryEditComponent,
    SelectDocumentTypeEditContentComponent,
  ],
  imports: [CommonModule, ProductCategoryRoutingModule, SharedModule],
})
export class ProductCategoryModule {}
