import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ProductCategoryModel, productCategoryFeatureKey } from './types';
import { IProductCategoryState } from './product-category.reducer';

const productCategoryFeatureState =
  createFeatureSelector<IProductCategoryState>(productCategoryFeatureKey);

export const productCategorySelectors = {
  getById: createApiCallSelectors<number, IProductCategoryState>(
    productCategoryFeatureState,
    'getById'
  ),
  createInstance: createApiCallSelectors<void, IProductCategoryState>(
    productCategoryFeatureState,
    'createInstance'
  ),
  add: createApiCallSelectors<ProductCategoryModel, IProductCategoryState>(
    productCategoryFeatureState,
    'add'
  ),
  update: createApiCallSelectors<ProductCategoryModel, IProductCategoryState>(
    productCategoryFeatureState,
    'update'
  ),
};
