import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { ProductCategoryDetailsDocumentComponent } from '../product-category-details-tab/product-category-documents/product-category-details-documents.component';

@Component({
  selector: 'app-product-category-tabs-details',
  templateUrl: './product-category-tabs-details.component.html',
  styleUrls: ['./product-category-tabs-details.component.scss'],
})
export class ProductCategoryTabsDetailsComponent implements OnInit, OnChanges {
  active = 1;

  tabsContent$: Observable<TabsContentContact[]> = of([]);
  tabsInit: TabsContentContact[] = [
    {
      title: 'PRODUCTLINE_TABS_DOCUMENTS',
      index: 1,
      name: 'insuranceDocuments',
      component: ProductCategoryDetailsDocumentComponent,
    },
  ];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.tabsContent$ = of(this.tabsInit);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }
}
