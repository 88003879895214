import { Component, OnInit } from '@angular/core';
import { LeadService } from '../../../../../../shared/store/lead/lead.service';
import { filter, Observable, take } from 'rxjs';
import { CarInsuranceLeadModel } from '../../../../../../shared/store/lead/types';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { formatDate } from '../../../../../../shared/helper/datehelper';
import { Entity } from '../../../../../../shared/store/view/types';
import { DropdownStyle } from '../../../../../../shared/store/typeahead/types';

@Component({
  selector: 'app-lead-form-driver',
  templateUrl: './lead-form-driver.component.html',
  styleUrls: ['./lead-form-driver.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LeadFormDriverComponent implements OnInit {
  leadForm: FormGroup<any>;
  carDetail$: Observable<CarInsuranceLeadModel>;

  constructor(
    private readonly leadService: LeadService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder
  ) {
    this.carDetail$ = this.leadService.getCarDetail.value$;
  }

  ngOnInit(): void {
    this.leadForm = this.parent.form;

    this.leadForm.addControl(
      'driverFormGroup',
      this.formBuilder.group({
        driverLastname: ['', [Validators.required]],
        driverFirstname: ['', [Validators.required]],
        driverBirthdate: [undefined, [Validators.required]],
        driverZipCode: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
          ],
        ],
        driverCity: [undefined, [Validators.required]],
        driverAddress: [''],
        driverLicenseDate: [undefined, [Validators.required]],
        driverYearsInsured: [undefined],
        driverBonusMalus: [undefined],
        driverMonthInsuranceInterruption: [undefined],
        driverInsuranceTermination: [undefined],
        driverLicenseAnnulation: [''],
        driverNoLicenseConviction: [''],
        driverLicenseCancel: [''],
        driverLicenseCancelMotive: [undefined],
        driverLicenseCancelDate: [undefined],
        driverLicenseSuspension: [''],
        driverLicenseSuspensionMotive: [undefined],
        driverLicenseSuspensionDate: [undefined],
        driverLongestSuspension: [undefined],
        driverAlcoholDrugControl: [''],
        driverVehicleRegistrationHolder: [true],
        driverDisasters: this.formBuilder.array([]),
      })
    );

    this.carDetail$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.leadForm.patchValue({
          driverFormGroup: {
            driverLastname: x.driverLastName,
            driverFirstname: x.driverFirstName,
            driverBirthdate: formatDate(x.driverBirthdate, 'ToInverseSqlDate'),
            driverZipCode: x.driverZipCode,
            driverCity: x.driverCity,
            driverAddress: x.driverAddress,
            driverLicenseDate: formatDate(x.licenseDate, 'ToInverseSqlDate'),
            driverYearsInsured: x.yearsInsured,
            driverBonusMalus: x.bonusMalus,
            driverMonthInsuranceInterruption: x.monthInsuranceInterruption,
            driverInsuranceTermination: x.insuranceTermination,
            driverLicenseAnnulation: x.licenseAnnulation === 'true',
            driverNoLicenseConviction: x.noLicenseConviction,
            driverLicenseCancel: x.licenseCancel,
            driverLicenseCancelMotive: x.licenseCancelMotive,
            driverLicenseCancelDate: formatDate(
              x.licenseCancelDate,
              'ToInverseSqlDate'
            ),
            driverLicenseSuspension: x.licenseSuspension,
            driverLicenseSuspensionMotive: x.licenseSuspensionMotive,
            driverLicenseSuspensionDate: formatDate(
              x.licenseSuspensionDate,
              'ToInverseSqlDate'
            ),
            driverLongestSuspension: x.longestSuspension,
            driverAlcoholDrugControl: x.alcoholDrugControl,
            driverVehicleRegistrationHolder:
              x.isDriverVehiculeRegistrationHolder,
          },
        });

        const disasters = x.carDisasters;
        var dataDisaster: any[] = [];
        for (let i = 0; i < disasters.length; i++) {
          this.addDisaster();
          dataDisaster.push({
            carDisasterReason: disasters[i].carDisasterReason,
            carDisasterResponsiblePerson:
              disasters[i].carDisasterResponsiblePerson,
            carDisasterDate: formatDate(
              disasters[i].carDisasterDate,
              'ToInverseSqlDate'
            ),
          });
        }
        this.driverDisasters.patchValue(dataDisaster);
      });
  }

  get driverDisasters() {
    let driverFormGroup = this.leadForm.controls[
      'driverFormGroup'
    ] as FormGroup;

    return driverFormGroup.controls['driverDisasters'] as FormArray;
  }

  addDisaster() {
    const disasterForm = this.formBuilder.group({
      carDisasterDate: [new Date()],
      carDisasterReason: [''],
      carDisasterResponsiblePerson: [''],
    });

    this.driverDisasters.push(disasterForm);
  }

  removeDisaster(index: number) {
    this.driverDisasters.removeAt(index);
  }

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;
}
