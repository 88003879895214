import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InsuranceCategory } from '../../../../../shared/store/lead/types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TabsContent } from '../../../../../shared/models/tabs-content';
import { LeadFormHealthComponent } from '../lead-form-tab/lead-form-health/lead-form-health.component';
import { LeadFormDriverComponent } from '../lead-form-tab/lead-form-driver/lead-form-driver.component';
import { LeadFormVehicleComponent } from '../lead-form-tab/lead-form-vehicle/lead-form-vehicle.component';
import { LeadFormCarInsuranceComponent } from '../lead-form-tab/lead-form-car-insurance/lead-form-car-insurance.component';
import { LeadFormHouseComponent } from '../lead-form-tab/lead-form-house/lead-form-house.component';
import { ErrorsNestedFormGroup } from '../../../../../shared/store/form/types';
import { FormService } from '../../../../../shared/store/form/form.service';

@Component({
  selector: 'app-lead-tabs-form',
  templateUrl: './lead-tabs-form.component.html',
  styleUrls: ['./lead-tabs-form.component.scss'],
})
export class LeadTabsFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() category: InsuranceCategory;
  errorsDescription$: Observable<ErrorsNestedFormGroup>;

  active = 1;

  tabsContent$: BehaviorSubject<TabsContent[]> = new BehaviorSubject<
    TabsContent[]
  >([]);

  tabsInit: TabsContent[] = [
    {
      title: 'LEAD_TABS_HEALTH_DETAIL',
      index: 1,
      name: 'health',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: LeadFormHealthComponent,
      formGroupName: 'healthFormGroup',
    },
    {
      title: 'LEAD_TABS_DRIVER',
      index: 1,
      name: 'driver',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: LeadFormDriverComponent,
      formGroupName: 'driverFormGroup',
    },
    {
      title: 'LEAD_TABS_VEHICLE',
      index: 2,
      name: 'vehicle',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: LeadFormVehicleComponent,
      formGroupName: 'vehicleFormGroup',
    },
    {
      title: 'LEAD_TABS_INFORMATIONS',
      index: 3,
      name: 'car-insurance',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: LeadFormCarInsuranceComponent,
      formGroupName: 'carInsuranceFormGroup',
    },
    {
      title: 'LEAD_TABS_HOUSE',
      index: 1,
      name: 'house',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: LeadFormHouseComponent,
      formGroupName: 'houseFormGroup',
    },
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.active = 1;
    if (this.tabsInit && this.tabsInit.length > 0) {
      this.tabsContent$.next(
        this.tabsInit.filter((x) => x.category === this.category),
      );
    }
    this.enableOrDisableControl();
    this.formService.countErrors(this.form, false);
  }

  trackByTabsContent(index: any, item: TabsContent) {
    return item.index;
  }

  enableOrDisableControl = () => {
    for (let i = 0; i < this.tabsInit.length; i++) {
      const formGroupName = this.tabsInit[i].formGroupName;

      if (formGroupName && this.form.controls[formGroupName]) {
        if (this.tabsInit[i].category === this.category) {
          //enable control
          this.form.controls[formGroupName].enable();
        } else {
          //disable control
          this.form.controls[formGroupName].disable();
        }
      }
    }
  };
}
