import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { LeadQuotesComponent } from 'src/app/shared/components/lead-quotes/lead-quotes.component';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import { LeadDetailModel } from 'src/app/shared/store/lead/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';

@UntilDestroy()
@Component({
  selector: 'app-lead-details-quotes',
  templateUrl: './lead-details-quotes.component.html',
  styleUrls: ['./lead-details-quotes.component.scss'],
})
export class LeadDetailsQuotesComponent implements OnInit {
  leadDetail$: Observable<LeadDetailModel>;

  constructor(
    private readonly router: Router,
    private readonly leadService: LeadService,
    private readonly modalService: NgbModal,
    private readonly subHeaderService: SubHeaderService,
  ) {}

  ngOnInit(): void {
    this.leadDetail$ = this.leadService.getByUniqueId.value$;

    // this.subHeaderService
    //   .getTriggerAction()
    //   .pipe(untilDestroyed(this))
    //   .subscribe((x) => {
    //     if (x == 'getComparatorQuotes') {
    //       this.addQuote()
    //     }
    //   });
  }

  addQuote(leadUniqueId: string, leadId: number) {
    const modalRef = this.modalService.open(LeadQuotesComponent, {
      size: 'xl',
      backdrop: 'static',
      scrollable: true,
      modalDialogClass: 'full',
    });

    modalRef.componentInstance.leadUniqueId = leadUniqueId;
    modalRef.componentInstance.leadId = leadId;
  }

  subscribe(leadId: number, productId: number) {
    this.router.navigate([
      '/Sales/ContractRequests/Create/Health',
      leadId,
      productId,
    ]);
  }
}
