import { createFeatureSelector } from '@ngrx/store';
import { ICommentState } from './comment.reducer';
import { InputByEntity, commentFeatureKey } from './types';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';

const commentFeatureState =
  createFeatureSelector<ICommentState>(commentFeatureKey);

export const commentSelectors = {
  getByEntityId: createApiCallSelectors<InputByEntity, ICommentState>(
    commentFeatureState,
    'getByEntityId',
  ),
  postComment: createApiCallSelectors<any, ICommentState>(
    commentFeatureState,
    'postComment',
  ),
};
