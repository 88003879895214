import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { DocumentRoutingModule } from './document-routing.module';

@NgModule({
  declarations: [DocumentDetailsComponent],
  imports: [CommonModule, DocumentRoutingModule, SharedModule],
  exports: [],
})
export class DocumentModule {}
