<ng-container *ngIf="(this.insuranceCompanyDetail$ | async)?.contact as contact">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label class="form-title-label" translate>PRODUCTLINE_LBL_NAME</label>
        <div class="form-title">{{ contact.name }}</div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!contact.isCompany && contact.parentId">
    <div class="col-6">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_COMPANY</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            <a
              [routerLink]="'/Contacts/Contacts/Details/' + contact.parentId"
              >{{ contact.parentName }}</a
            >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6 col-lg-6">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_ADDRESS</label
        >
        <div class="col">
          <div class="form-control-plaintext form-control-sm mb-2 h-100 p-0">
            <app-address
              [address]="contact.address"
              [zipCode]="contact.zipCode"
              [city]="contact.city"
              [country]="contact.countryName"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_PHONE_NUMBER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            <app-phone-number
              [phoneNumber]="contact.phoneNumber"
              [isLinkEnabled]="true"
            ></app-phone-number>
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_EMAIL</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            <a href="mailto:{{ contact.email }}">{{ contact.email }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-insurance-company-tabs-details />
</ng-container>
