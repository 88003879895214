import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { QuoteModel, quoteFeatureKey } from './types';
import { IQuoteState } from './quote.reducer';

const quoteFeatureState = createFeatureSelector<IQuoteState>(quoteFeatureKey);

export const quoteSelectors = {
  getById: createApiCallSelectors<number, IQuoteState>(
    quoteFeatureState,
    'getById'
  ),
  getComparatorQuotes: createApiCallSelectors<string, IQuoteState>(
    quoteFeatureState,
    'getComparatorQuotes'
  ),
  addQuotes: createApiCallSelectors<QuoteModel[], IQuoteState>(
    quoteFeatureState,
    'addQuotes'
  ),
};
