import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { ICountyState } from './county.reducer';
import { CountyModel, countyFeatureKey } from './types';

const countyFeatureState =
  createFeatureSelector<ICountyState>(countyFeatureKey);

export const countySelectors = {
  get: createApiCallSelectors<CountyModel[], ICountyState>(
    countyFeatureState,
    'get',
  ),
  getById: createApiCallSelectors<CountyModel, ICountyState>(
    countyFeatureState,
    'getById',
  ),
};
