export const bankFeatureKey = 'bank';
export type BankModel = {
    id: number;
    name: string;
    address: string;
    addresse2: string;
    zipCode: string;
    city: string;
    countryId: number | string | undefined | null;
    countryName: string;
    phoneNumber:string;
    email: string;
    bic: string;
    active: boolean;
}
