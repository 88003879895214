import { on, createReducer, ActionReducer, Action } from '@ngrx/store';
import { paginationActions } from './pagination.actions';

export interface IPaginationState {
  pageIndex: number;
  totalCount: number;
  pageSize: number;
  totalPages: number;
  filter: string[];
  sortBy: string;
  orderBy: string;
}

export const paginationInitialState: IPaginationState = {
  pageIndex: 1,
  totalCount: 0,
  pageSize: 60,
  totalPages: 0,
  filter: [],
  sortBy: '',
  orderBy: '',
};

const paginationReducers = [
  on(
    paginationActions.setPageIndex,
    (state: IPaginationState, { pageIndex }) => ({
      ...state,
      pageIndex: pageIndex,
    })
  ),
  on(
    paginationActions.setTotalCount,
    (state: IPaginationState, { totalCount }) => ({
      ...state,
      totalCount: totalCount,
    })
  ),
  on(
    paginationActions.setPageSize,
    (state: IPaginationState, { pageSize }) => ({
      ...state,
      pageSize: pageSize,
    })
  ),
  on(
    paginationActions.setTotalPages,
    (state: IPaginationState, { totalPages }) => ({
      ...state,
      totalPages: totalPages,
    })
  ),
  on(paginationActions.setFilter, (state: IPaginationState, { filter }) => ({
    ...state,
    filter: filter,
  })),
  on(paginationActions.setSortBy, (state: IPaginationState, { sortBy }) => ({
    ...state,
    sortBy: sortBy,
  })),
  on(paginationActions.setOrderBy, (state: IPaginationState, { orderBy }) => ({
    ...state,
    orderBy: orderBy,
  })),
];

export const paginationReducer = createReducer(
  paginationInitialState,
  ...paginationReducers
) as ActionReducer<IPaginationState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return paginationReducer(state, action);
}
