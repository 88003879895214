import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { ProductModel } from './types';
import { productActions } from './product.actions';

export interface IProductState extends IState<ProductModel> {
  getById: GenericState<ProductModel>;
  add: GenericState<ProductModel>;
  update: GenericState<ProductModel>;
  createInstance: GenericState<ProductModel>;
  comparatorProducts: ProductModel[];
}

export const productInitialState: IProductState = {
  getById: new GenericState<ProductModel>(),
  add: new GenericState<ProductModel>(),
  update: new GenericState<ProductModel>(),
  createInstance: new GenericState<ProductModel>(),
  comparatorProducts: [],
};

const productReducers = [
  ...onApiCall<ProductModel>(productActions.getById, 'getById'),
  ...onApiCall<ProductModel>(productActions.add, 'add'),
  ...onApiCall<ProductModel>(productActions.update, 'update'),
  ...onApiCall<ProductModel>(productActions.createInstance, 'createInstance'),
  on(productActions.comparatorProducts, (state: IProductState, { product }) => {
    let products = [...state.comparatorProducts];
    products.push(product);
    state = {
      ...state,
      comparatorProducts: products,
    };
    return state;
  }),
];

export const productReducer = createReducer(
  productInitialState,
  ...productReducers
) as ActionReducer<IProductState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return productReducer(state, action);
}
