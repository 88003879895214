import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { PaymentModel, paymentFeatureKey } from './types';
import { IPaymentState } from './payment.reducer';

const paymentFeatureState =
  createFeatureSelector<IPaymentState>(paymentFeatureKey);

export const paymentSelectors = {
  getById: createApiCallSelectors<number, IPaymentState>(
    paymentFeatureState,
    'getById'
  ),
};