<form [formGroup]="insuranceCompanyForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="insuranceCompanyForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row mb-2">
    <div class="col-12 mr-auto">

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label
              class="form-title-label"
              translate
            >CONTACT_LBL_NAME</label
            >
            <input
              class="form-control form-title"
              type="text"
              formControlName="contactName"
              [placeholder]="''"
              checkFormFieldValidity
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md">
      <app-address-edit [form]="this.insuranceCompanyForm.controls['contactAddress']"></app-address-edit>
    </div>
    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CONTACT_LBL_PHONE_NUMBER</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="contactPhoneNumber"
            [placeholder]="'CONTACT_PLACEHOLDER_PHONE_NUMBER' | translate"
            mask="00 00 00 00 00"
            checkFormFieldValidity
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >CONTACT_LBL_EMAIL</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            [placeholder]="'CONTACT_PLACEHOLDER_EMAIL' | translate"
            formControlName="contactEmail"
            checkFormFieldValidity
          />
        </div>
      </div>

    </div>
  </div>
</form>
