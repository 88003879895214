<ul class="navbar-nav flex-fill" *ngIf="this.menus$">
    <ng-container *ngFor="let menu of this.menus$ | async">
        <li class="dropdown d-inline" *ngIf="!this.menu.children?.length else subMenu">
            <a class="nav-link" [routerLink]="this.menu.route">
                {{ menu.name }}
            </a>
        </li>
        <ng-template #subMenu>
            <li class="dropdown d-inline" ngbDropdown>
                <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown" role="button" translate>{{
                    menu.name }}</a>
                <div ngbDropdownMenu aria-labelledby="navbarDropdown">
                  <ng-container *ngFor="let subMenu of this.menu.children">
<!--                    <div class="dropdown-divider no-margin" *ngIf="subMenu.startsGroup"></div>-->
                    <a ngbDropdownItem [disabled]="!!subMenu.children && subMenu.children.length > 0"
                            [routerLink]="(subMenu.route ? subMenu.route : '')"
                       [ngClass]="{'mt-1' : !!subMenu.children && subMenu.children.length > 0}"
                       class="dropdown-item"

                        >
                      <span class="mdi mdi-{{subMenu.icon}}" *ngIf="subMenu.icon && subMenu.icon.length > 0"></span>
                      <span class="ms-3 me-3">  {{ subMenu.name }}</span>
                    </a>

<!--                    Child of subMenu-->
                    <ng-container *ngFor="let child of subMenu.children">
                      <!--                    <div class="dropdown-divider no-margin" *ngIf="subMenu.startsGroup"></div>-->
                      <a ngbDropdownItem [disabled]="!!child.children && child.children.length > 0"
                              [routerLink]="(child.route ? child.route : '')"
                              class="dropdown-item"
                      >
                        <span class="mdi mdi-{{child.icon}}" *ngIf="child.icon && child.icon.length > 0"></span>
                        <span class="ms-4 me-3">  {{ child.name }}</span>
                      </a>
                    </ng-container>
<!--                    -->

                  </ng-container>
                </div>
            </li>
        </ng-template>
    </ng-container>
</ul>
