import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { paymentActions } from './payment.actions';
import { paymentSelectors } from './payment.selector';
import { PaymentModel } from './types';

@Injectable()
export class PaymentService
  extends BaseServiceFacade
{
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<PaymentModel, string> = this.genericApiCall(paymentActions.getById, paymentSelectors.getById,
true
);
}