<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.activityTypeModel$ | async as activityType">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row mb-2">
                <div class="col-12 col-md-9 mr-auto">
                  <div class="form-group">
                    <label class="form-title-label" translate>ACTIVITYTYPE_LBL_NAME</label>
                    <div class="form-title">{{ activityType.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.activityTypeModel$ | async as activityType">
      <app-comment-panel [entityType]="'ActivityType'" [entityId]="activityType.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
