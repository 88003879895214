import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { currencyActions } from './currency.actions';
import { CurrencyModel } from './types';

export interface ICurrencyState extends IState<CurrencyModel> {
  getById: GenericState<CurrencyModel>;
  postCurrency: GenericState<CurrencyModel>;
  updateCurrency: GenericState<CurrencyModel>;
}

export const currencyInitialState: ICurrencyState = {
  getById: new GenericState<CurrencyModel>(),
  postCurrency: new GenericState<CurrencyModel>(),
  updateCurrency: new GenericState<CurrencyModel>(),
};

const currencyReducers = [
  ...onApiCall<CurrencyModel>(currencyActions.getById, 'getById'),
  ...onApiCall<CurrencyModel>(currencyActions.postCurrency, 'postCurrency'),
  ...onApiCall<CurrencyModel>(currencyActions.updateCurrency, 'updateCurrency'),
];

export const currencyReducer = createReducer(
  currencyInitialState,
  ...currencyReducers,
) as ActionReducer<ICurrencyState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return currencyReducer(state, action);
}
