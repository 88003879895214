import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Entity } from '../../store/view/types';
import { DropdownStyle } from '../../store/typeahead/types';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of, throttleTime } from 'rxjs';
import { BankModel } from '../../store/bank/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BankApiService } from '../../store/bank/bank.api.service';
import { filter, take, tap } from 'rxjs/operators';
import { debugLog } from '../../pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-bank-account-edit',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
})
export class BankAccountComponent implements OnInit {
  @Input() form: AbstractControl | null;

  bankAccountForm: FormGroup;

  private bic$ = new BehaviorSubject<string>('');

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;

  customPatterns = {
    0: {
      pattern: new RegExp(/[0-9]/),
    },
    C: {
      pattern: new RegExp('[a-zA-Z0-9]'),
    },
    S: {
      pattern: new RegExp('[a-zA-Z]'),
    },
  };

  //Directly call the API method because it's a reusable module and many can be in the same form
  constructor(private readonly bankApiService: BankApiService) {}

  ngOnInit(): void {
    this.bankAccountForm = this.form as FormGroup;

    this.bic$.pipe(untilDestroyed(this)).subscribe((bic) => {
      this.bankAccountForm.patchValue({
        bic: bic,
      });
    });

    this.bankAccountForm.controls['bankId'].valueChanges
      .pipe(throttleTime(200))
      .subscribe((bankId) => {
        if (bankId) {
          this.bankApiService
            .getById(bankId)
            .pipe(take(1))
            .subscribe((bankModel) => {
              this.bic$.next(bankModel.bic);
            });
        } else {
          this.bic$.next('');
        }
      });
  }
}
