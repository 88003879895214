import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { insuranceCompanyActions } from './insurance-company.actions';
import { insuranceCompanySelectors } from './insurance-company.selector';
import { InsuranceCompanyModel } from './types';

@Injectable()
export class InsuranceCompanyService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<InsuranceCompanyModel, string> = this.genericApiCall(
    insuranceCompanyActions.getById,
    insuranceCompanySelectors.getById,
    true,
  );
  createInstance: GenericApiCall<InsuranceCompanyModel, void> =
    this.genericApiCall(
      insuranceCompanyActions.createInstance,
      insuranceCompanySelectors.createInstance,
      true,
    );
  add: GenericApiCall<InsuranceCompanyModel, InsuranceCompanyModel> =
    this.genericApiCall(
      insuranceCompanyActions.add,
      insuranceCompanySelectors.add,
      true,
    );
  update: GenericApiCall<InsuranceCompanyModel, InsuranceCompanyModel> =
    this.genericApiCall(
      insuranceCompanyActions.update,
      insuranceCompanySelectors.update,
      true,
    );
}
