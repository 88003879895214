import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { InvoiceRoutingModule } from './invoice-routing.module';

@NgModule({
  declarations: [InvoiceDetailsComponent],
  imports: [CommonModule, InvoiceRoutingModule, SharedModule],
})
export class InvoiceModule {}
