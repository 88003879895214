import { Component, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { ErrorsNestedFormGroup } from '../../../store/form/types';
import { FormService } from '../../../store/form/form.service';
import { ViewService } from '../../../store/view/views.service';
import { View } from '../../../store/view/types';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-toast-validation-content',
  templateUrl: './toast-validation-content.component.html',
  styleUrls: ['./toast-validation-content.component.scss'],
})
export class ToastValidationContentComponent implements OnInit {
  errorsDescription$: Observable<ErrorsNestedFormGroup>;
  view$: Observable<View>;
  errorsEntityType$: Observable<string>;
  entity: string | undefined;

  constructor(
    private readonly formService: FormService,
    private readonly viewService: ViewService,
  ) {}

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.view$ = this.viewService.getViews.value$;

    this.view$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.entity = x.entity;

        this.errorsEntityType$ = this.formService.getErrorsEntity();
      });
    this.errorsEntityType$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((errorsEntityType) => {
        this.entity = errorsEntityType;
      });
  }
}
