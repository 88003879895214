<div class="connexion-container bg-white shadow-lg">
  <div class="row align-items-end">
    <div class="col-auto">
      <div class="company-img">
        <img class="img-fluid"
             src="../../../assets/images/logo-login.png"
             alt="Groupe Gesco Assurances"
        />
      </div>
    </div>
    <div class="col">
      <h2 class="mb-4" translate>LOGIN_LBL_TITLE</h2>
    </div>
  </div>
    <input type="hidden" name="returnUrl" value="@ViewBag.ReturnUrl" />
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div
        *ngIf="this.showErrorLogin"
        class="alert alert-danger"
        id="validationSummary"
      >
        <ul>
          <li translate>LOGIN_MSG_WRONG_LOGIN</li>
        </ul>
      </div>
      <div class="form-group"
      >
        <label for="email" class="form-label" translate>LOGIN_LBL_USERNAME</label>
        <input
          id="email"
          type="text"
          formControlName="email"
          class="form-control form-control-md"
          required
          autocomplete="off"
          tabindex="1"
          checkFormFieldValidity
      />
      </div>
      <div class="form-group mt-4"
      >
        <div class="d-flex align-items-baseline">
          <div class="flex-fill">
            <label for="password" class="form-label" translate
              >LOGIN_LBL_PASSWORD</label
            >
          </div>
        </div>
        <input
          id="password"
          type="password"
          formControlName="password"
          class="form-control form-control-md"
          tabindex="2"
          required
          checkFormFieldValidity
        />
      </div>

      <div class="row align-items-center mt-4">
        <div class="col">
          <a routerLink="/ForgotPassword" translate>LOGIN_LBL_FORGET_PASSWORD</a>
        </div>
        <div class="col-auto">
        <button type="submit" class="btn btn-primary btn-lg" tabindex="3" translate>
          LOGIN_BTN_LOGIN
        </button>
        </div>
      </div>
    </form>
</div>
