import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { GenericState, IState } from '../../../common/store/types';
import { ContactModel } from './types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { contactActions } from './contact.actions';

export interface IContactState extends IState<ContactModel> {
  getById: GenericState<ContactModel>;
  postContact: GenericState<ContactModel>;
  updateContact: GenericState<ContactModel>;
  createInstance: GenericState<ContactModel>;
  getChildren: GenericState<ContactModel[]>;
}

export const contactInitialState: IContactState = {
  getById: new GenericState<ContactModel>(),
  postContact: new GenericState<ContactModel>(),
  updateContact: new GenericState<ContactModel>(),
  createInstance: new GenericState<ContactModel>(),
  getChildren: new GenericState<ContactModel[]>(),
};

const contactReducers = [
  ...onApiCall<ContactModel>(contactActions.getById, 'getById'),
  ...onApiCall<ContactModel>(contactActions.postContact, 'postContact'),
  ...onApiCall<ContactModel>(contactActions.updateContact, 'updateContact'),
  ...onApiCall<ContactModel>(contactActions.createInstance, 'createInstance'),
  ...onApiCall<ContactModel[]>(contactActions.getChildren, 'getChildren'),
];

export const contactReducer = createReducer(
  contactInitialState,
  ...contactReducers,
) as ActionReducer<IContactState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return contactReducer(state, action);
}
