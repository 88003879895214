import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductLineModel } from 'src/app/shared/store/product-line/types';
import { ProductLineService } from 'src/app/shared/store/product-line/product-line.service';

@Component({
  selector: 'app-product-line-details-products',
  templateUrl: './product-line-details-products.component.html',
  styleUrls: ['./product-line-details-products.component.scss'],
})
export class ProductLineDetailsProductComponent {
  productLine$: Observable<ProductLineModel>;

  constructor(private readonly productLineService: ProductLineService) {
    this.productLine$ = this.productLineService.getById.value$;
  }
}
