import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrencyService } from 'src/app/shared/store/currency/currency.service';
import { CurrencyModel } from 'src/app/shared/store/currency/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';

@Component({
  selector: 'app-currency-details',
  templateUrl: './currency-details.component.html',
  styleUrls: ['./currency-details.component.scss'],
})
export class CurrencyDetailsComponent implements OnInit {
  currencyId: number;
  currencyBase$: Observable<CurrencyModel>;
  view$: Observable<View>;
  constructor(
    private route: ActivatedRoute,
    private readonly currencyService: CurrencyService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.currencyBase$ = this.currencyService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.currencyId = this.route.snapshot.params['id'];
    this.currencyService.getById.call(this.currencyId.toString());

    this.currencyBase$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@currencyDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'currencyEdit') {
          this.router.navigate([
            'Configuration/Currencies/Edit/',
            this.currencyId,
          ]);
          this.subHeaderService.setTriggerAction('');
        }
      });
  }

  ngOnDestroy(): void {
    this.currencyService.getById.reset();
    this.breadcrumbService.set('@currencyDetails', ' ');
  }
}
