import { createFeatureSelector } from '@ngrx/store';
import { ICountryState } from './country.reducer';
import { countryFeatureKey, CountryModel } from './types';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';

const countryFeatureState =
  createFeatureSelector<ICountryState>(countryFeatureKey);

export const countrySelectors = {
  getById: createApiCallSelectors<number, ICountryState>(
    countryFeatureState,
    'getById',
  ),
  postCountry: createApiCallSelectors<CountryModel, ICountryState>(
    countryFeatureState,
    'postCountry',
  ),
  updateCountry: createApiCallSelectors<CountryModel, ICountryState>(
    countryFeatureState,
    'updateCountry',
  ),
};
