import { Component } from '@angular/core';

@Component({
  selector: 'app-insurance-reporting',
  templateUrl: './insurance-reporting.component.html',
  styleUrls: ['./insurance-reporting.component.scss']
})
export class InsuranceReportingComponent {

}
