import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { FileUploadState, UploadStatus } from './types';

const getError = (state: FileUploadState): string => state.error ?? '';

const getStarted = (state: FileUploadState): boolean =>
  state.status === UploadStatus.Started;

const getRequested = (state: FileUploadState): boolean =>
  state.status === UploadStatus.Requested;

const getReady = (state: FileUploadState): boolean =>
  state.status === UploadStatus.Ready;

const getProgress = (state: FileUploadState): number => state?.progress ?? 0;

const getInProgress = (state: FileUploadState): boolean =>
  state.status === UploadStatus.Started && (state?.progress ?? 0) >= 0;

const getFailed = (state: FileUploadState): boolean =>
  state.status === UploadStatus.Failed;

const getCompleted = (state: FileUploadState): boolean =>
  state.status === UploadStatus.Completed;

export const selectUploadFileFeatureState: MemoizedSelector<
  object,
  FileUploadState
> = createFeatureSelector<FileUploadState>('uploadFile');

export const selectUploadFileError: MemoizedSelector<
  object,
  string
> = createSelector(selectUploadFileFeatureState, getError);

export const selectUploadFileReady: MemoizedSelector<
  object,
  boolean
> = createSelector(selectUploadFileFeatureState, getReady);

export const selectUploadFileRequested: MemoizedSelector<
  object,
  boolean
> = createSelector(selectUploadFileFeatureState, getRequested);

export const selectUploadFileStarted: MemoizedSelector<
  object,
  boolean
> = createSelector(selectUploadFileFeatureState, getStarted);

export const selectUploadFileProgress: MemoizedSelector<
  object,
  number
> = createSelector(selectUploadFileFeatureState, getProgress);

export const selectUploadFileInProgress: MemoizedSelector<
  object,
  boolean
> = createSelector(selectUploadFileFeatureState, getInProgress);

export const selectUploadFileFailed: MemoizedSelector<
  object,
  boolean
> = createSelector(selectUploadFileFeatureState, getFailed);

export const selectUploadFileCompleted: MemoizedSelector<
  object,
  boolean
> = createSelector(selectUploadFileFeatureState, getCompleted);
