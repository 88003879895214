import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bank-account-edit',
  templateUrl: './bank-account-edit.component.html',
  styleUrls: ['./bank-account-edit.component.scss'],
})
export class BankAccountEditComponent {
  bankAccountId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.bankAccountId = this.route.snapshot.params['id'];
  }
}
