import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { LostReasonModel } from './types';
import { lostReasonActions } from './lost-reason.actions';

export interface ILostReasonState extends IState<LostReasonModel> {
  getById: GenericState<LostReasonModel>;
  createInstance: GenericState<LostReasonModel>;
  add: GenericState<LostReasonModel>;
  update: GenericState<LostReasonModel>;
}

export const lostReasonInitialState: ILostReasonState = {
  getById: new GenericState<LostReasonModel>(),
  createInstance: new GenericState<LostReasonModel>(),
  add: new GenericState<LostReasonModel>(),
  update: new GenericState<LostReasonModel>(),
};

const lostReasonReducers = [
  ...onApiCall<LostReasonModel>(lostReasonActions.getById, 'getById'),
  ...onApiCall<LostReasonModel>(
    lostReasonActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<LostReasonModel>(lostReasonActions.add, 'add'),
  ...onApiCall<LostReasonModel>(lostReasonActions.update, 'update'),
];

export const lostReasonReducer = createReducer(
  lostReasonInitialState,
  ...lostReasonReducers,
) as ActionReducer<ILostReasonState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return lostReasonReducer(state, action);
}
