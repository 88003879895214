import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, ActionMode, View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
  view$: Observable<View>;
  childrenPath: string;
  actions: Action[];
  protected readonly ActionMode = ActionMode;

  constructor(
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((x) => {
      this.childrenPath = x[0].path[0].toLowerCase() + x[0].path.substring(1);
      // console.log(this.childrenPath)
      this.view$
        .pipe(
          untilDestroyed(this),
          filter((x) => !!x)
        )
        .subscribe((x) => {
          // if(x.actions && x.actions[this.childrenPath]) this.actions = x.actions[this.childrenPath];
          if (x.actions) {
            const index = x.actions.findIndex(
              (y) => y.name === this.childrenPath
            );
            this.actions = [];
            if (index >= 0) {
              this.actions = x.actions[index]?.listOfActions;
            }
            // this.actions = x.actions[x.actions.findIndex(y => y.name === this.childrenPath)]?.listOfActions;
          }
          // console.log(this.actions)
        });
    });
  }

  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
