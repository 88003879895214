import { createFeatureSelector } from '@ngrx/store';
import { ISalesTeamState } from './sales-team.reducer';
import { SalesTeamModel, salesTeamFeatureKey } from './types';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';

const salesTeamFeatureState =
  createFeatureSelector<ISalesTeamState>(salesTeamFeatureKey);

export const salesTeamSelectors = {
  getById: createApiCallSelectors<number, ISalesTeamState>(
    salesTeamFeatureState,
    'getById',
  ),
  getList: createApiCallSelectors<void, ISalesTeamState>(
    salesTeamFeatureState,
    'getList',
  ),
  getSalesTeamMemberList: createApiCallSelectors<number, ISalesTeamState>(
    salesTeamFeatureState,
    'getSalesTeamMemberList',
  ),
  create: createApiCallSelectors<void, ISalesTeamState>(
    salesTeamFeatureState,
    'create',
  ),
  postSalesTeam: createApiCallSelectors<SalesTeamModel, ISalesTeamState>(
    salesTeamFeatureState,
    'postSalesTeam',
  ),
  updateSalesTeam: createApiCallSelectors<SalesTeamModel, ISalesTeamState>(
    salesTeamFeatureState,
    'updateSalesTeam',
  ),
};
