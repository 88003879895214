import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TerminationReasonModule } from './termination-reason/termination-reason.module';
import { InsuranceClaimModule } from './insurance-claim/insurance-claim.module';
import { InsuranceReportingComponent } from './insurance-reporting/insurance-reporting.component';
import { SharedModule } from '../../shared/shared.module';
import { ProductCategoryModule } from './product-category/product-category.module';
import { ProductLineModule } from './product-line/product-line.module';
import { ProductModule } from './product/product.module';
import { InsuranceCompanyModule } from './insurance-company/insurance-company.module';
import { InsuranceRoutingModule } from './insurance-routing.module';

@NgModule({
  declarations: [InsuranceReportingComponent],
  imports: [
    CommonModule,
    InsuranceClaimModule,
    TerminationReasonModule,
    ProductCategoryModule,
    ProductLineModule,
    ProductModule,
    InsuranceCompanyModule,
    SharedModule,
    InsuranceRoutingModule,
  ],
})
export class InsuranceModule {}
