import { Component, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ActivityModel,
  ActivityStatusFlow,
} from '../../../../../shared/store/activity/types';
import { Action, View } from '../../../../../shared/store/view/types';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { ActivityService } from '../../../../../shared/store/activity/activity.service';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';

@UntilDestroy()
@Component({
  selector: 'app-custom-actions-flow-activity',
  templateUrl: './custom-actions-flow-activity.component.html',
  styleUrls: ['./custom-actions-flow-activity.component.scss'],
})
export class CustomActionsFlowActivityComponent implements OnInit {
  view$: Observable<View>;
  activityModel$: Observable<ActivityModel>;
  activityStatusFlows$: Observable<ActivityStatusFlow[]>;
  customActions$: Observable<Action[]>;
  activityId: number;

  constructor(
    private readonly activityService: ActivityService,
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.activityModel$ = this.activityService.getById.value$;
    this.activityStatusFlows$ = this.activityService.getStatusFlow.value$;
    this.customActions$ = this.activityService.getStatusActions.value$;
  }

  ngOnInit(): void {
    this.activityModel$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((activityModel) => {
        this.activityService.getStatusFlow.call(activityModel.status);
        this.activityService.getStatusActions.call(activityModel.status);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'MarkAsDone') this.markAsDone();

        this.subHeaderService.setTriggerAction('');
      });
  }

  triggerStatusFlow(actionName: string, activityId: number) {
    this.activityId = activityId;
    this.subHeaderService.setTriggerAction(actionName);
  }

  markAsDone() {
    this.activityService.markAsDone.call(this.activityId);
  }
}
