import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  InsuranceCompanyAction,
  insuranceCompanyActions,
} from './insurance-company.actions';
import { InsuranceCompanyApiService } from './insurance-company.api.service';

@Injectable()
export class InsuranceCompanyEffects {
  constructor(
    private readonly insuranceCompanyApiService: InsuranceCompanyApiService,
    private readonly action$: Actions<InsuranceCompanyAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    insuranceCompanyActions.getById,
    this.insuranceCompanyApiService.getById,
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    insuranceCompanyActions.createInstance,
    this.insuranceCompanyApiService.createInstance,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    insuranceCompanyActions.add,
    this.insuranceCompanyApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    insuranceCompanyActions.update,
    this.insuranceCompanyApiService.update,
  );
}
