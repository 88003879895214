import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountMovePeriod',
})
export class AccountMovePeriodPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (value === '01/01/0001 - 01/01/0001') {
      return '-';
    }
    return value;
  }
}
