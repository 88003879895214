import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { PageResult } from '../../types';
import { ZipCodeModel, zipCodeFeatureKey } from './types';
import { IZipCodeState } from './zipcode.reducer';

const zipCodeFeatureState =
  createFeatureSelector<IZipCodeState>(zipCodeFeatureKey);

export const zipCodeSelectors = {
  get: createApiCallSelectors<ZipCodeModel[], IZipCodeState>(
    zipCodeFeatureState,
    'get',
  ),
  getById: createApiCallSelectors<ZipCodeModel, IZipCodeState>(
    zipCodeFeatureState,
    'getById',
  ),
  getByCodeAndCity: createApiCallSelectors<ZipCodeModel, IZipCodeState>(
    zipCodeFeatureState,
    'getByCodeAndCity',
  ),
};
