import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactModel } from '../../../../../../shared/store/contact/types';
import { ContactService } from '../../../../../../shared/store/contact/contact.service';

@Component({
  selector: 'app-contact-details-marketing',
  templateUrl: './contact-details-marketing.component.html',
  styleUrls: ['./contact-details-marketing.component.scss'],
})
export class ContactDetailsMarketingComponent {
  contactDetail$: Observable<ContactModel>;

  constructor(private readonly contactService: ContactService) {
    this.contactDetail$ = this.contactService.getById.value$;
  }
}
