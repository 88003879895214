<div class="card data-card h-100" [class.border-request]="item['requestDate'] && !item['fileExtension']">
  <div class="card-body">
    <div class="card-left pe-2">
      <div>
        <i class="icon icon-file-{{item['fileExtension']}}"></i>
      </div>
    </div>
    <div class="card-content">
      <h5 class="card-title" *ngIf="item['name']">{{ item["name"] }}</h5>
      <span class="card-subtitle text-muted mb-1" *ngIf="item['creationDate']">
              <i class="mdi mdi-calendar-plus me-1"></i>
        {{ item['creationDate'] | date : "dd/MM/yyyy HH:mm" }}
            </span>
      <span class="card-subtitle text-muted mb-1" *ngIf="item['size']">
              <i class="mdi mdi-attachment me-1"></i>
        {{ item['size'] | fileSize }}
      </span>
    </div>
  </div>
  <div class="card-ur-corner-wrapper-bottom-right" *ngIf="item['fileExtension']">
    <div class="dropdown card-menu">
      <button class="btn btn-outline-primary"
              [style.font-size]="'30px'"
              type="button"
              title="Télécharger"
              (click)="onClickDownload(item)"
      >
        <i class="mdi mdi-cloud-download" *ngIf="!downloadFinished"></i>
        <i class="mdi mdi-cloud-check" *ngIf="downloadFinished"></i>
      </button>
    </div>
  </div>
</div>
