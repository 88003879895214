import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    let isInteger = Number.isInteger(value);

    if (!isInteger) return value;

    let size = +value;

    if (size <= 0) return value;
    if (size < 1024) return `${size} B`;

    size = size / 1024;
    if (size < 1024) return `${Math.round(size)} Kb`;

    size = size / 1024;
    if (size < 1024) return `${Math.round(size)} Mb`;

    return value;
  }
}
