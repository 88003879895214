import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { lostReasonActions } from './lost-reason.actions';
import { lostReasonSelectors } from './lost-reason.selector';
import { LostReasonModel } from './types';

@Injectable()
export class LostReasonService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<LostReasonModel, string> = this.genericApiCall(
    lostReasonActions.getById,
    lostReasonSelectors.getById,
    true,
  );
  createInstance: GenericApiCall<LostReasonModel, void> = this.genericApiCall(
    lostReasonActions.createInstance,
    lostReasonSelectors.createInstance,
    true,
  );
  add: GenericApiCall<LostReasonModel, LostReasonModel> = this.genericApiCall(
    lostReasonActions.add,
    lostReasonSelectors.add,
    true,
  );
  update: GenericApiCall<LostReasonModel, LostReasonModel> =
    this.genericApiCall(
      lostReasonActions.update,
      lostReasonSelectors.update,
      true,
    );
}
