import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TerminationReasonApiService } from './termination-reason.api.service';
import { TerminationReasonEffects } from './termination-reason.effects';
import {
  ITerminationReasonState,
  terminationReasonInitialState,
  terminationReasonReducer,
} from './termination-reason.reducer';
import { TerminationReasonService } from './termination-reason.service';
import { terminationReasonFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<ITerminationReasonState>(
      terminationReasonFeatureKey,
      terminationReasonReducer,
      {
        initialState: terminationReasonInitialState,
      },
    ),
    EffectsModule.forFeature([TerminationReasonEffects]),
  ],
  providers: [TerminationReasonApiService, TerminationReasonService],
})
export class StoreTerminationReasonModule {}
