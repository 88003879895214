import { createFeatureSelector } from "@ngrx/store";
import { createApiCallSelectors } from "src/app/common/store/generic.selectors";
import { IBankState } from "./bank.reducer";
import { bankFeatureKey } from "./types";

const bankFeatureState = 
    createFeatureSelector<IBankState>(bankFeatureKey);

export const bankSelectors = {
    getById: createApiCallSelectors<number, IBankState>(
        bankFeatureState,
        'getById'
    ),
    postBank: createApiCallSelectors<any, IBankState>(
        bankFeatureState,
        'postBank'
    ),
    updateBank: createApiCallSelectors<any, IBankState>(
        bankFeatureState,
        'updateBank'
    )
};