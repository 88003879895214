import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menu, Module } from './types';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
  ) {}

  getModules = (): Observable<Module[]> => {
    let parameters: string[];
    parameters = [];
    const headers = new HttpHeaders();
    headers.set('Accept-Language', 'fr');
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Module[]>(url, { headers: headers }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getMenus = (moduleId: string): Observable<Menu[]> => {
    let parameters: string[];
    parameters = [moduleId, 'menus'];
    let url = this.formatUrl(parameters);

    return this.httpClient.get<Menu[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  formatUrl = (parameters?: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.layout.navigation.url;
    if (typeof parameters !== 'undefined') {
      parameters.forEach((parameters) => {
        url += '/';
        url += parameters;
      });
    }
    return url;
  };
}
