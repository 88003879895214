import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  ActivityTypeAction,
  activityTypeActions,
} from './activity-type.actions';
import { ActivityTypeApiService } from './activity-type.api.service';

@Injectable()
export class ActivityTypeEffects {
  constructor(
    private readonly activityTypeApiService: ActivityTypeApiService,
    private readonly action$: Actions<ActivityTypeAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    activityTypeActions.getById,
    this.activityTypeApiService.getById,
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    activityTypeActions.createInstance,
    this.activityTypeApiService.createInstance,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    activityTypeActions.add,
    this.activityTypeApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    activityTypeActions.update,
    this.activityTypeApiService.update,
  );
}
