import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { ActivityTypeModel } from './types';
import { activityTypeActions } from './activity-type.actions';

export interface IActivityTypeState extends IState<ActivityTypeModel> {
  getById: GenericState<ActivityTypeModel>;
  createInstance: GenericState<ActivityTypeModel>;
  add: GenericState<ActivityTypeModel>;
  update: GenericState<ActivityTypeModel>;
}

export const activityTypeInitialState: IActivityTypeState = {
  getById: new GenericState<ActivityTypeModel>(),
  createInstance: new GenericState<ActivityTypeModel>(),
  add: new GenericState<ActivityTypeModel>(),
  update: new GenericState<ActivityTypeModel>(),
};

const activityTypeReducers = [
  ...onApiCall<ActivityTypeModel>(activityTypeActions.getById, 'getById'),
  ...onApiCall<ActivityTypeModel>(
    activityTypeActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<ActivityTypeModel>(activityTypeActions.add, 'add'),
  ...onApiCall<ActivityTypeModel>(activityTypeActions.update, 'update'),
];

export const activityTypeReducer = createReducer(
  activityTypeInitialState,
  ...activityTypeReducers,
) as ActionReducer<IActivityTypeState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return activityTypeReducer(state, action);
}
