import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { CountryModel } from './types';
import { countrySelectors } from './country.selector';
import { countryActions } from './country.actions';

@Injectable()
export class CountryService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<CountryModel, string> = this.genericApiCall(
    countryActions.getById,
    countrySelectors.getById,
    true,
  );

  postCountry: GenericApiCall<CountryModel, CountryModel> = this.genericApiCall(
    countryActions.postCountry,
    countrySelectors.postCountry,
    true,
  );

  updateCountry: GenericApiCall<CountryModel, CountryModel> =
    this.genericApiCall(
      countryActions.updateCountry,
      countrySelectors.updateCountry,
      true,
    );
}
