import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document-type-edit',
  templateUrl: './document-type-edit.component.html',
  styleUrls: ['./document-type-edit.component.scss'],
})
export class DocumentTypeEditComponent implements OnInit {
  documentTypeId: number;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.documentTypeId = this.route.snapshot.params['id'];
  }
}
