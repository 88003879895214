import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { ILeadState } from './lead.reducer';
import {
  BaseLeadFormModel,
  CarInsuranceLeadModel,
  CarLeadFormModel,
  HealthInsuranceLeadModel,
  HealthLeadFormModel,
  HouseInsuranceLeadModel,
  HouseLeadFormModel,
  LeadDetailModel,
  LeadModel,
  LeadStatus,
  LeadStatusFlow,
  leadFeatureKey,
} from './types';

const leadFeatureState = createFeatureSelector<ILeadState>(leadFeatureKey);

export const leadSelectors = {
  getByUniqueId: createApiCallSelectors<LeadDetailModel, ILeadState>(
    leadFeatureState,
    'getByUniqueId',
  ),
  getHealthDetail: createApiCallSelectors<HealthInsuranceLeadModel, ILeadState>(
    leadFeatureState,
    'getHealthDetail',
  ),
  getCarDetail: createApiCallSelectors<CarInsuranceLeadModel, ILeadState>(
    leadFeatureState,
    'getCarDetail',
  ),
  getHouseDetail: createApiCallSelectors<HouseInsuranceLeadModel, ILeadState>(
    leadFeatureState,
    'getHouseDetail',
  ),
  sendLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'sendLead',
  ),
  updateLeadDetail: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'updateLeadDetail',
  ),
  updateHealthDetail: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'updateHealthDetail',
  ),
  updateCarDetail: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'updateCarDetail',
  ),
  updateHouseDetail: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'updateHouseDetail',
  ),
  createLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'createLead',
  ),
  createHealthLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'createHealthLead',
  ),
  createCarLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'createCarLead',
  ),
  createHouseLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'createHouseLead',
  ),
  getBaseLeadModel: createApiCallSelectors<BaseLeadFormModel, ILeadState>(
    leadFeatureState,
    'getBaseLeadModel',
  ),
  getHealthLeadModel: createApiCallSelectors<HealthLeadFormModel, ILeadState>(
    leadFeatureState,
    'getHealthLeadModel',
  ),
  getCarLeadModel: createApiCallSelectors<HouseLeadFormModel, ILeadState>(
    leadFeatureState,
    'getCarLeadModel',
  ),
  getHouseLeadModel: createApiCallSelectors<CarLeadFormModel, ILeadState>(
    leadFeatureState,
    'getHouseLeadModel',
  ),
  getStatusFlow: createApiCallSelectors<LeadStatusFlow[], ILeadState>(
    leadFeatureState,
    'getStatusFlow',
  ),
  getStatusActions: createApiCallSelectors<LeadStatus, ILeadState>(
    leadFeatureState,
    'getStatusActions',
  ),
  markAsWon: createApiCallSelectors<LeadDetailModel[], ILeadState>(
    leadFeatureState,
    'markAsWon',
  ),
  qualify: createApiCallSelectors<LeadDetailModel[], ILeadState>(
    leadFeatureState,
    'qualify',
  ),
  markAsLost: createApiCallSelectors<LeadDetailModel[], ILeadState>(
    leadFeatureState,
    'markAsLost',
  ),
  createContractRequest: createApiCallSelectors<LeadDetailModel[], ILeadState>(
    leadFeatureState,
    'createContractRequest',
  ),
  reactivate: createApiCallSelectors<LeadDetailModel[], ILeadState>(
    leadFeatureState,
    'reactivate',
  ),
};
