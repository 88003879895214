import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { productActions } from './product.actions';
import { productSelectors } from './product.selector';
import { ProductModel } from './types';

@Injectable()
export class ProductService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ProductModel, string> = this.genericApiCall(
    productActions.getById,
    productSelectors.getById,
    true
  );
  add: GenericApiCall<ProductModel, ProductModel> = this.genericApiCall(
    productActions.add,
    productSelectors.add,
    true
  );
  update: GenericApiCall<ProductModel, ProductModel> = this.genericApiCall(
    productActions.update,
    productSelectors.update,
    true
  );
  createInstance: GenericApiCall<ProductModel, void> = this.genericApiCall(
    productActions.createInstance,
    productSelectors.createInstance,
    true
  );

  addComparatorProduct = (product: ProductModel) => {
    this.store.dispatch(
      productActions.comparatorProducts({ product: product })
    );
  };

  getComparatorProducts = () =>
    this.store.select(productSelectors.getComparatorProducts);
}
