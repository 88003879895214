import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { LostReasonAction, lostReasonActions } from './lost-reason.actions';
import { LostReasonApiService } from './lost-reason.api.service';

@Injectable()
export class LostReasonEffects {
  constructor(
    private readonly lostReasonApiService: LostReasonApiService,
    private readonly action$: Actions<LostReasonAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    lostReasonActions.getById,
    this.lostReasonApiService.getById,
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    lostReasonActions.createInstance,
    this.lostReasonApiService.createInstance,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    lostReasonActions.add,
    this.lostReasonApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    lostReasonActions.update,
    this.lostReasonApiService.update,
  );
}
