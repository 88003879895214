import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { BankAccountModel } from './types';

export const bankAccountActions = {
  getById: createApiCallActions<BankAccountModel>('bankAccount', 'getById'),
  postBankAccount: createApiCallActions<BankAccountModel>(
    'bankAccount',
    'postBankAccount',
  ),
  updateBankAccount: createApiCallActions<BankAccountModel>(
    'bankAccount',
    'updateBankAccount',
  ),
};
export type BankAccountAction = typeof bankAccountActions;
