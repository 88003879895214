import { createFeatureSelector } from "@ngrx/store";
import { createApiCallSelectors } from "src/app/common/store/generic.selectors";
import { ITitleState } from "./title.reducer";
import { titleFeatureKey } from "./types";

const titleFeatureState = 
    createFeatureSelector<ITitleState>(titleFeatureKey);

export const titleSelectors = {
    getById: createApiCallSelectors<number, ITitleState>(
        titleFeatureState,
        'getById'
    ),
    postTitle: createApiCallSelectors<any, ITitleState>(
        titleFeatureState,
        'postTitle'
    ),
    updateTitle: createApiCallSelectors<any, ITitleState>(
        titleFeatureState,
        'updateTitle'
    )
};