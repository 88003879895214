import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Entity } from '../../store/view/types';
import { debugLog, filterTrue } from '../../pipe/rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinctUntilChanged, startWith, take } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { ProductCategoryApiService } from '../../store/product-category/product-category.api.service';

@UntilDestroy()
@Component({
  selector: 'product-category-edit',
  templateUrl: './product-category-edit.component.html',
  styleUrls: ['./product-category-edit.component.scss'],
})
export class ProductCategoryEditComponent implements OnInit, OnChanges {
  @Input() controlForm: AbstractControl;
  @Input() isReadonly: boolean = false;
  @Output() categoryChange = new EventEmitter<number>();

  productCategoryModel$ = new BehaviorSubject<number>(0);

  protected readonly Entity = Entity;

  constructor(
    private readonly productCategoryApiService: ProductCategoryApiService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    //When control is not initialized but the value is still there
    if (changes['controlForm'].firstChange) {
      if (changes['controlForm'].currentValue.value) {
        this.productCategoryApiService
          .getById(changes['controlForm'].currentValue.value.toString())
          .pipe(filterTrue(), take(1))
          .subscribe((productCategoryModel) => {
            this.productCategoryModel$.next(
              productCategoryModel.productCategoryEnumKey,
            );
          });
      }
    }
  }

  ngOnInit(): void {
    this.productCategoryModel$
      .pipe(untilDestroyed(this))
      .subscribe((value: number) => {
        this.categoryChange.emit(value);
      });

    this.controlForm.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.productCategoryApiService
            .getById(value.toString())
            .pipe(filterTrue(), take(1))
            .subscribe((productCategoryModel) => {
              this.productCategoryModel$.next(
                productCategoryModel.productCategoryEnumKey,
              );
            });
        } else {
          this.categoryChange.emit(0);
        }
      });
  }
}
