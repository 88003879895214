import { createApiCallActions } from '../../../common/store/generic.actions';
import { InsuranceCoverageModel } from './types';

export const insuranceCoverageActions = {
  getById: createApiCallActions<InsuranceCoverageModel>(
    'insuranceCoverage',
    'getById'
  ),
  getByProductCategory: createApiCallActions<InsuranceCoverageModel[]>(
    'insuranceCoverage',
    'getByProductCategory'
  ),
};

export type InsuranceCoverageAction = typeof insuranceCoverageActions;
