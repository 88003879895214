import { CurrencyDetailsComponent } from '../../../features/configuration/currency/currency-details/currency-details.component';
import { CurrencyCreateComponent } from '../../../features/configuration/currency/currency-create/currency-create.component';
import { JobCreateComponent } from '../../../features/human-resources/job/job-create/job-create.component';
import { JobDetailsComponent } from '../../../features/human-resources/job/job-details/job-details.component';
import { Type } from '@angular/core';
import { CurrencyDetailsContentComponent } from '../../../features/configuration/currency/currency-details/currency-details-content/currency-details-content.component';
import { CurrencyCreateContentComponent } from '../../../features/configuration/currency/currency-create/currency-create-content/currency-create-content.component';
import { Entity } from '../view/types';

export const typeaheadFeatureKey = 'typeahead';

export class ReferencesItem {
  name: string;
}
export class ListItem {
  id: number;
  name: string;
  value: string;
}

export type InputTypeahead = {
  filter: string;
  domain: string;
  pageSize: number;
  orderBy: string;
  endpoint: string;
};
export type InputReferenceDataTypeahead = {
  endpoint: string;
  field: string;
  filterParentName: string;
  filterParentValue: string;
  orderBy: string;
};
export type InputEnumDataTypeahead = {
  endpoint: string;
  enumType: string;
};

export type InputTypeaheadQuickAdd = {
  endpoint: string;
  name: string;
};

export type ComponentType = {
  modelType: Entity;
  details: Type<Component>;
  create: Type<Component>;
};
export type ComponentDef = {
  name: string;
  type: Type<Component>;
};

export type Component =
  | CurrencyDetailsComponent
  | CurrencyDetailsContentComponent
  | CurrencyCreateComponent
  | JobDetailsComponent
  | JobCreateComponent
  | CurrencyCreateContentComponent;

export enum DataType {
  ReferenceData = 0,
  Enum = 1,
}
export enum SourceEnum {
  Component = 0,
  Modal = 1,
}

export enum DropdownStyle {
  //     Edit portion is modifiable
  DropDown = 0,

  //     Edit portion is not modifiable
  DropDownList = 1,
}
export enum AutoCompleteMode {
  //
  // Summary:
  //     The actual value is determined at a higher level of the property resolution hierarchy.
  Default,
  //
  // Summary:
  //     Disables the automatic completion feature.
  None,
  //
  // Summary:
  //     Appends the remainder of the most likely candidate string to the existing characters,
  //     highlighting the appended characters.
  //
  // Remarks:
  //     With the 'Append' setting, characters are appended to the text typed in the edit
  //     portion so as to match the string representation of the "most likely" candidate
  //     in the list. For example, consider a list containing the strings "Apple", "Application",
  //     and "Apprehend". If the end user types an "A" into the edit portion when it is
  //     empty, the character string "pple" is appended to the "A" to produce "Apple",
  //     because that is the first entry in the list which matches the typed character
  //     string. The appended characters are selected. If the appended characters are
  //     overwritten to produce the character string "Appli", the character string "cation"
  //     is appended to produce "Application", since the presence of the character "i"
  //     now precludes "Apple" as a match.
  Append,
  //
  // Summary:
  //     Displays the dropdown list, which is populated with one or more suggested completion
  //     strings, and updated in real time as the end user changes the contents of the
  //     edit portion.
  //
  // Remarks:
  //     When set to 'Suggest', the contents of the list displayed to the end user is
  //     modified so as to only contain entries whose display text begins with the text
  //     in the edit portion of the control. For example, consider a list containing the
  //     strings "Apple", "Application", and "Apprehend". If the end user types an "A"
  //     into the edit portion when it is empty, all three list items appear in the dropdown
  //     list, since they all begin with the letter "A". If the end user continues typing
  //     to produce "Appl", the "Apprehend" item no longer appears in the dropdown list,
  //     since it is no longer a match candidate.
  Suggest,
  //
  // Summary:
  //     Combines the 'Suggest' and 'Append' settings.
  SuggestAppend,
}
