import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { TerminationReasonModel } from './types';
import { terminationReasonActions } from './termination-reason.actions';

export interface ITerminationReasonState
  extends IState<TerminationReasonModel> {
  getById: GenericState<TerminationReasonModel>;
  createInstance: GenericState<TerminationReasonModel>;
  add: GenericState<TerminationReasonModel>;
  update: GenericState<TerminationReasonModel>;
}

export const terminationReasonInitialState: ITerminationReasonState = {
  getById: new GenericState<TerminationReasonModel>(),
  createInstance: new GenericState<TerminationReasonModel>(),
  add: new GenericState<TerminationReasonModel>(),
  update: new GenericState<TerminationReasonModel>(),
};

const terminationReasonReducers = [
  ...onApiCall<TerminationReasonModel>(
    terminationReasonActions.getById,
    'getById',
  ),
  ...onApiCall<TerminationReasonModel>(
    terminationReasonActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<TerminationReasonModel>(terminationReasonActions.add, 'add'),
  ...onApiCall<TerminationReasonModel>(
    terminationReasonActions.update,
    'update',
  ),
];

export const terminationReasonReducer = createReducer(
  terminationReasonInitialState,
  ...terminationReasonReducers,
) as ActionReducer<ITerminationReasonState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return terminationReasonReducer(state, action);
}
