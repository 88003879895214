import { Component, Input, OnInit } from '@angular/core';
import { DocumentModel } from '../../../store/document/types';
import { Observable, withLatestFrom } from 'rxjs';
import { UploadFileService } from '../../../store/upload-file/upload-file.service.facade';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-document-request-card',
  templateUrl: './document-request-card.component.html',
  styleUrls: ['./document-request-card.component.scss'],
})
export class DocumentRequestCardComponent implements OnInit {
  @Input() document!: DocumentModel;
  uploadCompleted$: Observable<boolean>;

  isExpired: boolean = false;

  constructor(private readonly uploadFileService: UploadFileService) {}

  ngOnInit(): void {
    this.uploadCompleted$ = this.uploadFileService.completed$.pipe(
      withLatestFrom(this.uploadFileService.currentFileId$),
      filter(([_isInProgress, id]) => this.document.id == id),
      map(([isInProgress, _id]) => isInProgress),
      untilDestroyed(this),
    );

    this.isExpired =
      !!this.document.dueDate &&
      Date.parse(this.document.dueDate.toString()) <
        new Date().setHours(0, 0, 0, 0);
  }
}
