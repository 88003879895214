<div
  class="tab-pane fade flex-even active show"
  id="insuranceDocuments"
  role="tabpanel"
  aria-labelledby="insuranceDocuments-tab"
  *ngIf="this.product$ | async as product"
>
  <div
    class="d-flex flex-column h-100"
    *ngIf="product.productCoverages.length != 0"
  >
    <div class="row mt-2">
      <table class="table table-striped border-bottom">
        <thead>
          <tr>
            <th>
              <a translate>PRODUCT_LBL_VALUE</a>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let coverage of product.productCoverages"
            class="row-action"
          >
            <td>{{ coverage.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
