import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NavigationApiService } from './navigation.api.services';
import { NavigationEffects } from './navigation.effects';
import {
  INavigationState,
  navigationInitialState,
  navigationReducer,
} from './navigation.reducer';
import { NavigationService } from './navigation.service';
import { navigationFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<INavigationState>(navigationFeatureKey, navigationReducer, {
      initialState: navigationInitialState,
    }),
    EffectsModule.forFeature([NavigationEffects]),
  ],
  providers: [NavigationApiService, NavigationService],
})
export class StoreNavigationModule {}
