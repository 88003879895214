import { Component } from '@angular/core';

@Component({
  selector: 'app-insurance-company-create',
  templateUrl: './insurance-company-create.component.html',
  styleUrls: ['./insurance-company-create.component.scss']
})
export class InsuranceCompanyCreateComponent {

}
