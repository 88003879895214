import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {
  onApiCall,
  onArrayApiCall,
} from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { countyActions } from './county.actions';
import { CountyModel } from './types';

export interface ICountyState extends IState<CountyModel> {
  get: GenericState<CountyModel[]>;
  getById: GenericState<CountyModel>;
}

export const countyInitialState: ICountyState = {
  get: new GenericState<CountyModel[]>(),
  getById: new GenericState<CountyModel>(),
};

const countyReducers = [
  ...onArrayApiCall<CountyModel>(countyActions.get, 'get'),
  ...onApiCall<CountyModel>(countyActions.getById, 'getById'),
];

export const countyReducer = createReducer(
  countyInitialState,
  ...countyReducers,
) as ActionReducer<ICountyState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return countyReducer(state, action);
}
