import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../types';
import { uploadFileActions } from './upload-file.actions';
import * as fromFileUploadSelectors from './upload-file.selectors';
import { BaseServiceFacade } from '../../../common/store/types';

@Injectable()
export class UploadFileService extends BaseServiceFacade {
  completed$: Observable<boolean>;
  progress$: Observable<number>;
  error$: Observable<string>;

  isInProgress$: Observable<boolean>;
  isReady$: Observable<boolean>;
  hasFailed$: Observable<boolean>;

  currentFileId$: BehaviorSubject<number>;

  constructor(store: Store<AppState>) {
    super(store);

    this.completed$ = this.fromStore(
      fromFileUploadSelectors.selectUploadFileCompleted,
    );
    this.progress$ = this.fromStore(
      fromFileUploadSelectors.selectUploadFileProgress,
    );
    this.error$ = this.fromStore(fromFileUploadSelectors.selectUploadFileError);
    this.isInProgress$ = this.fromStore(
      fromFileUploadSelectors.selectUploadFileInProgress,
    );
    this.isReady$ = this.fromStore(
      fromFileUploadSelectors.selectUploadFileReady,
    );
    this.hasFailed$ = this.fromStore(
      fromFileUploadSelectors.selectUploadFileFailed,
    );

    this.currentFileId$ = new BehaviorSubject(-1);
  }

  uploadFile(id: any, entityType: any, entityId: any, file: any) {
    this.currentFileId$.next(id);
    this.store.dispatch(
      uploadFileActions.UPLOAD_REQUEST({ id, entityType, entityId, file }),
    );
  }

  resetUpload() {
    this.store.dispatch(uploadFileActions.UPLOAD_RESET());
  }

  cancelUpload() {
    this.store.dispatch(uploadFileActions.UPLOAD_CANCEL());
  }
}
