import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter } from 'rxjs';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import { LeadLostModel } from 'src/app/shared/store/lead/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { SourceEnum } from 'src/app/shared/store/typeahead/types';

@UntilDestroy()
@Component({
  selector: 'app-lead-lost-reason-content',
  templateUrl: './lead-lost-reason-content.component.html',
  styleUrls: ['./lead-lost-reason-content.component.scss'],
})
export class LeadLostReasonContentComponent implements OnInit {
  @Input() leadId: number;
  @Input() source: SourceEnum = SourceEnum.Component;

  leadLostReasonform: FormGroup;
  markAsLost$: Observable<any>;
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly leadService: LeadService,
    private readonly activeModalService: NgbActiveModal,
    private readonly subHeaderService: SubHeaderService
  ) {}
  ngOnInit(): void {
    this.markAsLost$ = this.leadService.markAsLost.value$;
    this.leadLostReasonform = this.formBuilder.group({
      lostReason: [undefined, [Validators.required]],
      lostSummary: [''],
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'MarkAsLost') this.onSubmit();

        this.subHeaderService.setTriggerAction('');
      });
  }
  onSubmit() {
    let leadLostModel: LeadLostModel = {
      leadId: this.leadId,
      leadLostReason: this.leadLostReasonform.get('lostReason')?.value,
      leadLostSummary: this.leadLostReasonform.get('lostSummary')?.value,
    };

    this.leadService.markAsLost.call(leadLostModel);

    this.activeModalService.close();
  }
}
