<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div
        class="page-container"
        *ngIf="this.productCategory$ | async as productCategory"
      >
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-title-label" translate
                      >PRODUCT_CATEGORY_LBL_NAME</label
                    >
                    <div class="form-title">{{ productCategory.name }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >PRODUCT_CATEGORY_LBL_TYPE</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm h-100"
                      >
                        {{ productCategory.productCategoryTypeName }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col">

                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-6 col-lg-6">-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col-4 col-form-label-sm" translate-->
<!--                    >PRODUCT_CATEGORY_LBL_PARENT</label-->
<!--                    >-->
<!--                    <div class="col">-->
<!--                      <span-->
<!--                        class="form-control-plaintext form-control-sm h-100"-->
<!--                      >-->
<!--                        {{ productCategory.parentName }}-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col">-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col-4 col-form-label-sm" translate-->
<!--                    >PRODUCT_CATEGORY_LBL_TYPE</label-->
<!--                    >-->
<!--                    <div class="col">-->
<!--                      <span-->
<!--                        class="form-control-plaintext form-control-sm h-100"-->
<!--                      >-->
<!--                        {{ productCategory.productCategoryTypeName }}-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="row">
                <div class="col-12 col-lg-12">
                  <div class="form-group row">
                    <label class="col-2 col-form-label-sm" translate
                    >PRODUCT_CATEGORY_LBL_DESCRIPTION</label
                    >
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm h-100"
                      >
                        {{ productCategory.description }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <app-product-category-tabs-details />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-comment"
      *ngIf="this.productCategory$ | async as productCategory"
    >
      <app-comment-panel
        [entityType]="'ProductCategory'"
        [entityId]="productCategory.id.toString()"
      ></app-comment-panel>
    </div>
  </div>
</div>
