import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../../shared/store/form/form.service';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../../shared/store/view/types';
import { DocumentTypeModel } from '../../../../../shared/store/document-type/types';
import { DocumentTypeService } from '../../../../../shared/store/document-type/document-type.service';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-document-type-form-content',
  templateUrl: './document-type-form-content.component.html',
  styleUrls: ['./document-type-form-content.component.scss'],
})
export class DocumentTypeFormContentComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  documentTypeModel$: Observable<DocumentTypeModel>;
  // createdocumentTypeInstance$: Observable<DocumentTypeModel>;

  documentTypeForm: FormGroup;

  isEditMode: boolean = false;
  documentTypeId: string;

  constructor(
    private readonly documentTypeService: DocumentTypeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.documentTypeModel$ = this.documentTypeService.getById.value$;
    // this.createdocumentTypeInstance$ = this.documentTypeService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.documentTypeForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.documentTypeId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.documentTypeId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.documentTypeService.getById.call(this.documentTypeId);
    } else {
      // this.documentTypeService.createInstance.call();
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'documentTypeCreate' || x == 'documentTypeUpdate')
          this.onSubmit();
        if (x == 'documentTypeList') {
          this.formService.clear();
          this.router.navigate(['/Documents/DocumentTypes/']);
        }
        if (x == 'documentTypeDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Documents/DocumentTypes/Details',
            this.documentTypeId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set('@documentTypeCreate', x.breadcrumbCreate);
      });

    if (this.isEditMode) {
      this.documentTypeModel$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.breadcrumbService.set('@documentTypeEdit', x.name);
          this.patchForm(x);
        });
    }

    // if (!this.isEditMode) {
    //   this.createdocumentTypeInstance$
    //     .pipe(
    //       filter((x) => !!x),
    //       take(1),
    //     )
    //     .subscribe((x) => {
    //       this.patchForm(x);
    //     });
    // }
  }

  private patchForm = (x: DocumentTypeModel) => {
    this.documentTypeForm.patchValue({
      name: x.name,
      description: x.description,
    });
  };

  onSubmit() {
    if (this.documentTypeForm.invalid) {
      this.documentTypeForm.markAllAsTouched();
      this.formService.setEntityErrors('DOCUMENT_TYPE');
      this.formService.countErrors(this.documentTypeForm, true);
      return;
    }

    const documentTypeModel: DocumentTypeModel = {
      id: this.documentTypeId ? +this.documentTypeId : 0,
      name: this.documentTypeForm.value.name!,
      description: this.documentTypeForm.value.description!,
    };

    let result$: Observable<DocumentTypeModel>;
    if (this.isEditMode)
      result$ = this.documentTypeService.update.call(documentTypeModel);
    else result$ = this.documentTypeService.add.call(documentTypeModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();

        this.router.navigate(['/Documents/DocumentTypes/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.documentTypeService.add.reset();
    this.documentTypeService.update.reset();

    if (this.isEditMode) {
      this.documentTypeService.getById.reset();
    } else {
      // this.documentTypeService.createInstance.reset();
    }

    this.breadcrumbService.set('@documentTypeEdit', ' ');
    this.breadcrumbService.set('@documentTypeCreate', ' ');
  }
}
