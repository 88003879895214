<app-comparator-subheader />
<span>comparantor-component-component</span>
<h4>Généralités</h4>
<table
  class="table"
  *ngIf="this.insuranceCoverages$ | async as insuranceCoverages"
>
  <thead>
    <th></th>
    <th>Produit 1</th>
    <th>Produit 2</th>
  </thead>
  <tr *ngFor="let insuranceCoverage of insuranceCoverages">
    <th [class.title]="insuranceCoverage.insuranceCoverageType == 1">
      {{ insuranceCoverage.description }}
    </th>
    <td>Produit 1 Info</td>
    <td>Produit 2 Info</td>
  </tr>
</table>

<!-- {{ this.joinedArray$ | async | json }} -->

<!-- <table *ngIf="this.insuranceCoverages$ | async as insuranceCoverages">
  <thead></thead>
  <tbody></tbody>
  <tr *ngFor="let insuranceCoverage of insuranceCoverages">
    <th>
      {{ insuranceCoverage.description }}
    </th>
    <td>Stuff</td>
  </tr>
</table> -->
