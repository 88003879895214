import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BankDetailsComponent } from './bank-details/bank-details.component';
import { BankRoutingModule } from './bank-routing.module';

@NgModule({
  declarations: [BankDetailsComponent],
  imports: [CommonModule, SharedModule, BankRoutingModule],
})
export class BankModule {}
