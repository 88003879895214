import { HomeModule } from './home/home.module';
import { NgModule } from '@angular/core';
import { LeadModule } from './crm/lead/lead.module';
import { AuthentificationModule } from './authentification/authentification.module';
import { CmsModule } from './cms/cms.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { CrmModule } from './crm/crm.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { HumanResourcesModule } from './human-resources/human-resources.module';
import { AccountModule } from './account/account.module';
import { ContactsModule } from './contacts/contacts.module';
import { ProductsModule } from './products/products.module';
import { DocumentsModule } from './documents/documents.module';
import { ComparatorModule } from './products/comparator/comparator-module';
import { InsuranceModule } from './insurance/insurance.module';
import { FinanceModule } from './finance/finance.module';

@NgModule({
  imports: [
    HomeModule,
    LeadModule,
    AuthentificationModule,
    CmsModule,
    ConfigurationModule,
    CrmModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterLink,
    HumanResourcesModule,
    AccountModule,
    ContactsModule,
    ProductsModule,
    DocumentsModule,
    ComparatorModule,
    InsuranceModule,
    FinanceModule,
  ],
  exports: [],
  declarations: [],
})
export class FeaturesModule {}
