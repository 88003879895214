import { Injectable } from '@angular/core';
import { EmployeeApiService } from './employee.api.service';
import { Actions } from '@ngrx/effects';
import { EmployeeActions, employeeActions } from './employee.actions';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { campaignActions } from '../campaign/campaign.action';

@Injectable()
export class EmployeeEffects {
  constructor(
    private readonly employeeApiService: EmployeeApiService,
    private readonly action$: Actions<EmployeeActions>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    employeeActions.getById,
    this.employeeApiService.getById,
  );

  getCampaignModel = createApiCallEffects(
    this,
    this.action$,
    employeeActions.getEmployeeModel,
    this.employeeApiService.getEmployeeModel,
  );

  postCampaign = createApiCallEffects(
    this,
    this.action$,
    employeeActions.postEmployee,
    this.employeeApiService.postEmployee,
  );

  updateCampaign = createApiCallEffects(
    this,
    this.action$,
    employeeActions.updateEmployee,
    this.employeeApiService.updateEmployee,
  );
}
