import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActivityTypeCreateComponent } from './activity-type-create/activity-type-create.component';
import { ActivityTypeDetailsComponent } from './activity-type-details/activity-type-details.component';
import { ActivityTypeEditComponent } from './activity-type-edit/activity-type-edit.component';
import { ActivityTypeRoutingModule } from './activity-type-routing.module';

@NgModule({
  declarations: [
    ActivityTypeDetailsComponent,
    ActivityTypeCreateComponent,
    ActivityTypeEditComponent,
  ],
  imports: [CommonModule, SharedModule, ActivityTypeRoutingModule],
})
export class ActivityTypeModule {}
