import { Component, OnInit } from '@angular/core';
import { ButtonType } from '../../../../../../shared/store/subheader/type';
import { Observable } from 'rxjs';
import { ContactService } from '../../../../../../shared/store/contact/contact.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../../../shared/service/modal.service';
import { AccountingService } from '../../../../../../shared/store/accounting/accounting.service';
import { DocumentService } from '../../../../../../shared/store/document/document.service';
import { ModalContentComponent } from '../../../../../../shared/components/modal-content/modal-content.component';
import { toFirstLetterLower } from '../../../../../../shared/helper/stringhelper';
import { SourceEnum } from '../../../../../../shared/store/typeahead/types';
import {
  AccountMoveLightModel,
  AccountMoveLineModel,
  AccountMoveModel,
  AccountMoveStatus,
  BindType,
} from '../../../../../../shared/store/accounting/types';
import { filterTrue } from '../../../../../../shared/pipe/rxjs/operators';
import { filter, take } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import { InsuranceCompanyService } from '../../../../../../shared/store/insurance-company/insurance-company.service';
import { InsuranceCompanyModel } from '../../../../../../shared/store/insurance-company/types';
import { ActionButton } from '../../../../../../shared/components/modal-content/action-button';
import { Action } from '../../../../../../shared/store/view/types';

@Component({
  selector: 'app-insurance-company-details-payment',
  templateUrl: './insurance-company-details-payment.component.html',
  styleUrls: ['./insurance-company-details-payment.component.scss'],
})
export class InsuranceCompanyDetailsPaymentComponent implements OnInit {
  contactId: number;
  payment$: Observable<AccountMoveModel[]>;
  insuranceCompanyModel$: Observable<InsuranceCompanyModel>;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly contactService: ContactService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly accountingService: AccountingService,
    private readonly documentService: DocumentService,
    private readonly insuranceCompanyService: InsuranceCompanyService,
  ) {
    this.payment$ = this.accountingService.getByContactId.value$;
    this.insuranceCompanyModel$ = this.insuranceCompanyService.getById.value$;
  }

  ngOnInit(): void {
    this.insuranceCompanyModel$
      .pipe(filterTrue(), take(1))
      .subscribe((model) => {
        this.contactId = model.contactId;
        this.accountingService.getByContactId.call(model.contactId.toString());
      });
  }

  openModalCreatePayment(contactId: number) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass('PaymentAddContentComponent');
    modalRef.componentInstance.title = 'PAYMENT_LBL_ADD';

    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('Payment') + 'Create',
      },
    ];

    modalRef.componentInstance.inputParams = {
      contactId: contactId,
      isReimbursement: true,
      isCompany: true,
      contactIsCompany: true,
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.accountingService.getByContactId.call(this.contactId.toString());
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  openModalBindAccountMoves(contactId: number, accountMoveId?: number) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'AccountMoveAssignSlipContentComponent',
      );
    modalRef.componentInstance.title = 'PAYMENT_LBL_BIND';

    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('accountMoveAssign') + 'Create',
      },
    ];

    modalRef.componentInstance.inputParams = {
      contactId: contactId,
      paymentId: accountMoveId,
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.accountingService.getByContactId.call(this.contactId.toString());
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  openModalCreateReimbursement(contactId: number) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass('PaymentAddContentComponent');
    modalRef.componentInstance.title = 'REIMBOURSEMENT_LBL_ADD';

    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('Payment') + 'Create',
      },
    ];
    modalRef.componentInstance.inputParams = {
      contactId: contactId,
      source: SourceEnum.Modal,
      isReimbursement: true,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.accountingService.getByContactId.call(this.contactId.toString());
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  openModalBindAccountMovesDetails(
    contactId: number,
    invoice: AccountMoveModel,
    type: BindType,
  ) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: true,
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'AccountMoveAssignDetailsContentComponent',
      );
    modalRef.componentInstance.title = 'ACCOUNTMOVE_LBL_PAYMENTS_INVOICES';
    if (type === 'Repayment') {
      modalRef.componentInstance.title = 'ACCOUNTMOVE_LBL_PAYMENTS_REPAYMENT';
    }

    modalRef.componentInstance.closeCrossButton = true;
    modalRef.componentInstance.cancelButton = false;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        label: 'PAYMENT_LBL_UNBIND',
        actionName: 'accountMovesUnbind',
      },
      // <ActionButton>{
      //   actionName:  'generatePDF'
      // },
    ];

    switch (type) {
      case 'Repayment':
        modalRef.componentInstance.inputParams = {
          source: SourceEnum.Modal,
          accountMoveId: invoice.id,
          labelPayments: 'ACCOUNTMOVE_LBL_CLIENT',
          labelInvoices: 'ACCOUNTMOVE_LBL_VENDOR',
          isVendorInvoice: true,
          bindType: 'Repayment',
        };
        break;
      case 'Slip':
        modalRef.componentInstance.inputParams = {
          source: SourceEnum.Modal,
          accountMoveId: invoice.id,
          bindType: 'Slip',
        };
        break;
      case 'Payment':
      default:
        modalRef.componentInstance.inputParams = {
          source: SourceEnum.Modal,
          accountMoveId: invoice.id,
          bindType: 'Payment',
        };
        break;
    }

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.accountingService.getByContactId.call(contactId.toString());
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  openModalSlipContent(contactId: number) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass('SlipAddContentComponent');
    modalRef.componentInstance.title = 'PAYMENT_LBL_IMPORT_PAYMENT_SLIP';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'slipImport',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      slipType: 'Payment',
      contactId: contactId,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          // this.contactService.getById.call(contactId.toString());
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  onClickChangeAccountMoveStatus(
    id: number,
    status: AccountMoveStatus,
    contactId: number,
  ) {
    let result$ = this.accountingService.changeAccountMoveStatus.call({
      id: id.toString(),
      status: status,
    });

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.accountingService.getByContactId.call(contactId.toString());
      this.accountingService.changeAccountMoveStatus.reset();
    });
  }
  onClickDeleteAccountMove(id: number, contactId: number) {
    let result$ = this.accountingService.delete.call(id);

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.accountingService.getByContactId.call(contactId.toString());
      this.accountingService.delete.reset();
    });
  }

  onClickShowDocument(documentId: number) {
    const getFileSubscription = this.documentService.getFile.value$
      .pipe(
        // tap((x) => {
        //   console.log(x);
        // }),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result: Blob) => {
        var fileURL = window.URL.createObjectURL(result);
        let tab = window.open();
        if (tab) {
          tab.location.href = fileURL;
        }
        this.documentService.getFile.reset();
      });

    this.documentService.getFile.call(documentId.toString());
  }

  onValidationEmitterUploadFile(
    file: File | undefined,
    accountMoveLineId: number,
    contactId: number,
  ) {
    if (file) {
      let result$ = this.accountingService.uploadProof.call({
        id: accountMoveLineId,
        file: file,
      });

      result$
        .pipe(
          filter((x) => x || !x),
          untilDestroyed(this),
        )
        .subscribe((result) => {
          if (result) {
            this.accountingService.uploadProof.reset();
            this.accountingService.getByContactId.call(contactId.toString());
          }
        });
    }
  }

  triggerAction(actionName: string, accountMoveId: number, contactId: number) {
    let status: AccountMoveStatus = 'Draft';
    switch (actionName) {
      case 'CancelAccountMove':
        status = 'Cancel';
        this.onClickChangeAccountMoveStatus(accountMoveId, status, contactId);
        break;
      case 'ValidateAccountMove':
        status = 'Posted';
        this.onClickChangeAccountMoveStatus(accountMoveId, status, contactId);
        break;
      case 'InvalidateAccountMove':
        status = 'Draft';
        this.onClickChangeAccountMoveStatus(accountMoveId, status, contactId);
        break;
      case 'DeleteAccountMove':
        this.onClickDeleteAccountMove(accountMoveId, contactId);
        break;
    }
  }

  trackByAccountMoveId(index: any, item: AccountMoveModel) {
    return item.id;
  }
  trackByAccountMoveLineId(index: any, item: AccountMoveLineModel) {
    return item.id;
  }
}
