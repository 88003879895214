import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { ProductLineModel } from './types';
import { productLineActions } from './product-line.actions';

export interface IProductLineState extends IState<ProductLineModel> {
  getById: GenericState<ProductLineModel>;
  createInstance: GenericState<ProductLineModel>;
  add: GenericState<ProductLineModel>;
  update: GenericState<ProductLineModel>;
}

export const productLineInitialState: IProductLineState = {
  getById: new GenericState<ProductLineModel>(),
  createInstance: new GenericState<ProductLineModel>(),
  add: new GenericState<ProductLineModel>(),
  update: new GenericState<ProductLineModel>(),
};

const productLineReducers = [
  ...onApiCall<ProductLineModel>(productLineActions.getById, 'getById'),
  ...onApiCall<ProductLineModel>(
    productLineActions.createInstance,
    'createInstance'
  ),
  ...onApiCall<ProductLineModel>(productLineActions.add, 'add'),
  ...onApiCall<ProductLineModel>(productLineActions.update, 'update'),
];

export const productLineReducer = createReducer(
  productLineInitialState,
  ...productLineReducers
) as ActionReducer<IProductLineState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return productLineReducer(state, action);
}
