import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentModule } from './document/document.module';
import { DocumentTypeModule } from './document-type/document-type.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    DocumentsRoutingModule,
    DocumentModule,
    DocumentTypeModule,
  ],
  exports: [],
})
export class DocumentsModule {}
