import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { ProductCategoryModel } from 'src/app/shared/store/product-category/types';
import { ProductCategoryService } from 'src/app/shared/store/product-category/product-category.service';

@UntilDestroy()
@Component({
  selector: 'app-product-category-details',
  templateUrl: './product-category-details.component.html',
  styleUrls: ['./product-category-details.component.scss'],
})
export class ProductCategoryDetailsComponent implements OnInit, OnDestroy {
  productCategoryId: number;
  productCategory$: Observable<ProductCategoryModel>;
  view$: Observable<View>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly productCategoryService: ProductCategoryService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.productCategory$ = this.productCategoryService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.productCategoryId = this.route.snapshot.params['id'];
    this.productCategoryService.getById.call(this.productCategoryId.toString());

    this.productCategory$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@productCategoryDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'productCategoryEdit') {
          this.router.navigate([
            'Insurance/ProductCategories/Edit/',
            this.productCategoryId,
          ]);
          this.subHeaderService.setTriggerAction('');
        }
      });
  }

  ngOnDestroy(): void {
    this.productCategoryService.getById.reset();
    this.breadcrumbService.set('@productCategoryDetails', ' ');
  }
}
