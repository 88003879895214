import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { contractActions } from './contract.actions';
import { contractSelectors } from './contract.selectors';
import {
  ContractModel,
  ContractHealthFormModel,
  ContractStatus,
  ContractStatusFlow,
  CreateContractFormModel,
  HealthInsuranceContractDetailModel,
  ContractCarFormModel,
  ContractHouseFormModel,
  CarInsuranceContractModel,
  HouseInsuranceContractModel,
  ContractHealthUpdateModel,
  ContractHouseUpdateModel,
  ContractCarUpdateModel,
  CreateContractEntityModel,
  ContractAddBaseModel,
  ContractUpdateBaseModel,
} from './types';
import { Action } from '../view/types';

@Injectable()
export class ContractService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ContractModel, string> = this.genericApiCall(
    contractActions.getById,
    contractSelectors.getById,
    true,
  );
  getByUniqueId: GenericApiCall<ContractModel, string> = this.genericApiCall(
    contractActions.getByUniqueId,
    contractSelectors.getByUniqueId,
    true,
  );

  getByLeadId: GenericApiCall<ContractModel, string> = this.genericApiCall(
    contractActions.getByLeadId,
    contractSelectors.getByLeadId,
    true,
  );

  getHealthDetail: GenericApiCall<
    HealthInsuranceContractDetailModel[],
    number
  > = this.genericApiCall(
    contractActions.getHealthDetail,
    contractSelectors.getHealthDetail,
    true,
  );

  getCarDetail: GenericApiCall<CarInsuranceContractModel, number> =
    this.genericApiCall(
      contractActions.getCarDetail,
      contractSelectors.getCarDetail,
      true,
    );

  getHouseDetail: GenericApiCall<HouseInsuranceContractModel, number> =
    this.genericApiCall(
      contractActions.getHouseDetail,
      contractSelectors.getHouseDetail,
      true,
    );

  getStatusFlow: GenericApiCall<ContractStatusFlow[], ContractStatus> =
    this.genericApiCall(
      contractActions.getStatusFlow,
      contractSelectors.getStatusFlow,
      true,
    );

  confirm: GenericApiCall<ContractModel, number> = this.genericApiCall(
    contractActions.confirm,
    contractSelectors.confirm,
    true,
  );

  saveAsDraft: GenericApiCall<ContractModel, number> = this.genericApiCall(
    contractActions.saveAsDraft,
    contractSelectors.saveAsDraft,
    true,
  );
  requestValidation: GenericApiCall<ContractModel, number> =
    this.genericApiCall(
      contractActions.requestValidation,
      contractSelectors.requestValidation,
      true,
    );
  validate: GenericApiCall<ContractModel, number> = this.genericApiCall(
    contractActions.validate,
    contractSelectors.validate,
    true,
  );
  reject: GenericApiCall<ContractModel, number> = this.genericApiCall(
    contractActions.reject,
    contractSelectors.reject,
    true,
  );
  markAsRegularized: GenericApiCall<ContractModel, number> =
    this.genericApiCall(
      contractActions.markAsRegularized,
      contractSelectors.markAsRegularized,
      true,
    );
  verify: GenericApiCall<ContractModel, number> = this.genericApiCall(
    contractActions.verify,
    contractSelectors.verify,
    true,
  );
  suspendContract: GenericApiCall<ContractModel, number> = this.genericApiCall(
    contractActions.suspendContract,
    contractSelectors.suspendContract,
    true,
  );
  closeContract: GenericApiCall<ContractModel, number> = this.genericApiCall(
    contractActions.closeContract,
    contractSelectors.closeContract,
    true,
  );
  getStatusActions: GenericApiCall<Action[], ContractStatus> =
    this.genericApiCall(
      contractActions.getStatusActions,
      contractSelectors.getStatusActions,
      true,
    );
  getContractModel: GenericApiCall<ContractModel, CreateContractEntityModel> =
    this.genericApiCall(
      contractActions.getContractModel,
      contractSelectors.getContractModel,
      true,
    );
  getHealthContractModel: GenericApiCall<
    HealthInsuranceContractDetailModel[],
    number
  > = this.genericApiCall(
    contractActions.getHealthContractModel,
    contractSelectors.getHealthContractModel,
    true,
  );
  getCarContractModel: GenericApiCall<CarInsuranceContractModel, number> =
    this.genericApiCall(
      contractActions.getCarContractModel,
      contractSelectors.getCarContractModel,
      true,
    );
  getHouseContractModel: GenericApiCall<HouseInsuranceContractModel, number> =
    this.genericApiCall(
      contractActions.getHouseContractModel,
      contractSelectors.getHouseContractModel,
      true,
    );
  createContract: GenericApiCall<ContractModel, ContractAddBaseModel> =
    this.genericApiCall(
      contractActions.createContract,
      contractSelectors.createContract,
      true,
    );
  createHealthContract: GenericApiCall<ContractModel, ContractHealthFormModel> =
    this.genericApiCall(
      contractActions.createHealthContract,
      contractSelectors.createHealthContract,
      true,
    );
  createCarContract: GenericApiCall<ContractModel, ContractCarFormModel> =
    this.genericApiCall(
      contractActions.createCarContract,
      contractSelectors.createCarContract,
      true,
    );
  createHouseContract: GenericApiCall<ContractModel, ContractHouseFormModel> =
    this.genericApiCall(
      contractActions.createHouseContract,
      contractSelectors.createHouseContract,
      true,
    );
  updateContract: GenericApiCall<ContractModel, ContractUpdateBaseModel> =
    this.genericApiCall(
      contractActions.updateContract,
      contractSelectors.updateContract,
      true,
    );
  updateHealthContract: GenericApiCall<
    ContractModel,
    ContractHealthUpdateModel
  > = this.genericApiCall(
    contractActions.updateHealthContract,
    contractSelectors.updateHealthContract,
    true,
  );
  updateCarContract: GenericApiCall<ContractModel, ContractCarUpdateModel> =
    this.genericApiCall(
      contractActions.updateCarContract,
      contractSelectors.updateCarContract,
      true,
    );

  updateHouseContract: GenericApiCall<ContractModel, ContractHouseUpdateModel> =
    this.genericApiCall(
      contractActions.updateHouseContract,
      contractSelectors.updateHouseContract,
      true,
    );
}
