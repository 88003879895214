import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { subHeaderActions } from './subheader.actions';

export interface ISubHeaderState {
  triggerAction: string;
}

export const subHeaderInitialState: ISubHeaderState = {
  triggerAction: '',
};

const subHeaderReducers = [
  on(
    subHeaderActions.triggerAction,
    (state: ISubHeaderState, { actionName }) => ({
      ...state,
      triggerAction: actionName,
    })
  ),
];

export const subHeaderReducer = createReducer(
  subHeaderInitialState,
  ...subHeaderReducers
) as ActionReducer<ISubHeaderState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return subHeaderReducer(state, action);
}
