import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LostReasonService } from 'src/app/shared/store/lost-reason/lost-reason.service';
import { LostReasonModel } from 'src/app/shared/store/lost-reason/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-lost-reason-details',
  templateUrl: './lost-reason-details.component.html',
  styleUrls: ['./lost-reason-details.component.scss'],
})
export class LostReasonDetailsComponent implements OnInit, OnDestroy {
  lostReasonId: number;
  lostReasonModel$: Observable<LostReasonModel>;
  view$: Observable<View>;

  @Input() modalId: number;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly lostReasonService: LostReasonService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.lostReasonModel$ = this.lostReasonService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.lostReasonId = this.route.snapshot.params['id'];

    if (this.modalId) this.lostReasonId = this.modalId;

    this.lostReasonService.getById.call(this.lostReasonId.toString());

    this.lostReasonModel$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@lostReasonDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'lostReasonEdit') {
          this.router.navigate(['Crm/LostReasons/Edit/', this.lostReasonId]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy(): void {
    this.lostReasonService.getById.reset();
    this.breadcrumbService.set('@lostReasonDetails', ' ');
  }
}
