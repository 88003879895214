<div
  class="sub-header"
>
  <div class="sub-header-row border-top align-items-center px-1">
    <ng-container *ngIf="this.view$ |async as view">
      <div class="row" *ngIf="this.user$ | async as user">
        <div class="col">
          <a
            *ngIf="user.aspNetUserEmailConfirmed"
            class="btn-sm btn btn-outline-primary"
            (click)="triggerAction('userLock')"
            translate
          >
            USER_LBL_LOCK
          </a>
          <a
            *ngIf="!user.aspNetUserEmailConfirmed"
            class="btn-sm btn btn-outline-primary"
            (click)="triggerAction('userUnlock')"
            translate
          >
            USER_LBL_UNLOCK
          </a>
        </div>

        <div class="col-auto state-breadcrumb-col">
          <div class="state-breadcrumb">
            <li
              *ngIf="user.aspNetUserEmailConfirmed"
              class="active-success"
            >
              <span translate>USER_LBL_STATUS_ACTIVE</span>
            </li>
            <li
              *ngIf="!user.aspNetUserEmailConfirmed"
              class="active-danger"
            >
              <span translate>USER_LBL_STATUS_INACTIVE</span>
            </li>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
