import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState } from 'src/app/common/store/types';
import { leadActions } from './lead.actions';
import {
  BaseLeadFormModel,
  CarInsuranceLeadModel,
  CarLeadFormModel,
  HealthInsuranceLeadModel,
  HealthLeadFormModel,
  HouseInsuranceLeadModel,
  HouseLeadFormModel,
  LeadDetailModel,
  LeadStatusFlow,
} from './types';

export interface ILeadState {
  getByUniqueId: GenericState<LeadDetailModel>;
  getHealthDetail: GenericState<HealthInsuranceLeadModel>;
  getCarDetail: GenericState<CarInsuranceLeadModel>;
  getHouseDetail: GenericState<HouseInsuranceLeadModel>;
  sendLead: GenericState<any>;
  updateLeadDetail: GenericState<any>;
  updateHealthDetail: GenericState<any>;
  updateCarDetail: GenericState<any>;
  updateHouseDetail: GenericState<any>;
  createLead: GenericState<any>;
  createHealthLead: GenericState<any>;
  createCarLead: GenericState<any>;
  createHouseLead: GenericState<any>;
  getBaseLeadModel: GenericState<BaseLeadFormModel>;
  getHealthLeadModel: GenericState<HealthLeadFormModel>;
  getCarLeadModel: GenericState<CarLeadFormModel>;
  getHouseLeadModel: GenericState<HouseLeadFormModel>;
  getStatusFlow: GenericState<LeadStatusFlow[]>;
  getStatusActions: GenericState<any[]>;
}

export const leadInitialState: ILeadState = {
  getByUniqueId: new GenericState<LeadDetailModel>(),
  getHealthDetail: new GenericState<HealthInsuranceLeadModel>(),
  getCarDetail: new GenericState<CarInsuranceLeadModel>(),
  getHouseDetail: new GenericState<HouseInsuranceLeadModel>(),
  sendLead: new GenericState<any>(),
  updateLeadDetail: new GenericState<any>(),
  updateHealthDetail: new GenericState<any>(),
  updateCarDetail: new GenericState<any>(),
  updateHouseDetail: new GenericState<any>(),
  createLead: new GenericState<any>(),
  createHealthLead: new GenericState<any>(),
  createCarLead: new GenericState<any>(),
  createHouseLead: new GenericState<any>(),
  getBaseLeadModel: new GenericState<BaseLeadFormModel>(),
  getHealthLeadModel: new GenericState<HealthLeadFormModel>(),
  getCarLeadModel: new GenericState<CarLeadFormModel>(),
  getHouseLeadModel: new GenericState<HouseLeadFormModel>(),
  getStatusFlow: new GenericState<LeadStatusFlow[]>(),
  getStatusActions: new GenericState<any[]>(),
};

const leadReducers = [
  ...onApiCall<LeadDetailModel>(leadActions.getByUniqueId, 'getByUniqueId'),
  ...onApiCall<HealthInsuranceLeadModel>(
    leadActions.getHealthDetail,
    'getHealthDetail',
  ),
  ...onApiCall<CarInsuranceLeadModel>(leadActions.getCarDetail, 'getCarDetail'),
  ...onApiCall<HouseInsuranceLeadModel>(
    leadActions.getHouseDetail,
    'getHouseDetail',
  ),
  ...onApiCall<any>(leadActions.sendLead, 'sendLead'),
  ...onApiCall<any>(leadActions.updateLeadDetail, 'updateLeadDetail'),
  ...onApiCall<any>(leadActions.updateHealthDetail, 'updateHealthDetail'),
  ...onApiCall<any>(leadActions.updateCarDetail, 'updateCarDetail'),
  ...onApiCall<any>(leadActions.updateHouseDetail, 'updateHouseDetail'),
  ...onApiCall<any>(leadActions.createLead, 'createLead'),
  ...onApiCall<any>(leadActions.createHealthLead, 'createHealthLead'),
  ...onApiCall<any>(leadActions.createCarLead, 'createCarLead'),
  ...onApiCall<any>(leadActions.createHouseLead, 'createHouseLead'),
  ...onApiCall<any>(leadActions.getBaseLeadModel, 'getBaseLeadModel'),
  ...onApiCall<any>(leadActions.getHealthLeadModel, 'getHealthLeadModel'),
  ...onApiCall<any>(leadActions.getCarLeadModel, 'getCarLeadModel'),
  ...onApiCall<any>(leadActions.getHouseLeadModel, 'getHouseLeadModel'),
  ...onApiCall<LeadStatusFlow[]>(leadActions.getStatusFlow, 'getStatusFlow'),
  ...onApiCall<any[]>(leadActions.getStatusActions, 'getStatusActions'),
  // on(leadActions.markAsWon, (state: ILeadState, { leadDetailModel }) => ({
  //   ...state,
  //   getByUniqueId: leadDetailModel,
  // })),
];

export const leadReducer = createReducer(
  leadInitialState,
  ...leadReducers,
) as ActionReducer<ILeadState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return leadReducer(state, action);
}
