import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter, map } from 'rxjs';
import { QuoteService } from '../../store/quote/quote.service';
import { QuoteModel, QuoteResponseModel } from '../../store/quote/types';
import { ButtonType } from '../../store/subheader/type';
import { GenericApiCall } from 'src/app/common/store/types';

@UntilDestroy()
@Component({
  selector: 'app-lead-quotes',
  templateUrl: `./lead-quotes.component.html`,
  styleUrls: ['./lead-quotes.component.scss'],
})
export class LeadQuotesComponent implements OnInit, OnDestroy {
  protected readonly ButtonType = ButtonType;
  @Input() leadUniqueId: string;
  @Input() leadId: string;
  quoteResponseModels$: Observable<QuoteResponseModel[]>;
  apiCall: GenericApiCall<any, any>;
  formArray$: Observable<any>;
  quoteForm: FormGroup;
  isLoading$: Observable<boolean>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly quoteService: QuoteService,
    protected readonly activeModalService: NgbActiveModal,
    config: NgbRatingConfig
  ) {
    config.max = 4;
    config.readonly = true;
  }

  ngOnInit(): void {
    this.apiCall = this.quoteService.getComparatorQuotes;
    this.isLoading$ = this.quoteService.getComparatorQuotes.isLoading$;
    this.quoteResponseModels$ = this.quoteService.getComparatorQuotes.value$;
    this.quoteService.getComparatorQuotes.call(this.leadUniqueId);

    this.quoteForm = this.formBuilder.group({
      quotes: this.formBuilder.array([]),
    });

    this.formArray$ = this.quotes.valueChanges;

    this.quoteResponseModels$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((quotes) => {
        quotes.forEach((quote) => {
          const quoteForm = this.formBuilder.group({
            id: [quote.id],
            checked: [false, Validators.required],
            leadId: [this.leadId, Validators.required],
            productId: [quote.productId, Validators.required],
            monthlyCost: [quote.monthlyCost, Validators.required],
            applicationFees: [quote.applicationFees, Validators.required],
            quoteSource: [quote.quoteSource, Validators.required],
            isBestValue: [quote.isBestValue, Validators.required],
          });
          this.quotes.push(quoteForm);
        });
      });
  }

  ngOnDestroy(): void {
    this.quoteService.getComparatorQuotes.reset();
  }
  get quotes() {
    return this.quoteForm.controls['quotes'] as FormArray;
  }

  submit() {
    let controls = this.quotes.controls as FormControl[];

    const quotes: QuoteModel[] = controls
      .filter((x: FormControl) => x.value.checked == true)
      .map((x: FormControl) => ({
        id: 0,
        leadId: x.value.leadId,
        productId: x.value.productId,
        monthlyCost: x.value.monthlyCost,
        applicationFees: x.value.applicationFees,
        quoteSource: x.value.quoteSource,
        isBestValue: x.value.isBestValue,
        productName: '',
        productProductLineId: 1,
        productProductLineName: '',
        productProductLineInsuranceCompanyId: 1,
        productProductLineInsuranceCompanyContactName: '',
      }));

    const result$ = this.quoteService.addQuotes.call(quotes);
    result$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        this.closeModal();
      });
  }

  closeModal() {
    this.activeModalService.close();
  }

  addProduct(quoteResponseModelId: number) {
    let controls = this.quotes.controls as FormControl[];
    const index = controls.findIndex(
      (x: FormControl) => x.value.id == quoteResponseModelId
    );
    const value = (this.quotes.controls[index] as FormGroup).controls['checked']
      .value;

    (this.quotes.controls[index] as FormGroup).controls['checked'].patchValue(
      !value
    );
  }
}
