import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { CommissionPlanRoutingModule } from './commission-plan-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, CommissionPlanRoutingModule, SharedModule],
})
export class CommissionPlanModule {}
