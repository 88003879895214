<ng-container *ngIf="tabsContent$ | async as tabsContent">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="active"
    class="nav-tabs"
    [destroyOnHide]="false"
  >
    <li
      [ngbNavItem]="tabContent.index"
      *ngFor="
        let tabContent of tabsContent;
        count as count;
        last as isLast;
        trackBy: trackByTabsContent
      "
    >
      <button ngbNavLink class="nav-link" translate>
        {{ tabContent.title }}
      </button>
      <ng-template ngbNavContent>
        <ng-container *ngComponentOutlet="tabContent.component" />
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</ng-container>
