import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ProductCoverageModel, productCoverageFeatureKey } from './types';
import { IProductCoverageState } from './product-coverage.reducer';

const productCoverageFeatureState =
  createFeatureSelector<IProductCoverageState>(productCoverageFeatureKey);

export const productCoverageSelectors = {
  getById: createApiCallSelectors<ProductCoverageModel, IProductCoverageState>(
    productCoverageFeatureState,
    'getById'
  ),
  getByProductId: createApiCallSelectors<
    ProductCoverageModel,
    IProductCoverageState
  >(productCoverageFeatureState, 'getByProductId'),
};
