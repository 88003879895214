import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { createFeatureSelector } from '@ngrx/store';
import { ICampaignState } from './campaign.reducer';
import { campaignFeatureKey, CampaignModel } from './types';

const campaignFeatureState =
  createFeatureSelector<ICampaignState>(campaignFeatureKey);

export const campaignSelectors = {
  getById: createApiCallSelectors<number, ICampaignState>(
    campaignFeatureState,
    'getById',
  ),
  getList: createApiCallSelectors<void, ICampaignState>(
    campaignFeatureState,
    'getList',
  ),
  createInstance: createApiCallSelectors<void, ICampaignState>(
    campaignFeatureState,
    'createInstance',
  ),
  postCampaign: createApiCallSelectors<CampaignModel, ICampaignState>(
    campaignFeatureState,
    'postCampaign',
  ),
  updateCampaign: createApiCallSelectors<CampaignModel, ICampaignState>(
    campaignFeatureState,
    'updateCampaign',
  ),
};
