import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { QuoteModel, QuoteResponseModel } from './types';
import { quoteActions } from './quote.actions';

export interface IQuoteState extends IState<QuoteModel> {
  getById: GenericState<QuoteModel>;
  getComparatorQuotes: GenericState<QuoteResponseModel[]>;
  addQuotes: GenericState<any>;
}

export const quoteInitialState: IQuoteState = {
  getById: new GenericState<QuoteModel>(),
  getComparatorQuotes: new GenericState<QuoteResponseModel[]>(),
  addQuotes: new GenericState<any>(),
};

const quoteReducers = [
  ...onApiCall<QuoteModel>(quoteActions.getById, 'getById'),
  ...onApiCall<QuoteResponseModel[]>(
    quoteActions.getComparatorQuotes,
    'getComparatorQuotes'
  ),
  ...onApiCall<any>(quoteActions.addQuotes, 'addQuotes'),
];

export const quoteReducer = createReducer(
  quoteInitialState,
  ...quoteReducers
) as ActionReducer<IQuoteState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return quoteReducer(state, action);
}
