import { createApiCallActions } from '../../../common/store/generic.actions';
import { ProductCategoryModel } from './types';

export const productCategoryActions = {
  getById: createApiCallActions<ProductCategoryModel>(
    'productCategory',
    'getById'
  ),
  createInstance: createApiCallActions<ProductCategoryModel>(
    'productCategory',
    'createInstance'
  ),
  add: createApiCallActions<ProductCategoryModel>('productCategory', 'add'),
  update: createApiCallActions<ProductCategoryModel>(
    'productCategory',
    'update'
  ),
};

export type ProductCategoryAction = typeof productCategoryActions;
