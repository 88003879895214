import { Action } from '../view/types';

export const accountingFeatureKey = 'accounting';

export type AccountModel = {
  id: number;
  contactId?: number;
  contactName: string;
  accountType: AccountType;
  name: string;
  code: string;
  active: boolean;
};

export type AccountMoveModel = {
  id: number;
  contactId?: number | null;
  currencyId: number;
  salesPersonId: number;
  creditAccountId: number;
  debitAccountId: number;
  contractId?: number | null;
  paymentStatus: PaymentStatus;
  paymentStatusLoc: string;
  name: string;
  reference: string;
  directionSign: number;
  status: AccountMoveStatus;
  statusLoc: string;
  moveType: MoveType;
  moveTypeLoc: string;
  paymentReference: string;
  autoPost: AutoPost;
  deliveryDate: Date;
  creationDate: Date;
  accountMoveLines: AccountMoveLineModel[];
  periodEndDate: Date;
  periodStartDate: Date;
  period: string;
  journalId?: number;
  journalName: string;
  issuingAccountMovesCount: number;
  payingAccountMovesCount: number;
  issuingAccountMoves: AccountMoveModel[];
  payingAccountMoves: AccountMoveModel[];
  repaymentAccountMoves: AccountMoveModel[];
  actions: Action[];
};

export type AccountMoveLineModel = {
  id: number;
  accountMoveId: number;
  currencyId: number;
  contactId?: number;
  name: string;
  description: string;
  sequence: number;
  quantity: number;
  unitPrice: number;
  discount: number;
  debit: number;
  credit: number;
  paymentMode: PaymentMode | null;
  paymentModeLoc: string;
  paymentModeShortNameLoc?: string;
  paymentStatus: PaymentStatus | null;
  paymentStatusLoc: string;
  creationDate: Date;
  documentId?: number;
  proofFile?: File;
};

export type IssuingAccountMovePayingAccountMoveModel = {
  issuingAccountMoves: number[];
  payingAccountMoves: number[];
  bindType: BindType;
};

export type AccountMoveInstanceParams = {
  moveType?: MoveType;
  contractId?: number;
};

export type AccountMovePeriodModel = {
  name: string;
  startDate: Date;
  endDate: Date;
};

export type AccountMoveLightModel = {
  id: number;
  name: string;
  reference: string;
};

export type SlipModel = {
  debitAccountId: number;
  creditAccountId: number;
  reference: string;
  file: File;
};

export type SlipRecordModel = {
  contractName: string;
  accountMoveName: string;
  accountMoveType: AccountMoveType;
  amount: number;
  startDate?: Date;
  endDate?: Date;
};

export type SlipResponseModel = {
  slipRecordModel: SlipRecordModel;
  status: SlipStatus;
  message: string;
};

export type JournalAssignRecord = {
  id: number;
  name: string;
  amount: number;
  creationDate: Date;
};

export type AccountMoveStatus = 'Draft' | 'Posted' | 'Cancel';
export type MoveType = 'Entry' | 'CustomerInvoice' | 'VendorInvoice';
export type PaymentMode = 'Cash' | 'Card' | 'BankTransfert' | 'Cheque';
export type PaymentStatus =
  | 'NotPaid'
  | 'InPayment'
  | 'Paid'
  | 'PartiallyPaid'
  | 'Reversed';
export type AutoPost = 'No' | 'AtDate' | 'Monthly' | 'Quartly' | 'Yearly';
export type AccountType = '';
export type BindType = 'Payment' | 'Repayment' | 'Slip';
export type AccountMoveType =
  | 'RecurringFees'
  | 'ApplicationFees'
  | 'LitigationFees'
  | 'OverPayment';
export type SlipStatus = 'Ok' | 'Error';
