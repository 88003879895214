import { Observable, OperatorFunction, TruthyTypesOf } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

export const filterTrue =
  <T>(): OperatorFunction<T, TruthyTypesOf<T>> =>
  (source: Observable<any>) =>
    source.pipe(filter(Boolean));

export const debugLog = (message: string) => (source: Observable<any>) =>
  source.pipe(
    tap((val) => {
      console.log(message + ': ', val);
    })
  );

export const debugStop = () => (source: Observable<any>) =>
  source.pipe(
    tap((val) => {
      debugger;
    })
  );
