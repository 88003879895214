<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <div class="card-body">
      <div class="container-fluid">
        <form
          [formGroup]="productCategoryForm"
          (ngSubmit)="onSubmit()"
          [hasUnsavedData]="productCategoryForm"
        >
          <button type="submit" hidden="hidden"></button>

          <div class="row">
            <div class="col-6 col-lg-6">
              <div class="form-group">
                <label class="form-title-label" for="name" translate
                >PRODUCT_CATEGORY_LBL_NAME</label
                >
                <input
                  id="name"
                  class="form-control form-title"
                  type="text"
                  formControlName="name"
                  checkFormFieldValidity
                  [maxLength]="80"
                />
              </div>
            </div>
            <div class="col"></div>
          </div>

          <div class="row">
            <div class="col-6 col-lg-6">
              <div class="form-group row">
                <label
                  class="col-2 col-form-label-sm"
                  for="productCategoryType"
                  translate
                >PRODUCT_CATEGORY_LBL_TYPE</label
                >
                <div class="col">
                  <app-typeahead
                    id="productCategoryType"
                    [limitToList]="true"
                    [dataType]="DataType.Enum"
                    [referenceEndpoint]="'Views/enum/values'"
                    [referenceField]="'Comparanoo.Core.Enums.ProductCategoryType'"
                    [dropdownStyle]="DropdownStyle.DropDown"
                    [controlForm]="this.productCategoryForm.controls['productCategoryType']"
                  ></app-typeahead>
                </div>
              </div>
            </div>

            <div class="col">

              <div class="form-group row">
                <label
                  class="col-2 col-form-label-sm"
                  for="description"
                  translate
                >PRODUCT_CATEGORY_LBL_DESCRIPTION</label
                >
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="description"
                    type="text"
                    formControlName="description"
                    checkFormFieldValidity
                  />
                </div>
              </div>
            </div>
          </div>

          <ng-container>
            <div class="d-flex justify-content-between align-items-center">
              <div class="form-group-title my-1" translate>
                PRODUCT_CATEGORY_LBL_DOCUMENTTYPE
              </div>
              <div class="text-end ms-auto">
                <button type="button" class="btn-sm btn btn-primary mb-2" (click)="openModalChoiceDocumentType()"
                        translate>GLOBAL_BTN_ADD
                </button>
              </div>
            </div>
            <div class="d-flex flex-column h-100">
              <table class="table table-striped border-bottom" formArrayName="productCategoryDocumentTypes">
                <thead>
                <tr>
                  <th>
                    <a translate>PRODUCT_CATEGORY_LBL_DOCUMENTTYPE</a>
                  </th>
                  <th>
                    <a translate>PRODUCT_CATEGORY_LBL_MANDATORY</a>
                  </th>
                  <th>
                    <a translate>PRODUCT_CATEGORY_LBL_DOCUMENTFLOW</a>
                  </th>
                  <th></th>
                </tr>
                </thead>

                <tbody>
                <tr
                  class="row-action"
                  *ngFor="let productCategoryDocumentType of productCategoryDocumentTypes.controls; index as i "
                  [formGroupName]="i"
                >
                  <td>
                    <input
                      class="form-control-plaintext form-control-sm"
                      id="documentTypeName"
                      name="documentTypeName"
                      type="text"
                      formControlName="documentTypeName"
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="active"
                      formControlName="isMandatory"
                    />
                  </td>
                  <td>
                    <select
                      class="form-control form-control-sm"
                      id="documentFlow"
                      name="documentFlow"
                      formControlName="documentFlow"
                      checkFormFieldValidity
                    >
                      <option [value]="'ToReceive'" translate>PRODUCT_CATEGORY_LBL_DOCUMENTFLOW_TO_RECEIVE</option>
                      <option [value]="'ToSend'" translate>PRODUCT_CATEGORY_LBL_DOCUMENTFLOW_TO_SEND</option>
                    </select>
                  </td>
                  <td class="text-end">
                    <button type="button" class="btn-sm btn btn-outline-primary"
                    (click)="removeProductCategoryDocumentType(i)">
                      <i class="mdi mdi-delete-forever"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</div>
