export const invoiceFeatureKey = 'invoice';

export type InvoiceModel = {
  id: number;
  insuranceCompanyId: number;
  insuranceCompanyContactName: number;
  contactId: number;
  name: string;
  description: string;
  invoiceType: number;
  invoiceTypeLoc: string;
  status: number;
  invoiceStatusLoc: string;
  uniqueId: string;
  dueDate: Date;
  creationDate: Date;
  invoiceLines: InvoiceLineModel[];
  totalAmount: number;
};

export type InvoiceLineModel = {
  name: string;
  amount: number;
  amountTaxIncluded: number;
};
