import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  TerminationReasonAction,
  terminationReasonActions,
} from './termination-reason.actions';
import { TerminationReasonApiService } from './termination-reason.api.service';

@Injectable()
export class TerminationReasonEffects {
  constructor(
    private readonly terminationReasonApiService: TerminationReasonApiService,
    private readonly action$: Actions<TerminationReasonAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    terminationReasonActions.getById,
    this.terminationReasonApiService.getById,
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    terminationReasonActions.createInstance,
    this.terminationReasonApiService.createInstance,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    terminationReasonActions.add,
    this.terminationReasonApiService.add,
  );

  update = createApiCallEffects(
    this,
    this.action$,
    terminationReasonActions.update,
    this.terminationReasonApiService.update,
  );
}
