import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { BankModel } from 'src/app/shared/store/bank/types';
import { BankService } from 'src/app/shared/store/bank/bank.service';

@UntilDestroy()
@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss'],
})
export class BankDetailsComponent implements OnInit, OnDestroy {
  bankId: number;
  bankBase$: Observable<BankModel>;
  view$: Observable<View>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly bankService: BankService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.bankBase$ = this.bankService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.bankId = this.route.snapshot.params['id'];
    this.bankService.getById.call(this.bankId.toString());

    this.bankBase$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@bankDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'bankEdit') {
          this.router.navigate(['Contacts/Banks/Edit/', this.bankId]);
          this.subHeaderService.setTriggerAction('');
        }
      });
  }

  ngOnDestroy(): void {
    this.bankService.getById.reset();
    this.breadcrumbService.set('@bankDetails', ' ');
  }
}
