import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalNumber',
})
export class DecimalNumberPipe implements PipeTransform {
  transform(value: number): string {
    var decPart = (value + '').split('.')[1];
    if (decPart == undefined) decPart = '00';
    return decPart;
  }
}
