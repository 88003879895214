import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { BankAccountModel } from './types';
import { bankAccountActions } from './bank-account.actions';
import { bankAccountSelectors } from './bank-account.selectors';

@Injectable()
export class BankAccountService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<BankAccountModel, string> = this.genericApiCall(
    bankAccountActions.getById,
    bankAccountSelectors.getById,
    true,
  );
  postBankAccount: GenericApiCall<BankAccountModel, BankAccountModel> =
    this.genericApiCall(
      bankAccountActions.postBankAccount,
      bankAccountSelectors.postBankAccount,
      true,
    );
  updateBankAccount: GenericApiCall<BankAccountModel, BankAccountModel> =
    this.genericApiCall(
      bankAccountActions.updateBankAccount,
      bankAccountSelectors.updateBankAccount,
      true,
    );
}
