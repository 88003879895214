import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { CountryModel } from './types';

export const countryActions = {
  getById: createApiCallActions<CountryModel>('country', 'getById'),
  postCountry: createApiCallActions<CountryModel>('country', 'postCountry'),
  updateCountry: createApiCallActions<CountryModel>('country', 'updateCountry'),
};

export type CountryAction = typeof countryActions;
