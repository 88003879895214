import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LostReasonCreateComponent } from './lost-reason-create/lost-reason-create.component';
import { LostReasonDetailsComponent } from './lost-reason-details/lost-reason-details.component';
import { LostReasonEditComponent } from './lost-reason-edit/lost-reason-edit.component';
import { LostReasonRoutingModule } from './lost-reason-routing.module';

@NgModule({
  declarations: [
    LostReasonDetailsComponent,
    LostReasonCreateComponent,
    LostReasonEditComponent,
  ],
  imports: [CommonModule, SharedModule, LostReasonRoutingModule],
})
export class LostReasonModule {}
