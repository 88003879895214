import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ProductLineModel, productLineFeatureKey } from './types';
import { IProductLineState } from './product-line.reducer';

const productLineFeatureState = createFeatureSelector<IProductLineState>(
  productLineFeatureKey
);

export const productLineSelectors = {
  getById: createApiCallSelectors<ProductLineModel, IProductLineState>(
    productLineFeatureState,
    'getById'
  ),
  createInstance: createApiCallSelectors<ProductLineModel, IProductLineState>(
    productLineFeatureState,
    'createInstance'
  ),
  add: createApiCallSelectors<ProductLineModel, IProductLineState>(
    productLineFeatureState,
    'add'
  ),
  update: createApiCallSelectors<ProductLineModel, IProductLineState>(
    productLineFeatureState,
    'update'
  ),
};
