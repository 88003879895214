import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, View } from '../../../../../shared/store/view/types';
import { Article } from '../../../../../shared/store/article/types';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { ArticleService } from '../../../../../shared/store/article/article.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-custom-actions-flow-article',
  templateUrl: './custom-actions-flow-article.component.html',
  styleUrls: ['./custom-actions-flow-article.component.scss'],
})
export class CustomActionsFlowArticleComponent implements OnInit {
  view$: Observable<View>;
  customActions$: Observable<Action[]>;
  article$: Observable<Article>;
  comparanooUrl: string;

  constructor(
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
    private readonly articleService: ArticleService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.article$ = this.articleService.getById.value$;
  }

  ngOnInit(): void {
    this.comparanooUrl = environment.comparanoourl;
  }

  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
