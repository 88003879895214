<div class="comment-content pt-0 h-100">
  <ng-container *ngIf="(this.filteredHistories$ | async) as histories">
    <div class="row m-2">

      <div class="col-2">
      </div>

      <div class="col-6">
      </div>

      <div class="col-2">
      </div>

    </div>
    <div class="row">
      <div class="col-10">
        <input
          class="form-control form-control-sm search-input"
          type="text"
          [placeholder]="'HISTORY_PLACEHOLDER_FILTER_SEARCH' | translate"
          [formControl]="searchControl">
      </div>
      <div class="col-2">
        <ng-container *ngIf="!(historiesTypeFilterSubject$ | async) as historyTypeFilter">
          <div ngbDropdown class="dropdown dropdown-filter d-inline">
            <button class="btn btn-primary btn-sm"
                    type="button"
                    id="dropdownBasic1"
                    ngbDropdownToggle
                    [title]="'DOCUMENT_PLACEHOLDER_ADD' | translate">
              <i class="mdi mdi-comment-plus-outline" [style.font-size]="'15px'"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button
                type="button"
                class="dropdown-item dropdown-item-normal dropdown-filter"
                (click)="newComment.isVisible = !newComment.isVisible"
              >
                <span translate> HISTORY_PLACEHOLDER_FILTER_COMMENT </span>
              </button>

              <button
                type="button"
                class="dropdown-item dropdown-item-normal dropdown-filter"
                (click)="newDocument.fileupload.nativeElement.click()"
              >
                <span translate=""> HISTORY_PLACEHOLDER_FILTER_DOCUMENT </span>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="historiesTypeFilterSubject$ | async as historyTypeFilter">
          <div ngbDropdown class="dropdown d-inline">
            <button class="btn btn-primary btn-sm" *ngIf="historyTypeFilter === 'Comment'"
                    type="button"
                    id="dropdownBasic10"
                    ngbDropdownToggle
                    [title]="'DOCUMENT_PLACEHOLDER_COMMENT_ADD' | translate"
                    (click)="newComment.isVisible = !newComment.isVisible"
            >
              <i class="mdi mdi-comment-plus-outline" [style.font-size]="'15px'"></i>
            </button>
            <button class="btn btn-primary btn-sm" *ngIf="historyTypeFilter === 'Document'"
                    type="button"
                    id="dropdownBasic20"
                    ngbDropdownToggle
                    [title]="'DOCUMENT_PLACEHOLDER_DOCUMENT_ADD' | translate"
                    (click)="newDocument.fileupload.nativeElement.click()"
            >
              <i class="mdi mdi-comment-plus-outline" [style.font-size]="'15px'"></i>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="inside-btn">
          <button class="btn btn-sm btn-outline-primary filter-btn"
                  [class.active]="(historiesTypeFilterSubject$ | async) === ''" type="button"
                  (click)="onClickHistoryType(''); newComment.isVisible = false;">
            <div class="div-btn">
              <div translate>
                HISTORY_PLACEHOLDER_FILTER_ALL
              </div>
            </div>
          </button>
          <button class="btn btn-sm btn-outline-primary filter-btn"
                  [class.active]="(historiesTypeFilterSubject$ | async)  === 'Comment'" type="button"
                  (click)="onClickHistoryType('Comment')">
            <div class="div-btn">
              <div translate>
                HISTORY_PLACEHOLDER_FILTER_COMMENT
              </div>
            </div>
          </button>
          <button class="btn btn-sm btn-outline-primary filter-btn"
                  [class.active]="(historiesTypeFilterSubject$ | async)  === 'Document'" type="button"
                  (click)="onClickHistoryType('Document'); newComment.isVisible = false;">
            <div class="div-btn">
              <div translate>
                HISTORY_PLACEHOLDER_FILTER_DOCUMENT
              </div>
            </div>
          </button>
        </div>
        <div class="row" [style.display]="'none'">
          <div class="col">
            <label class="me-1" for="allowUpload">Autoriser l'upload</label>
            <input type="checkbox" [checked]="true" (change)="true" id="allowUpload" #allowUpload>
          </div>
        </div>
      </div>
    </div>

    <app-comment-add [entityId]="this.entityId" [entityType]="this.entityType"
                     (newComment)="refreshComment($event)" #newComment></app-comment-add>

    <app-document-add [entityId]="this.entityId" [entityType]="this.entityType" [multiple]="true"
                      (newDocument)="refreshDocument($event)" #newDocument></app-document-add>

    <ul class="list-group list-group-flush">
      <li class="list-group-item align-items-start mt-2 px-1 py-2"
          *ngFor="let history of histories; trackBy: trackByHistory">

        <ng-container *ngIf="history.historyType == 'Document'">
          <app-document-card *ngIf="history.value['fileExtension']" [document]="$any(history.value)" class="d-flex"/>
          <app-document-request-card *ngIf="history.value['requestDate'] && !history.value['fileExtension']"
                                     [document]="$any(history.value)" class="d-flex"/>
        </ng-container>

        <ng-container *ngIf="history.historyType == 'Comment'">
          <app-comment [comment]="$any(history.value)" class="d-flex"/>
        </ng-container>

      </li>
    </ul>
  </ng-container>
</div>
