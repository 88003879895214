import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { ProductLineDetailsInsuranceDocumentComponent } from '../product-line-details-tab/product-line-details-insurance-documents/product-line-details-insurance-documents.component';
import { ProductLineDetailsProductComponent } from '../product-line-details-tab/product-line-details-products/product-line-details-products.component';
import { ProductLineDetailsNotesComponent } from '../product-line-details-tab/product-line-details-notes/product-line-details-notes.component';
@Component({
  selector: 'app-product-line-tabs-details',
  templateUrl: './product-line-tabs-details.component.html',
  styleUrls: ['./product-line-tabs-details.component.scss'],
})
export class ProductLineTabsDetailsComponent implements OnInit, OnChanges {
  active = 1;

  tabsContent$: Observable<TabsContentContact[]> = of([]);
  tabsInit: TabsContentContact[] = [
    {
      title: 'PRODUCTLINE_TABS_PRODUCTS',
      index: 1,
      name: 'products',
      component: ProductLineDetailsProductComponent,
    },
    {
      title: 'PRODUCTLINE_TABS_INSURANCEDOCUMENTS',
      index: 2,
      name: 'insuranceDocuments',
      component: ProductLineDetailsInsuranceDocumentComponent,
    },
    {
      title: 'PRODUCTLINE_TABS_NOTES',
      index: 3,
      name: 'notes',
      component: ProductLineDetailsNotesComponent,
    },
  ];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.tabsContent$ = of(this.tabsInit);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }
}
