import { Pipe, PipeTransform } from '@angular/core';
import { AccountMoveModel } from 'src/app/shared/store/accounting/types';

@Pipe({
  name: 'accountmovesamount',
})
export class AccountMovesAmountPipe implements PipeTransform {
  transform(values?: AccountMoveModel[]): number {
    let result = 0;

    values?.forEach((accountMoves) => {
      accountMoves.accountMoveLines.forEach((accountMoveLines) => {
        result += accountMoveLines.unitPrice * accountMoveLines.quantity;
      });
    });

    return result;
  }
}
