import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { DocumentApiService } from "./document.api.service";
import { DocumentEffects } from "./document.effects";
import { IDocumentState, documentInitialState, documentReducer } from "./document.reducer";
import { DocumentService } from "./document.service";
import { documentFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IDocumentState>(documentFeatureKey, documentReducer,{
            initialState: documentInitialState,
        }),
        EffectsModule.forFeature([DocumentEffects])
    ],
    providers:[DocumentApiService, DocumentService]
})
export class StoreDocumentModule{}
