<div [formGroup]="addressForm">
  <div class="form-group row">
    <label class="col-4 col-form-label-sm" translate
    >{{this.title}}</label
    >
    <div class="col">
      <input
        class="form-control form-control-sm"
        type="text"
        formControlName="address"
        [placeholder]="'ADDRESS_PLACEHOLDER_ADDRESS' | translate"
        checkFormFieldValidity
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-4 col-form-label-sm" translate
    ></label
    >
    <div class="col">
      <input
        class="form-control form-control-sm"
        type="text"
        formControlName="address2"
        [placeholder]="'ADDRESS_PLACEHOLDER_ADDRESS2' | translate"
        checkFormFieldValidity
      />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-4 col-form-label-sm" translate></label>
    <div class="col">
      <div class="row">
        <div class="col-3">
          <input
            class="form-control form-control-sm"
            type="text"
            formControlName="zipCode"
            [placeholder]="'ADDRESS_PLACEHOLDER_ZIPCODE' | translate"
            checkFormFieldValidity
            mask="00000"
            maxlength="5"
          />
        </div>
        <div class="col">
          <app-typeahead
            [entity]="Entity.ZipCode"
            [referenceEndpoint]="'ZipCodes/Cities/'"
            [dropdownStyle]="DropdownStyle.DropDown"
            [controlForm]="this.addressForm.controls['city']"
            [limitToList]="true"
            [referenceFilterParentName]="'Code'"
            [referenceFilterParentValue]="
                                this.addressForm.controls['zipCode']?.value
                              "
            [valueMember]="'name'"
            [displayMember]="'name'"
            [dbNameOfId]="'City'"
            [dbNameOfName]="'City'"
            [referenceField]="'City'"
            [showFirstElement]="true"
            [placeholder]="'ADDRESS_PLACEHOLDER_CITY' | translate"
          ></app-typeahead>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-4 col-form-label-sm" translate
    ></label
    >
    <div class="col">
      <app-typeahead
        [entity]="Entity.Country"
        [routeEndpoint]="'Countries'"
        [dropdownStyle]="DropdownStyle.DropDownList"
        [controlForm]="this.addressForm.controls['countryId']"
        [limitToList]="true"
        [orderBy]="'IsPrimary desc, Name asc'"
        [placeholder]="'ADDRESS_PLACEHOLDER_COUNTRY' | translate"
      ></app-typeahead>
    </div>
  </div>
</div>
