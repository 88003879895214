import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { EmployeeModel } from './types';
import { GenericState, IState } from '../../../common/store/types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { employeeActions } from './employee.actions';

export interface IEmployeeState extends IState<EmployeeModel> {
  getById: GenericState<EmployeeModel>;
  getEmployeeModel: GenericState<EmployeeModel>;
  postEmployee: GenericState<EmployeeModel>;
  updateEmployee: GenericState<EmployeeModel>;
}

export const employeeInitialState: IEmployeeState = {
  getById: new GenericState<EmployeeModel>(),
  getEmployeeModel: new GenericState<EmployeeModel>(),
  postEmployee: new GenericState<EmployeeModel>(),
  updateEmployee: new GenericState<EmployeeModel>(),
};

const employeeReducers = [
  ...onApiCall<EmployeeModel>(employeeActions.getById, 'getById'),
  ...onApiCall<EmployeeModel>(
    employeeActions.getEmployeeModel,
    'getEmployeeModel',
  ),
  ...onApiCall<EmployeeModel>(employeeActions.postEmployee, 'postEmployee'),
  ...onApiCall<EmployeeModel>(employeeActions.updateEmployee, 'updateEmployee'),
];

export const employeeReducer = createReducer(
  employeeInitialState,
  ...employeeReducers,
) as ActionReducer<IEmployeeState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return employeeReducer(state, action);
}
