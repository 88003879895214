<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <div class="card-body">
      <div class="container-fluid">
        <form
          [formGroup]="productForm"
          (ngSubmit)="onSubmit()"
          [hasUnsavedData]="productForm"
        >
          <button type="submit" hidden="hidden"></button>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-title-label" for="name" translate
                  >PRODUCTLINE_LBL_NAME</label
                >
                <input
                  id="name"
                  class="form-control form-title"
                  type="text"
                  formControlName="name"
                  checkFormFieldValidity
                  [maxLength]="80"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" translate
                  >PRODUCT_LBL_PRODUCTLINENAME</label
                >
                <div class="col">
                  <app-typeahead
                    [routeEndpoint]="'ProductLines'"
                    [limitToList]="true"
                    [controlForm]="this.productForm.get('productLineId')"
                    [orderBy]="'name asc'"
                    [dbNameOfName]="'name'"
                  ></app-typeahead>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" translate
                  >PRODUCT_LBL_STARTDATE</label
                >
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="startDate"
                    type="date"
                    formControlName="startDate"
                    checkFormFieldValidity
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" translate
                  >PRODUCT_LBL_ENDDATE</label
                >
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="endDate"
                    type="date"
                    formControlName="endDate"
                    checkFormFieldValidity
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" translate
                  >PRODUCT_LBL_TAXRATE</label
                >
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="taxRate"
                    type="numeric"
                    formControlName="taxRate"
                    checkFormFieldValidity
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
