<form [formGroup]="bankAccountForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="bankAccountForm">
  <button type="submit" hidden="hidden"></button>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="form-title-label" for="iban" translate
        >BANKACCOUNT_LBL_IBAN</label
        >
        <div class="form-title">
          <input
            id="iban"
            class="form-control form-title"
            type="text"
            [placeholder]="'BANKACCOUNT_PLACEHOLDER_IBAN' | translate"
            formControlName="iban"
            checkFormFieldValidity
            ibanValidators
            oninput="this.value = this.value.toUpperCase()"
            [patterns]="customPatterns"
            mask="SS00 CCCC CCCC CCCC CCCC CCCC CCCC CCCC CC" [validation]="false"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6 col-lg-6">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >BANKACCOUNT_LBL_BANK</label
        >
        <div class="col">
          <app-typeahead
            [routeEndpoint]="'Banks'"
            [entity]="Entity.Bank"
            [controlForm]="this.bankAccountForm.controls['bankId']"
            [orderBy]="'name asc'"
            [limitToList]="false"
            [placeholder]="'BANKACCOUNT_PLACEHOLDER_BANK' | translate"
          ></app-typeahead>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="bic" translate
        >BANKACCOUNT_LBL_BIC</label
        >
        <div class="col">
          <input
            id="bic"
            class="form-control form-control-sm"
            type="text"
            [placeholder]="'BANKACCOUNT_PLACEHOLDER_BIC' | translate"
            formControlName="bic"
            oninput="this.value = this.value.toUpperCase()"
            checkFormFieldValidity
            [minlength]="8"
            [maxLength]="11"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="holderName" translate
        >BANKACCOUNT_LBL_HOLDER</label
        >
        <div class="col">
          <app-typeahead
            [routeEndpoint]="'Contacts'"
            [entity]="Entity.Contact"
            [controlForm]="this.bankAccountForm.controls['contactId']"
            [orderBy]="'name asc'"
            [allowCustomActions]="true"
            [limitToList]="true"
            [placeholder]="'BANKACCOUNT_PLACEHOLDER_CONTACT'|translate"
          ></app-typeahead>
        </div>
      </div>

<!--      <div class="form-group row" [style.display]="'none'">-->
<!--        <label class="col-4 col-form-label-sm" for="holderName" translate-->
<!--        ></label-->
<!--        >-->
<!--        <div class="col">-->
<!--          <input-->
<!--            id="holderName"-->
<!--            class="form-control form-control-sm"-->
<!--            type="text"-->
<!--            formControlName="holderName"-->
<!--            [placeholder]="'BANKACCOUNT_PLACEHOLDER_HOLDERNAME' | translate "-->
<!--            checkFormFieldValidity-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="col">
      <app-address-edit
        [form]="this.bankAccountForm.controls['agencyAddress']"
        [title]="'BANKACCOUNT_LBL_AGENCY_ADDRESS' | translate"
      />
    </div>
  </div>
</form>
