import { ProductLineListItemModel } from '../product-line/types';
import { ContactModel } from '../contact/types';

export const insuranceCompanyFeatureKey = 'insuranceCompany';

export class InsuranceCompanyModel {
  id: number;
  contactId: number;
  contact: ContactModel;
  productLines: ProductLineListItemModel[];
}
