import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { ICurrencyState } from './currency.reducer';
import { CurrencyModel, currencyFeatureKey } from './types';

const currencyFeatureState =
  createFeatureSelector<ICurrencyState>(currencyFeatureKey);

export const currencySelectors = {
  getById: createApiCallSelectors<number, ICurrencyState>(
    currencyFeatureState,
    'getById',
  ),
  postCurrency: createApiCallSelectors<CurrencyModel, ICurrencyState>(
    currencyFeatureState,
    'postCurrency',
  ),
  updateCurrency: createApiCallSelectors<CurrencyModel, ICurrencyState>(
    currencyFeatureState,
    'updateCurrency',
  ),
};
