import { Component, OnDestroy, OnInit } from '@angular/core';
import { Entity, View } from '../../../../shared/store/view/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { ActivityModel } from '../../../../shared/store/activity/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../shared/store/form/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { ActivityService } from '../../../../shared/store/activity/activity.service';
import { take } from 'rxjs/operators';
import { format } from 'path';
import { formatDate } from '../../../../shared/helper/datehelper';

@UntilDestroy()
@Component({
  selector: 'app-activity-form-content',
  templateUrl: './activity-form-content.component.html',
  styleUrls: ['./activity-form-content.component.scss'],
})
export class ActivityFormContentComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  activityModel$: Observable<ActivityModel>;
  createActivityInstance$: Observable<ActivityModel>;

  activityForm: FormGroup;

  isEditMode: boolean = false;
  activityId: string;

  protected readonly Entity = Entity;

  constructor(
    private readonly activityService: ActivityService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.activityModel$ = this.activityService.getById.value$;
    this.createActivityInstance$ = this.activityService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.activityForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      summary: [''],
      activityTypeId: [undefined, [Validators.required]],
      deadline: [new Date(), [Validators.required]],
      assignedTo: [undefined, [Validators.required]],
      contactId: [undefined],
      notes: [''],
      entityType: ['', [Validators.maxLength(50)]],
      entityId: [0, [Validators.required]],
      entityName: [''],
      isAutomated: [false],
      creationDate: [],
      status: [1],
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.activityId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.activityId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.activityService.getById.call(this.activityId);
    } else {
      this.activityService.createInstance.call();
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'activityCreate' || x == 'activityUpdate') this.onSubmit();
        if (x == 'activityList') {
          this.formService.clear();
          this.router.navigate(['Crm/Activities/']);
        }
        if (x == 'activityDetails') {
          this.formService.clear();
          this.router.navigate(['/Crm/Activities/Details', this.activityId]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set('@activityCreate', x.breadcrumbCreate);
      });

    if (this.isEditMode) {
      this.activityModel$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.breadcrumbService.set('@activityEdit', x.name);
          this.activityForm.patchValue({
            name: x.name,
            summary: x.summary,
            activityTypeId: x.activityTypeId,
            deadline: formatDate(x.deadline, 'ToInverseSqlDate'),
            assignedTo: x.assignedTo,
            contactId: x.contactId,
            notes: x.notes,
            entityType: x.entityType,
            entityId: x.entityId,
            entityName: x.entityName,
            isAutomated: x.isAutomated,
            creationDate: x.creationDate,
            status: x.status,
          });
        });
    }

    if (!this.isEditMode) {
      this.createActivityInstance$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.activityForm?.patchValue({
            deadline: formatDate(x.deadline, 'ToInverseSqlDate'),
            status: x.status,
          });
        });
    }
  }

  onSubmit() {
    if (this.activityForm.invalid) {
      this.activityForm.markAllAsTouched();
      this.formService.countErrors(this.activityForm, true);
      return;
    }

    this.formService.clear();

    const activityModel: ActivityModel = {
      id: this.activityId ? +this.activityId : 0,
      name: this.activityForm.value.name!,
      summary: this.activityForm.value.summary!,
      deadline: this.activityForm.value.deadline!,
      assignedTo: this.activityForm.value.assignedTo!,
      notes: this.activityForm.value.notes!,
      entityType: this.activityForm.value.entityType!,
      entityId: this.activityForm.value.entityId!,
      entityName: this.activityForm.value.entityName!,
      isAutomated: this.activityForm.value.isAutomated!,
      activityTypeId: this.activityForm.value.activityTypeId!,
      contactId: this.activityForm.value.contactId!,
      creationDate: this.activityForm.value.creationDate!,
      status: this.activityForm.value.status!,

      //Others
      activityTypeName: '',
      assignedToName: '',
      contactName: '',
    };

    let result$: Observable<ActivityModel>;
    if (this.isEditMode)
      result$ = this.activityService.update.call(activityModel);
    else result$ = this.activityService.add.call(activityModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Crm/Activities/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.activityService.add.reset();
    this.activityService.update.reset();

    // if (this.isEditMode) {
    //   this.activityService.getById.reset();
    // } else {
    //   this.activityService.createInstance.reset();
    // }

    this.breadcrumbService.set('@activityEdit', ' ');
    this.breadcrumbService.set('@activityCreate', ' ');
  }
}
