<div class="card data-card w-100" [class.expired]="this.isExpired">
  <div class="card-body">
    <div class="card-content">
      <h3 class="card-title" *ngIf="document.name">{{ document.name }}</h3>
      <span class="card-subtitle text-muted mb-1" *ngIf="document.dueDate">
             {{ 'DOCUMENT_LBL_DUE' | translate:
        {dueDate:document.dueDate | date: 'EEE dd LLLL':'+0200':'fr-FR' } | firstLetterToUppercase  }}
      </span>
    </div>
    <div class="card-right ps-2">
      <div>
        <button
          class="btn btn-outline-primary"
          type="button"
          [disabled]="this.uploadCompleted$ | async"
          [title]="'DOCUMENT_PLACEHOLDER_DOCUMENT_ADD' | translate"
          (click)="newDocument.fileupload.nativeElement.click()">
          <i class="mdi mdi-cloud-upload" *ngIf="!(this.uploadCompleted$ | async)"></i>
          <i class="mdi mdi-cloud-check" *ngIf="this.uploadCompleted$ | async"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<app-document-add #newDocument [documentId]="document.id" />
