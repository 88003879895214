import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Avatar } from '../../store/user/type';

@UntilDestroy()
@Component({
  selector: 'app-kanban-view',
  templateUrl: './kanban-view.component.html',
  styleUrls: ['./kanban-view.component.scss'],
})
export class KanbanViewComponent implements OnInit {
  currentAvatar$: Observable<Avatar>;

  @Input() data: any[] | undefined;
  @Input() entity: string | undefined;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {}

  onCardClick(id: string): void {
    this.router.navigate([
      this.router.url.substring(0, this.router.url.lastIndexOf('/')),
      'Details',
      id,
    ]);
  }
}
