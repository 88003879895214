<form
  [formGroup]="slipForm"
  (ngSubmit)="onSubmit()"
  [hasUnsavedData]="slipForm"
>
  <button type="submit" hidden="hidden"></button>

  <div class="row">
    <div class="col-12 col-md">

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >PAYMENT_LBL_FROM</label
        >
        <div class="col">
          <app-typeahead
            [routeEndpoint]="'Accounts'"
            [controlForm]="this.slipForm.get('fromAccountId')"
            [domain]="'accountType=2,active=1'"
            [orderBy]="'name asc'"
            [limitToList]="true"
            [readonly]="this.readonly"
          ></app-typeahead>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >PAYMENT_LBL_TO</label
        >
        <div class="col">
          <app-typeahead
            [routeEndpoint]="'Accounts'"
            [controlForm]="this.slipForm.get('toAccountId')"
            [domain]="'contactId=' + this.contactId"
            [orderBy]="'name asc'"
            [limitToList]="true"
            [readonly]="true"
          ></app-typeahead>

        </div>
      </div>

    </div>
    <div class="col">

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >SLIP_LBL_REFERENCE</label
        >
        <div class="col">
          <input
            class="form-control form-control-sm"
            type="text"
            [placeholder]="''"
            formControlName="reference"
            checkFormFieldValidity
            [readonly]="this.readonly"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
        >SLIP_LBL_SLIP
          <br/>
          <a href="{{this.csvTemplateUrl}}"
             target="_blank"
             download
             translate>SLIP_LBL_DOWNLOAD_CSV_EXAMPLE</a>
        </label
        >
        <div class="col">
          <app-generic-file-input (fileEmitter)="onFileEmitterPaymentSlip($event)" [acceptFiles]="'.csv'"/>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="this.slipResponses$ | async as slipResponses">
    <div class="col">

      <table class="table table-striped border-bottom">
        <thead>
        <tr>
          <th></th>
          <th>
            <a translate>ContractName</a>
          </th>
          <th>
            <a translate>AccountMoveName</a>
          </th>
          <th>
            <a translate>AccountMoveType</a>
          </th>
          <th>
            <a translate>Amount</a>
          </th>
          <th>
            <a translate>StartDate</a>
          </th>
          <th>
            <a translate>EndDate</a>
          </th>
          <th>
            <a translate>Message d'erreur</a>
          </th>
        </tr>
        </thead>

        <tbody>
        <ng-container
          *ngFor="let slipResponse of slipResponses"
          class="row-action"
        >
          <tr>
            <td *ngIf="slipResponse.status ==='Error'">
              <div class="i-circle text-danger"><i class="mdi mdi-alert-circle-outline"></i></div>
            </td>
            <td *ngIf="slipResponse.status ==='Ok'">
            </td>
            <td>{{ slipResponse.slipRecordModel.contractName }}</td>
            <td>{{ slipResponse.slipRecordModel.accountMoveName }}</td>
            <td>{{ slipResponse.slipRecordModel.accountMoveType }}</td>
            <td>{{ slipResponse.slipRecordModel.amount }}</td>
            <td>{{ slipResponse.slipRecordModel.startDate | date: 'dd-MM-YYYY' }}</td>
            <td>{{ slipResponse.slipRecordModel.endDate | date: 'dd-MM-YYYY' }}</td>
            <td [style.background-color]="{'green': slipResponse.status === 'Ok', 'red': slipResponse.status === 'Error'}">{{ slipResponse.message }}</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>


</form>
