<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div
        class="page-container"
        *ngIf="this.bankAccount$ | async as bankAccount"
      >
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-title-label" translate
                      >BANKACCOUNT_LBL_IBAN</label
                    >
                    <div class="form-title">{{ bankAccount.iban }}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-lg-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >BANKACCOUNT_LBL_BANK</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ bankAccount.bankName }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >BANKACCOUNT_LBL_BIC</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ bankAccount.bic }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >BANKACCOUNT_LBL_HOLDER</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ bankAccount.holderName }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >BANKACCOUNT_LBL_AGENCY_ADDRESS</label
                    >
                    <div class="col">
                      <div
                        class="form-control-plaintext form-control-sm mb-2 h-100 p-0"
                      >
                        <app-address
                          [address]="bankAccount.address"
                          [zipCode]="bankAccount.zipCode"
                          [city]="bankAccount.city"
                          [country]="bankAccount.countryName"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-comment" *ngIf="this.bankAccount$ | async as bankAccount">
      <app-comment-panel
        [entityType]="'BankAccount'"
        [entityId]="this.bankAccountId.toString()"
      ></app-comment-panel>
    </div>
  </div>
</div>
