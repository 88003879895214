<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <form [formGroup]="glossaryForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="glossaryForm">
      <button type="submit" hidden="hidden"></button>
      <div class="card-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-10">
              <div class="form-group">
                <input
                  class="form-control form-title pt-0 required"
                  [placeholder]="'GLOSSARY_PLACEHOLDER_NAME' | translate"
                  id="name"
                  name="name"
                  type="name"
                  value=""
                  formControlName="name"
                  checkFormFieldValidity
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-lg-6">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="url" translate
                >GLOSSARY_LBL_URL</label
                >
                <div class="col">
                  <input
                    [placeholder]="'GLOSSARY_PLACEHOLDER_URL' | translate"
                    class="form-control form-control-sm"
                    id="url"
                    name="url"
                    type="text"
                    value=""
                    formControlName="url"
                    checkFormFieldValidity
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="form-group">
              <label class="form-title-label" translate
              >GLOSSARY_LBL_PAGECONTENT</label
              >
              <div class="col-8 px-0">
                <div class="NgxEditor__Wrapper">
                  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                  </ngx-editor-menu>
                  <ngx-editor
                    [editor]="editor"
                    formControlName="pageContent"
                    [disabled]="false"
                    [placeholder]="'GLOSSARY_PLACEHOLDER_CONTENT' | translate"
                  ></ngx-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
