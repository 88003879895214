import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ErrorsNestedFormGroup } from '../../../../../shared/store/form/types';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { FormService } from '../../../../../shared/store/form/form.service';

@Component({
  selector: 'app-contact-tabs-add-form',
  templateUrl: './contact-tabs-add-form.component.html',
  styleUrls: ['./contact-tabs-add-form.component.scss'],
})
export class ContactTabsAddFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  errorsDescription$: Observable<ErrorsNestedFormGroup>;

  active = 1;

  tabsContent$: Observable<TabsContentContact[]> = of([]);

  tabsInit: TabsContentContact[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.tabsContent$ = of(this.tabsInit);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }
}
