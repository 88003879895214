import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, map } from 'rxjs';
import { GenericApiCall } from 'src/app/common/store/types';
import { debugLog } from '../../pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() apiCall: GenericApiCall<any, any>;
  @Input() loaderId: string;
  @Input() loader: boolean = false;

  showContent$: Observable<boolean>;
  showEmptyContent$: Observable<boolean>;
  showError$: Observable<HttpErrorResponse | null>;

  @Input() emptyMessage: string = 'LOADER_COMPONENT_EMPTY_MESSAGE_DEFAULT';
  @Input() errorMessage: string = 'LOADER_COMPONENT_ERROR_MESSAGE_DEFAULT';

  ngOnInit(): void {
    this.showContent$ = combineLatest([
      this.apiCall.isLoaded$,
      this.apiCall.value$,
    ]).pipe(
      map(
        ([isLoaded, data]) =>
          isLoaded &&
          data &&
          ((Array.isArray(data) && data.length > 0) || !Array.isArray(data)),
      ),
      untilDestroyed(this),
    );

    this.showEmptyContent$ = combineLatest([
      this.apiCall.isLoaded$,
      this.apiCall.value$,
    ]).pipe(
      map(
        ([isLoaded, data]) =>
          isLoaded &&
          (data == null || (Array.isArray(data) && data.length == 0)),
      ),
      untilDestroyed(this),
    );
    this.showError$ = this.apiCall.error$;
  }

  ngOnDestroy(): void {}
}
