<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="houseFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="form-group-title my-1" translate>CONTRACT_FIELDSET_HOUSE</div>
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_HOUSING_TYPE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/House/ReferenceData'"
              [referenceField]="'HousingType'"
              [controlForm]="
                this.form.controls['houseFormGroup'].get('houseHousingType')
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_NUMBER_ROOMS</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="houseNbOfRooms"
              name="houseNbOfRooms"
              type="number"
              min="1"
              max="12"
              formControlName="houseNbOfRooms"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_AMOUNT_INSURE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/House/ReferenceData'"
              [referenceField]="'AmountToInsure'"
              [controlForm]="
                this.form.controls['houseFormGroup'].get('houseAmountToInsure')
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_ADDRESS</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="houseAddress"
              name="houseAddress"
              type="text"
              formControlName="houseAddress"
              checkFormFieldValidity
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm"> </label>
          <div class="col-2">
            <input
              class="form-control form-control-sm"
              id="houseZipCode"
              name="houseZipCode"
              type="text"
              [placeholder]="'CONTRACT_PLACEHOLDER_ZIPCODE' | translate"
              formControlName="houseZipCode"
              checkFormFieldValidity
              mask="00000"
              maxlength="5"
            />
          </div>
          <div class="col">
            <app-typeahead
              [entity]="Entity.ZipCode"
              [referenceEndpoint]="'ZipCodes/Cities/'"
              [dropdownStyle]="DropdownStyle.DropDown"
              [controlForm]="
                this.form.controls['houseFormGroup'].get('houseCity')
              "
              [limitToList]="true"
              [referenceFilterParentName]="'Code'"
              [referenceFilterParentValue]="
                this.form.controls['houseFormGroup'].get('houseZipCode')?.value
              "
              [valueMember]="'name'"
              [displayMember]="'name'"
              [dbNameOfId]="'City'"
              [dbNameOfName]="'City'"
              [referenceField]="'City'"
              [showFirstElement]="true"
            ></app-typeahead>
          </div>
        </div>

        <div class="form-group row" *ngIf="true">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_FLAT_LEVEL</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/House/ReferenceData'"
              [referenceField]="'FlatLevel'"
              [controlForm]="
                this.form.controls['houseFormGroup'].get('houseFlatLevel')
              "
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_RESIDENCY_TYPE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/House/ReferenceData'"
              [referenceField]="'ResidencyType'"
              [controlForm]="
                this.form.controls['houseFormGroup'].get('houseResidencyType')
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_AREA</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="houseArea"
              name="houseArea"
              type="number"
              min="1"
              max="350"
              formControlName="houseArea"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_CONSTRUCTION_TYPE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/House/ReferenceData'"
              [referenceField]="'ConstructionType'"
              [controlForm]="
                this.form.controls['houseFormGroup'].get(
                  'houseConstructionType'
                )
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_ROOF_TYPE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/House/ReferenceData'"
              [referenceField]="'RoofType'"
              [controlForm]="
                this.form.controls['houseFormGroup'].get('houseRoofType')
              "
            ></app-typeahead>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="form-group-title my-1" translate>
        CONTRACT_FIELDSET_OCCUPANT
      </div>
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_OCCUPANCY_ROLE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/House/ReferenceData'"
              [referenceField]="'OccupancyRole'"
              [controlForm]="
                this.form.controls['houseFormGroup'].get('houseOccupancyRole')
              "
            ></app-typeahead>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_CURRENT_INSURANCE_COMPANY</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/House/ReferenceData'"
              [referenceField]="'CurrentInsuranceCompany'"
              [controlForm]="
                this.form.controls['houseFormGroup'].get(
                  'houseCurrentInsuranceCompany'
                )
              "
            ></app-typeahead>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="form-group-title my-1" translate>
        CONTRACT_FIELDSET_HOUSE_NEED
      </div>
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_FIRE_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="houseFireCoverage"
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_WATER_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="houseWaterCoverage"
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_THEFT_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="houseTheftCoverage"
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_GLASS_BREAK_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="houseGlassBreakCoverage"
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_PUBLIC_LIABILITY_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="housePublicLiabilityCoverage"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_FEE_CANCELLATION_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="houseFeeCancellationCoverage"
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_POOL_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="housePoolCoverage"
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_DOG_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="houseDogCoverage"
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_HOUSE_ELECTRICAL_DAMAGE_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="houseElectricalDamageCoverage"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
