import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from 'src/app/shared/store/product/product.service';
import { ProductModel } from 'src/app/shared/store/product/types';

@Component({
  selector: 'app-product-details-coverage',
  templateUrl: './product-details-coverage.component.html',
  styleUrls: ['./product-details-coverage.component.scss'],
})
export class ProductDetailsCoverageComponent {
  product$: Observable<ProductModel>;

  constructor(private readonly productService: ProductService) {
    this.product$ = this.productService.getById.value$;
  }
}
