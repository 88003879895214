<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.houseDetail$ | async as houseDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="form-group-title my-1" translate>LEAD_FIELDSET_HOUSE</div>
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_HOUSING_TYPE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.housingType }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_NUMBER_ROOMS</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.nbOfRooms }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_AMOUNT_INSURE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.amountToInsure }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_ADDRESS</label
          >
          <div class="col-auto">
            <div class="form-control-plaintext form-control-sm mb-2 h-100 p-0">
              <app-address
                [address]="houseDetail.address"
                [zipCode]="houseDetail.zipCode"
                [city]="houseDetail.city"
              />
            </div>
          </div>
        </div>
        <div class="form-group row" *ngIf="houseDetail.flatLevel">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_FLAT_LEVEL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.flatLevel }}
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_RESIDENCY_TYPE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.residencyType }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_AREA</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.area }}
            </span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_CONSTRUCTION_TYPE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.constructionType }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_ROOF_TYPE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.roofType }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="form-group-title my-1" translate>LEAD_FIELDSET_OCCUPANT</div>
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_OCCUPANCY_ROLE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.occupancyRole }}
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_CURRENT_INSURANCE_COMPANY</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ houseDetail.currentInsuranceCompany }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="form-group-title my-1" translate>
        LEAD_FIELDSET_HOUSE_NEED
      </div>
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_FIRE_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.fireCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_WATER_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.waterCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_THEFT_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.theftCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_GLASS_BREAK_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.glassBreakCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_PUBLIC_LIABILITY_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.publicLiabilityCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_FEE_CANCELLATION_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.feeCancellationCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_POOL_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.poolCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_DOG_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.dogCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HOUSE_ELECTRICAL_DAMAGE_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="houseDetail.electricalDamageCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
