import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { invoiceActions } from './invoice.actions';
import { invoiceSelectors } from './invoice.selector';
import { InvoiceModel } from './types';

@Injectable()
export class InvoiceService
  extends BaseServiceFacade
{
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<InvoiceModel, string> = this.genericApiCall(invoiceActions.getById, invoiceSelectors.getById,
true
);
}