import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { CampaignApiService } from './campaign.api.service';
import { CampaignAction, campaignActions } from './campaign.action';

@Injectable()
export class CampaignEffects {
  constructor(
    private readonly campaignApiService: CampaignApiService,
    private readonly action$: Actions<CampaignAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    campaignActions.getById,
    this.campaignApiService.getById,
  );

  getList = createApiCallEffects(
    this,
    this.action$,
    campaignActions.getList,
    this.campaignApiService.getList,
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    campaignActions.createInstance,
    this.campaignApiService.createInstance,
  );

  postCampaign = createApiCallEffects(
    this,
    this.action$,
    campaignActions.postCampaign,
    this.campaignApiService.postCampaign,
  );

  updateCampaign = createApiCallEffects(
    this,
    this.action$,
    campaignActions.updateCampaign,
    this.campaignApiService.updateCampaign,
  );
}
