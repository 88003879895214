import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { InvoiceApiService } from "./invoice.api.service";
import { InvoiceEffects } from "./invoice.effects";
import { IInvoiceState, invoiceInitialState, invoiceReducer } from "./invoice.reducer";
import { InvoiceService } from "./invoice.service";
import { invoiceFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IInvoiceState>(invoiceFeatureKey, invoiceReducer,{
            initialState: invoiceInitialState,
        }),
        EffectsModule.forFeature([InvoiceEffects])
    ],
    providers:[InvoiceApiService, InvoiceService]
})
export class StoreInvoiceModule{}
