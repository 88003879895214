import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ComparatorRoutingModule } from './comparator-routing.module';
import { ProductComparatorComponent } from './product-comparator.component/product-comparator.component';

@NgModule({
  declarations: [ProductComparatorComponent],
  imports: [CommonModule, SharedModule, ComparatorRoutingModule],
})
export class ComparatorModule {}
