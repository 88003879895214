import { CampaignModel } from './types';
import { GenericState, IState } from '../../../common/store/types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { campaignActions } from './campaign.action';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { ListItem } from '../typeahead/types';

export interface ICampaignState extends IState<CampaignModel> {
  getById: GenericState<CampaignModel>;
  getList: GenericState<ListItem[]>;
  createInstance: GenericState<CampaignModel>;
  postCampaign: GenericState<CampaignModel>;
  updateCampaign: GenericState<CampaignModel>;
}

export const campaignInitialState: ICampaignState = {
  getById: new GenericState<CampaignModel>(),
  getList: new GenericState<ListItem[]>(),
  createInstance: new GenericState<CampaignModel>(),
  postCampaign: new GenericState<CampaignModel>(),
  updateCampaign: new GenericState<CampaignModel>(),
};

const campaignReducers = [
  ...onApiCall<CampaignModel>(campaignActions.getById, 'getById'),
  ...onApiCall<ListItem[]>(campaignActions.getList, 'getList'),
  ...onApiCall<CampaignModel>(campaignActions.createInstance, 'createInstance'),
  ...onApiCall<CampaignModel>(campaignActions.postCampaign, 'postCampaign'),
  ...onApiCall<CampaignModel>(campaignActions.updateCampaign, 'updateCampaign'),
];

export const campaignReducer = createReducer(
  campaignInitialState,
  ...campaignReducers,
) as ActionReducer<ICampaignState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return campaignReducer(state, action);
}
