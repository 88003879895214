import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { insuranceCoverageActions } from './insurance-coverage.actions';
import { insuranceCoverageSelectors } from './insurance-coverage.selector';
import { InsuranceCoverageModel } from './types';

@Injectable()
export class InsuranceCoverageService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<InsuranceCoverageModel, string> = this.genericApiCall(
    insuranceCoverageActions.getById,
    insuranceCoverageSelectors.getById,
    true
  );
  getByProductCategory: GenericApiCall<InsuranceCoverageModel[], number> =
    this.genericApiCall(
      insuranceCoverageActions.getByProductCategory,
      insuranceCoverageSelectors.getByProductCategory,
      true
    );
}
