<div
  class="tab-pane fade flex-even active show"
  id="productLines"
  role="tabpanel"
  aria-labelledby="produc-lines-tab"
  *ngIf="this.insuranceCompany$ | async as insuranceCompany"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <table class="table table-striped border-bottom">
        <thead>
          <tr>
            <th>
              <a translate>INSURANCECOMPANY_LBL_PRODUCT_LINES_NAME</a>
            </th>
            <th>
              <a translate>INSURANCECOMPANY_LBL_PRODUCT_LINES_CATEGORY</a>
            </th>
            <th>
              <a translate>INSURANCECOMPANY_LBL_PRODUCT_LINES_PRODUCT_COUNT</a>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let productLine of insuranceCompany.productLines"
            class="row-action"
          >
            <td>{{ productLine.name }}</td>
            <td>{{ productLine.productCategoryName }}</td>
            <td>{{ productLine.productsCount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
