import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ProductModel, productFeatureKey } from './types';
import { IProductState } from './product.reducer';

const productFeatureState =
  createFeatureSelector<IProductState>(productFeatureKey);

export const productSelectors = {
  getById: createApiCallSelectors<ProductModel, IProductState>(
    productFeatureState,
    'getById'
  ),
  add: createApiCallSelectors<ProductModel, IProductState>(
    productFeatureState,
    'add'
  ),
  update: createApiCallSelectors<ProductModel, IProductState>(
    productFeatureState,
    'update'
  ),
  createInstance: createApiCallSelectors<ProductModel, IProductState>(
    productFeatureState,
    'createInstance'
  ),

  getComparatorProducts: createSelector(
    productFeatureState,
    (state: IProductState) => state.comparatorProducts
  ),
};
