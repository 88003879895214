import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { View } from '../../../store/view/types';
import { ViewService } from '../../../store/view/views.service';
import { filter, take } from 'rxjs/operators';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-kanban-view-empty',
  templateUrl: './kanban-view-empty.component.html',
  styleUrls: ['./kanban-view-empty.component.scss'],
})
export class KanbanViewEmptyComponent implements OnInit {
  view$: Observable<View>;

  constructor(
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.view$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@salesTeamMembersList', x.title);
        this.breadcrumbService.set('@insuranceClaimList', x.title);
        this.breadcrumbService.set('@insuranceReportingList', x.title);
        this.breadcrumbService.set('@commissionList', x.title);
        this.breadcrumbService.set('@paymentModeList', x.title);
        this.breadcrumbService.set('@list', x.title);
      });
  }
}
