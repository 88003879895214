import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  constructor(
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  getConfig(): GridOptions<any> {
    let gridOptions: GridOptions = {};

    gridOptions.rowSelection = 'single';
    gridOptions.suppressCellFocus = true;
    gridOptions.headerHeight = 37.2;
    gridOptions.animateRows = true;
    gridOptions.suppressMiddleClickScrolls = true;

    return gridOptions;
  }
}
