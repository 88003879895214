import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { InputCodeAndCityZipCode, ZipCodeModel } from './types';
import { zipCodeActions } from './zipcode.actions';
import { zipCodeSelectors } from './zipcode.selectors';

@Injectable()
export class ZipCodeService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  get: GenericApiCall<ZipCodeModel[], void> = this.genericApiCall(
    zipCodeActions.get,
    zipCodeSelectors.get,
  );

  getById: GenericApiCall<ZipCodeModel, string> = this.genericApiCall(
    zipCodeActions.getById,
    zipCodeSelectors.getById,
    true,
  );

  getByCodeAndCity: GenericApiCall<ZipCodeModel, InputCodeAndCityZipCode> =
    this.genericApiCall(
      zipCodeActions.getByCodeAndCity,
      zipCodeSelectors.getByCodeAndCity,
      true,
    );
}
