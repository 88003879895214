import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from '../../error';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { History, InputHistory } from './types';

@Injectable({
  providedIn: 'root',
})
export class HistoryApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
  ) {}

  getByEntityId = (inputHistory: InputHistory): Observable<History[]> => {
    let parameters: string[];
    parameters = [inputHistory.entityType, inputHistory.entityId.toString()];

    // let params = new HttpParams().append('orderBy', 'CreationTime desc');

    let url = this.formatUrl(parameters);
    return this.httpClient.get<History[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  formatUrl = (parameters: string[]): string => {
    let url = environment.comparanooapiurl;
    url += environment.features.history.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
