import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabsContent } from 'src/app/shared/models/tabs-content';
import { InsuranceCategory } from 'src/app/shared/store/lead/types';
import { ContractDetailsInsuredPersonsComponent } from '../contract-details-tab/contract-details-insured-persons/contract-details-insured-persons.component';
import { ContractDetailsDriverComponent } from 'src/app/features/crm/contract/contract-details/contract-details-tab/contract-details-driver/contract-details-driver.component';
import { ContractDetailsVehicleComponent } from 'src/app/features/crm/contract/contract-details/contract-details-tab/contract-details-vehicle/contract-details-vehicle.component';
import { ContractDetailsCarInsuranceComponent } from 'src/app/features/crm/contract/contract-details/contract-details-tab/contract-details-car-insurance/contract-details-car-insurance.component';
import { ContractDetailsHouseComponent } from 'src/app/features/crm/contract/contract-details/contract-details-tab/contract-details-house/contract-details-house.component';
import { ContractModel } from 'src/app/shared/store/contract/types';

@Component({
  selector: 'app-contract-tabs-details',
  templateUrl: './contract-tabs-details.component.html',
  styleUrls: ['./contract-tabs-details.component.scss'],
})
export class ContractTabsDetailsComponent implements OnChanges, OnDestroy {
  @Input() category: InsuranceCategory;
  @Input() contractBase: ContractModel;
  active = 1;
  currentActive: number = 1;

  tabsContent$: BehaviorSubject<TabsContent[]> = new BehaviorSubject<
    TabsContent[]
  >([]);

  tabsInit: TabsContent[] = [
    {
      title: 'CONTRACT_TABS_HEALTH',
      index: 2,
      name: 'health',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: ContractDetailsInsuredPersonsComponent,
      formGroupName: 'healthFormGroup',
    },
    {
      title: 'CONTRACT_TABS_DRIVER',
      index: 2,
      name: 'driver',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractDetailsDriverComponent,
    },
    {
      title: 'CONTRACT_TABS_VEHICLE',
      index: 3,
      name: 'vehicle',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractDetailsVehicleComponent,
    },
    {
      title: 'CONTRACT_TABS_INFORMATIONS',
      index: 4,
      name: 'car-insurance',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractDetailsCarInsuranceComponent,
    },
    {
      title: 'CONTRACT_TABS_HOUSE',
      index: 2,
      name: 'house',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: ContractDetailsHouseComponent,
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    } else {
      this.active = 1;
    }
    if (this.tabsInit && this.tabsInit.length > 0) {
      this.tabsContent$.next(
        this.tabsInit.filter((x) => x.category === this.category),
      );
    }
  }

  trackByTabsContent(index: any, item: TabsContent) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
