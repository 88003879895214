import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { viewActions } from './view.actions';
import { viewSelectors } from './view.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import {
  Entity,
  EnumModel,
  HeaderMenuType,
  Path,
  View,
  ViewType,
} from './types';
import { RouterService } from '../router/router.service';
import { PaginationService } from '../pagination/pagination.service';
import {
  combineLatest,
  debounceTime,
  filter,
  map,
  Observable,
  tap,
  withLatestFrom,
} from 'rxjs';
import { FormService } from '../form/form.service';
import { NotificationType } from '../notification/types';
import { NotificationService } from '../notification/notification.service';
import { SearchService } from '../search/search.service';

@Injectable()
export class ViewService extends BaseServiceFacade {
  views$: Observable<View>;
  entity$: Observable<Entity>;
  module$: Observable<HeaderMenuType>;
  isFormDirty$: Observable<boolean>;

  constructor(
    store: Store<AppState>,
    private readonly routerService: RouterService,
    private readonly paginationService: PaginationService,
    private readonly searchService: SearchService,
    private readonly formService: FormService,
    private readonly notificationService: NotificationService
  ) {
    super(store);
    this.entity$ = this.routerService.routeEntity$;
    this.module$ = this.routerService.routeHeadMenuData$;
    this.isFormDirty$ = this.formService.getIsDirty();

    combineLatest([this.module$, this.entity$, this.isFormDirty$])
      .pipe(
        filter(([module, entity, isDirty]) => !isDirty),
        tap((x) => this.getViews.reset()),
        filter(([module, entity, isDirty]) => !!module && !!entity),
        map(([module, entity]) => {
          return { module: module, entity: entity } as Path;
        }),
        debounceTime(200),
        withLatestFrom(this.entity$)
      )
      .subscribe(([params]) => {
        this.getViews.call(params);
        this.paginationService.setPageIndex(1);
        this.searchService.resetActiveFilter();
        this.searchService.setCustomFilters([]);
        this.notificationService.clearByType(NotificationType.ToastValidation);
      });
  }

  getViews: GenericApiCall<View, Path> = this.genericApiCall(
    viewActions.getViews,
    viewSelectors.getViews,
    true
  );
  getEnumValues: GenericApiCall<EnumModel[], string> = this.genericApiCall(
    viewActions.getEnumValues,
    viewSelectors.getEnumValues,
    true
  );

  setViewType = (viewType: ViewType) => {
    this.store.dispatch(viewActions.setViewType({ viewType: viewType }));
  };

  getViewType = () => this.store.select(viewSelectors.getViewType);
}
