import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { ProductLineService } from 'src/app/shared/store/product-line/product-line.service';
import { ProductLineModel } from 'src/app/shared/store/product-line/types';

@UntilDestroy()
@Component({
  selector: 'app-product-line-details',
  templateUrl: './product-line-details.component.html',
  styleUrls: ['./product-line-details.component.scss'],
})
export class ProductLineDetailsComponent implements OnInit, OnDestroy {
  productLineId: number;
  productLine$: Observable<ProductLineModel>;
  view$: Observable<View>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly productLineService: ProductLineService
  ) {
    this.productLine$ = this.productLineService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.productLineId = this.route.snapshot.params['id'];
    this.productLineService.getById.call(this.productLineId.toString());

    this.productLine$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@productLineDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'productLineEdit') {
          this.router.navigate([
            'Insurance/ProductLines/Edit/',
            this.productLineId,
          ]);
          this.subHeaderService.setTriggerAction('');
        }
      });
  }

  ngOnDestroy(): void {
    this.productLineService.getById.reset();
    this.breadcrumbService.set('@productLineDetails', ' ');
  }
}
