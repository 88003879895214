import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  ProductCategoryAction,
  productCategoryActions,
} from './product-category.actions';
import { ProductCategoryApiService } from './product-category.api.service';

@Injectable()
export class ProductCategoryEffects {
  constructor(
    private readonly productCategoryApiService: ProductCategoryApiService,
    private readonly action$: Actions<ProductCategoryAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    productCategoryActions.getById,
    this.productCategoryApiService.getById
  );
  createInstance = createApiCallEffects(
    this,
    this.action$,
    productCategoryActions.createInstance,
    this.productCategoryApiService.createInstance
  );
  add = createApiCallEffects(
    this,
    this.action$,
    productCategoryActions.add,
    this.productCategoryApiService.add
  );
  update = createApiCallEffects(
    this,
    this.action$,
    productCategoryActions.update,
    this.productCategoryApiService.update
  );
}
