import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from '../store/authentication/authentication.service';
import { LoginModel, UserConnected } from '../store/authentication/types';
import { ErrorService } from '../error';
import { UserService } from '../store/user/user.service';
import { filter } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs';

@Injectable()
export class AuthentificationService {
  constructor(
    private readonly oauthService: OAuthService,
    private readonly authenticationService: AuthenticationService,
    private readonly errorService: ErrorService,
    private readonly userService: UserService,
  ) {
    this.oauthService.events.subscribe((event) => {
      switch (event.type) {
        case 'user_profile_loaded':
          let claims = this.oauthService.getIdentityClaims();
          var user: UserConnected = {
            username: claims['username'],
            email: claims['email'],
          };
          this.authenticationService.setUserConnected(user);
          break;
      }
    });

    this.authenticationService
      .getUserConnected()
      .pipe(distinctUntilChanged())
      .subscribe((user) => {
        this.userService.getCurrentUser.call();
      });
  }

  public loginwithcredentials(loginModel: LoginModel): any {
    this.oauthService.oidc = false;
    return this.oauthService
      .fetchTokenUsingPasswordFlow(loginModel.mail, loginModel.password)
      .then((resp) => {
        return this.oauthService.loadUserProfile();
      })
      .catch((error) => {
        throw this.errorService.serverErrorRedirect(error);
      });
  }

  public getUserProfile(): any {
    this.oauthService.oidc = false;
    return this.oauthService.loadUserProfile();
  }

  public logout(): any {
    return this.oauthService.logOut();
  }

  public isAuthenticated = (): boolean => {
    return this.oauthService.hasValidAccessToken();
  };
}
