import { createApiCallActions } from '../../../common/store/generic.actions';
import { DocumentTypeModel } from './types';
import { ListItem } from '../typeahead/types';
import { createAction, props } from '@ngrx/store';

export const documentTypeActions = {
  getById: createApiCallActions<DocumentTypeModel>('documentType', 'getById'),
  getListItem: createApiCallActions<ListItem[]>('documentType', 'getListItem'),
  createInstance: createApiCallActions<DocumentTypeModel>(
    'documentType',
    'createInstance',
  ),
  add: createApiCallActions<DocumentTypeModel>('documentType', 'add'),
  update: createApiCallActions<DocumentTypeModel>('documentType', 'update'),
  setDocumentTypes: createAction(
    '[Product Category] Set document types',
    props<{ documentTypes: DocumentTypeModel[] }>(),
  ),
};

export type DocumentTypeAction = typeof documentTypeActions;
