import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor() {}

  getMaxSize = (): string => {
    return '5'; //MB
  };
  getMaxFiles = (): string => {
    return '5';
  };
  getAutorizedFileExtensions = (): string[] => {
    //https://www.w3schools.com/TAGS/att_input_accept.asp#:~:text=HTML%20%3Cinput%3E%20accept%20Attribute%201%20Definition%20and%20Usage,...%204%20Attribute%20Values%20%E2%9D%AE%20HTML%20%3Cinput%3E%20tag
    return ['.jpg', '.jpeg', '.png', '.pdf'];
  };

  getAvatarMaxSize = (): string => {
    return '3'; //Mb
  };

  //generic
  getGenericAutorizedFileExtensions = (): string[] => {
    return ['.jpg', '.jpeg', '.png', '.pdf'];
  };
  getGenericMaxSize = (): string => {
    return '2'; //Mb
  };
}

export type ConfigKey =
  | 'MaxSize'
  | 'MaxFiles'
  | 'AutorizedFileExtensions'
  | 'AvatarMaxSize'
  | 'GenericMaxSize'
  | 'GenericAutorizedFileExtensions';
