import { createApiCallActions } from 'src/app/common/store/generic.actions';
import {
  HealthInsuranceLeadModel,
  CarInsuranceLeadModel,
  HouseInsuranceLeadModel,
  LeadDetailModel,
  HealthLeadFormModel,
  CarLeadFormModel,
  HouseLeadFormModel,
  BaseLeadFormModel,
  LeadStatusFlow,
} from './types';
import { Action } from '../view/types';

export const leadActions = {
  getByUniqueId: createApiCallActions<LeadDetailModel>('lead', 'getByUniqueId'),
  getHealthDetail: createApiCallActions<HealthInsuranceLeadModel>(
    'lead',
    'getHealthDetail',
  ),
  getCarDetail: createApiCallActions<CarInsuranceLeadModel>(
    'lead',
    'getCarDetail',
  ),
  getHouseDetail: createApiCallActions<HouseInsuranceLeadModel>(
    'lead',
    'getHouseDetail',
  ),
  sendLead: createApiCallActions<any>('lead', 'sendLead'),
  updateLeadDetail: createApiCallActions<any>('lead', 'updateLeadDetail'),
  updateHealthDetail: createApiCallActions<any>('lead', 'updateHealthDetail'),
  updateCarDetail: createApiCallActions<any>('lead', 'updateCarDetail'),
  updateHouseDetail: createApiCallActions<any>('lead', 'updateHouseDetail'),
  createLead: createApiCallActions<any>('lead', 'createLead'),
  createHealthLead: createApiCallActions<any>('lead', 'createHealthLead'),
  createCarLead: createApiCallActions<any>('lead', 'createCarLead'),
  createHouseLead: createApiCallActions<any>('lead', 'createHouseLead'),
  getBaseLeadModel: createApiCallActions<BaseLeadFormModel>(
    'lead',
    'getBaseLeadModel',
  ),
  getHealthLeadModel: createApiCallActions<HealthLeadFormModel>(
    'lead',
    'getHealthLeadModel',
  ),
  getCarLeadModel: createApiCallActions<CarLeadFormModel>(
    'lead',
    'getCarLeadModel',
  ),
  getHouseLeadModel: createApiCallActions<HouseLeadFormModel>(
    'lead',
    'getHouseLeadModel',
  ),
  getStatusFlow: createApiCallActions<LeadStatusFlow[]>(
    'lead',
    'getStatusFlow',
  ),
  getStatusActions: createApiCallActions<Action[]>('lead', 'getStatusActions'),
  qualify: createApiCallActions<LeadDetailModel>('lead', 'qualify'),
  markAsWon: createApiCallActions<LeadDetailModel>('lead', 'markAsWon'),
  markAsLost: createApiCallActions<LeadDetailModel>('lead', 'markAsLost'),
  createContractRequest: createApiCallActions<LeadDetailModel>(
    'lead',
    'createContractRequest',
  ),
  reactivate: createApiCallActions<LeadDetailModel>('lead', 'reactivate'),
};
export type LeadAction = typeof leadActions;
