import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { Store } from '@ngrx/store';
import { SalesTeamModel } from './types';
import { salesTeamActions } from './sales-team.action';
import { salesTeamSelectors } from './sales-team.selector';
import { SalesTeamMember } from '../sales-team-member/types';
import { ListItem } from '../typeahead/types';

@Injectable()
export class SalesTeamService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<SalesTeamModel, string> = this.genericApiCall(
    salesTeamActions.getById,
    salesTeamSelectors.getById,
    true,
  );
  getList: GenericApiCall<ListItem[], void> = this.genericApiCall(
    salesTeamActions.getList,
    salesTeamSelectors.getList,
    true,
  );
  getSalesTeamMemberList: GenericApiCall<SalesTeamMember[], number> =
    this.genericApiCall(
      salesTeamActions.getSalesTeamMemberList,
      salesTeamSelectors.getSalesTeamMemberList,
      true,
    );
  create: GenericApiCall<SalesTeamModel, void> = this.genericApiCall(
    salesTeamActions.create,
    salesTeamSelectors.create,
    true,
  );
  postSalesTeam: GenericApiCall<SalesTeamModel, SalesTeamModel> =
    this.genericApiCall(
      salesTeamActions.postSalesTeam,
      salesTeamSelectors.postSalesTeam,
      true,
    );

  updateSalesTeam: GenericApiCall<SalesTeamModel, SalesTeamModel> =
    this.genericApiCall(
      salesTeamActions.updateSalesTeam,
      salesTeamSelectors.updateSalesTeam,
      true,
    );
}
