import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { DocumentTypeDetailsComponent } from './document-type-details/document-type-details.component';
import { DocumentTypeRoutingModule } from './document-type-routing.module';
import { DocumentTypeCreateComponent } from './document-type-create/document-type-create.component';
import { DocumentTypeEditComponent } from './document-type-edit/document-type-edit.component';
import { DocumentTypeFormContentComponent } from './shared/document-type-form-content/document-type-form-content.component';

@NgModule({
  declarations: [
    DocumentTypeDetailsComponent,
    DocumentTypeCreateComponent,
    DocumentTypeEditComponent,
    DocumentTypeFormContentComponent,
  ],
  imports: [CommonModule, DocumentTypeRoutingModule, SharedModule],
})
export class DocumentTypeModule {}
