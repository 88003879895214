<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container partner-details">
        <div class="card card-light w-100">
          <form
            [formGroup]="leadForm"
            (ngSubmit)="onSubmit()"
            [hasUnsavedData]="leadForm"
            autocomplete="off"
            formAccessible
          >
            <button type="submit" hidden="hidden"></button>
            <div class="card-body">
              <div class="container-fluid">
                <div class="row mb-2">
                  <div class="col-6 mr-auto">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-6">
                          <label
                            class="form-title-label"
                            for="lastname"
                            translate
                          >LEAD_LBL_LASTNAME</label
                          >
                          <input
                            class="form-control form-title"
                            id="lastname"
                            name="lastname"
                            type="text"
                            formControlName="lastname"
                            oninput="this.value = this.value.toUpperCase()"
                            (blur)="onBlurLastName($event)"
                            checkFormFieldValidity
                          />
                        </div>
                        <div class="col-6">
                          <label
                            class="form-title-label"
                            for="firstname"
                            translate
                          >LEAD_LBL_FIRSTNAME</label
                          >
                          <input
                            class="form-control form-title"
                            id="firstname"
                            name="firstname"
                            type="text"
                            formControlName="firstname"
                            (blur)="onBlurFirstName($event)"
                            checkFormFieldValidity
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_ADDRESS</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="address"
                          name="address"
                          type="text"
                          formControlName="address"
                          [placeholder]="'LEAD_PLACEHOLDER_ADDRESS' | translate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate></label>
                      <div class="col">
                        <div class="row">
                          <div class="col-2">
                            <input
                              class="form-control form-control-sm"
                              id="zipCode"
                              name="zipCode"
                              type="text"
                              formControlName="zipCode"
                              [placeholder]="'LEAD_PLACEHOLDER_ZIPCODE' | translate"
                              checkFormFieldValidity
                              mask="00000"
                              maxlength="5"
                            />
                          </div>
                          <div class="col">
                            <app-typeahead
                              [entity]="Entity.ZipCode"
                              [referenceEndpoint]="'ZipCodes/Cities/'"
                              [dropdownStyle]="DropdownStyle.DropDown"
                              [controlForm]="this.leadForm.controls['city']"
                              [limitToList]="true"
                              [referenceFilterParentName]="'Code'"
                              [referenceFilterParentValue]="
                                this.leadForm.controls['zipCode'].value
                              "
                              [valueMember]="'name'"
                              [displayMember]="'name'"
                              [dbNameOfId]="'City'"
                              [dbNameOfName]="'City'"
                              [referenceField]="'City'"
                              [showFirstElement]="true"
                            ></app-typeahead>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_PHONE_NUMBER</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="phoneNumber"
                          name="phoneNumber"
                          type="text"
                          formControlName="phoneNumber"
                          [placeholder]="'LEAD_PLACEHOLDER_PHONE_NUMBER' | translate"
                          mask="00 00 00 00 00"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_EMAIL</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="email"
                          name="email"
                          type="text"
                          formControlName="email"
                          [placeholder]="'LEAD_PLACEHOLDER_EMAIL' | translate"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_CATEGORY</label
                      >
                      <div class="col">
                        <product-category-edit
                          [controlForm]="this.leadForm.controls['productCategoryId']"
                          [isReadonly]="true"
                          (categoryChange)="onCategoryChange($event)"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_SALETEAM</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams'"
                          [controlForm]="this.leadForm.controls['salesTeam']"
                          [limitToList]="true"
                          [dropdownStyle]="DropdownStyle.DropDown"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_SALETEAM_MEMBER</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams/SalesTeamMembers'"
                          [controlForm]="
                            this.leadForm.controls['salesTeamMember']
                          "
                          [limitToList]="true"
                          [referenceFilterParentName]="'SalesTeamId'"
                          [referenceFilterParentValue]="
                            this.leadForm.controls['salesTeam'].value
                          "
                          [dbNameOfName]="'UserContactName'"
                          [dbNameOfId]="'UserId'"
                          [dropdownStyle]="DropdownStyle.DropDown"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_EFFECTIVE_DATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="effectiveDate"
                          name="effectiveDate"
                          type="date"
                          formControlName="effectiveDate"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <app-lead-tabs-form [category]="this.category" [form]="this.leadForm"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
