import { createApiCallActions } from '../../../common/store/generic.actions';
import { CampaignModel } from './types';
import { ListItem } from '../typeahead/types';

export const campaignActions = {
  getById: createApiCallActions<CampaignModel>('campaign', 'getById'),
  getList: createApiCallActions<ListItem[]>('campaign', 'getList'),
  createInstance: createApiCallActions<CampaignModel>(
    'campaign',
    'createInstance',
  ),
  postCampaign: createApiCallActions<CampaignModel>('campaign', 'postCampaign'),
  updateCampaign: createApiCallActions<CampaignModel>(
    'campaign',
    'updateCampaign',
  ),
};

export type CampaignAction = typeof campaignActions;
