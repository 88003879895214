import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DataType,
  DropdownStyle,
  InputTypeahead,
  ListItem,
  ReferencesItem,
} from '../../store/typeahead/types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Entity } from '../../store/view/types';
import {
  AddTagFn,
  NgSelectComponent,
} from '@ng-select/ng-select/lib/ng-select.component';
import { BehaviorSubject, debounceTime, Observable, startWith } from 'rxjs';
import { TypeaheadApiService } from '../../store/typeahead/typeahead.api.service';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit, AfterViewInit {
  @Input() dbNameOfName: string = 'name';
  @Input() dbNameOfId: string = 'id';
  @Input() orderBy: string;
  //Look like a Dropdown or a DropdownList with an input search
  @Input() dropdownStyle: DropdownStyle = DropdownStyle.DropDownList;
  @Input() domain: string = '';
  @Input() pageSize: number = 10;
  @Input() entity: Entity;
  //Limit values from/to a list of a dropdown
  @Input() limitToList: boolean = false;
  @Input() routeEndpoint: string;
  @Input() allowCustomActions: boolean = false;
  @Input() readonly: boolean = false;

  @Input() referenceEndpoint: string;
  @Input() quickAdd: boolean | AddTagFn = false;

  @Input() valueMember: string = 'id';
  @Input() displayMember: string = 'name';
  private usedMember: string = 'name';

  @Input() dataType: DataType = DataType.ReferenceData;

  @Input() showFirstElement: boolean = false;
  private isNewValue: boolean = false;

  @Input() controlForm: any;
  @Input() placeholder: string = '';

  //dev
  // @Input() resultObject: boolean = false;
  @Output() dataSourceEmitter = new EventEmitter<ListItem[]>();

  @ViewChild('select') ngSelect: NgSelectComponent;

  protected readonly DropdownStyle = DropdownStyle;
  dataSource$: Observable<ListItem[] | ReferencesItem[]>;
  searchFilter: string;
  filter: string;
  searchInput$ = new BehaviorSubject<string>('');
  termSearchString: string = '';
  allowEdit: boolean = true;

  searchResult$ = new BehaviorSubject<ListItem[]>([]);

  private isInitEnded: boolean = false;

  initValues$ = new BehaviorSubject<ListItem[]>([]);

  constructor(private readonly typeaheadApiService: TypeaheadApiService) {}

  ngAfterViewInit(): void {
    // console.log(this.controlForm);
  }

  ngOnInit(): void {
    this.searchInput$
      .pipe(
        filter((term) => term !== null),
        // distinctUntilChanged(),
        debounceTime(200),
        // tap((x) => console.log(this.routeEndpoint)),
        // tap((x) => console.log(x)),
        tap((term) => (this.termSearchString = term)),
      )
      .subscribe((termSearchString) => {
        this.searchFilter = '';
        if (termSearchString.length > 0) {
          this.searchFilter = this.dbNameOfName + '=*' + termSearchString;
        }
        let filter: string = '';
        if (this.searchFilter) filter = this.searchFilter;
        if (this.filter) filter = this.filter;
        if (this.searchFilter && this.filter)
          filter = this.searchFilter + ',' + this.filter;

        let inputTypeahead: InputTypeahead = {
          endpoint: this.routeEndpoint,
          orderBy: this.orderBy,
          pageSize: this.pageSize,
          domain: this.domain,
          filter: filter,
        };
        this.typeaheadApiService
          .get(inputTypeahead)
          .pipe(take(1))
          .subscribe((typeahead) => {
            this.searchResult$.next(typeahead);
            this.dataSourceEmitter.emit(typeahead);
          });
      });

    this.dataSource$ = this.searchResult$.pipe(startWith([<ListItem>{}])).pipe(
      withLatestFrom(this.initValues$),
      map(([results, initValues]) => {
        if (initValues && initValues.length > 0) {
          for (const initValue in initValues) {
            var isInArray = results.some(
              (x) =>
                x.id === initValues[initValue].id &&
                x.value === initValues[initValue].value &&
                x.name === initValues[initValue].name,
            );
            if (!isInArray) {
              results.push(initValues[initValue]);
            }
          }
          this.initValues$.next([]);
        }
        return results;
      }),
    );
  }

  onAdd(event: any) {
    if (this.searchInput$.getValue()) {
      this.searchInput$.next('');
    }
  }

  //selectFn et compareWithFunc font la même chose !
  selectedFn = (item, selected) => {
    //On recherche le selected dans le controlForm.
    //Si ce dernier existe, on regarde si un name est présent. Si oui, alors return false.
    //Si ça n'existe pas, alors on va vérifier dans un tableau interne pour voir si les valeurs sont présentes.
    //Si le tableau est vide, alors, on va le charger la première fois (tant qu'il est vide) avec les valeurs du control form
    //Et les push dans les items pour qu'ils soient initialisées
    //Première approche.
    if (!this.isInitEnded) {
      if (
        this.controlForm &&
        Array.isArray(this.controlForm.value) &&
        this.controlForm.value.length > 0
      ) {
        if (this.controlForm.value[0][this.displayMember]) {
          //displayMember exists so just show the name
          return false;
        } else {
          //on va init.
          let filterString: string = '';
          for (let i = 0; i < this.controlForm.value.length; i++) {
            if (this.dbNameOfId && this.controlForm.value[i][this.valueMember])
              filterString +=
                this.dbNameOfId +
                '=' +
                this.controlForm.value[i][this.valueMember] +
                '|';
            else {
              if (this.dbNameOfId && this.controlForm.value[i])
                filterString +=
                  this.dbNameOfId + '=' + this.controlForm.value[i] + '|';
            }
          }
          if (filterString[filterString.length - 1] === '|')
            filterString = filterString.substring(0, filterString.length - 1);
          let inputTypeahead: InputTypeahead = {
            endpoint: this.routeEndpoint,
            orderBy: this.orderBy,
            pageSize: 25,
            domain: '',
            filter: filterString,
          };

          this.typeaheadApiService
            .get(inputTypeahead)
            .pipe(take(1))
            .subscribe((x) => {
              this.initValues$.next(x);
              this.searchInput$.next('');
            });
        }
      }
      this.isInitEnded = true;
    }
    if (selected && item[this.valueMember]) {
      return item[this.valueMember] === selected;
    }
    //Si this.displayMember exist, alors on return false directement
    return false;
  };

  // onValueMemberChange(newValue: any) {
  //   if (this.resultObject) {
  //     this.controlForm.setValue(newValue ? newValue : undefined);
  //   }
  // }
  onClear() {
    this.searchInput$.next('');
  }
}
