import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { InsuranceCategory } from '../../../../../shared/store/lead/types';
import { ContractFormHealthComponent } from '../contract-form-tabs/contract-form-health/contract-form-health.component';
import { TabsContent } from '../../../../../shared/models/tabs-content';
import { ErrorsNestedFormGroup } from '../../../../../shared/store/form/types';
import { ContractFormDriverComponent } from '../contract-form-tabs/contract-form-driver/contract-form-driver.component';
import { ContractFormVehicleComponent } from '../contract-form-tabs/contract-form-vehicle/contract-form-vehicle.component';
import { ContractFormCarInsuranceComponent } from '../contract-form-tabs/contract-form-car-insurance/contract-form-car-insurance.component';
import { ContractFormHouseComponent } from '../contract-form-tabs/contract-form-house/contract-form-house.component';
import { FormService } from '../../../../../shared/store/form/form.service';

@Component({
  selector: 'app-contract-tabs-form',
  templateUrl: './contract-tabs-form.component.html',
  styleUrls: ['./contract-tabs-form.component.scss'],
})
export class ContractTabsFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() category: InsuranceCategory;
  errorsDescription$: Observable<ErrorsNestedFormGroup>;

  active = 1;

  tabsContent$: BehaviorSubject<TabsContent[]> = new BehaviorSubject<
    TabsContent[]
  >([]);

  tabsInit: TabsContent[] = [
    {
      title: 'CONTRACT_TABS_HEALTH',
      index: 1,
      name: 'health',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: ContractFormHealthComponent,
      formGroupName: 'healthFormGroup',
    },
    {
      title: 'CONTRACT_TABS_DRIVER',
      index: 1,
      name: 'driver',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormDriverComponent,
      formGroupName: 'driverFormGroup',
    },
    {
      title: 'CONTRACT_TABS_VEHICLE',
      index: 2,
      name: 'vehicle',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormVehicleComponent,
      formGroupName: 'vehicleFormGroup',
    },
    {
      title: 'CONTRACT_TABS_INFORMATIONS',
      index: 3,
      name: 'car-insurance',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormCarInsuranceComponent,
      formGroupName: 'carInsuranceFormGroup',
    },
    {
      title: 'CONTRACT_TABS_HOUSE',
      index: 1,
      name: 'house',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: ContractFormHouseComponent,
      formGroupName: 'houseFormGroup',
    },
  ];
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.active = 1;
    if (this.tabsInit && this.tabsInit.length > 0) {
      this.tabsContent$.next(
        this.tabsInit.filter((x) => x.category === this.category),
      );
    }
    this.enableOrDisableControl();
    this.formService.countErrors(this.form, false);
  }

  trackByTabsContent(index: any, item: TabsContent) {
    return item.index;
  }

  enableOrDisableControl = () => {
    for (let i = 0; i < this.tabsInit.length; i++) {
      const formGroupName = this.tabsInit[i].formGroupName;

      if (formGroupName && this.form.controls[formGroupName]) {
        if (this.tabsInit[i].category === this.category) {
          //enable control
          this.form.controls[formGroupName].enable();
        } else {
          //disable control
          this.form.controls[formGroupName].disable();
        }
      }
    }
  };
}
