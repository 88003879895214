<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <div class="card-body">
      <div class="container-fluid">
        <form [formGroup]="salesTeamForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="salesTeamForm">
          <button type="submit" hidden="hidden"></button>

          <div class="row mb-2">
            <div class="col-6 mr-auto">
              <div class="form-group">
                <label class="form-title-label" for="name" translate>SALES_TEAM_LBL_NAME</label>
                <input
                  id="name"
                  class="form-control form-title"
                  type="text"
                  formControlName="name"
                  checkFormFieldValidity
                  [maxLength]="50"
                />
              </div>
            </div>
            <div class="col"></div>
          </div>

          <div class="row">

            <div class="col-6 col-lg-6">

              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="code" translate>SALES_TEAM_LBL_CODE</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="code"
                    type="text"
                    formControlName="code"
                    checkFormFieldValidity
                    [maxLength]="8"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="teamLeaderId" translate>SALES_TEAM_LBL_TEAM_LEADER</label>
                <div class="col">
                  <app-typeahead
                    id="teamLeaderId"
                    [entity]="Entity.SalesTeam"
                    [routeEndpoint]="'SalesTeams/SalesTeamMembers'"
                    [controlForm]="
                            this.salesTeamForm.controls['teamLeaderId']
                          "
                    [limitToList]="true"
                    [dbNameOfName]="'UserContactName'"
                    [dbNameOfId]="'UserId'"
                  ></app-typeahead>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="active" translate>SALES_TEAM_LBL_ACTIVE</label>
                <div class="col">
                  <input
                    class="form-control-sm"
                    id="active"
                    type="checkbox"
                    formControlName="active"
                    checkFormFieldValidity
                  />
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
