import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { INavigationState } from './navigation.reducer';
import { Menu, Module, navigationFeatureKey } from './types';

const navigationFeatureState =
  createFeatureSelector<INavigationState>(navigationFeatureKey);

export const navigationSelectors = {
  getModules: createApiCallSelectors<Module[], INavigationState>(
    navigationFeatureState,
    'getModules',
  ),
  getMenus: createApiCallSelectors<Menu[], INavigationState>(
    navigationFeatureState,
    'getMenus',
  ),
};
