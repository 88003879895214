import { GenericState, IState } from 'src/app/common/store/types';
import { viewActions } from './view.actions';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { EnumModel, View, ViewType } from './types';

export interface IViewState extends IState<View> {
  getViews: GenericState<View>;
  getEnumValues: GenericState<EnumModel[]>;
  viewType: ViewType;
}

export const ViewInitialState: IViewState = {
  getViews: new GenericState<View>(),
  getEnumValues: new GenericState<EnumModel[]>(),
  viewType: 'TreeView',
};

const ViewReducers = [
  ...onApiCall<View>(viewActions.getViews, 'getViews'),
  ...onApiCall<EnumModel[]>(viewActions.getEnumValues, 'getEnumValues'),
  on(viewActions.setViewType, (state: IViewState, { viewType }) => ({
    ...state,
    viewType: viewType,
  })),
];

export const viewReducer = createReducer(
  ViewInitialState,
  ...ViewReducers
) as ActionReducer<IViewState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return viewReducer(state, action);
}
