import { Injectable } from '@angular/core';
import { BaseServiceFacade } from '../../../common/store/types';
import { AppState } from '../types';
import { Store } from '@ngrx/store';
import { formActions } from './form.actions';
import { formSelectors } from './form.selectors';
import { FormArray, FormGroup } from '@angular/forms';
import { AbstractControlExtended, ErrorsNestedFormGroup } from './types';
import { Observable } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { NotificationStatus, NotificationType } from '../notification/types';

@Injectable()
export class FormService extends BaseServiceFacade {
  constructor(
    store: Store<AppState>,
    private readonly notificationService: NotificationService,
  ) {
    super(store);
  }

  setIsDirty = (name: string, isDirty: boolean) => {
    this.store.dispatch(
      formActions.setIsDirty({ formName: name, isDirty: isDirty }),
    );
  };

  getIsDirty = () => this.store.select(formSelectors.getIsDirty);

  clear = () => {
    this.store.dispatch(formActions.clear());
  };

  getTotalErrors = () => this.store.select(formSelectors.getTotalCountErrors);

  getErrors = () => this.store.select(formSelectors.getErrors);

  private updateErrorsCount(errorsCount: ErrorsNestedFormGroup) {
    this.store.dispatch(formActions.countErrors(errorsCount));
  }

  getErrorsEntity = () => this.store.select(formSelectors.getErrorsEntity);

  setEntityErrors = (entityType: string) => {
    this.store.dispatch(
      formActions.setErrorsEntity({ entityType: entityType }),
    );
  };

  countErrors = (
    ctrl: FormGroup,
    show: boolean,
    formGroupName?: string,
  ): Observable<ErrorsNestedFormGroup> => {
    let errors = this.processErrorsCount(formGroupName, ctrl);
    // console.log(errors);
    this.updateErrorsCount(errors);
    if (show && errors.root.countTotal > 0) {
      this.notificationService.clearByType(NotificationType.ToastValidation);
      this.notificationService.add({
        content: errors.root.countTotal.toString(),
        notificationStatus: NotificationStatus.Info,
        timestamp: new Date().getTime(),
        type: NotificationType.ToastValidation,
        userCancelled: false,
        timeout: 5000,
      });
    }

    return this.store.select(formSelectors.getErrors);
  };

  private processErrorsCount(
    formGroupName: string | undefined,
    ctrl: FormGroup<any>,
  ): ErrorsNestedFormGroup {
    let errors: ErrorsNestedFormGroup;
    // console.log(ctrl);
    if (formGroupName) {
      errors = this._countErrors(
        (ctrl.controls[formGroupName] as FormGroup).controls,
        false,
      );
    } else {
      errors = this._countErrors(ctrl.controls, false);
    }
    // console.log(errors);
    return errors;
  }

  private _countErrors(
    ctrl: AbstractControlExtended,
    isChildren: boolean,
  ): ErrorsNestedFormGroup {
    const controls = ctrl;
    let nestedFormGroup: ErrorsNestedFormGroup = {
      root: {
        groupName: '',
        countTotal: 0,
        count: 0,
        fieldName: [],
      },
      children: [],
    };

    for (const name in controls) {
      if (controls[name] instanceof FormArray) {
        for (
          let i = 0;
          i < (controls[name] as FormArray).controls.length;
          i++
        ) {
          if ((controls[name] as FormArray).controls[i]) {
            // console.log('== ' + i);
            // console.log((controls[name] as FormArray).controls[i]);
            let result = this._countErrors(
              ((controls[name] as FormArray).controls[i] as FormGroup).controls,
              true,
            ).root;
            // console.log(result);
            nestedFormGroup.root.countTotal += result.count;
            nestedFormGroup.root.count += result.count;
            // nestedFormGroup.children.push(result);
            nestedFormGroup.root.fieldName.push(...result.fieldName);
          }
        }
      } else if (controls[name] instanceof FormGroup) {
        if ((controls[name] as FormGroup).controls) {
          // console.log('--' + name);
          // console.log(controls[name]);
          let result = this._countErrors(
            (controls[name] as FormGroup).controls,
            true,
          ).root;
          nestedFormGroup.root.countTotal += result.count;
          if (isChildren) {
            nestedFormGroup.root.count += result.count;
            for (let i = 0; i < result.fieldName.length; i++) {
              nestedFormGroup.root.fieldName.push(
                `${name}_${result.fieldName[i]}`,
              );
            }
          } else {
            result.groupName = name;
            nestedFormGroup.children.push(result);
          }
        }
      } else {
        if (controls[name].invalid) {
          // console.log('$$$ ' + name);
          nestedFormGroup.root.fieldName.push(name);
          nestedFormGroup.root.count++;
          nestedFormGroup.root.countTotal++;
        }
      }
    }

    //console.log(`Total invalid fields: ${invalidCount}`);
    return nestedFormGroup;
  }
}
