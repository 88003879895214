import { EmployeeModel } from './types';
import { createApiCallActions } from '../../../common/store/generic.actions';

export const employeeActions = {
  getById: createApiCallActions<EmployeeModel>('employee', 'getById'),
  getEmployeeModel: createApiCallActions<EmployeeModel>(
    'employee',
    'getEmployeeModel',
  ),
  postEmployee: createApiCallActions<EmployeeModel>('employee', 'postEmployee'),
  updateEmployee: createApiCallActions<EmployeeModel>(
    'employee',
    'updateEmployee',
  ),
};

export type EmployeeActions = typeof employeeActions;
