import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { AppState } from '../types';
import { bankActions } from './bank.action';
import { bankSelectors } from './bank.selector';
import { BankModel } from './types';

@Injectable()
export class BankService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<BankModel, string> = this.genericApiCall(
    bankActions.getById,
    bankSelectors.getById,
    true
  );

  postBank: GenericApiCall<any, BankModel> = this.genericApiCall(
    bankActions.postBank,
    bankSelectors.postBank,
    true
  );

  updateBank: GenericApiCall<any, BankModel> = this.genericApiCall(
    bankActions.updateBank,
    bankSelectors.updateBank,
    true
  );
}
