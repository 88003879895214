import { Injectable } from '@angular/core';
import { HistoryAction, historyActions } from './history.action';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { Actions } from '@ngrx/effects';
import { HistoryApiService } from './history.api.service';

@Injectable()
export class HistoryEffects {
  constructor(
    private readonly historyApiService: HistoryApiService,
    private readonly action$: Actions<HistoryAction>,
  ) {}

  getByEntityId = createApiCallEffects(
    this,
    this.action$,
    historyActions.getByEntityId,
    this.historyApiService.getByEntityId,
  );
}
