import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { InsuranceCompanyModel } from 'src/app/shared/store/insurance-company/types';
import { InsuranceCompanyService } from 'src/app/shared/store/insurance-company/insurance-company.service';

@Component({
  selector: 'app-insurance-company-details-product-lines',
  templateUrl: './insurance-company-details-product-lines.component.html',
  styleUrls: ['./insurance-company-details-product-lines.component.scss'],
})
export class InsuranceCompanyDetailsProductLinesComponent {
  insuranceCompany$: Observable<InsuranceCompanyModel>;

  constructor(
    private readonly insuranceCompanyService: InsuranceCompanyService
  ) {
    this.insuranceCompany$ = this.insuranceCompanyService.getById.value$;
  }
}
