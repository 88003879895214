import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { ProductsRoutingModule } from './products-routing.module';
import { CommissionPlanModule } from './commission-plan/commission-plan.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    ProductsRoutingModule,
    CommissionPlanModule,
  ],
  exports: [],
})
export class ProductsModule {}
