import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { ProductLineAction, productLineActions } from './product-line.actions';
import { ProductLineApiService } from './product-line.api.service';

@Injectable()
export class ProductLineEffects {
  constructor(
    private readonly productLineApiService: ProductLineApiService,
    private readonly action$: Actions<ProductLineAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    productLineActions.getById,
    this.productLineApiService.getById
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    productLineActions.createInstance,
    this.productLineApiService.createInstance
  );

  add = createApiCallEffects(
    this,
    this.action$,
    productLineActions.add,
    this.productLineApiService.add
  );

  update = createApiCallEffects(
    this,
    this.action$,
    productLineActions.update,
    this.productLineApiService.update
  );
}
