export const countryFeatureKey = 'country';
export type CountryModel = {
  id: number;
  name: string;
  englishName: string;
  alpha2Code: string;
  alpha3Code: string;
  nationality: string;
  isPrimary: boolean;
  currencyId: number | undefined | null;
  currencyName: string;
  currencySymbol: string;
  currencyIsoCode: string;
};
