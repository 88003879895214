import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NotificationService } from '../../store/notification/notification.service';
import { ConfigurationService } from '../../service/configuration.service';
import { environment } from '../../../../environments/environment';
import {
  NotificationStatus,
  NotificationType,
} from '../../store/notification/types';

@Component({
  selector: 'app-avatar-edit',
  templateUrl: './avatar-edit.component.html',
  styleUrls: ['./avatar-edit.component.scss'],
})
export class AvatarEditComponent implements OnInit, OnChanges {
  @ViewChild('contactPicture') contactPhoto: ElementRef;

  @Input() picturePath: string = '';
  @Input() isCompany: boolean = false;
  @Output() fileEmitter: EventEmitter<File | undefined> = new EventEmitter();
  imageUrl: string = '';
  file: File | undefined;
  apiUrl: string = environment.comparanooapiurl;

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    private readonly imageCompressService: NgxImageCompressService,
  ) {}

  ngOnInit(): void {
    this.imageUrl = this.picturePath;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isCompany']) return;

    this.imageUrl = this.picturePath;
  }

  onFileSelected(event) {
    const files = event.target.files as File[];
    const file = files[0];

    if (file) {
      if (
        file.size >
        parseInt(this.configurationService.getAvatarMaxSize()) * 1024 * 1024
      ) {
        this.notificationService.add({
          timestamp: new Date().getTime(),
          content: this.translateService.instant(
            'DOCUMENT_MSG_ERROR_MAX_SIZE',
            {
              fileName: file.name,
              max: this.configurationService.getAvatarMaxSize(),
            },
          ),
          notificationStatus: NotificationStatus.Error,
          userCancelled: false,
          type: NotificationType.Toast,
        });
      } else {
        // this.file = file;
        this.convertAsDataURL(file);
      }
    }
  }

  convertAsDataURL = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
      //dev
      if (file.type.indexOf('svg') === -1) {
        this.imageCompressService
          // .compressFile(this.imageUrl, -1, 50, 50)
          .compressFile(this.imageUrl, -1, 50, 50, 300, 300) //with maxWidth and maxHeight
          .then(async (result) => {
            this.imageUrl = result;

            //convert base64 compressed to Blob, and then to File
            const base64Response = await fetch(result);
            const blob = await base64Response.blob();
            const compressedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            });
            this.fileEmitter.emit(compressedFile);
            // this.file = compressedFile;
          });
      } else {
        this.fileEmitter.emit(file);
        // this.file = file;
      }
      //
    };

    reader.readAsDataURL(file);
  };

  onClickClearSelectedPicture() {
    if (this.contactPhoto.nativeElement.value) {
      this.imageUrl = this.picturePath;
    } else {
      this.imageUrl = '';
    }
    this.fileEmitter.emit(undefined);
    this.contactPhoto.nativeElement.value = null;
  }
}
