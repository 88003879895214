import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactModel } from '../../../../shared/store/contact/types';
import { SmartAction, View } from '../../../../shared/store/view/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { ContactService } from '../../../../shared/store/contact/contact.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
  contactId: number;
  view$: Observable<View>;
  smartActions: SmartAction[];
  contactDetail$: Observable<ContactModel>;
  relatedContactsDetail$: Observable<ContactModel[]>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly contactService: ContactService
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.contactDetail$ = this.contactService.getById.value$;
    this.relatedContactsDetail$ = this.contactService.getChildren.value$;
  }

  ngOnInit(): void {
    this.contactId = this.route.snapshot.params['id'];

    this.smartActions = [
      {
        id: 'leads',
        label: 'SMARTACTIONS_LBL_LEADS',
        url: '/Crm/Leads/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'ContactId',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'star-outline',
        actif: true,
      },
      {
        id: 'contracts',
        label: 'SMARTACTIONS_LBL_CONTRACTS',
        url: '/Crm/ContractRequests/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'ContactId',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'star-outline',
        actif: true,
      },
      {
        id: 'bankAccounts',
        label: 'SMARTACTIONS_LBL_BANKACCOUNTS',
        url: '/Contacts/BankAccounts/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'ContactId',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'star-outline',
        actif: true,
      },
      {
        id: 'documents',
        label: 'SMARTACTIONS_LBL_DOCUMENTS',
        url: '/Documents/Documents/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'ContactId',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'file-outline',
        actif: true,
      },
    ];

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'contactEdit') {
          this.router.navigate(['Contacts/Contacts/Edit/', this.contactId]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.contactDetail$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        this.contactId = x.id;
        if (x.isCompany) {
          this.contactService.getChildren.call(x.id);
        }
        if (x.leads) {
          this.smartActions[0].count = x.leads.length;
          this.smartActions[0].filter.domains[0].value = x.id;
          this.smartActions[0].filter.name = x.name;
          this.smartActions[0].filter.text = x.name;
          this.smartActions[0].label =
            x.leads.length > 1
              ? 'SMARTACTIONS_LBL_LEADS'
              : 'SMARTACTIONS_LBL_LEAD';
        }
        if (x.contracts) {
          this.smartActions[1].count = x.contracts.length;
          this.smartActions[1].filter.domains[0].value = x.id;
          this.smartActions[1].filter.name = x.name;
          this.smartActions[1].filter.text = x.name;
          this.smartActions[1].label =
            x.contracts.length > 1
              ? 'SMARTACTIONS_LBL_CONTRACTS'
              : 'SMARTACTIONS_LBL_CONTRACT';
        }
        if (x.bankAccounts) {
          this.smartActions[2].count = x.bankAccounts.length;
          this.smartActions[2].filter.domains[0].value = x.id;
          this.smartActions[2].filter.name = x.name;
          this.smartActions[2].filter.text = x.name;
          this.smartActions[2].label =
            x.bankAccounts.length > 1
              ? 'SMARTACTIONS_LBL_BANKACCOUNTS'
              : 'SMARTACTIONS_LBL_BANKACCOUNT';
        }
        if (x.documents) {
          this.smartActions[3].count = x.documents.length;
          this.smartActions[3].filter.domains[0].value = x.id;
          this.smartActions[3].filter.name = x.name;
          this.smartActions[3].filter.text = x.name;
          this.smartActions[3].label =
            x.documents.length > 1
              ? 'SMARTACTIONS_LBL_DOCUMENTS'
              : 'SMARTACTIONS_LBL_DOCUMENT';
        }
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });
  }
}
