import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { ProductCoverageModel } from './types';
import { productCoverageActions } from './product-coverage.actions';
import { productCoverageSelectors } from './product-coverage.selector';

@Injectable()
export class ProductCoverageService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ProductCoverageModel, string> = this.genericApiCall(
    productCoverageActions.getById,
    productCoverageSelectors.getById,
    true
  );
  getByProductId: GenericApiCall<ProductCoverageModel[], string> =
    this.genericApiCall(
      productCoverageActions.getByProductId,
      productCoverageSelectors.getByProductId,
      true
    );
}
