import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ProductApiService } from "./product.api.service";
import { ProductEffects } from "./product.effects";
import { IProductState, productInitialState, productReducer } from "./product.reducer";
import { ProductService } from "./product.service";
import { productFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IProductState>(productFeatureKey, productReducer,{
            initialState: productInitialState,
        }),
        EffectsModule.forFeature([ProductEffects])
    ],
    providers:[ProductApiService, ProductService]
})
export class StoreProductModule{}
