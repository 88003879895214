import { Component, OnInit } from '@angular/core';
import { Entity, View } from '../../../../../shared/store/view/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressEdit } from '../../../../../shared/components/address-edit/address-edit';
import { BankAccountService } from '../../../../../shared/store/bank-account/bank-account.service';
import { FormService } from '../../../../../shared/store/form/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { filter, Observable } from 'rxjs';
import { BankAccountModel } from '../../../../../shared/store/bank-account/types';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContactModel } from '../../../../../shared/store/contact/types';
import { ContactService } from '../../../../../shared/store/contact/contact.service';

@UntilDestroy()
@Component({
  selector: 'app-bank-account-edit-card',
  templateUrl: './bank-account-edit-card.component.html',
  styleUrls: ['./bank-account-edit-card.component.scss'],
})
export class BankAccountEditCardComponent implements OnInit {
  //Form
  bankAccountForm: FormGroup;

  //Observable
  bankAccountModel$: Observable<BankAccountModel>;
  view$: Observable<View>;

  //var
  isEditMode: boolean = false;
  bankAccountId: string;

  //custom patterns for iban validation
  customPatterns = {
    0: {
      pattern: new RegExp(/[0-9]/),
    },
    C: {
      pattern: new RegExp('[a-zA-Z0-9]'),
    },
    S: {
      pattern: new RegExp('[a-zA-Z]'),
    },
  };

  //protected (used with TypeAhead)
  protected readonly Entity = Entity;

  constructor(
    private readonly bankAccountService: BankAccountService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly contactService: ContactService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.bankAccountModel$ = this.bankAccountService.getById.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.bankAccountForm = this.formBuilder.group({
      iban: ['', [Validators.required]],
      bic: [''],
      bankId: [undefined],
      contactId: [undefined, [Validators.required]],
      agencyAddress: this.formBuilder.group<AddressEdit>({
        address: [''],
        address2: [''],
        zipCode: [''],
        city: [undefined],
        countryId: [undefined],
      }),
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.bankAccountId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.bankAccountId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.bankAccountService.getById.call(this.bankAccountId);
    } else {
      //Pas de createInstance pour l'instant
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'bankAccountCreate' || x == 'bankAccountUpdate')
          this.onSubmit();
        if (x == 'bankAccountList') {
          this.formService.clear();
          this.router.navigate(['/Contacts/BankAccounts/']);
        }
        if (x == 'bankAccountDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Contacts/BankAccounts/Details',
            this.bankAccountId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set('@bankAccountCreate', x.breadcrumbCreate);
      });

    //Si on est en Edit, on patch le formulaire avec les données existantes
    if (this.isEditMode) {
      this.bankAccountModel$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.breadcrumbService.set('@bankAccountEdit', x.iban);
          this.bankAccountForm.patchValue({
            iban: x.iban,
            bic: x.bic,
            bankId: x.bankId,
            contactId: x.contactId,
            agencyAddress: {
              address: x.address,
              address2: x.address2,
              zipCode: x.zipCode,
              city: x.city,
              countryId: x.countryId,
            },
          });
        });
    }

    //Get contact to automatically fill in the holder name
    // this.bankAccountForm.controls['contactId']?.valueChanges.subscribe((x) => {
    //   let contact$: Observable<ContactModel>;
    //   contact$ = this.contactService.getById.value$;
    //   this.contactService.getById.call(x);
    //
    //   contact$
    //     .pipe(
    //       filter((x) => !!x),
    //       untilDestroyed(this),
    //     )
    //     .subscribe((x: ContactModel) => {
    //       this.bankAccountForm.patchValue({
    //         holderName: x.name,
    //       });
    //     });
    // });
  }

  onSubmit() {
    if (this.bankAccountForm.invalid) {
      this.bankAccountForm.markAllAsTouched();
      this.formService.countErrors(this.bankAccountForm, true);
      return;
    }

    let bankAccountModel: BankAccountModel = {
      id: this.bankAccountId ? +this.bankAccountId : 0,

      //Bank account
      iban: this.bankAccountForm.value.iban!,
      bic: this.bankAccountForm.value.bic!,

      //Contact
      contactId: this.bankAccountForm.value.contactId!,
      holderName: '',

      //Bank
      bankId: this.bankAccountForm.value.bankId!,

      //Agency
      address: this.bankAccountForm.value.agencyAddress.address!,
      address2: this.bankAccountForm.value.agencyAddress.address2!,
      zipCode: this.bankAccountForm.value.agencyAddress.zipCode!,
      city: this.bankAccountForm.value.agencyAddress.city!,
      countryId: this.bankAccountForm.value.agencyAddress.countryId!,
      countryName: '',

      //Flags
      active: true,

      //Others
      fullCity: '',
      bankName: '',
      bankBIC: '',
    };

    let result$: Observable<BankAccountModel>;
    if (this.isEditMode)
      result$ =
        this.bankAccountService.updateBankAccount.call(bankAccountModel);
    else
      result$ = this.bankAccountService.postBankAccount.call(bankAccountModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate(['Contacts/BankAccounts/Details', result.id]);
      });
  }
}
