import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnumModel, HeaderMenuType, Path, View } from './types';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ViewApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
  ) {}

  getViews = (path: Path): Observable<View> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);

    const options = {
      params: new HttpParams()
        .set('name', path.entity)
        .set('moduleId', HeaderMenuType[path.module]),
    };

    return this.httpClient.get<View>(url, options).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getEnumValues = (enumType: string): Observable<EnumModel[]> => {
    let parameters: string[];
    parameters = ['enum', 'values'];
    let url = this.formatUrl(parameters);

    const options = {
      params: new HttpParams().set('enumType', enumType),
    };

    return this.httpClient.get<EnumModel[]>(url, options).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.views.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
