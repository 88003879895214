import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ActivityTypeModel, activityTypeFeatureKey } from './types';
import { IActivityTypeState } from './activity-type.reducer';

const activityTypeFeatureState = createFeatureSelector<IActivityTypeState>(
  activityTypeFeatureKey,
);

export const activityTypeSelectors = {
  getById: createApiCallSelectors<ActivityTypeModel, IActivityTypeState>(
    activityTypeFeatureState,
    'getById',
  ),

  createInstance: createApiCallSelectors<ActivityTypeModel, IActivityTypeState>(
    activityTypeFeatureState,
    'createInstance',
  ),

  add: createApiCallSelectors<ActivityTypeModel, IActivityTypeState>(
    activityTypeFeatureState,
    'add',
  ),

  update: createApiCallSelectors<ActivityTypeModel, IActivityTypeState>(
    activityTypeFeatureState,
    'update',
  ),
};
