export const currencyFeatureKey = 'currency';
export type CurrencyModel = {
  id: number;
  isoCode: string;
  name: string;
  symbol: string;
  accuracy: number;
  rounding: number;
  currentRate: number | null;
  symbolPosition: SymbolPosition;
  symbolPositionLoc: string;
};

export enum SymbolPosition {
  AfterAmount = 1,
  BeforeAmount,
}
