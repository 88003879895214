import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { quoteActions } from './quote.actions';
import { quoteSelectors } from './quote.selector';
import { QuoteModel, QuoteResponseModel } from './types';

@Injectable()
export class QuoteService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<QuoteModel, string> = this.genericApiCall(
    quoteActions.getById,
    quoteSelectors.getById,
    true
  );
  getComparatorQuotes: GenericApiCall<QuoteResponseModel[], string> =
    this.genericApiCall(
      quoteActions.getComparatorQuotes,
      quoteSelectors.getComparatorQuotes,
      true
    );
  addQuotes: GenericApiCall<any, QuoteModel[]> = this.genericApiCall(
    quoteActions.addQuotes,
    quoteSelectors.addQuotes,
    true
  );
}
