import { createApiCallActions } from '../../../common/store/generic.actions';
import { ActivityModel, ActivityStatusFlow } from './types';
import { Action } from '../view/types';

export const activityActions = {
  getById: createApiCallActions<ActivityModel>('activity', 'getById'),
  createInstance: createApiCallActions<ActivityModel>(
    'activity',
    'createInstance',
  ),
  add: createApiCallActions<ActivityModel>('activity', 'add'),
  update: createApiCallActions<ActivityModel>('activity', 'update'),
  getStatusFlow: createApiCallActions<ActivityStatusFlow[]>(
    'activity',
    'getStatusFlow',
  ),
  getStatusActions: createApiCallActions<Action[]>(
    'activity',
    'getStatusActions',
  ),
  markAsDone: createApiCallActions<ActivityModel>('activity', 'markAsDone'),
};

export type ActivityAction = typeof activityActions;
