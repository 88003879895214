import { IEmployeeState } from './employee.reducer';
import { createFeatureSelector } from '@ngrx/store';
import { employeeFeatureKey, EmployeeModel } from './types';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';

const employeeFeatureState =
  createFeatureSelector<IEmployeeState>(employeeFeatureKey);

export const employeeSelectors = {
  getById: createApiCallSelectors<number, IEmployeeState>(
    employeeFeatureState,
    'getById',
  ),
  getEmployeeModel: createApiCallSelectors<void, IEmployeeState>(
    employeeFeatureState,
    'getEmployeeModel',
  ),
  postEmployee: createApiCallSelectors<EmployeeModel, IEmployeeState>(
    employeeFeatureState,
    'postEmployee',
  ),
  updateEmployee: createApiCallSelectors<EmployeeModel, IEmployeeState>(
    employeeFeatureState,
    'updateEmployee',
  ),
};
