export const insuranceCoverageFeatureKey = 'insuranceCoverage';

export type InsuranceCoverageModel = {
  id: number;
  description: string;
  sequence: number;
  insuranceCoverageType: number;
  insuranceCoverageValueType: InsuranceCoverageValueType;
};

export type InsuranceCoverageListItemModel = {
  id: number;
};

export type InsuranceCoverageType = 'Title' | 'Coverage';
export type InsuranceCoverageValueType = 'Percent' | 'Rating' | 'Text';
