import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, filter, forkJoin, map } from 'rxjs';
import { InsuranceCoverageService } from 'src/app/shared/store/insurance-coverage/insurance-coverage.service';
import { InsuranceCoverageModel } from 'src/app/shared/store/insurance-coverage/types';
import { ProductCoverageService } from 'src/app/shared/store/product-coverage/product-coverage.service';
import { ProductCoverageModel } from 'src/app/shared/store/product-coverage/types';
import { ProductModel } from 'src/app/shared/store/product/types';
import { View } from 'src/app/shared/store/view/types';
import { ViewService } from 'src/app/shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@UntilDestroy()
@Component({
  selector: 'app-product-comparator',
  templateUrl: './product-comparator.component.html',
  styleUrls: ['./product-comparator.component.scss'],
})
export class ProductComparatorComponent implements OnInit {
  view$: Observable<View>;
  insuranceCoverages$: Observable<InsuranceCoverageModel[]>;
  context: ComparatorContext;
  productCoverageModels$: Observable<ProductCoverageModel[]>;
  productCategoryId: number = 1;
  productId: number = 1488;

  joinedArray: any[];

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly insuranceCoverageService: InsuranceCoverageService,
    private readonly productCoverageService: ProductCoverageService
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.insuranceCoverages$ =
      this.insuranceCoverageService.getByProductCategory.value$;
    this.productCoverageModels$ =
      this.productCoverageService.getByProductId.value$;
  }
  ngOnInit(): void {
    this.productCoverageService.getByProductId.call(this.productId.toString());

    this.insuranceCoverageService.getByProductCategory.call(
      this.productCategoryId
    );

    // combineLatest([this.insuranceCoverages$, this.productCoverageModels$]).subscribe(([insuranceCoverages, productCoverageModels])=>{
    //   joinedArray = insuranceCoverages
    // })

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@productComparator', x.title);
      });
  }
}

export type ComparatorContext = {
  productCategoryId: number;
  insuranceCoverages$: Observable<InsuranceCoverageModel[]>;
  productCoverageModels$: Observable<ProductCoverageModel[]>;
  products: ProductModel[];
};
