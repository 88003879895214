import { createApiCallActions } from '../../../common/store/generic.actions';
import { QuoteModel, QuoteResponseModel } from './types';

export const quoteActions = {
  getById: createApiCallActions<QuoteModel>('quote', 'getById'),
  getComparatorQuotes: createApiCallActions<QuoteResponseModel[]>(
    'quote',
    'getComparatorQuotes'
  ),
  addQuotes: createApiCallActions<any>('quote', 'addQuotes'),
};

export type QuoteAction = typeof quoteActions;
