import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  InsuranceCoverageAction,
  insuranceCoverageActions,
} from './insurance-coverage.actions';
import { InsuranceCoverageApiService } from './insurance-coverage.api.service';

@Injectable()
export class InsuranceCoverageEffects {
  constructor(
    private readonly insuranceCoverageApiService: InsuranceCoverageApiService,
    private readonly action$: Actions<InsuranceCoverageAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    insuranceCoverageActions.getById,
    this.insuranceCoverageApiService.getById
  );

  getByProductCategory = createApiCallEffects(
    this,
    this.action$,
    insuranceCoverageActions.getByProductCategory,
    this.insuranceCoverageApiService.getByProductCategory
  );
}
