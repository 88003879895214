import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Menu } from '../../store/navigation/types';
import { NavigationService } from '../../store/navigation/navigation.service';

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class MenuDropdownComponent {
  @Input('items') items: Menu[] | undefined;
  @Input('baseRoute') baseRoute: string | undefined;
  menus$: Observable<Menu[]>;

  constructor(
    private readonly router: Router,
    private readonly navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.menus$ = this.navigationService.getMenus.value$;
  }

  navigateTo(url?: string | undefined) {
    this.router.navigateByUrl(url ? url : '');
    this.closeDropdown();
  }

  closeDropdown(): void {
    Array.from(document.getElementsByClassName('dropdown-menu')).forEach(
      (element) => {
        element.classList.add('display-none');
        setTimeout(() => {
          element.classList.remove('display-none');
        }, 50);
      },
    );
  }

  Nothing() {}
}
