import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { PaymentAction, paymentActions } from './payment.actions';
import { PaymentApiService } from './payment.api.service';

@Injectable()
export class PaymentEffects {
  constructor(
    private readonly paymentApiService: PaymentApiService,
    private readonly action$: Actions<PaymentAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    paymentActions.getById,
    this.paymentApiService.getById,
  );
}