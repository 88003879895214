import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';
import { AuthentificationGuard } from '../../../shared/guard/authentification-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ModuleId } from '../../../shared/store/navigation/types';
import { Entity, HeaderMenuType } from '../../../shared/store/view/types';
import { DocumentDetailsComponent } from './document-details/document-details.component';

const routes: Routes = [
  {
    path: 'Documents/Documents',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Documents,
          moduleId: ModuleId.documents,
          entity: Entity.Document,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: DocumentDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Documents,
          moduleId: ModuleId.documents,
          entity: Entity.Document,
          breadcrumb: { label: ' ', alias: 'documentDetails' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentRoutingModule {}
