import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreService } from './core/core.service';
import { RouterService } from './shared/store/router/router.service';
import { registerLocaleData } from '@angular/common';
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  fullscreen: Observable<boolean>;

  constructor(
    private readonly coreService: CoreService,
    private oauthService: OAuthService,
    private readonly routerService: RouterService,
  ) {
    this.coreService.configure();
    // Login-Url
    this.oauthService.tokenEndpoint =
      environment.comparanooapiurl + '/connect/token';

    // Url with user info endpoint
    // This endpont is described by OIDC and provides data about the loggin user
    // This sample uses it, because we don't get an id_token when we use the password flow
    // If you don't want this lib to fetch data about the user (e. g. id, name, email) you can skip this line
    this.oauthService.userinfoEndpoint =
      environment.comparanooapiurl + '/connect/userinfo';

    // The SPA's id. Register SPA with this id at the auth-server
    this.oauthService.clientId = 'ComparanooApi';

    // set the scope for the permissions the client should request
    this.oauthService.scope = 'profile email roles';

    // Set a dummy secret
    // Please note that the auth-server used here demand the client to transmit a client secret, although
    // the standard explicitly cites that the password flow can also be used without it. Using a client secret
    // does not make sense for a SPA that runs in the browser. That's why the property is called dummyClientSecret
    // Using such a dummy secret is as safe as using no secret.
    this.oauthService.dummyClientSecret =
      '1264614b-1b66-45de-a014-b8d840cfa4be';

    //Local Storage
    this.oauthService.setStorage(localStorage);

    //Silent refresh
    // this.oauthService.setupAutomaticSilentRefresh();

    this.fullscreen = this.routerService.routeFullscreenData$;
  }

  title = 'angular-comparanoo-admin';
}
