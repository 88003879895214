export const zipCodeFeatureKey = 'zipCodes';
export type ZipCodeModel = {
  id: number;
  code: string;
  city: string;
  countryId: string;
  countryName: string;
};

export type InputCodeAndCityZipCode = {
  code: string;
  city: string;
};
