import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
  host: { class: 'card data-card' },
})
export class KpiComponent implements OnInit {
  @Input() title: string;
  @Input() kpiData: string;
  @Input() kpiUnit: string;

  constructor() {}

  ngOnInit(): void {}
}
