<div class="sub-header">
  <div class="sub-header-row px-1 py-2" >
    <div class="row">
      <div class="col">
        <app-breadcrumb></app-breadcrumb>
      </div>

      <div class="col-5 text-right">
<!--        <app-search-bar></app-search-bar>-->
      </div>
    </div>

    <div class="row"  [style.visibility]="'hidden'">
      <div class="col">
        <app-actions></app-actions>
      </div>

      <ng-container>
        <div class="col text-center">

        </div>

        <div class="col-5 d-flex justify-content-between flex-wrap">
          <app-search-filters-dropdown />
          <app-pagination></app-pagination>
          <div>
            <a
              class="btn btn-outline-light btn-xs"
            >
              <i class="mdi mdi-format-list-bulleted-square"></i>
            </a>
            <a
              class="btn btn-outline-light btn-xs"
            >
              <i class="mdi mdi-view-grid"></i>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
