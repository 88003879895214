import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { InsuranceCompanyModel } from './types';
import { insuranceCompanyActions } from './insurance-company.actions';

export interface IInsuranceCompanyState extends IState<InsuranceCompanyModel> {
  getById: GenericState<InsuranceCompanyModel>;
  createInstance: GenericState<InsuranceCompanyModel>;
  add: GenericState<InsuranceCompanyModel>;
  update: GenericState<InsuranceCompanyModel>;
}

export const insuranceCompanyInitialState: IInsuranceCompanyState = {
  getById: new GenericState<InsuranceCompanyModel>(),
  createInstance: new GenericState<InsuranceCompanyModel>(),
  add: new GenericState<InsuranceCompanyModel>(),
  update: new GenericState<InsuranceCompanyModel>(),
};

const insuranceCompanyReducers = [
  ...onApiCall<InsuranceCompanyModel>(
    insuranceCompanyActions.getById,
    'getById',
  ),
  ...onApiCall<InsuranceCompanyModel>(
    insuranceCompanyActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<InsuranceCompanyModel>(insuranceCompanyActions.add, 'add'),
  ...onApiCall<InsuranceCompanyModel>(insuranceCompanyActions.update, 'update'),
];

export const insuranceCompanyReducer = createReducer(
  insuranceCompanyInitialState,
  ...insuranceCompanyReducers,
) as ActionReducer<IInsuranceCompanyState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return insuranceCompanyReducer(state, action);
}
