import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ActivityApiService } from './activity.api.service';
import { ActivityEffects } from './activity.effects';
import {
  IActivityState,
  activityInitialState,
  activityReducer,
} from './activity.reducer';
import { ActivityService } from './activity.service';
import { activityFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IActivityState>(
      activityFeatureKey,
      activityReducer,
      {
        initialState: activityInitialState,
      },
    ),
    EffectsModule.forFeature([ActivityEffects]),
  ],
  providers: [ActivityApiService, ActivityService],
})
export class StoreActivityModule {}
