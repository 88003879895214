<div class="modal-header">
  <h5 class="modal-title">
    <span translate>QUOTES_LBL_QUOTES</span>
    <span
      class="fs-4 lead-quote-count"
      *ngIf="this.quoteResponseModels$ | async as quoteResponseModels"
    >
      - {{ quoteResponseModels.length }}
    </span>
    <span
      class="fs-4 lead-quote-count"
      translate
      *ngIf="this.quoteResponseModels$ | async as quoteResponseModels"
      >QUOTES_LBL_QUOTES_LOWER</span
    >
  </h5>
</div>
<div class="modal-body" [formGroup]="this.quoteForm">
  <app-loader
    [apiCall]="this.apiCall"
    [loader]="true"
    [loaderId]="'quotesLoader'"
    style="min-height: 500px"
  >
    <table
      class="table table-striped table-hover text-center"
      formArrayName="quotes"
    >
      <thead>
        <tr class="sticky-top">
          <th></th>
          <th translate>QUOTES_HEADER_COMPANY</th>
          <th translate>QUOTES_HEADER_PRODUCT</th>
          <th translate>QUOTES_HEADER_ROUTINE_CARE</th>
          <th translate>QUOTES_HEADER_HOSPITAL_CARE</th>
          <th translate>QUOTES_HEADER_DENTAL_CARE</th>
          <th translate>QUOTES_HEADER_OPTICAL_CARE</th>
          <th translate>QUOTES_HEADER_MONTHLY_COST</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let quoteResponseModel of this.quoteResponseModels$ | async;
            index as i
          "
          [formGroupName]="i"
          [ngClass]="{
            selected: this.formArray$ | async | quoteArrayChecked : i
          }"
          (click)="addProduct(quoteResponseModel.id)"
        >
          <td class="align-middle">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [formControlName]="'checked'"
              />
            </div>
          </td>
          <td>
            <img
              class="lead-quote-img"
              src="assets/images/partners/logo/{{
                quoteResponseModel.insuranceCompanyName | lowercase
              }}.webp"
              alt="Insurance Company logo"
            />
          </td>
          <td class="align-middle">
            <div class="lead-quotes-product-line">
              {{ quoteResponseModel.productLineName }}
            </div>
            <div class="lead-quotes-product-product">
              {{ quoteResponseModel.productName }}
            </div>
          </td>
          <td class="align-middle">
            <ngb-rating [rate]="quoteResponseModel.routineCareCoverageLevel">
              <ng-template let-fill="fill" let-index="index">
                <i
                  class="fs-4 mdi mdi-star{{ fill !== 100 ? '-outline' : '' }}"
                  [class.filled]="fill === 100"
                ></i>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="align-middle">
            <ngb-rating
              [rate]="quoteResponseModel.hospitalizationCoverageLevel"
            >
              <ng-template let-fill="fill" let-index="index">
                <i
                  class="fs-4 mdi mdi-star{{ fill !== 100 ? '-outline' : '' }}"
                  [class.filled]="fill === 100"
                ></i>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="align-middle">
            <ngb-rating [rate]="quoteResponseModel.dentalCoverageLevel">
              <ng-template let-fill="fill" let-index="index">
                <i
                  class="fs-4 mdi mdi-star{{ fill !== 100 ? '-outline' : '' }}"
                  [class.filled]="fill === 100"
                ></i>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="align-middle">
            <ngb-rating [rate]="quoteResponseModel.opticalCoverageLevel">
              <ng-template let-fill="fill" let-index="index">
                <i
                  class="fs-4 mdi mdi-star{{ fill !== 100 ? '-outline' : '' }}"
                  [class.filled]="fill === 100"
                ></i>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="align-middle text-nowrap text-primary">
            <span class="h3"
              >{{ quoteResponseModel.monthlyCost | wholeNumber }},</span
            >
            <span class="h5"
              >{{ quoteResponseModel.monthlyCost | decimalNumber
              }}<sup>€</sup></span
            >
            <span translate>QUOTES_LBL_PER_MONTH</span>
          </td>
        </tr>
      </tbody>
    </table>
  </app-loader>
</div>
<div class="modal-footer">
  <!-- <div class="me-auto">
    <span>{{ this.formArray$ | async | selectedQuoteCount }}</span>
    <span translate> QUOTES_LBL_QUOTES_SELECTED</span>
  </div> -->
  <button
    [class]="ButtonType.Light | buttontypepipe"
    class="btn-sm btn"
    (click)="closeModal()"
    translate
  >
    GLOBAL_BTN_CANCEL
  </button>
  <button
    [class]="ButtonType.Primary | buttontypepipe"
    class="btn-sm btn"
    type="submit"
    (click)="submit()"
    translate
  >
    <span translate> GLOBAL_BTN_ADD </span>
    <ng-container *ngIf="this.formArray$ | async as formArray">
      <span *ngIf="(formArray | selectedQuoteCount) != 0">
        {{ formArray | selectedQuoteCount }}
      </span>
      <span *ngIf="(formArray | selectedQuoteCount) == 1" translate
        >QUOTES_BTN_OFFER
      </span>
      <span
        translate
        *ngIf="
          (formArray | selectedQuoteCount) != 0 &&
          (formArray | selectedQuoteCount) != 1
        "
        >QUOTES_BTN_OFFERS
      </span>
    </ng-container>
  </button>
</div>

<!-- <div class="d-flex flex-column h-100" formArrayName="quotes">
    <ng-container *ngFor="let quoteResponseModel of quoteResponseModels; index as i" [formGroupName]="i">
      <div class="row mb-4 border border-light rounded">
        <div class="col-auto">
          <input type="checkbox" [formControlName]="'checked'" />
        </div>

        <div class="col-2">
          <div class="row result-logo">
            
            <div class="mt-3 mx-auto text-center" style="font-weight: 700; opacity: 0.5">
              
            </div>
          </div>
        </div>
        <div class="result-rating col-2">
          
          <div class="result-rating-item">
            <div class="rating-item-name">Hospitalisation</div>
            <div class="rating-points-wrapper">
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary':
                      quoteResponseModel.hospitalizationCoverageLevel > 0
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary':
                      quoteResponseModel.hospitalizationCoverageLevel > 1
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary':
                      quoteResponseModel.hospitalizationCoverageLevel > 2
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary':
                      quoteResponseModel.hospitalizationCoverageLevel > 3
                  }"></div>
            </div>
          </div>
          <div class="result-rating-item">
            <div class="rating-item-name"></div>
            <div class="rating-item-name">Dentaire</div>
            <div class="rating-points-wrapper">
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary': quoteResponseModel.dentalCoverageLevel > 0
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary': quoteResponseModel.dentalCoverageLevel > 1
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary': quoteResponseModel.dentalCoverageLevel > 2
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary': quoteResponseModel.dentalCoverageLevel > 3
                  }"></div>
            </div>
          </div>
          <div class="result-rating-item">
            <div class="rating-item-name">Optique</div>
            <div class="rating-points-wrapper">
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary': quoteResponseModel.opticalCoverageLevel > 0
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary': quoteResponseModel.opticalCoverageLevel > 1
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary': quoteResponseModel.opticalCoverageLevel > 2
                  }"></div>
              <div class="rating-point rounded" [ngClass]="{
                    'bg-primary': quoteResponseModel.opticalCoverageLevel > 3
                  }"></div>
            </div>
          </div>
        </div>

        <div class="result-price flex-2 col-4">
          <div class="result-price-wrapper">
            <span class="h1">{{ quoteResponseModel.monthlyCost | wholeNumber }},</span>
            <span class="h4">{{ quoteResponseModel.monthlyCost | decimalNumber
              }}<sup>€</sup></span>
            <span>/ mois</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div> -->
