import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { BankAccountAction, bankAccountActions } from './bank-account.actions';
import { BankAccountApiService } from './bank-account.api.services';
import { titleActions } from '../title/title.action';

@Injectable()
export class BankAccountEffects {
  constructor(
    private readonly bankAccountApiService: BankAccountApiService,
    private readonly actions$: Actions<BankAccountAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.actions$,
    bankAccountActions.getById,
    this.bankAccountApiService.getById,
  );

  postBankAccount = createApiCallEffects(
    this,
    this.actions$,
    bankAccountActions.postBankAccount,
    this.bankAccountApiService.postBankAccount,
  );

  updateBankAccount = createApiCallEffects(
    this,
    this.actions$,
    bankAccountActions.updateBankAccount,
    this.bankAccountApiService.updateBankAccount,
  );
}
