import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InsuranceCompanyService } from '../../../../../shared/store/insurance-company/insurance-company.service';
import { FormService } from '../../../../../shared/store/form/form.service';
import { AddressEdit } from '../../../../../shared/components/address-edit/address-edit';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../../shared/store/view/types';
import { InsuranceCompanyModel } from '../../../../../shared/store/insurance-company/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-insurance-company-edit-content',
  templateUrl: './insurance-company-form-content.component.html',
  styleUrls: ['./insurance-company-form-content.component.scss'],
})
export class InsuranceCompanyFormContentComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  insuranceCompanyModel$: Observable<InsuranceCompanyModel>;
  createinsuranceCompanyInstance$: Observable<InsuranceCompanyModel>;

  insuranceCompanyForm: FormGroup;

  isEditMode: boolean = false;
  insuranceCompanyId: string;

  insuranceCompanyModel: InsuranceCompanyModel;

  constructor(
    private readonly insuranceCompanyService: InsuranceCompanyService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.insuranceCompanyModel$ = this.insuranceCompanyService.getById.value$;
    this.createinsuranceCompanyInstance$ =
      this.insuranceCompanyService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.insuranceCompanyForm = this.formBuilder.group({
      contactId: [''],
      contactName: ['', Validators.required],
      contactAddress: this.formBuilder.group<AddressEdit>({
        address: [''],
        address2: [''],
        zipCode: [''],
        city: [undefined],
        countryId: [undefined],
      }),
      contactEmail: ['', [Validators.email]],
      contactPhoneNumber: [''],
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.insuranceCompanyId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.insuranceCompanyId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.insuranceCompanyService.getById.call(this.insuranceCompanyId);
    } else {
      this.insuranceCompanyService.createInstance.call();
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'insuranceCompanyCreate' || x == 'insuranceCompanyUpdate')
          this.onSubmit();
        if (x == 'insuranceCompanyList') {
          this.formService.clear();
          this.router.navigate(['/Insurance/InsuranceCompanies/']);
        }
        if (x == 'insuranceCompanyDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Insurance/InsuranceCompanies/Details',
            this.insuranceCompanyId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set(
            '@insuranceCompanyCreate',
            x.breadcrumbCreate,
          );
      });

    if (this.isEditMode) {
      this.insuranceCompanyModel$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.breadcrumbService.set('@insuranceCompanyEdit', x.contact.name);
          this.patchForm(x);
          this.insuranceCompanyModel = x;
        });
    }

    if (!this.isEditMode) {
      this.createinsuranceCompanyInstance$
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.patchForm(x);
          this.insuranceCompanyModel = x;
        });
    }
  }

  private patchForm = (x: InsuranceCompanyModel) => {
    this.insuranceCompanyForm.patchValue({
      contactId: x.contactId,
      contactName: x.contact.name,
      contactAddress: {
        address: x.contact.address,
        address2: x.contact.address2,
        zipCode: x.contact.zipCode,
        city: x.contact.city,
        countryId: x.contact.countryId,
      },
      contactPhoneNumber: x.contact.phoneNumber,
      // mobilePhoneNumber: x.mobilePhoneNumber,
      contactEmail: x.contact.email,
    });
  };

  onSubmit = () => {
    if (this.insuranceCompanyForm.invalid) {
      this.insuranceCompanyForm.markAllAsTouched();
      this.formService.setEntityErrors('CONTACT');
      this.formService.countErrors(this.insuranceCompanyForm, true);
      return;
    }

    //Initialisation de l'objet avec les valeurs reçues de contact
    let insuranceCompanyModel: InsuranceCompanyModel = {
      ...this.insuranceCompanyModel,
    };
    insuranceCompanyModel.contact = { ...this.insuranceCompanyModel.contact };

    //Ensuite on initialise les valeurs avec celles du formulaire
    insuranceCompanyModel.id = this.insuranceCompanyId
      ? +this.insuranceCompanyId
      : 0;
    insuranceCompanyModel.contactId =
      this.insuranceCompanyForm.value.contactId!;
    insuranceCompanyModel.contact.id =
      this.insuranceCompanyForm.value.contactId!;
    insuranceCompanyModel.contact.name =
      this.insuranceCompanyForm.value.contactName!;

    //Address
    insuranceCompanyModel.contact.addressType = 1;
    insuranceCompanyModel.contact.address =
      this.insuranceCompanyForm.value.contactAddress.address;
    insuranceCompanyModel.contact.address2 =
      this.insuranceCompanyForm.value.contactAddress.address2;
    insuranceCompanyModel.contact.zipCode =
      this.insuranceCompanyForm.value.contactAddress.zipCode;
    insuranceCompanyModel.contact.city =
      this.insuranceCompanyForm.value.contactAddress.city;
    insuranceCompanyModel.contact.countryId =
      this.insuranceCompanyForm.value.contactAddress.countryId;

    //Informations
    insuranceCompanyModel.contact.phoneNumber =
      this.insuranceCompanyForm.value.contactPhoneNumber;
    insuranceCompanyModel.contact.email =
      this.insuranceCompanyForm.value.contactEmail;

    let result$: Observable<InsuranceCompanyModel>;
    if (this.isEditMode)
      result$ = this.insuranceCompanyService.update.call(insuranceCompanyModel);
    else result$ = this.insuranceCompanyService.add.call(insuranceCompanyModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();

        this.router.navigate([
          '/Insurance/InsuranceCompanies/Details',
          result.id,
        ]);
      });
  };

  ngOnDestroy(): void {
    this.insuranceCompanyService.add.reset();
    this.insuranceCompanyService.update.reset();

    if (this.isEditMode) {
      this.insuranceCompanyService.getById.reset();
    } else {
      this.insuranceCompanyService.createInstance.reset();
    }

    this.breadcrumbService.set('@insuranceCompanyEdit', ' ');
    this.breadcrumbService.set('@insuranceCompanyCreate', ' ');
  }
}
