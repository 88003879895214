import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { EnumModel, View, ViewType } from './types';
import { createAction, props } from '@ngrx/store';

export const viewActions = {
  getViews: createApiCallActions<View>('view', 'getViews'),
  getEnumValues: createApiCallActions<EnumModel[]>('view', 'getEnumValues'),
  setViewType: createAction('[view] Set page', props<{ viewType: ViewType }>()),
};

export type ViewAction = typeof viewActions;
