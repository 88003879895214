import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity-edit',
  templateUrl: './activity-edit.component.html',
  styleUrls: ['./activity-edit.component.scss'],
})
export class ActivityEditComponent implements OnInit {
  activityId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.activityId = this.route.snapshot.params['id'];
  }
}
