<div class="card-list p-3" *ngIf="relatedContactsDetails$ | async as relatedContactDetails">
  <div class="card-col" *ngFor="let item of relatedContactDetails">
    <div class="card data-card h-100">
      <div class="card-body">
        <div class="card-left pe-2">
          <ng-container *ngIf="item['isEmployee'] && (this.currentAvatar$ | async) as avatar">
            <img
              src="{{ this.avatar.url }}?name={{ item['name'] }}&bgColor={{
                  this.avatar.bgColor
                }}&color=FFFFFF"
              class="card-img"
            />
          </ng-container>
          <img *ngIf="!item['isEmployee'] && item['isCompany']"
               class="card-img"
               src="../../../../assets/images/avatar_company.svg"
          />
          <img *ngIf="!item['isEmployee'] && !item['isCompany']"
               class="card-img"
               src="../../../../assets/images/user.svg"
          />
        </div>
        <div class="card-content">
          <h5 class="card-title" *ngIf="item['name']">{{ item['parentName'] }}, {{ item['name'] }} </h5>
          <!--            <h5 class="card-title" *ngIf="!item['name']"> {{ item['id'] }} </h5>-->
          <span class="card-subtitle text-muted mb-1" *ngIf="item['city'] && item['zipCode']">
                  <i class="mdi mdi-map-marker me-1"></i>
            {{ item['zipCode'] }} {{ item['city'] }}
                </span>
          <span class="card-subtitle text-muted mb-1" *ngIf="item['email']">
                  <i class="mdi mdi-email me-1"></i>
            {{ item['email'] }}
                </span>
        </div>
      </div>
      <a (click)="onCardClick(item['id'].toString())" class="stretched-link"></a>
    </div>
  </div>
</div>
