import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { AccountingAction, accountingActions } from './accounting.actions';
import { AccountingApiService } from './accounting.api.service';
import { Store } from '@ngrx/store';
import { IAccountingState } from './accounting.reducer';
import { accountingSelectors } from './accounting.selector';

@Injectable()
export class AccountingEffects {
  constructor(
    private readonly store: Store<IAccountingState>,
    private readonly accountingApiService: AccountingApiService,
    private readonly action$: Actions<AccountingAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getById,
    this.accountingApiService.getById,
    () => {},
    () => {
      return this.store.select(accountingSelectors.getById.paramsSelector);
    },
  );

  getByContactId = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getByContactId,
    this.accountingApiService.getByContactId,
  );
  getByJournalId = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getByJournalId,
    this.accountingApiService.getByJournaltId
  );

  getUnbindedByContactId = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getUnbindedByContactId,
    this.accountingApiService.getUnbindedByContactId,
  );

  getUnbindedByContractId = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getUnbindedByContractId,
    this.accountingApiService.getUnbindedByContractId,
  );

  getUnbindedRepayment = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getUnbindedRepayment,
    this.accountingApiService.getUnbindedRepayment,
  );
  getUnbindedSlip = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getUnbindedSlip,
    this.accountingApiService.getUnbindedSlip,
  );

  createInstance = createApiCallEffects(
    this,
    this.action$,
    accountingActions.createInstance,
    this.accountingApiService.createInstance,
  );

  add = createApiCallEffects(
    this,
    this.action$,
    accountingActions.add,
    this.accountingApiService.add,
  );
  getPeriods = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getPeriods,
    this.accountingApiService.getPeriods,
  );

  bind = createApiCallEffects(
    this,
    this.action$,
    accountingActions.bind,
    this.accountingApiService.bind,
  );

  importSlip = createApiCallEffects(
    this,
    this.action$,
    accountingActions.importSlip,
    this.accountingApiService.importSlip,
  );
  changeAccountMoveStatus = createApiCallEffects(
    this,
    this.action$,
    accountingActions.changeAccountMoveStatus,
    this.accountingApiService.changeAccountMoveStatus,
  );
  uploadProof = createApiCallEffects(
    this,
    this.action$,
    accountingActions.uploadProof,
    this.accountingApiService.uploadProof,
  );
  getAccountMovePdf = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getAccountMovePdf,
    this.accountingApiService.getAccountMovePdf
  );
  delete = createApiCallEffects(
    this,
    this.action$,
    accountingActions.delete,
    this.accountingApiService.delete
  );
  unbind = createApiCallEffects(
    this,
    this.action$,
    accountingActions.unbind,
    this.accountingApiService.unbind
  );
  getBindedAccountMoveByAccountMoveId = createApiCallEffects(
    this,
    this.action$,
    accountingActions.getBindedAccountMoveByAccountMoveId,
    this.accountingApiService.getBindedAccountMoveByAccountMoveId,
  );
}
