<form
  [formGroup]="leadLostReasonform"
  (ngSubmit)="onSubmit()"
  [hasUnsavedData]="leadLostReasonform"
>
  <button type="submit" hidden="hidden"></button>

  <div class="row">
    <div class="col-lg-12 col-12">
      <div class="form-group row">
        <label class="col col-form-label-sm" translate>
          LEAD_LBL_LEADLOSTREASON
        </label>
        <app-typeahead
          [routeEndpoint]="'LeadLostReasons'"
          [controlForm]="this.leadLostReasonform.get('lostReason')"
          [limitToList]="true"
          [orderBy]="'name asc'"
        ></app-typeahead>
      </div>

      <div class="form-group row">
        <div class="col">
          <textarea
            formControlName="lostSummary"
            [placeholder]="'LEAD_PLACEHOLDER_LEADLOSTSUMMARY' | translate"
            class="form-control form-control-sm height-size"
          >
          </textarea>
      </div>
      </div>
    </div>
  </div>
</form>
