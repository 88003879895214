import { createApiCallActions } from '../../../common/store/generic.actions';
import { ActivityTypeModel } from './types';

export const activityTypeActions = {
  getById: createApiCallActions<ActivityTypeModel>(
    'activityType',
    'getById',
  ),
  createInstance: createApiCallActions<ActivityTypeModel>(
    'activityType',
    'createInstance',
  ),
  add: createApiCallActions<ActivityTypeModel>('activityType', 'add'),
  update: createApiCallActions<ActivityTypeModel>(
    'activityType',
    'update',
  ),
};

export type ActivityTypeAction = typeof activityTypeActions;
