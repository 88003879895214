import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Avatar } from '../../../../shared/store/user/type';
import { ContactModel } from '../../../../shared/store/contact/types';
import { ContactService } from '../../../../shared/store/contact/contact.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-kanban-view-contacts',
  templateUrl: './kanban-view-contacts.component.html',
  styleUrls: ['./kanban-view-contacts.component.scss'],
})
export class KanbanViewContactsComponent implements OnInit {
  currentAvatar$: Observable<Avatar>;
  relatedContactsDetails$: Observable<ContactModel[]>;

  constructor(
    private readonly router: Router,
    private readonly contactService: ContactService,
  ) {
    this.relatedContactsDetails$ = this.contactService.getChildren.value$;
  }

  ngOnInit(): void {
    let avatar = new Avatar(
      '',
      [
        environment.comparanooapiurl,
        environment.features.thumbnail.url,
        environment.features.thumbnail.avatar,
      ].join(''),
      window
        .getComputedStyle(document.body)
        .getPropertyValue('--comment-avatar-bg')
        .replace('#', ''),
    );

    this.currentAvatar$ = of(avatar);
  }

  onCardClick(id: string): void {
    console.log(id);
    this.router.navigate([
      this.router.url
        .substring(0, this.router.url.lastIndexOf('/'))
        .replace('Edit', 'Details'),
      id,
    ]);
  }
}
