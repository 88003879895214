import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { DocumentTypeModel, documentTypeFeatureKey } from './types';
import { IDocumentTypeState } from './document-type.reducer';
import { ListItem } from '../typeahead/types';
import { IProductCategoryState } from '../product-category/product-category.reducer';

const documentTypeFeatureState = createFeatureSelector<IDocumentTypeState>(
  documentTypeFeatureKey,
);

export const documentTypeSelectors = {
  getById: createApiCallSelectors<DocumentTypeModel, IDocumentTypeState>(
    documentTypeFeatureState,
    'getById',
  ),
  getListItem: createApiCallSelectors<ListItem[], IDocumentTypeState>(
    documentTypeFeatureState,
    'getListItem',
  ),
  createInstance: createApiCallSelectors<DocumentTypeModel, IDocumentTypeState>(
    documentTypeFeatureState,
    'createInstance',
  ),
  add: createApiCallSelectors<DocumentTypeModel, IDocumentTypeState>(
    documentTypeFeatureState,
    'add',
  ),
  update: createApiCallSelectors<DocumentTypeModel, IDocumentTypeState>(
    documentTypeFeatureState,
    'update',
  ),
  getDocumentTypes: createSelector(
    documentTypeFeatureState,
    (state: IDocumentTypeState) => state.documentTypes,
  ),
};
