import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActivityCreateComponent } from './activity-create/activity-create.component';
import { ActivityDetailsComponent } from './activity-details/activity-details.component';
import { ActivityEditComponent } from './activity-edit/activity-edit.component';
import { ActivityRoutingModule } from './activity-routing.module';
import { ActivityFormContentComponent } from './activity-form-content/activity-form-content.component';

@NgModule({
  declarations: [
    ActivityDetailsComponent,
    ActivityCreateComponent,
    ActivityEditComponent,
    ActivityFormContentComponent,
  ],
  imports: [CommonModule, SharedModule, ActivityRoutingModule],
})
export class ActivityModule {}
