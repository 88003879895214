import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { GlossaryCreateComponent } from './glossary-create/glossary-create.component';
import { GlossaryDetailComponent } from './glossary-detail/glossary-detail.component';
import { GlossaryEditComponent } from './glossary-edit/glossary-edit.component';
import { GlossaryRoutingModule } from './glossary-routing.module';

@NgModule({
  declarations: [
    GlossaryCreateComponent,
    GlossaryDetailComponent,
    GlossaryEditComponent,
  ],
  imports: [CommonModule, SharedModule, GlossaryRoutingModule],
})
export class GlossaryModule {}
