export const navigationFeatureKey = 'navigation';

export type Module = {
  name: string;
  description: string;
  sequence: string;
  visible: boolean;
  icon: string;
  route: string;
};

export class Menu {
  id: number;
  name: string;
  moduleId: string;
  sequence: number;
  parentId?: number;
  startsGroup: boolean;
  route?: string;
  icon?: string;
  children?: Menu[];
  displayName: string;
}

export enum ModuleId {
  cms = 1,
  contacts = 2,
  crm = 3,
  documents = 4,
  insurance = 5,
  products = 6,
  configuration = 7,
  finance,
}
