<div
  class="tab-pane fade flex-even active show"
  id="contacts"
  role="tabpanel"
  aria-labelledby="contacts-tab"
  *ngIf="this.productLineDetail$ | async as productLineDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row">
      <div class="col">
        <div class="form-group row">
          <div class="col-auto">
            {{ productLineDetail.notes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
