<div class="connexion-container bg-white shadow-lg">
  <div class="row align-items-end">
    <div class="col-auto">
      <div class="company-img">
        <img class="img-fluid"
             src="../../../assets/images/logo-login.png"
             alt="Groupe Gesco Assurances"
        />
      </div>
    </div>
    <div class="col">
      <h2 class="mb-4" translate>LOGIN_LBL_PASSWORD_FORGOT</h2>
    </div>
  </div>
<!--<h2 class="mb-4" translate>LOGIN_LBL_PASSWORD_FORGOT</h2>-->
<input type="hidden" name="returnUrl" value="@ViewBag.ReturnUrl"/>
<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
  <div
    *ngIf="this.showErrorLogin"
    class="alert {{this.css}}"
    id="validationSummary"
  >
    <ul>
      <li>{{ this.msgError }}</li>
    </ul>
  </div>
  <div class="form-group mb-4">
    <label for="email" class="form-label" translate>LOGIN_LBL_PASSWORD_FORGOT_EMAIL</label>
    <input
      id="email"
      type="text"
      formControlName="email"
      class="input-validation-error form-control form-control-md"
      required
      autocomplete="off"
      [placeholder]="'LOGIN_PLACEHOLDER_PASSWORD_FORGOT_EMAIL' | translate"
      checkFormFieldValidity
    />
  </div>
  <div class="text-right">
    <a
      href="/"
      class="btn-outline-primary btn me-2"
      translate
    >
      GLOBAL_BTN_CANCEL
    </a>
    <button type="submit" class="btn btn-primary mr-2" translate>
      LOGIN_BTN_PASSWORD_FORGOT_SAVE
    </button>
  </div>
</form>
</div>
