import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { ProductCoverageModel } from './types';
import { productCoverageActions } from './product-coverage.actions';

export interface IProductCoverageState extends IState<ProductCoverageModel> {
  getById: GenericState<ProductCoverageModel>;
  getByProductId: GenericState<ProductCoverageModel[]>;
}

export const productCoverageInitialState: IProductCoverageState = {
  getById: new GenericState<ProductCoverageModel>(),
  getByProductId: new GenericState<ProductCoverageModel[]>(),
};

const productCoverageReducers = [
  ...onApiCall<ProductCoverageModel>(productCoverageActions.getById, 'getById'),
  ...onApiCall<ProductCoverageModel[]>(
    productCoverageActions.getByProductId,
    'getByProductId'
  ),
];

export const productCoverageReducer = createReducer(
  productCoverageInitialState,
  ...productCoverageReducers
) as ActionReducer<IProductCoverageState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return productCoverageReducer(state, action);
}
