import { InsuranceCoverageModel } from '../insurance-coverage/types';

export const productCategoryFeatureKey = 'productCategory';

export class ProductCategoryModel {
  id: number;
  name: string;
  description: string;
  productCategoryEnumKey: number;
  productCategoryType: number;
  productCategoryTypeName: string;
  sequence: number;
  icon: string;
  productCategoryDocumentTypes: ProductCategoryDocumentType[];
  insuranceCoverages: InsuranceCoverageModel[];
}

export type ProductCategoryDocumentType = {
  id: number;
  productCategoryId: number;
  productCategoryName: string;
  documentTypeId: number;
  documentTypeName: string;
  documentTypeDescription: string;
  documentFlow: DocumentFlow;
  mandatory: boolean;
};

export type DocumentFlow = 'ToSend' | 'ToReceive';
