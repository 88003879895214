export const paymentFeatureKey = 'payment';

export type PaymentModel = {
  id: number;
  contactId: number;
  contactName: string;
  contractId: number;
  userId: number;
  userContactName: string;
  name: string;
  description: string;
  amount: number;
  payementMode: number;
  recievedDate: Date;
  creationDate: Date;
};
