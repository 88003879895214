import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { TerminationReasonModel, terminationReasonFeatureKey } from './types';
import { ITerminationReasonState } from './termination-reason.reducer';

const terminationReasonFeatureState =
  createFeatureSelector<ITerminationReasonState>(terminationReasonFeatureKey);

export const terminationReasonSelectors = {
  getById: createApiCallSelectors<
    TerminationReasonModel,
    ITerminationReasonState
  >(terminationReasonFeatureState, 'getById'),

  createInstance: createApiCallSelectors<
    TerminationReasonModel,
    ITerminationReasonState
  >(terminationReasonFeatureState, 'createInstance'),

  add: createApiCallSelectors<TerminationReasonModel, ITerminationReasonState>(
    terminationReasonFeatureState,
    'add',
  ),

  update: createApiCallSelectors<
    TerminationReasonModel,
    ITerminationReasonState
  >(terminationReasonFeatureState, 'update'),
};
