import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TerminationReasonCreateComponent } from './termination-reason-create/termination-reason-create.component';
import { TerminationReasonDetailsComponent } from './termination-reason-details/termination-reason-details.component';
import { TerminationReasonEditComponent } from './termination-reason-edit/termination-reason-edit.component';
import { TerminationReasonRoutingModule } from './termination-reason-routing.module';

@NgModule({
  declarations: [
    TerminationReasonDetailsComponent,
    TerminationReasonCreateComponent,
    TerminationReasonEditComponent,
  ],
  imports: [CommonModule, SharedModule, TerminationReasonRoutingModule],
})
export class TerminationReasonModule {}
