import { createApiCallActions } from '../../../common/store/generic.actions';
import { DocumentModel } from './types';

export const documentActions = {
  getById: createApiCallActions<DocumentModel>('document', 'getById'),
  getByEntityId: createApiCallActions<DocumentModel[]>(
    'document',
    'getByEntityId',
  ),
  getFile: createApiCallActions<any>('document', 'getFile'),
  add: createApiCallActions<DocumentModel>('document', 'add'),
  update: createApiCallActions<DocumentModel>('document', 'update'),
};

export type DocumentAction = typeof documentActions;
