import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  BaseLeadFormModel,
  CarInsuranceLeadModel,
  CarLeadFormModel,
  HealthInsuranceLeadModel,
  HealthLeadFormModel,
  HouseInsuranceLeadModel,
  HouseLeadFormModel,
  InsuranceCategory,
  LeadDetailModel,
  LeadLostModel,
  LeadStatus,
  LeadStatusFlow,
  PushLeadModel,
} from './types';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';
import { Action } from '../view/types';

@Injectable({
  providedIn: 'root',
})
export class LeadApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}
  getByUniqueId = (id: string): Observable<LeadDetailModel> => {
    let parameters: string[];
    parameters = ['uniqueid', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<LeadDetailModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  getHealthDetail = (id: string): Observable<HealthInsuranceLeadModel> => {
    let parameters: string[];
    parameters = ['HealthDetail', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<HealthInsuranceLeadModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  getCarDetail = (id: string): Observable<CarInsuranceLeadModel> => {
    let parameters: string[];
    parameters = ['CarDetail', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CarInsuranceLeadModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  getHouseDetail = (id: string): Observable<HouseInsuranceLeadModel> => {
    let parameters: string[];
    parameters = ['HouseDetail', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<HouseInsuranceLeadModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  sendLead = (pushLeadModel: PushLeadModel): any => {
    let parameters: string[];
    parameters = ['send-lead'];
    let url = this.formatUrl(parameters);
    return this.httpClient.post<any>(url, pushLeadModel).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  updateLeadDetail = (baseLeadFormModel: BaseLeadFormModel): any => {
    let parameters: string[];
    parameters = ['LeadDetail', baseLeadFormModel.leadFormModel.id!.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<BaseLeadFormModel>(url, baseLeadFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  updateHealthDetail = (healthLeadFormModel: HealthLeadFormModel): any => {
    let parameters: string[];
    parameters = [
      'HealthDetail',
      healthLeadFormModel.baseLeadFormModel.leadFormModel.id!.toString(),
    ];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<HealthLeadFormModel>(url, healthLeadFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  updateCarDetail = (carLeadFormModel: CarLeadFormModel): any => {
    let parameters: string[];
    parameters = [
      'CarDetail',
      carLeadFormModel.baseLeadFormModel.leadFormModel.id!.toString(),
    ];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<CarLeadFormModel>(url, carLeadFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  updateHouseDetail = (houseLeadFormModel: HouseLeadFormModel): any => {
    let parameters: string[];
    parameters = [
      'HouseDetail',
      houseLeadFormModel.baseLeadFormModel.leadFormModel.id!.toString(),
    ];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<HouseLeadFormModel>(url, houseLeadFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  createLead = (leadCreate: BaseLeadFormModel): any => {
    let parameters: string[];
    parameters = ['Lead'];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<any>(url, leadCreate, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  createHealthLead = (leadCreate: HealthLeadFormModel): any => {
    let parameters: string[];
    parameters = ['HealthLead'];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<any>(url, leadCreate, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  createCarLead = (leadCreate: CarLeadFormModel): any => {
    let parameters: string[];
    parameters = ['CarLead'];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<any>(url, leadCreate, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  createHouseLead = (leadCreate: HouseLeadFormModel): any => {
    let parameters: string[];
    parameters = ['HouseLead'];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<any>(url, leadCreate, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  getBaseLeadModel = (
    category: InsuranceCategory,
  ): Observable<BaseLeadFormModel> => {
    let parameters: string[];
    parameters = ['LeadModel'];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('insuranceCategory', category);

    return this.httpClient
      .get<BaseLeadFormModel>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  getHealthLeadModel = (): Observable<HealthLeadFormModel> => {
    let parameters: string[];
    parameters = ['HealthModel'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<HealthLeadFormModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  getCarLeadModel = (): Observable<CarLeadFormModel> => {
    let parameters: string[];
    parameters = ['CarModel'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CarLeadFormModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  getHouseLeadModel = (): Observable<HouseLeadFormModel> => {
    let parameters: string[];
    parameters = ['HouseModel'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<HouseLeadFormModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getStatusFlow = (statusFlow: LeadStatus): Observable<LeadStatusFlow[]> => {
    let parameters: string[];
    parameters = ['statusflow', statusFlow.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<LeadStatusFlow[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getStatusActions = (statusFlow: LeadStatus): Observable<Action[]> => {
    let parameters: string[];
    parameters = ['statusactions', statusFlow.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Action[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  qualify = (leadId: number): Observable<LeadDetailModel> => {
    let parameters: string[];
    parameters = ['qualify', leadId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<LeadDetailModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  markAsWon = (leadId: number): Observable<LeadDetailModel> => {
    let parameters: string[];
    parameters = ['markaswon', leadId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<LeadDetailModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  markAsLost = (leadLostModel: LeadLostModel): Observable<LeadDetailModel> => {
    let parameters: string[];
    parameters = ['markaslost', leadLostModel.leadId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.post<LeadDetailModel>(url, leadLostModel).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  createContractRequest = (
    leadLostModel: LeadLostModel,
  ): Observable<LeadDetailModel> => {
    let parameters: string[];
    parameters = ['createcontractrequest', leadLostModel.leadId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.post<LeadDetailModel>(url, leadLostModel).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  reactivate = (leadId: number): Observable<LeadDetailModel> => {
    let parameters: string[];
    parameters = ['reactivate', leadId.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<LeadDetailModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.leads.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
