import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterToUppercase',
})
export class FirstLetterToUppercasePipe implements PipeTransform {
  transform(sentence: string): string {
    if (!sentence) return sentence;
    return sentence[0].toUpperCase() + sentence.substring(1).toLowerCase();
  }
}
