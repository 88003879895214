import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { BankAccountModel } from 'src/app/shared/store/bank-account/types';
import { BankAccountService } from 'src/app/shared/store/bank-account/bank-account.service';

@UntilDestroy()
@Component({
  selector: 'app-bank-account-details',
  templateUrl: './bank-account-details.component.html',
  styleUrls: ['./bank-account-details.component.scss'],
})
export class BankAccountDetailsComponent implements OnInit, OnDestroy {
  bankAccountId: number;
  bankAccount$: Observable<BankAccountModel>;
  view$: Observable<View>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly bankAccountService: BankAccountService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.bankAccount$ = this.bankAccountService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.bankAccountId = this.route.snapshot.params['id'];
    this.bankAccountService.getById.call(this.bankAccountId.toString());

    this.bankAccount$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@bankAccountDetails', x.iban);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'bankAccountEdit') {
          this.router.navigate([
            'Contacts/BankAccounts/Edit/',
            this.bankAccountId,
          ]);
          this.subHeaderService.setTriggerAction('');
        }
      });
  }

  ngOnDestroy(): void {
    this.bankAccountService.getById.reset();
    this.breadcrumbService.set('@bankAccountDetails', ' ');
  }
}
