import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LostReasonService } from 'src/app/shared/store/lost-reason/lost-reason.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-lost-reason-create',
  templateUrl: './lost-reason-create.component.html',
  styleUrls: ['./lost-reason-create.component.scss'],
})
export class LostReasonCreateComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  lostReasonForm: FormGroup;
  constructor(
    private readonly lostReasonService: LostReasonService,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'lostReasonCreate') this.onSubmit();
        if (x == 'lostReasonList') {
          this.formService.clear();
          this.router.navigate(['Crm/LostReasons/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@lostReasonCreate', x.breadcrumbCreate);
      });

    this.lostReasonForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      active: [true],
      needSummary: [false],
    });
  }

  onSubmit() {
    if (this.lostReasonForm.invalid) {
      this.lostReasonForm.markAllAsTouched();
      this.formService.countErrors(this.lostReasonForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.lostReasonService.add.call({
      id: 0,
      name: this.lostReasonForm.value.name!,
      active: this.lostReasonForm.value.active!,
      needSummary: this.lostReasonForm.value.needSummary!,
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.router.navigate(['/Crm/LostReasons/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.lostReasonService.add.reset();
  }
}
