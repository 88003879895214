<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="leadDetail$ | async as leadDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row mb-4">
      <div class="col text-center">
        <table
          class="table table-hover"
          *ngIf="leadDetail.quotes.length != 0; else elseBlock"
        >
          <thead>
            <tr class="sticky-top">
              <th></th>
              <th>Produit</th>
              <th>Prime mensuelle</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let quote of leadDetail.quotes">
              <td>
                <img
                  class="lead-quote-img"
                  src="assets/images/partners/logo/{{
                    quote.productProductLineInsuranceCompanyContactName
                      | lowercase
                  }}.webp"
                  alt="Insurance Company logo"
                />
              </td>
              <td class="align-middle">
                {{ quote.productProductLineName }} -
                {{ quote.productName }}
              </td>
              <td class="align-middle">
                <span class="h1">{{ quote.monthlyCost | wholeNumber }},</span>
                <span class="h4"
                  >{{ quote.monthlyCost | decimalNumber }}<sup>€</sup></span
                >
                <span>/ mois</span>
              </td>
              <td class="align-middle">
                <button
                  disabled
                  class="btn btn-lg btn-primary"
                  (click)="subscribe(leadDetail.id, quote.productId)"
                >
                  Souscrire
                </button>
                <a
                  class="btn btn-lg btn-outline-primary my-3"
                  disabled
                  target="_blank"
                  >Afficher les détails</a
                >
                <button
                  disabled
                  type="button"
                  class="btn btn-outline-primary send-email"
                >
                  Devis par email
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ng-template #elseBlock>
          <span translate>QUOTES_MSG_NO_QUOTES</span>
        </ng-template>
        <!-- <ng-container *ngFor="let quote of leadDetail.quotes; index as i">
          <div class="row mb-4 border border-light rounded">
            <div class="col-2">
              <div class="row result-logo">
                <img
                  class="m-auto d-block"
                  src="assets/images/partners/logo/{{
                    quote.productProductLineInsuranceCompanyContactName
                      | lowercase
                  }}.webp"
                  alt="Insurance Company logo"
                />
                <div class="mt-3 mx-auto text-center">
                  {{ quote.productProductLineName }} -
                  {{ quote.productName }}
                </div>
              </div>
            </div>
            <div class="result-rating col-2">
              <div class="result-rating-item">
                <div class="rating-item-name">Soins médicaux</div>
                <div class="rating-points-wrapper">
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                </div>
              </div>
              <div class="result-rating-item">
                <div class="rating-item-name">Hospitalisation</div>
                <div class="rating-points-wrapper">
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                </div>
              </div>
              <div class="result-rating-item">
                <div class="rating-item-name"></div>
                <div class="rating-item-name">Dentaire</div>
                <div class="rating-points-wrapper">
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                </div>
              </div>
              <div class="result-rating-item">
                <div class="rating-item-name">Optique</div>
                <div class="rating-points-wrapper">
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                  <div class="rating-point rounded"></div>
                </div>
              </div>
            </div>

            <div class="result-price flex-2 col-4">
              <div class="result-price-wrapper">
                <span class="h1">{{ quote.monthlyCost | wholeNumber }},</span>
                <span class="h4"
                  >{{ quote.monthlyCost | decimalNumber }}<sup>€</sup></span
                >
                <span>/ mois</span>
              </div>
            </div>

            <div class="result-actions col-4">
              <button
                class="btn btn-lg btn-primary"
                (click)="subscribe(leadDetail.id, quote.productId)"
              >
                Souscrire
              </button>
              <a class="btn btn-lg btn-outline-primary my-3" target="_blank"
                >Afficher les détails</a
              >
              <button type="button" class="btn btn-outline-primary send-email">
                Devis par email
              </button>
            </div>
          </div>
        </ng-container> -->
        <button
          style="width: fit-content"
          class="btn btn-primary btn-sm"
          type="button"
          (click)="addQuote(leadDetail.uniqueId, leadDetail.id)"
        >
          Ajouter une proposition
        </button>
      </div>
    </div>
  </div>
</div>
