import { IContactState } from './contact.reducer';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { createFeatureSelector } from '@ngrx/store';
import { contactFeatureKey, ContactModel } from './types';

const contactFeatureState =
  createFeatureSelector<IContactState>(contactFeatureKey);

export const contactSelectors = {
  getById: createApiCallSelectors<number, IContactState>(
    contactFeatureState,
    'getById',
  ),
  postContact: createApiCallSelectors<ContactModel, IContactState>(
    contactFeatureState,
    'postContact',
  ),
  updateContact: createApiCallSelectors<ContactModel, IContactState>(
    contactFeatureState,
    'updateContact',
  ),
  createInstance: createApiCallSelectors<void, IContactState>(
    contactFeatureState,
    'createInstance',
  ),
  getChildren: createApiCallSelectors<number, IContactState>(
    contactFeatureState,
    'getChildren',
  ),
};
