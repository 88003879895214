<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.terminationReasonModel$ | async as terminationReason">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row mb-2">
                <div class="col-12 col-md-9 mr-auto">
                  <div class="form-group">
                    <label class="form-title-label" translate>TERMINATIONREASON_LBL_NAME</label>
                    <div class="form-title">{{ terminationReason.name }}</div>
                  </div>
                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-12 col-md">-->
<!--                  <div class="form-group row">-->
<!--                    <label class="col-4 col-form-label-sm" translate-->
<!--                    >TERMINATIONREASON_LBL_ACTIVE</label-->
<!--                    >-->
<!--                    <div class="col">-->
<!--                      <span class="form-control-plaintext form-control-sm">-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          name="active"-->
<!--                          [(ngModel)]="terminationReason.active"-->
<!--                          onclick="return false;"-->
<!--                          disabled-->
<!--                        />-->
<!--                        &lt;!&ndash;   onkeydown="e = e || window.event; if(e.keyCode !== 9) return false;"  &ndash;&gt;-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="form-group row">-->
<!--                    <label class="col-4 col-form-label-sm" translate-->
<!--                    >TERMINATIONREASON_LBL_NEEDSUMMARY</label-->
<!--                    >-->
<!--                    <div class="col">-->
<!--                      <span class="form-control-plaintext form-control-sm">-->
<!--                        <input-->
<!--                          type="checkbox"-->
<!--                          name="active"-->
<!--                          [(ngModel)]="terminationReason.needSummary"-->
<!--                          onclick="return false;"-->
<!--                          disabled-->
<!--                        />-->
<!--                        &lt;!&ndash;   onkeydown="e = e || window.event; if(e.keyCode !== 9) return false;"  &ndash;&gt;-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.terminationReasonModel$ | async as terminationReason">
      <app-comment-panel [entityType]="'TerminationReason'"
                         [entityId]="terminationReason.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
