import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { View } from '../../../../shared/store/view/types';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { take } from 'rxjs/operators';
import { ProductLineService } from 'src/app/shared/store/product-line/product-line.service';
import { ProductLineModel } from 'src/app/shared/store/product-line/types';

@UntilDestroy()
@Component({
  selector: 'app-product-line-edit',
  templateUrl: './product-line-edit.component.html',
  styleUrls: ['./product-line-edit.component.scss'],
})
export class ProductLineEditComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  productLineForm: FormGroup;
  productLine$: Observable<ProductLineModel>;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly productLineService: ProductLineService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.productLine$ = this.productLineService.getById.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'productLineUpdate') this.onSubmit();
        if (x == 'productLineDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Insurance/ProductLines/Details',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.productLineService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.productLineForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      productCategoryId: [undefined, [Validators.required]],
      insuranceCompanyId: [undefined, [Validators.required]],
      commissionRate: [''],
    });

    this.productLine$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@productLineEdit', x.name);
        this.productLineForm.patchValue({
          name: x.name,
          productCategoryId: x.productCategoryId,
          insuranceCompanyId: x.insuranceCompanyId,
          commissionRate: x.commissionRate,
        });
      });
  }

  onSubmit() {
    if (this.productLineForm.invalid) {
      this.productLineForm.markAllAsTouched();
      this.formService.countErrors(this.productLineForm, true);
      return;
    }

    let productLineModel: ProductLineModel = {
      id: this.route.snapshot.params['id'],
      name: this.productLineForm.value.name!,
      productCategoryId: this.productLineForm.value.productCategoryId!,
      productCategoryName: '',
      insuranceCompanyId: this.productLineForm.value.insuranceCompanyId!,
      commissionRate: this.productLineForm.value.commissionRate!,
      insuranceCompanyContactName: '',
      products: [],
      productCategoryInsuranceDocuments: [],
      notes: '',
    };

    let result$ = this.productLineService.update.call(productLineModel);
    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate(['/Insurance/ProductLines/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.productLineService.update.reset();
    this.breadcrumbService.set('@productLineEdit', ' ');
  }
}
