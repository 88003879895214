import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactsRoutingModule} from './contacts-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {ContactModule} from './contact/contact.module';
import {ZipCodeModule} from './zipcode/zipcode.module';
import {CountyModule} from './county/county.module';
import {CountryModule} from './country/country.module';
import {TitleModule} from './title/title.module';
import {BankModule} from './bank/bank.module';
import {BankAccountModule} from './bank-account/bank-account.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, 
    SharedModule, 
    ContactsRoutingModule, 
    ContactModule,
    ZipCodeModule,
    CountyModule,
    CountryModule,
    TitleModule,
    BankModule, 
    BankAccountModule],

  exports: [],
})
export class ContactsModule {}
