import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { InsuranceCompanyCreateComponent } from './insurance-company-create/insurance-company-create.component';
import { InsuranceCompanyDetailsContentComponent } from './insurance-company-details/insurance-company-details-content/insurance-company-details-content.component';
import { InsuranceCompanyDetailsNotesComponent } from './insurance-company-details/insurance-company-details-tab/insurance-company-details-notes/insurance-company-details-notes.component';
import { InsuranceCompanyDetailsProductLinesComponent } from './insurance-company-details/insurance-company-details-tab/insurance-company-details-product-lines/insurance-company-details-product-lines.component';
import { InsuranceCompanyDetailsComponent } from './insurance-company-details/insurance-company-details.component';
import { InsuranceCompanyTabsDetailsComponent } from './insurance-company-details/insurance-company-tabs-details/insurance-company-tabs-details.component';
import { InsuranceCompanyEditComponent } from './insurance-company-edit/insurance-company-edit.component';
import { InsuranceCompanyRoutingModule } from './insurance-company-routing.module';
import { InsuranceCompanyFormContentComponent } from './shared/insurance-company-form-content/insurance-company-form-content.component';
import { InsuranceCompanyDetailsPaymentComponent } from './insurance-company-details/insurance-company-details-tab/insurance-company-details-payment/insurance-company-details-payment.component';

@NgModule({
  declarations: [
    InsuranceCompanyDetailsComponent,
    InsuranceCompanyDetailsContentComponent,
    InsuranceCompanyTabsDetailsComponent,
    InsuranceCompanyDetailsNotesComponent,
    InsuranceCompanyDetailsProductLinesComponent,
    InsuranceCompanyCreateComponent,
    InsuranceCompanyEditComponent,
    InsuranceCompanyFormContentComponent,
    InsuranceCompanyDetailsPaymentComponent,
  ],
  imports: [CommonModule, InsuranceCompanyRoutingModule, SharedModule],
})
export class InsuranceCompanyModule {}
