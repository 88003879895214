import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'wholeNumber',
})
export class WholeNumberPipe implements PipeTransform {
  transform(value: number): number {
    return Math.trunc(value);
  }
}
