import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFormState } from './form.reducer';
import { ErrorsNestedFormGroup, formFeatureKey } from './types';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';

const formFeatureState = createFeatureSelector<IFormState>(formFeatureKey);

export const formSelectors = {
  getIsDirty: createSelector(
    formFeatureState,
    (state: IFormState) => state.isDirty,
  ),
  getErrors: createSelector(
    formFeatureState,
    (state: IFormState) => state.countErrors,
  ),
  getTotalCountErrors: createSelector(
    formFeatureState,
    (state: IFormState) => state.countErrors.root.countTotal,
  ),
  getErrorsEntity: createSelector(
    formFeatureState,
    (state: IFormState) => state.entityType,
  ),
};
