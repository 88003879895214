import { SalesTeamMember } from '../sales-team-member/types';

export const salesTeamFeatureKey = 'salesTeam';

export type SalesTeamModel = {
  id: number;
  name: string;
  code: string;
  notes: string;
  active: boolean;
  teamLeaderId: number | null;
  teamLeaderContactName: string;
  parentId: number | null;
  parentName: string;
  salesTeamMembers: SalesTeamMember[];
};
