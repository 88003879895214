import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TitleApiService } from './title.api.service';
import { TitleEffects } from './title.effects';
import { ITitleState, titleReducer, titleInitialState } from './title.reducer';
import { TitleService } from './title.service';
import { titleFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<ITitleState>(titleFeatureKey, titleReducer, {
      initialState: titleInitialState,
    }),
    EffectsModule.forFeature([TitleEffects]),
  ],
  providers: [TitleApiService, TitleService],
})
export class StoreTitleModule {}
