import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { filter, Observable, of } from 'rxjs';
import { DropdownStyle } from '../../store/typeahead/types';
import { Entity } from '../../store/view/types';
import {
  InputCodeAndCityZipCode,
  ZipCodeModel,
} from '../../store/zipcode/types';
import { ZipCodeApiService } from '../../store/zipcode/zipcode.api.services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss'],
})
export class AddressEditComponent implements OnInit {
  @Input() form: AbstractControl;
  @Input() title: string = 'ADDRESS_LBL_ADDRESS';

  addressForm: FormGroup;

  zipCodeModel$: Observable<ZipCodeModel>;

  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;

  constructor(private readonly zipCodeApiService: ZipCodeApiService) {}

  ngOnInit(): void {
    this.addressForm = this.form as FormGroup;

    this.addressForm.controls['city']?.valueChanges.subscribe((city) => {
      if (city) {
        this.zipCodeModel$ = this.zipCodeApiService.getByCodeAndCity(<
          InputCodeAndCityZipCode
        >{
          code: this.addressForm.controls['zipCode']?.value,
          city: city,
        });
        this.zipCodeModel$
          .pipe(
            filter((x) => !!x),
            untilDestroyed(this),
          )
          .subscribe((x) => {
            this.addressForm.patchValue({
              countryId: x.countryId,
            });
          });
      }
      this.addressForm.patchValue({
        countryId: undefined,
      });
    });
  }
}
