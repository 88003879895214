import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivityTypeService } from 'src/app/shared/store/activity-type/activity-type.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-activity-type-create',
  templateUrl: './activity-type-create.component.html',
  styleUrls: ['./activity-type-create.component.scss'],
})
export class ActivityTypeCreateComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  activityTypeForm: FormGroup;
  constructor(
    private readonly activityTypeService: ActivityTypeService,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'activityTypeCreate') this.onSubmit();
        if (x == 'activityTypeList') {
          this.formService.clear();
          this.router.navigate(['Crm/ActivityTypes/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@activityTypeCreate', x.breadcrumbCreate);
      });

    this.activityTypeForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(80)]],
      description: ['', [Validators.maxLength(255)]],
      delay: [0, [Validators.required]],
      delayUnit: [0, [Validators.required]],
      delayFrom: [0, [Validators.required]],
      entityType: ['', [Validators.maxLength(50)]],
      nextActivityPolicy: [0, [Validators.required]],
      nextActivityTypeId: [undefined],
      defaultUserId: [undefined],
    });
  }

  onSubmit() {
    if (this.activityTypeForm.invalid) {
      this.activityTypeForm.markAllAsTouched();
      this.formService.countErrors(this.activityTypeForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.activityTypeService.add.call({
      id: 0,
      name: this.activityTypeForm.value.name!,
      description: this.activityTypeForm.value.description!,
      delay: this.activityTypeForm.value.delay!,
      delayUnit: this.activityTypeForm.value.delayUnit!,
      delayFrom: this.activityTypeForm.value.delayFrom!,
      entityType: this.activityTypeForm.value.entityType!,
      nextActivityPolicy: this.activityTypeForm.value.nextActivityPolicy!,
      nextActivityTypeId: this.activityTypeForm.value.nextActivityTypeId!,
      defaultUserId: this.activityTypeForm.value.defaultUserId!,
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.router.navigate(['/Crm/ActivityTypes/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.activityTypeService.add.reset();
  }
}
