import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ArticleModule } from './article/article.module';
import { GlossaryModule } from './glossary/glossary.module';
import { CmsRoutingModule } from './cms-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    CmsRoutingModule,
    ArticleModule,
    GlossaryModule,
  ],
  exports: [],
})
export class CmsModule {}
