import { createReducer, ActionReducer, Action } from "@ngrx/store";
import { onApiCall } from "src/app/common/store/generic.reducer";
import { IState, GenericState } from "src/app/common/store/types";
import { bankActions } from "./bank.action";
import { BankModel } from "./types";

export interface IBankState extends IState<BankModel> {
    getById: GenericState<BankModel>;
    postBank: GenericState<BankModel>;
    updateBank: GenericState<BankModel>;
}

export const bankInitialState: IBankState = {
    getById: new GenericState<BankModel>(),
    postBank: new GenericState<BankModel>(),
    updateBank: new GenericState<BankModel>(),
};

const bankReducers = [
    ...onApiCall<BankModel>(bankActions.getById, 'getById'),
    ...onApiCall<BankModel>(bankActions.postBank, 'postBank'),
    ...onApiCall<BankModel>(bankActions.updateBank, 'updateBank'),
];

export const bankReducer = createReducer(
    bankInitialState,
    ...bankReducers
) as ActionReducer<IBankState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
 return bankReducer(state, action);
}