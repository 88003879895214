import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { ZipCodeAction, zipCodeActions } from './zipcode.actions';
import { ZipCodeApiService } from './zipcode.api.services';
import { IZipCodeState } from './zipcode.reducer';

@Injectable()
export class ZipCodeEffects {
  constructor(
    private readonly store: Store<IZipCodeState>,
    private readonly zipCodeApiService: ZipCodeApiService,
    private readonly actions$: Actions<ZipCodeAction>,
  ) {}

  get = createApiCallEffects(
    this,
    this.actions$,
    zipCodeActions.get,
    this.zipCodeApiService.get,
  );

  getById = createApiCallEffects(
    this,
    this.actions$,
    zipCodeActions.getById,
    this.zipCodeApiService.getById,
  );

  getByCodeAndCity = createApiCallEffects(
    this,
    this.actions$,
    zipCodeActions.getByCodeAndCity,
    this.zipCodeApiService.getByCodeAndCity,
  );
}
