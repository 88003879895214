import { ProductListItemModel } from '../product/types';
import { InsuranceDocumentListItemModel } from '../../models/insurance-document-model';

export const productLineFeatureKey = 'productLine';

export type ProductLineModel = {
  id: number;
  name: string;
  productCategoryId: number;
  productCategoryName: string;
  insuranceCompanyId: number;
  insuranceCompanyContactName: string;
  commissionRate: number;
  products: ProductListItemModel[];
  productCategoryInsuranceDocuments: InsuranceDocumentListItemModel[];
  notes: string;
};

export type ProductLineListItemModel = {
  id: number;
  name: string;
  productCategoryName: string;
  insuranceCompanyName: string;
  productsCount: number;
};
