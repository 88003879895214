import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';
import { AuthentificationGuard } from 'src/app/shared/guard/authentification-guard';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ModuleId } from '../../../shared/store/navigation/types';
import { HeaderMenuType } from '../../../shared/store/view/types';
import { CountryCreateComponent } from './country-create/country-create.component';
import { CountryDetailsComponent } from './country-details/country-details.component';
import { CountryEditComponent } from './country-edit/country-edit.component';

const routes: Routes = [
  {
    path: 'Contacts/Countries',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          moduleId: ModuleId.contacts,
          entity: Entity.Country,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: CountryDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          moduleId: ModuleId.contacts,
          entity: Entity.Country,
          breadcrumb: { label: ' ', alias: 'countryDetails' },
        },
      },
      {
        path: 'Create',
        component: CountryCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          moduleId: ModuleId.contacts,
          entity: Entity.Country,
          breadcrumb: { label: ' ', alias: 'countryCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: CountryEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          moduleId: ModuleId.contacts,
          entity: Entity.Country,
          breadcrumb: { label: ' ', alias: 'countryEdit' },
        },
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CountryRoutingModule {}
