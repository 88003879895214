import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { PaymentApiService } from "./payment.api.service";
import { PaymentEffects } from "./payment.effects";
import { IPaymentState, paymentInitialState, paymentReducer } from "./payment.reducer";
import { PaymentService } from "./payment.service";
import { paymentFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IPaymentState>(paymentFeatureKey, paymentReducer,{
            initialState: paymentInitialState,
        }),
        EffectsModule.forFeature([PaymentEffects])
    ],
    providers:[PaymentApiService, PaymentService]
})
export class StorePaymentModule{}
