import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InsuranceCompanyApiService } from './insurance-company.api.service';
import { InsuranceCompanyEffects } from './insurance-company.effects';
import {
  IInsuranceCompanyState,
  insuranceCompanyInitialState,
  insuranceCompanyReducer,
} from './insurance-company.reducer';
import { InsuranceCompanyService } from './insurance-company.service';
import { insuranceCompanyFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IInsuranceCompanyState>(
      insuranceCompanyFeatureKey,
      insuranceCompanyReducer,
      {
        initialState: insuranceCompanyInitialState,
      }
    ),
    EffectsModule.forFeature([InsuranceCompanyEffects]),
  ],
  providers: [InsuranceCompanyApiService, InsuranceCompanyService],
})
export class StoreInsuranceCompanyModule {}
