<form
  [formGroup]="assignForm"
  (ngSubmit)="onSubmit()"
  [hasUnsavedData]="assignForm"
>
  <button type="submit" hidden="hidden"></button>
  <div class="row mb-2" *ngIf="accountMoves$ | async as accountMoves">
    <div class="col-6 col-md">
      <label class="form-title-label" translate>ACCOUNTMOVE_LBL_CLIENT</label>

      <table
        class="table table-striped table-hover"
        formArrayName="clientAccountMoves"
      >
        <tbody>
        <ng-container *ngFor="let accountMove of accountMoves; index as i; trackBy: trackByAccountMoveId">
          <ng-container *ngIf="accountMove.moveType === 'CustomerInvoice'">
            <tr
              *ngFor="let accountMoveLine of accountMove.accountMoveLines; trackBy: trackByAccountMoveLineId"
              [formGroupName]="i"
              (click)="addClientAccountMove(accountMove.id)"
            >
              <td class="align-middle">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [formControlName]="'checked'"
                  />
                </div>
              </td>
              <td>
                {{ accountMove.name }}
              </td>
              <td>
                {{ accountMoveLine.unitPrice | currency : "EUR" }}
              </td>
              <td>
                {{ accountMove.periodStartDate | period: accountMove.periodEndDate }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
        </tbody>
      </table>
    </div>

    <div class="col">
      <label class="form-title-label" translate
      >ACCOUNTMOVE_LBL_REPAYMENT</label
      >

      <table class="table table-striped table-hover" formArrayName="repayments">
        <tbody>
        <ng-container *ngFor="let accountMove of accountMoves; index as i; trackBy: trackByAccountMoveId">
          <ng-container *ngIf="accountMove.moveType === 'VendorInvoice'">
            <tr
              *ngFor="let accountMoveLine of accountMove.accountMoveLines; trackBy: trackByAccountMoveLineId"
              [formGroupName]="i"
              (click)="addRepayment(accountMove.id)"
            >
              <td class="align-middle">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [formControlName]="'checked'"
                  />
                </div>
              </td>
              <td>
                {{ accountMove.name }}
              </td>
              <td>
                {{ accountMoveLine.unitPrice | currency : "EUR" }}
              </td>
              <td>
                {{ accountMove.periodStartDate | period: accountMove.periodEndDate }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</form>
<!-- <div>
    <div class="row">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >ACCOUNTMOVE_LBL_TOTAL_PAYMENT</label
          >
          <div class="col-auto">
              <span class="form-control-plaintext form-control-sm">
                {{ this.paymentAmount  | currency:'EUR' }}
              </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >ACCOUNTMOVE_LBL_TOTAL_INVOICE</label
          >
          <div class="col-auto">
              <span class="form-control-plaintext form-control-sm">
                {{ this.invoiceAmount | currency:'EUR' }}
              </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >ACCOUNTMOVE_LBL_BALANCE</label
          >
          <div class="col">
              <span class="form-control-plaintext form-control-sm">
               {{ this.paymentAmount - this.invoiceAmount  | currency:'EUR' }}
              </span>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div> -->
