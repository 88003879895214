import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { InvoiceLineModel } from './types';
import { invoiceLineActions } from './invoice-line.actions';

export interface IInvoiceLineState extends IState<InvoiceLineModel> {
  getById: GenericState<InvoiceLineModel>;
  updateById: GenericState<InvoiceLineModel>;
}

export const invoiceLineInitialState: IInvoiceLineState = {
  getById: new GenericState<InvoiceLineModel>(),
  updateById: new GenericState<InvoiceLineModel>(),
};

const invoiceLineReducers = [
  ...onApiCall<InvoiceLineModel>(invoiceLineActions.getById, 'getById'),
  ...onApiCall<InvoiceLineModel>(invoiceLineActions.updateById, 'updateById'),
];

export const invoiceLineReducer = createReducer(
  invoiceLineInitialState,
  ...invoiceLineReducers
) as ActionReducer<IInvoiceLineState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return invoiceLineReducer(state, action);
}
