<ng-container *ngIf="tabsContent$ | async as tabsContent">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="active"
    class="nav-tabs"
    [destroyOnHide]="false"
    (activeIdChange)="onActiveIdChangeSaveCurrent($event)"
  >
    <ng-container *ngIf="contactDetails$ | async as contactDetail">
      <li [ngbNavItem]="0" *ngIf="contactDetail.isCompany">
        <button ngbNavLink class="nav-link" translate>
          CONTACT_TABS_CONTACTS
        </button>
        <ng-template ngbNavContent>
          <app-contact-details-children />
        </ng-template>
      </li>
    </ng-container>

    <li [ngbNavItem]="tabContent.index"
        *ngFor="let tabContent of tabsContent; count as count; last as isLast; trackBy: trackByTabsContent">
      <button ngbNavLink class="nav-link" translate>
        {{ tabContent.title }}
      </button>
      <ng-template ngbNavContent>
        <ng-container *ngComponentOutlet="tabContent.component"/>
      </ng-template>
    </li>


    <li [ngbNavItem]="tabsContent.length + 1">
      <button ngbNavLink class="nav-link" translate>
        CONTACT_TABS_PAYMENTS
      </button>
      <ng-template ngbNavContent>
        <app-contact-details-payment />
      </ng-template>
    </li>

    <li [ngbNavItem]="tabsContent.length + 2">
      <button ngbNavLink class="nav-link" translate>
        CONTACT_TABS_MARKETING
      </button>
      <ng-template ngbNavContent>
        <app-contact-details-marketing/>
      </ng-template>
    </li>


    <ng-container *ngIf="contactDetails$ | async as contactDetail">
      <li [ngbNavItem]="3" *ngIf="contactDetail.notes && contactDetail.notes.length > 0">
        <button ngbNavLink class="nav-link" translate>
          CONTACT_TABS_NOTES
        </button>
        <ng-template ngbNavContent>
          <app-contact-details-notes />
        </ng-template>
      </li>
    </ng-container>


  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</ng-container>
