import { createApiCallActions } from '../../../common/store/generic.actions';
import { InsuranceCompanyModel } from './types';

export const insuranceCompanyActions = {
  getById: createApiCallActions<InsuranceCompanyModel>(
    'insuranceCompany',
    'getById',
  ),
  createInstance: createApiCallActions<InsuranceCompanyModel>(
    'insuranceCompany',
    'createInstance',
  ),
  add: createApiCallActions<InsuranceCompanyModel>('insuranceCompany', 'add'),
  update: createApiCallActions<InsuranceCompanyModel>(
    'insuranceCompany',
    'update',
  ),
};

export type InsuranceCompanyAction = typeof insuranceCompanyActions;
