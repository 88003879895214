import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { InvoiceLineModel, invoiceLineFeatureKey } from './types';
import { IInvoiceLineState } from './invoice-line.reducer';

const invoiceLineFeatureState = createFeatureSelector<IInvoiceLineState>(
  invoiceLineFeatureKey
);

export const invoiceLineSelectors = {
  getById: createApiCallSelectors<number, IInvoiceLineState>(
    invoiceLineFeatureState,
    'getById'
  ),
  updateById: createApiCallSelectors<number, IInvoiceLineState>(
    invoiceLineFeatureState,
    'updateById'
  ),
};
