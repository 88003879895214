<ng-container *ngIf="tabsContent$ | async as tabsContent">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="active"
    class="nav-tabs"
    [destroyOnHide]="false"
    (activeIdChange)="onActiveIdChangeSaveCurrent($event)"
  >
    <li [ngbNavItem]="1">
      <button ngbNavLink class="nav-link" translate>
        CONTRACT_TABS_GENERAL_INFORMATIONS
      </button>
      <ng-template ngbNavContent>
        <app-contract-details-general-informations />
      </ng-template>
    </li>
    <li
      [ngbNavItem]="tabContent.index"
      *ngFor="
        let tabContent of tabsContent;
        count as count;
        last as isLast;
        trackBy: trackByTabsContent
      "
    >
      <button ngbNavLink class="nav-link" translate>
        {{ tabContent.title }}
      </button>
      <ng-template ngbNavContent>
        <ng-container *ngComponentOutlet="tabContent.component" />
      </ng-template>
    </li>

    <li [ngbNavItem]="tabsContent.length + 2">
      <button ngbNavLink class="nav-link" translate>
        CONTRACT_TABS_ACCOUNTMOVE_CLIENT
      </button>
      <ng-template ngbNavContent>
        <app-contract-details-account-moves
          [accountMoves]="contractBase.clientAccountMoves"
          [contractId]="contractBase.id"
          [isClient]="true"
        />
      </ng-template>
    </li>

    <li [ngbNavItem]="tabsContent.length + 3">
      <button ngbNavLink class="nav-link" translate>
        CONTRACT_TABS_ACCOUNTMOVE_COMPANY
      </button>
      <ng-template ngbNavContent>
        <app-contract-details-account-moves
          [accountMoves]="contractBase.companyAccountMoves"
          [contractId]="contractBase.id"
          [isClient]="false"
        />
      </ng-template>
    </li>

    <li [ngbNavItem]="tabsContent.length + 4">
      <button ngbNavLink class="nav-link" translate>
        CONTRACT_TABS_PAYMENT_SPLITTING
      </button>
      <ng-template ngbNavContent>
        <app-contract-details-payment-splitting />
      </ng-template>
    </li>

    <li [ngbNavItem]="tabsContent.length + 5">
      <button ngbNavLink class="nav-link" translate>
        CONTRACT_TABS_COMMISSIONS
      </button>
      <ng-template ngbNavContent>
        <app-contract-details-commission />
      </ng-template>
    </li>

    <li [ngbNavItem]="tabsContent.length + 6">
      <button ngbNavLink class="nav-link" translate>
        CONTRACT_TABS_MARKETING
      </button>
      <ng-template ngbNavContent>
        <app-contract-details-marketing />
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</ng-container>
