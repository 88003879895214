import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  DataType,
  DropdownStyle,
} from '../../../../../../shared/store/typeahead/types';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { filter, Observable, take } from 'rxjs';
import { HealthInsuranceContractDetailModel } from '../../../../../../shared/store/contract/types';
import {
  InsuredType,
  RegimeEnum,
} from '../../../../../../shared/store/lead/types';
import { formatDate } from '../../../../../../shared/helper/datehelper';
import {
  removeValidators,
  setValidators,
} from 'src/app/shared/helper/formhelper';
import { debugLog } from '../../../../../../shared/pipe/rxjs/operators';
import { ContactModel } from '../../../../../../shared/store/contact/types';
import { ContactService } from '../../../../../../shared/store/contact/contact.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-contract-form-health',
  templateUrl: './contract-form-health.component.html',
  styleUrls: ['./contract-form-health.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormHealthComponent implements OnInit {
  form: FormGroup;
  hasPartner: boolean;
  dropdownStyle = DropdownStyle.DropDown;
  protected readonly DataType = DataType;

  healthDetail$: Observable<HealthInsuranceContractDetailModel[]>;
  healthDetailModel$: Observable<HealthInsuranceContractDetailModel[]>;
  contact$: Observable<ContactModel>;

  constructor(
    private readonly contractService: ContractService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
    private readonly contactService: ContactService,
  ) {
    this.healthDetail$ = this.contractService.getHealthDetail.value$;
    this.healthDetailModel$ =
      this.contractService.getHealthContractModel.value$;
    this.contact$ = this.contactService.getById.value$;
  }

  ngOnInit(): void {
    this.hasPartner = false;

    this.form = this.parent.form;
    this.form.addControl(
      'healthFormGroup',
      this.formBuilder.group({
        subscriberId: [+''],
        subscriberLastName: [''],
        subscriberFirstName: [''],
        subscriberBirthDate: ['', [Validators.required]],
        subscriberRegime: [undefined, [Validators.required]],
        subscriberGender: [undefined, [Validators.required]],
        subscriberSocialSecurityNumber: [
          '',
          [
            Validators.required,
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        subcriberCodeCpam: [
          '',
          [Validators.minLength(9), Validators.maxLength(9)],
        ],
        partners: this.formBuilder.array([]),
        children: this.formBuilder.array([]),
      }),
    );

    this.healthDetailModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        //patch lead
        this.form.patchValue({
          healthFormGroup: {
            subscriberId: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].id,
            subscriberLastName: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personLastName,
            subscriberFirstName: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personFirstName,
            subscriberBirthDate: formatDate(
              x.filter((x) => x.insuredType === InsuredType.Holder)[0]
                .personBirthDate,
              'ToInverseSqlDate',
            ),
            subscriberRegime:
              RegimeEnum[
                x.filter((x) => x.insuredType === InsuredType.Holder)[0]
                  .personSocialSecurityKey
              ],
            subscriberGender:
              x.filter((x) => x.insuredType === InsuredType.Holder)[0]
                .personGenderInt === 0
                ? undefined
                : x.filter((x) => x.insuredType === InsuredType.Holder)[0]
                    .personGenderInt,
            subscriberSocialSecurityNumber: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personSocialSecurityNumber,
            subcriberCodeCpam: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personCodeCpam,
          },
        });

        //patch partner
        const partner = x.filter((x) => x.insuredType === InsuredType.Partner);
        var dataPartner: any[] = [];
        for (let i = 0; i < partner.length; i++) {
          this.addPartner();
          dataPartner.push({
            id: partner[i].id,
            partnerLastName: partner[i].personLastName,
            partnerFirstName: partner[i].personFirstName,
            partnerBirthDate: formatDate(
              partner[i].personBirthDate,
              'ToInverseSqlDate',
            ),
            partnerRegime: RegimeEnum[partner[i].personSocialSecurityKey],
            partnerGender: undefined,
            partnerSocialSecurityNumber: partner[i].personSocialSecurityNumber,
            partnerCodeCpam: partner[i].personCodeCpam,
          });
        }
        this.partners.patchValue(dataPartner);

        //patch child
        const children = x.filter((x) => x.insuredType === InsuredType.Child);
        var dataChild: any[] = [];
        for (let i = 0; i < children.length; i++) {
          this.addChild(i);
          dataChild.push({
            id: children[i].id,
            index: i,
            childLastName: children[i].personLastName,
            childFirstName: children[i].personFirstName,
            childBirthDate: formatDate(
              children[i].personBirthDate,
              'ToInverseSqlDate',
            ),
            childRegime: RegimeEnum[children[i].personSocialSecurityKey],
            childGender: undefined,
            childSocialSecurityNumber: children[i].personSocialSecurityNumber,
            childCodeCpam: children[i].personCodeCpam,
          });
        }
        this.children.patchValue(dataChild);
      });

    this.healthDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        //patch lead
        this.form.patchValue({
          healthFormGroup: {
            subscriberId: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].id,
            subscriberLastName: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personLastName,
            subscriberFirstName: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personFirstName,
            subscriberBirthDate: formatDate(
              x.filter((x) => x.insuredType === InsuredType.Holder)[0]
                .personBirthDate,
              'ToInverseSqlDate',
            ),
            subscriberRegime:
              RegimeEnum[
                x.filter((x) => x.insuredType === InsuredType.Holder)[0]
                  .personSocialSecurityKey
              ],
            subscriberGender: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personGenderInt,
            subscriberSocialSecurityNumber: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personSocialSecurityNumber,
            subcriberCodeCpam: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personCodeCpam,
          },
        });

        //patch partner
        const partner = x.filter((x) => x.insuredType === InsuredType.Partner);
        var dataPartner: any[] = [];
        for (let i = 0; i < partner.length; i++) {
          this.addPartner();
          dataPartner.push({
            id: partner[i].id,
            partnerLastName: partner[i].personLastName,
            partnerFirstName: partner[i].personFirstName,
            partnerBirthDate: formatDate(
              partner[i].personBirthDate,
              'ToInverseSqlDate',
            ),
            partnerRegime: RegimeEnum[partner[i].personSocialSecurityKey],
            partnerGender: partner[i].personGenderInt,
            partnerSocialSecurityNumber: partner[i].personSocialSecurityNumber,
            partnerCodeCpam: partner[i].personCodeCpam,
          });
        }
        this.partners.patchValue(dataPartner);

        //patch child
        const children = x.filter((x) => x.insuredType === InsuredType.Child);
        var dataChild: any[] = [];
        for (let i = 0; i < children.length; i++) {
          this.addChild(i);
          dataChild.push({
            id: children[i].id,
            index: i,
            childLastName: children[i].personLastName,
            childFirstName: children[i].personFirstName,
            childBirthDate: formatDate(
              children[i].personBirthDate,
              'ToInverseSqlDate',
            ),
            childRegime: RegimeEnum[children[i].personSocialSecurityKey],
            childGender: children[i].personGenderInt,
            childSocialSecurityNumber: children[i].personSocialSecurityNumber,
            childCodeCpam: children[i].personCodeCpam,
          });
        }
        this.children.patchValue(dataChild);
      });

    this.contact$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.form.patchValue({
        healthFormGroup: {
          subscriberFirstName: x ? x.firstName : '',
          subscriberLastName: x ? x.lastName : '',
        },
      });
    });
  }

  get children() {
    let healthFormGroup = this.form.controls['healthFormGroup'] as FormGroup;

    return healthFormGroup.controls['children'] as FormArray;
  }

  get partners() {
    let healthFormGroup = this.form.controls['healthFormGroup'] as FormGroup;

    return healthFormGroup.controls['partners'] as FormArray;
  }

  addPartner() {
    const partnerForm = this.formBuilder.group({
      partnerId: [+''],
      partnerLastName: [''],
      partnerFirstName: [''],
      partnerBirthDate: ['', [Validators.required]],
      partnerRegime: [undefined, [Validators.required]],
      partnerGender: [undefined, [Validators.required]],
      partnerSocialSecurityNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(15),
          Validators.maxLength(15),
        ],
      ],
      partnerCodeCpam: ['', [Validators.minLength(9), Validators.maxLength(9)]],
    });
    this.partners.push(partnerForm);
    this.hasPartner = true;
  }

  addChild(index: number) {
    const childForm = this.formBuilder.group({
      childId: [+''],
      index: [index],
      childLastName: [''],
      childFirstName: [''],
      childBirthDate: ['', [Validators.required]],
      childRegime: [undefined, [Validators.required]],
      childGender: [undefined, [Validators.required]],
      childSocialSecurityNumber: [''],
      childCodeCpam: ['', [Validators.minLength(9), Validators.maxLength(9)]],
      ['childAffiliation' + index]: ['Subscriber'],
    });

    this.children.push(childForm);
  }

  removeChild(index: number) {
    this.children.removeAt(index);
  }

  removePartner() {
    this.partners.removeAt(0);
    this.hasPartner = false;
  }

  onChildAffiliationChange(isNoneChecked: boolean, index: number) {
    (
      (this.form.controls['healthFormGroup'].get('children') as FormArray).at(
        index,
      ) as FormGroup
    ).get('childSocialSecurityNumber')?.reset;
    (
      (this.form.controls['healthFormGroup'].get('children') as FormArray).at(
        index,
      ) as FormGroup
    ).get('childCodeCpam')?.reset;

    if (isNoneChecked) {
      setValidators(
        (
          (
            this.form.controls['healthFormGroup'].get('children') as FormArray
          ).at(index) as FormGroup
        ).get('childSocialSecurityNumber'),
        Validators.required,
      );
      setValidators(
        (
          (
            this.form.controls['healthFormGroup'].get('children') as FormArray
          ).at(index) as FormGroup
        ).get('childSocialSecurityNumber'),
        Validators.minLength(15),
      );
      setValidators(
        (
          (
            this.form.controls['healthFormGroup'].get('children') as FormArray
          ).at(index) as FormGroup
        ).get('childSocialSecurityNumber'),
        Validators.maxLength(15),
      );
    } else {
      removeValidators(
        (
          (
            this.form.controls['healthFormGroup'].get('children') as FormArray
          ).at(index) as FormGroup
        ).get('childSocialSecurityNumber'),
        Validators.required,
      );
      removeValidators(
        (
          (
            this.form.controls['healthFormGroup'].get('children') as FormArray
          ).at(index) as FormGroup
        ).get('childSocialSecurityNumber'),
        Validators.minLength(15),
      );
      removeValidators(
        (
          (
            this.form.controls['healthFormGroup'].get('children') as FormArray
          ).at(index) as FormGroup
        ).get('childSocialSecurityNumber'),
        Validators.maxLength(15),
      );
    }
  }
}
