import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { InsuranceCompanyModel, insuranceCompanyFeatureKey } from './types';
import { IInsuranceCompanyState } from './insurance-company.reducer';

const insuranceCompanyFeatureState =
  createFeatureSelector<IInsuranceCompanyState>(insuranceCompanyFeatureKey);

export const insuranceCompanySelectors = {
  getById: createApiCallSelectors<
    InsuranceCompanyModel,
    IInsuranceCompanyState
  >(insuranceCompanyFeatureState, 'getById'),

  createInstance: createApiCallSelectors<
    InsuranceCompanyModel,
    IInsuranceCompanyState
  >(insuranceCompanyFeatureState, 'createInstance'),

  add: createApiCallSelectors<InsuranceCompanyModel, IInsuranceCompanyState>(
    insuranceCompanyFeatureState,
    'add',
  ),

  update: createApiCallSelectors<InsuranceCompanyModel, IInsuranceCompanyState>(
    insuranceCompanyFeatureState,
    'update',
  ),
};
