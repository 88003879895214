<div [formGroup]="bankAccountForm">
  <div class="form-group row">
    <label class="col-4 col-form-label-sm" translate
    >CONTRACT_LBL_PAYMENT_SPLITTING_BANK_NAME</label
    >
    <div class="col">
      <app-typeahead
        [entity]="Entity.Bank"
        [routeEndpoint]="'Banks'"
        [dropdownStyle]="DropdownStyle.DropDownList"
        [controlForm]="this.bankAccountForm.controls['bankId']"
        [limitToList]="true"
      ></app-typeahead>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-4 col-form-label-sm" translate
    >CONTRACT_LBL_PAYMENT_SPLITTING_IBAN</label
    >
    <div class="col">
      <input
        class="form-control form-control-sm"
        type="text"
        [placeholder]="'CONTRACT_PLACEHOLDER_PAYMENT_SPLITTING_IBAN' | translate"
        formControlName="iban"
        checkFormFieldValidity
        ibanValidators
        oninput="this.value = this.value.toUpperCase()"
        [patterns]="customPatterns"
        mask="SS00 CCCC CCCC CCCC CCCC CCCC CCCC CCCC CC" [validation]="false"
      />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-4 col-form-label-sm" translate
    >CONTRACT_LBL_PAYMENT_SPLITTING_BIC</label
    >
    <div class="col">
      <input
        class="form-control form-control-sm"
        type="text"
        [placeholder]="'CONTRACT_PLACEHOLDER_PAYMENT_SPLITTING_BIC' | translate"
        formControlName="bic"
        oninput="this.value = this.value.toUpperCase()"
        checkFormFieldValidity
        [minlength]="8"
        [maxLength]="11"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-4 col-form-label-sm" translate
    >CONTRACT_LBL_PAYMENT_SPLITTING_HOLDER_NAME</label
    >
    <div class="col">
      <input
        class="form-control form-control-sm"
        type="text"
        formControlName="holderName"
        checkFormFieldValidity
      />
    </div>
  </div>
</div>
