<div class="row mb-2">
  <div class="col-6 col-md">
    <label class="form-title-label" translate>ACCOUNTMOVE_LBL_INVOICES</label>

    <table class="table table-striped table-hover">
      <tbody>
        <tr *ngFor="let accountMoveLine of invoice.accountMoveLines; trackBy: trackByAccountMoveLineId">
          <td>
            {{ invoice.name }}
          </td>
          <td>
            {{ accountMoveLine.unitPrice | currency : "EUR" }}
          </td>
          <td>{{ invoice.periodStartDate | period: invoice.periodEndDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col">
    <label class="form-title-label" translate>{{ 'ACCOUNTMOVE_LBL_INVOICES_JOURNAL' | translate : {journalName: journalName} }}</label>

    <table class="table table-striped table-hover">
      <tbody>
      <ng-container *ngIf="accountMoves$ | async as accountMoves">
      <ng-container *ngFor="let accountMove of accountMoves; trackBy: trackByAccountMoveId">
        <tr *ngFor="let accountMoveLine of accountMove.accountMoveLines; trackBy: trackByAccountMoveLineId">
          <td>
            {{ accountMove.name }}
          </td>
          <td>
            {{ accountMoveLine.unitPrice | currency : "EUR" }}
          </td>
          <td>{{ accountMove.periodStartDate | period: accountMove.periodEndDate }}</td>
        </tr>
      </ng-container>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!--<div>-->
<!--  <div class="row">-->
<!--    <div class="col-12 col-md">-->
<!--      <div class="form-group row">-->
<!--        <label class="col-4 col-form-label-sm" translate-->
<!--        >ACCOUNTMOVE_LBL_TOTAL_PAYMENT</label-->
<!--        >-->
<!--        <div class="col-auto">-->
<!--          <span class="form-control-plaintext form-control-sm">-->
<!--            {{ this.paymentAmount | currency : "EUR" }}-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col">-->
<!--      <div class="form-group row">-->
<!--        <label class="col-4 col-form-label-sm" translate-->
<!--        >ACCOUNTMOVE_LBL_TOTAL_INVOICE</label-->
<!--        >-->
<!--        <div class="col-auto">-->
<!--          <span class="form-control-plaintext form-control-sm">-->
<!--            {{ this.invoiceAmount | currency : "EUR" }}-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div class="col-12 col-md">-->
<!--      <div class="form-group row">-->
<!--        <label class="col-4 col-form-label-sm" translate-->
<!--        >ACCOUNTMOVE_LBL_BALANCE</label-->
<!--        >-->
<!--        <div class="col">-->
<!--          <span class="form-control-plaintext form-control-sm">-->
<!--            {{ this.paymentAmount - this.invoiceAmount | currency : "EUR" }}-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col"></div>-->
<!--  </div>-->
<!--</div>-->
