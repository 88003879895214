import { Injectable } from '@angular/core';
import { Data, NavigationStart, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';
import { AppState } from '../types';
import {
  routeEntity,
  routeFullscreenData,
  routeHeaderMenu,
  routeHeaderSelector,
  routeModuleId,
  routeTitleSelector,
  selectQueryParams,
  selectRouteData,
  selectRouteParams,
  selectUrl,
} from './store-router.selectors';
import { Entity, HeaderMenuType } from '../view/types';
import { SearchService } from '../search/search.service';
import { ModuleId } from '../navigation/types';

@Injectable()
export class RouterService {
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly searchService: SearchService,
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((x) => {
        const nav = router.getCurrentNavigation();
        if (!!nav) {
          const state = nav.extras.state as { useTempFilter: boolean };
          if (!!state) {
            this.searchService.setContextFilterFlag(
              state.useTempFilter || false,
            );
          }
        }
      });
  }

  title$: Observable<string | null> = this.store.pipe(
    select(routeTitleSelector),
  );

  header$: Observable<string | null> = this.store.pipe(
    select(routeHeaderSelector),
  );

  requestedUrl$: Observable<string> = this.store.pipe(select(selectUrl));
  queryParams$: Observable<Params> = this.store.pipe(select(selectQueryParams));
  routeParams$: Observable<Params> = this.store.pipe(select(selectRouteParams));
  routeData$: Observable<Data> = this.store.pipe(select(selectRouteData));
  routeHeadMenuData$: Observable<HeaderMenuType> = this.store.pipe(
    select(routeHeaderMenu),
  );
  routeModuleIdData$: Observable<ModuleId> = this.store.pipe(
    select(routeModuleId),
  );
  routeFullscreenData$: Observable<boolean> = this.store.pipe(
    select(routeFullscreenData),
  );
  routeEntity$: Observable<Entity> = this.store.pipe(select(routeEntity));
}
