import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  ProductCoverageAction,
  productCoverageActions,
} from './product-coverage.actions';
import { ProductCoverageApiService } from './product-coverage.api.service';

@Injectable()
export class ProductCoverageEffects {
  constructor(
    private readonly productCoverageApiService: ProductCoverageApiService,
    private readonly action$: Actions<ProductCoverageAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    productCoverageActions.getById,
    this.productCoverageApiService.getById
  );
  getByProductId = createApiCallEffects(
    this,
    this.action$,
    productCoverageActions.getByProductId,
    this.productCoverageApiService.getByProductId
  );
}
