import { Component, OnInit } from '@angular/core';
import { LeadService } from '../../../../../../shared/store/lead/lead.service';
import { filter, Observable } from 'rxjs';
import {
  BaseLeadFormModel,
  LeadDetailModel,
} from '../../../../../../shared/store/lead/types';
import { Entity } from '../../../../../../shared/store/view/types';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { take } from 'rxjs/operators';
import { formatDate } from '../../../../../../shared/helper/datehelper';

@Component({
  selector: 'app-lead-form-marketing',
  templateUrl: './lead-form-marketing.component.html',
  styleUrls: ['./lead-form-marketing.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LeadFormMarketingComponent implements OnInit {
  leadDetail$: Observable<LeadDetailModel>;
  baseLeadFormModel$: Observable<BaseLeadFormModel>;
  leadForm: FormGroup<any>;
  protected readonly Entity = Entity;
  constructor(
    private readonly leadService: LeadService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.leadDetail$ = this.leadService.getByUniqueId.value$;
    this.baseLeadFormModel$ = this.leadService.getBaseLeadModel.value$;

    this.leadForm = this.parent.form;
    this.leadForm.addControl(
      'marketingFormGroup',
      this.formBuilder.group({
        marketingCampaignId: [undefined, [Validators.required]],
        marketingMedium: [undefined],
        marketingSource: [undefined],
        marketingCreationDate: [''],
      }),
    );

    this.baseLeadFormModel$.pipe(filter((x) => !!x)).subscribe((x) => {
      this.leadForm.patchValue({
        marketingFormGroup: {
          marketingSource: x.leadFormModel.source,
        },
      });
    });

    this.leadDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.leadForm.patchValue({
          marketingFormGroup: {
            marketingCampaignId: x.campaignId,
            marketingMedium: x.medium === '' ? undefined : x.medium,
            marketingSource: x.source === '' ? undefined : x.source,
            marketingCreationDate: formatDate(
              x.creationDate,
              'ToInverseSqlDate',
            ),
          },
        });
      });
  }
}
