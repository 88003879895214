<div class="comment-content pt-0 h-100">
  <ng-container *ngIf="(this.commentBase$ | async) as comments">

    <div>
      <div class="row new-comment-container" [class]="{'bg-light':newComment.isVisible}">
        <div class="col">
          <button class="btn btn-sm btn-outline-primary mt-1" (click)="newComment.isVisible = !newComment.isVisible"
                  translate>COMMENT_LBL_NEW_COMMENT
          </button>
        </div>
      </div>

      <app-comment-add #newComment [entityId]="this.entityId" [entityType]="this.entityType"
                       (newComment)="refresh($event)"></app-comment-add>
    </div>

    <ng-container *ngIf="comments.length >= 1">
      <ul class="list-group list-group-flush">
        <li class="list-group-item align-items-start mt-2 px-1 py-2"
            *ngFor="let comment of comments; trackBy: trackByComment">
          <app-comment [comment]="comment" class="d-flex"></app-comment>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="comments.length == 0 && !newComment.isVisible">
      <app-comment-none></app-comment-none>
    </ng-container>

  </ng-container>
</div>
