import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';
import { AuthentificationGuard } from '../../../shared/guard/authentification-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../../shared/store/view/types';
import { EmployeeCreateComponent } from './employee-create/employee-create.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';

const routes: Routes = [
  // {
  //   path: 'HumanResources/Employees',
  //   data: {
  //     breadcrumb: { label: ' ', alias: 'list' },
  //   },
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'List',
  //       pathMatch: 'full',
  //     },
  //     {
  //       path: 'List',
  //       component: DataListingProviderComponent,
  //       canActivate: [AuthentificationGuard],
  //       canDeactivate: [HasUnsavedChangesGuard],
  //       data: {
  //         headerMenu: HeaderMenuType.HumanResources,
  //         entity: Entity.Employee,
  //         breadcrumb: { skip: true },
  //       },
  //     },
  //     {
  //       path: 'Details/:id',
  //       component: EmployeeDetailsComponent,
  //       canActivate: [AuthentificationGuard],
  //       canDeactivate: [HasUnsavedChangesGuard],
  //       data: {
  //         headerMenu: HeaderMenuType.HumanResources,
  //         entity: Entity.Employee,
  //         breadcrumb: { label: ' ', alias: 'employeeDetails' },
  //       },
  //     },
  //     {
  //       path: 'Edit/:id',
  //       component: EmployeeEditComponent,
  //       canActivate: [AuthentificationGuard],
  //       canDeactivate: [HasUnsavedChangesGuard],
  //       data: {
  //         headerMenu: HeaderMenuType.HumanResources,
  //         entity: Entity.Employee,
  //         breadcrumb: { label: ' ', alias: 'employeeEdit' },
  //       },
  //     },
  //     {
  //       path: 'Create',
  //       component: EmployeeCreateComponent,
  //       canActivate: [AuthentificationGuard],
  //       canDeactivate: [HasUnsavedChangesGuard],
  //       data: {
  //         headerMenu: HeaderMenuType.HumanResources,
  //         entity: Entity.Employee,
  //         breadcrumb: { label: ' ', alias: 'employeeCreate' },
  //       },
  //     },
  //   ],
  // },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeeRoutingModule {}
