import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { TerminationReasonService } from 'src/app/shared/store/termination-reason/termination-reason.service';
import { TerminationReasonModel } from 'src/app/shared/store/termination-reason/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-termination-reason-edit',
  templateUrl: './termination-reason-edit.component.html',
  styleUrls: ['./termination-reason-edit.component.scss'],
})
export class TerminationReasonEditComponent implements OnInit, OnDestroy {
  terminationReason$: Observable<TerminationReasonModel>;
  terminationReasonForm: FormGroup;
  view$: Observable<View>;

  constructor(
    private readonly terminationReasonService: TerminationReasonService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder
  ) {
    this.terminationReason$ = this.terminationReasonService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'terminationReasonUpdate') this.onSubmit();
        if (x == 'terminationReasonDetails') {
          this.formService.clear();
          this.router.navigate([
            'Crm/TerminationReasons/Details/',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.terminationReasonService.getById.call(
      this.route.snapshot.params['id']
    );

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.terminationReasonForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      active: [false],
      needSummary: [false],
    });

    this.terminationReason$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@terminationReasonEdit', x.name);
        this.terminationReasonForm.patchValue({
          name: x.name,
          active: x.active,
          needSummary: x.needSummary,
        });
      });
  }

  onSubmit(): void {
    if (this.terminationReasonForm.invalid) {
      this.terminationReasonForm.markAllAsTouched();
      this.formService.countErrors(this.terminationReasonForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.terminationReasonService.update.call({
      id: this.route.snapshot.params['id'],
      name: this.terminationReasonForm.value.name!,
      active: this.terminationReasonForm.value.active!,
      needSummary: this.terminationReasonForm.value.needSummary!,
    });
    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.router.navigate(['/Crm/TerminationReasons/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.terminationReasonService.update.reset();
    this.breadcrumbService.set('@terminationReasonEdit', ' ');
  }
}
