<ng-container *ngIf="this.documentTypeListItem$ | async as documentTypes">
  <form [formGroup]="this.form">
  <table
    class="table table-striped table-hover"
    formArrayName="documentTypes"
  >
    <tbody>
    <tr
      *ngFor="let documentType of documentTypes; index as i"
      [formGroupName]="i"
      (click)="addDocumentType(documentType.id)"
    >
      <td class="align-middle">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="'checked'"
          />
        </div>
      </td>
      <td>
        {{documentType.name }}
      </td>
    </tr>
    </tbody>
  </table>
  </form>
</ng-container>
