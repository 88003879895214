import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Editor, Toolbar } from 'ngx-editor';
import { GlossaryService } from 'src/app/shared/store/glossary/glossary.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-glossary-create',
  templateUrl: './glossary-create.component.html',
  styleUrls: ['./glossary-create.component.scss'],
})
export class GlossaryCreateComponent implements OnInit, OnDestroy {
  editor: Editor;
  toolbar: Toolbar;
  view$: Observable<View>;

  constructor(
    private readonly glossaryService: GlossaryService,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'glossaryCreate') this.onSubmit();
        if (x == 'glossaryList') {
          this.formService.clear();
          this.router.navigate(['Cms/Glossaries/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.editor = new Editor();
    this.toolbar = [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      ['link', 'image'],
    ];

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@glossaryCreate', x.breadcrumbCreate);
      });
  }

  glossaryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required]),
    pageContent: new FormControl('', [Validators.required]),
    meta: new FormControl(''),
  });

  onSubmit(): void {
    if (this.glossaryForm.invalid) {
      this.glossaryForm.markAllAsTouched();
      this.formService.countErrors(this.glossaryForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.glossaryService.postGlossary.call({
      name: this.glossaryForm.value.name!,
      url: this.glossaryForm.value.url!,
      pageContent: this.glossaryForm.value.pageContent!,
      meta: this.glossaryForm.value.meta!,
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.router.navigate(['/Cms/Glossaries/Details', result.page.id]);
      });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    this.glossaryService.postGlossary.reset();
  }
}
