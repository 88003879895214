import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ZipCodeDetailsComponent } from './zipcode-details/zipcode-details.component';
import { ZipCodeRoutingModule } from './zipcode-routing.module';

@NgModule({
  declarations: [ZipCodeDetailsComponent],
  imports: [CommonModule, SharedModule, ZipCodeRoutingModule],
})
export class ZipCodeModule {}
