export const subHeaderFeatureKey = 'subheader';

export enum ButtonType {
  Primary = 'Primary',
  Light = 'Light',
}
export class SubHeaderButton {
  title: string;
  actionName: string;
  route: string;
  type: ButtonType;
}
export class SubHeaderOption {
  id: number;
  title: string;
  buttons: SubHeaderButton[];
  searchView: boolean;
  breadcrumb: boolean;
}

export enum SubHeaderType {
  Home,
  Lead,
  LeadDetail,
  Contract,
  ContractDetail,
  ContractCreate,
  ContractRequest,
  ContractRequestDetail,
  ContractRequestCreate,
  ZipCode,
  ZipCodeDetail,
  County,
  CountyDetail,
  User,
  UserDetail,
  UserCreate,
  UserEdit,
  UserRolesEdit,
  Login,
  Article,
  ArticleDetail,
  ArticleCreate,
  ArticleEdit,
  Glossary,
  GlossaryDetail,
  GlossaryCreate,
  GlossaryEdit,
  Currency,
  CurrencyDetail,
  CurrencyCreate,
  CurrencyEdit,
  Country,
  CountryDetail,
  CountryCreate,
  CountryEdit,
  Job,
  JobDetail,
  JobCreate,
  JobEdit,
  SalesTeam,
  Campaign,
  SalesDashboard,
  HumanResourcesDashboard,
  Employee,
  SalesTeamsDetail,
  EmployeeDetail,
  PasswordManage,
  LeadEdit,
  LeadCreate,
  Contact,
  Title,
  Bank,
  BankAccount
}
