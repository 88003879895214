import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InsuranceCoverageApiService } from './insurance-coverage.api.service';
import { InsuranceCoverageEffects } from './insurance-coverage.effects';
import {
  IInsuranceCoverageState,
  insuranceCoverageInitialState,
  insuranceCoverageReducer,
} from './insurance-coverage.reducer';
import { InsuranceCoverageService } from './insurance-coverage.service';
import { insuranceCoverageFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IInsuranceCoverageState>(
      insuranceCoverageFeatureKey,
      insuranceCoverageReducer,
      {
        initialState: insuranceCoverageInitialState,
      }
    ),
    EffectsModule.forFeature([InsuranceCoverageEffects]),
  ],
  providers: [InsuranceCoverageApiService, InsuranceCoverageService],
})
export class StoreInsuranceCoverageModule {}
