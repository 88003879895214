export const quoteFeatureKey = 'quote';

export class QuoteModel {
  id: number;
  leadId: number;
  productId: number;
  productName: string;
  productProductLineName: string;
  productProductLineInsuranceCompanyContactName: string;
  monthlyCost: number;
  applicationFees: number;
  quoteSource: number;
  isBestValue: boolean;
}

export class QuoteResponseModel {
  id: number;
  leadId: number;
  productId: number;
  productName: string;
  productLineName: string;
  insuranceCompanyName: string;
  monthlyCost: number;
  applicationFees: number;
  quoteSource: number;
  isBestValue: boolean;
  routineCareCoverageLevel: number;
  hospitalizationCoverageLevel: number;
  dentalCoverageLevel: number;
  opticalCoverageLevel: number;
}
