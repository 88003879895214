import { createApiCallActions } from '../../../common/store/generic.actions';
import { ProductLineModel } from './types';

export const productLineActions = {
  getById: createApiCallActions<ProductLineModel>('productLine', 'getById'),
  createInstance: createApiCallActions<ProductLineModel>(
    'productLine',
    'createInstance'
  ),
  add: createApiCallActions<ProductLineModel>('productLine', 'add'),
  update: createApiCallActions<ProductLineModel>('productLine', 'update'),
};

export type ProductLineAction = typeof productLineActions;
