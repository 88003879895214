<div
  class="tab-pane fade flex-even active show"
  id="contacts"
  role="tabpanel"
  aria-labelledby="contacts-tab"
  *ngIf="this.payment$ | async as accountMoves"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="ps-3">
          <button
            type="button"
            [class]="ButtonType.Primary | buttontypepipe"
            class="btn-sm btn"
            translate
            (click)="
              openModalCreatePayment(this.contactId)
            "
          >
            CONTACT_LBL_PAYMENT_ADD
          </button>
          <button
            type="button"
            [class]="ButtonType.Primary | buttontypepipe"
            class="btn-sm btn"
            translate
            (click)="openModalSlipContent(this.contactId)"
          >
            CONTACT_LBL_IMPORT_SLIP
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <table class="table table-striped border-bottom">
        <thead>
        <tr>
          <th>
            <a translate>PAYMENT_LBL_DATE</a>
          </th>
          <th>
            <a translate>PAYMENT_LBL_MODE</a>
          </th>
          <th>
            <a translate>PAYMENT_LBL_AMOUNT</a>
          </th>
          <th>
            <a translate>PAYMENT_LBL_PROOF</a>
          </th>
          <th>
            <a translate>PAYMENT_LBL_STATUS</a>
          </th>
          <th>
            <a translate>PAYMENT_LBL_ACTIONS</a>
          </th>
          <th>
            <a translate>PAYMENT_LBL_ACCOUNT_MOVE_SLIP_BINDED</a>
          </th>
        </tr>
        </thead>

        <tbody>
        <ng-container
          *ngFor="let accountMove of accountMoves; trackBy: trackByAccountMoveId"
          class="row-action"
        >
          <tr *ngFor="let accountMoveLine of accountMove.accountMoveLines; trackBy: trackByAccountMoveLineId">
            <td>{{ accountMoveLine.creationDate | date : "dd/MM/yyyy" }}</td>
            <td>{{ accountMoveLine.paymentModeLoc }}</td>
            <td>{{ accountMoveLine.unitPrice | currency : "EUR" }}</td>
            <td>
              <ng-container *ngIf="!accountMoveLine.documentId">
                <app-generic-file-input [format]="'link'" [isValidationButton]="true"
                                        (validationEmitter)="onValidationEmitterUploadFile($event, accountMoveLine.id, this.contactId)"/>
              </ng-container>
              <ng-container *ngIf="accountMoveLine.documentId">
                <a
                  role="button"
                  (click)="onClickShowDocument(accountMoveLine.documentId)"
                  translate
                >PAYMENT_LBL_SHOW_DOCUMENT</a>
                <!--                <ng-container *ngIf="accountMoveLine.paymentMode === 'Cash'">-->
                <!--                 | <app-generic-file-input [format]="'link'" [isValidationButton]="true" (validationEmitter)="onValidationEmitterUploadFile($event, accountMoveLine.id, contactDetail.id)" />-->
                <!--                </ng-container>-->
              </ng-container>
            </td>
            <td>{{ accountMove.statusLoc }}</td>
            <td>
              <ng-container *ngFor="let action of accountMove.actions; let last = last;">
                <a role='button'
                   (click)="this.triggerAction(action.name, accountMove.id, this.contactId)"
                   translate>{{ action.actionName }}{{ last ? '' : ' | ' }}</a>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="accountMove.issuingAccountMoves.length == 0 && accountMove.status != 'Cancel'">
                <a
                  role='button'
                  (click)="
                      openModalBindAccountMoves(
                        this.contactId,
                        accountMove.id
                      )
                    "
                  translate
                >PAYMENT_LBL_BIND_ACCOUNT_MOVE_SLIP</a
                >
              </ng-container>
              <ng-container *ngIf="accountMove.issuingAccountMoves.length > 0">
                  <a
                    role='button'
                    (click)="openModalBindAccountMovesDetails(this.contactId ,accountMove, 'Slip')"
                    translate>{{ accountMove.issuingAccountMoves | showJournalAndTruncate: [50] }}</a>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
