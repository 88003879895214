import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { CommentModel, InputByEntity } from './types';
import { commentActions } from './comment.action';
import { commentSelectors } from './comment.selector';

@Injectable()
//implements IGridDataService
export class CommentService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getByEntityId: GenericApiCall<CommentModel[], InputByEntity> =
    this.genericApiCall(
      commentActions.getByEntityId,
      commentSelectors.getByEntityId,
      true,
    );

  postComment: GenericApiCall<CommentModel, CommentModel> = this.genericApiCall(
    commentActions.postComment,
    commentSelectors.postComment,
    true,
  );
}
