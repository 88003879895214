import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {
  onApiCall
} from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { InvoiceModel } from './types';
import { invoiceActions } from './invoice.actions';

export interface IInvoiceState extends IState<InvoiceModel> {
  getById: GenericState<InvoiceModel>;
}

export const invoiceInitialState: IInvoiceState = {
  getById: new GenericState<InvoiceModel>(),
};

const invoiceReducers = [
  ...onApiCall<InvoiceModel>(invoiceActions.getById, 'getById'),
];

export const invoiceReducer = createReducer(
  invoiceInitialState,
  ...invoiceReducers
) as ActionReducer<IInvoiceState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return invoiceReducer(state, action);
}