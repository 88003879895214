import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CrmRoutingModule } from './crm-routing.module';
import { NgModule } from '@angular/core';
import { LeadModule } from './lead/lead.module';
import { ContractModule } from './contract/contract.module';
import { CampaignModule } from './campaign/campaign.module';
import { SalesDashboardModule } from './dashboard/sale-dashboard-module';
import { SalesTeamModule } from './sales-team/sales-team.module';
import { ActivityModule } from './activity/activity.module';
import { ActivityTypeModule } from './activity-type/activity-type.module';
import { LostReasonModule } from './lost-reason/lost-reason.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    CrmRoutingModule,
    LeadModule,
    ContractModule,
    CampaignModule,
    SalesDashboardModule,
    SalesTeamModule,
    ActivityModule,
    ActivityTypeModule,
    LostReasonModule,
  ],
  exports: [],
})
export class CrmModule {}
