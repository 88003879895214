import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InvoiceLineApiService } from './invoice-line.api.service';
import { InvoiceLineEffects } from './invoice-line.effects';
import {
  IInvoiceLineState,
  invoiceLineInitialState,
  invoiceLineReducer,
} from './invoice-line.reducer';
import { InvoiceLineService } from './invoice-line.service';
import { invoiceLineFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IInvoiceLineState>(
      invoiceLineFeatureKey,
      invoiceLineReducer,
      {
        initialState: invoiceLineInitialState,
      }
    ),
    EffectsModule.forFeature([InvoiceLineEffects]),
  ],
  providers: [InvoiceLineApiService, InvoiceLineService],
})
export class StoreInvoiceLineModule {}
