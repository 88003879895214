<div
  class="tab-pane fade flex-even active show"
  id="insuranceDocuments"
  role="tabpanel"
  aria-labelledby="insuranceDocuments-tab"
  *ngIf="this.productLine$ | async as productLine"
>
  <div
    class="d-flex flex-column h-100"
    *ngIf="this.productLine.products.length != 0"
  >
    <div class="row mt-2">
      <table class="table table-striped border-bottom">
        <thead>
          <tr>
            <th>
              <a translate>PRODUCT_LBL_NAME</a>
            </th>
            <th class="text-center">
              <a translate>PRODUCT_LBL_ACTIVE</a>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let product of productLine.products" class="row-action">
            <td>{{ product.name }}</td>
            <td class="text-center">
              <app-badge
                [message]="'PRODUCT_LBL_ACTIVE'"
                [color]="'light-success'"
                *ngIf="product.isActive; else elseBlock"
              />
            </td>
            <!-- <td>{{ product.isActive }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #elseBlock
  ><app-badge [message]="'PRODUCT_LBL_INACTIVE'" [color]="'light-danger'"
/></ng-template>
