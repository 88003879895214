import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { titleActions } from './title.action';
import { titleSelectors } from './title.selector';
import { Title } from './types';

@Injectable()
export class TitleService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<Title, string> = this.genericApiCall(
    titleActions.getById,
    titleSelectors.getById,
    true,
  );

  postTitle: GenericApiCall<any, Title> = this.genericApiCall(
    titleActions.postTitle,
    titleSelectors.postTitle,
    true,
  );

  updateTitle: GenericApiCall<any, Title> = this.genericApiCall(
    titleActions.updateTitle,
    titleSelectors.updateTitle,
    true,
  );
}
