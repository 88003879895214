import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { InvoiceModel } from 'src/app/shared/store/invoice/types';
import { InvoiceService } from 'src/app/shared/store/invoice/invoice.service';
import {
  ColDef,
  GridOptions,
  GridSizeChangedEvent,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { InvoiceLineService } from 'src/app/shared/store/invoice-line/invoice-line.service';
import { InvoiceLineModel } from 'src/app/shared/store/invoice-line/types';

@UntilDestroy()
@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
  invoiceId: number;
  invoice$: Observable<InvoiceModel>;
  view$: Observable<View>;
  colDefs: ColDef[];
  config: GridOptions = {};
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly invoiceService: InvoiceService,
    private readonly invoiceLineService: InvoiceLineService
  ) {
    this.invoice$ = this.invoiceService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.invoiceId = this.route.snapshot.params['id'];
    this.invoiceService.getById.call(this.invoiceId.toString());

    this.invoice$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@invoiceDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'invoiceEdit') {
          this.router.navigate(['Insurance/Invoices/Edit/', this.invoiceId]);
          this.subHeaderService.setTriggerAction('');
        }
      });

    this.config.rowSelection = 'single';
    this.config.suppressCellFocus = true;
    this.config.headerHeight = 37.2;

    this.colDefs = [
      {
        field: 'name',
        headerName: 'Nom',
        suppressMovable: true,
        resizable: false,
      },
      // {
      //   field: 'amount',
      //   headerName: 'Montant',
      //   suppressMovable: true,
      //   resizable: false,
      //   editable: true,
      // },
      {
        headerName: 'Montant',
        editable: true,
        valueGetter: (params: ValueGetterParams) => {
          return params.data.amount;
        },
        valueSetter: (params: ValueSetterParams) => {
          let invoiceLine: InvoiceLineModel = {
            id: params.data.id,
            invoiceId: params.data.invoiceId,
            name: params.data.name,
            amount: params.newValue,
            amountTaxIncluded: params.data.amountTaxIncluded,
          };
          this.gridValueEdit(invoiceLine);
          return true;
        },
        suppressMovable: true,
        resizable: false,
      },
      {
        field: 'amountTaxIncluded',
        headerName: 'Montant TTC',
        suppressMovable: true,
        resizable: false,
      },
    ];
  }

  ngOnDestroy(): void {
    this.invoiceService.getById.reset();
    this.breadcrumbService.set('@invoiceDetails', ' ');
  }
  onGridSizeChanged($event: GridSizeChangedEvent<any>) {
    this.agGrid.api.sizeColumnsToFit();
  }
  gridValueEdit(data: InvoiceLineModel) {
    this.invoiceLineService.updateById.call(data);
  }
}
