import { Component, Input, OnInit } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { Action, View } from '../../../../../shared/store/view/types';
import {
  InsuranceCategory,
  LeadDetailModel,
  LeadStatusFlow,
} from '../../../../../shared/store/lead/types';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { LeadService } from '../../../../../shared/store/lead/lead.service';
import { ModalService } from '../../../../../shared/service/modal.service';
import { ModalContentComponent } from '../../../../../shared/components/modal-content/modal-content.component';
import { SourceEnum } from '../../../../../shared/store/typeahead/types';
import { environment } from '../../../../../../environments/environment';
import { ActionButton } from '../../../../../shared/components/modal-content/action-button';
import { toFirstLetterLower } from '../../../../../shared/helper/stringhelper';

@UntilDestroy()
@Component({
  selector: 'app-custom-actions-flow-lead',
  templateUrl: './custom-actions-flow-lead.component.html',
  styleUrls: ['./custom-actions-flow-lead.component.scss'],
})
export class CustomActionsFlowLeadComponent implements OnInit {
  protected readonly InsuranceCategory = InsuranceCategory;
  comparanooUrl: string;
  view$: Observable<View>;
  customActions$: Observable<Action[]>;
  leadDetail$: Observable<LeadDetailModel>;
  leadStatusFlows$: Observable<LeadStatusFlow[]>;
  leadId: number;
  productCategoryId: number;

  constructor(
    private readonly router: Router,
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
    private readonly leadService: LeadService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.leadDetail$ = this.leadService.getByUniqueId.value$;
    this.leadStatusFlows$ = this.leadService.getStatusFlow.value$;
    this.customActions$ = this.leadService.getStatusActions.value$;

    this.comparanooUrl = environment.comparanoourl;

    this.leadDetail$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((lead) => {
        this.leadService.getStatusFlow.call(lead.leadStatus);
        this.leadService.getStatusActions.call(lead.leadStatus);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'Qualify') this.qualify();
        if (x == 'MarkAsWon') this.markAsWon();
        if (x == 'CreateContract') this.createContract();
        if (x == 'MarkAsLostModal') this.markAsLost();
        if (x == 'Reactivate') this.reactivate();

        this.subHeaderService.setTriggerAction('');
      });
  }
  triggerStatusFlow(
    actionName: string,
    leadId: number,
    productCategoryId?: number,
  ) {
    this.leadId = leadId;
    if (productCategoryId) this.productCategoryId = productCategoryId;
    this.subHeaderService.setTriggerAction(actionName);
  }

  qualify() {
    this.leadService.qualify.call(this.leadId);
  }
  markAsWon() {
    this.leadService.markAsWon.call(this.leadId);
  }
  createContract() {
    this.router.navigate([
      '/Crm/ContractRequests/Create/',
      this.productCategoryId,
      this.leadId,
    ]);
  }
  markAsLost() {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'LeadLostReasonContentComponent',
      );
    modalRef.componentInstance.inputParams = {
      leadId: this.leadId,
      source: SourceEnum.Modal,
    };
    modalRef.componentInstance.title = 'Marquer comme perdu';

    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'MarkAsLost',
      },
    ];
  }
  reactivate() {
    this.leadService.reactivate.call(this.leadId);
  }
  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
