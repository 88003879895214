import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { Entity } from 'src/app/shared/store/view/types';
import { DropdownStyle } from '../../../../../../shared/store/typeahead/types';

@Component({
  selector: 'app-contact-form-marketing',
  templateUrl: './contact-form-marketing.component.html',
  styleUrls: ['./contact-form-marketing.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContactFormMarketingComponent implements OnInit {
  createContactFormModel$: Observable<unknown>;
  form: FormGroup;
  protected readonly Entity = Entity;
  constructor(
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {
    // this.createContractFormModel$ =
    //   this.contractService.getContractModel.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl(
      'marketingFormGroup',
      this.formBuilder.group({
        marketingCampaignId: [undefined],
        marketingMedium: [undefined],
        marketingSource: [undefined],
        marketingCreationDate: [''],
        salesTeamId: [undefined],
        salesPersonId: [undefined],
      }),
    );

    // this.createContractFormModel$.pipe(filter((x) => !!x)).subscribe((x) => {
    //   this.form.patchValue({
    //     marketingFormGroup: {
    //       marketingSource: x.contractFormModel.source,
    //     },
    //   });
    // });
  }

  protected readonly DropdownStyle = DropdownStyle;
}
