import { Component } from '@angular/core';

@Component({
  selector: 'app-sales-team-members-reporting',
  templateUrl: './sales-team-members-reporting.component.html',
  styleUrls: ['./sales-team-members-reporting.component.scss']
})
export class SalesTeamMembersReportingComponent {

}
